import React from 'react';
import { number, string } from 'prop-types';

import { dimensions } from '../../../../../constants';

const StickerPosition = ({ position1, position2, yOffset }) => (
  <>
    <text
      fontFamily="Gilroy"
      fontWeight="normal"
      fontSize={2.1}
      fill="black"
      fillOpacity={1}
      x={3}
      y={dimensions.stickerHeight - 2.6 + yOffset}
    >
      {position1}
    </text>
    {position2 && (
      <text
        fontFamily="Gilroy"
        fontWeight="normal"
        fontSize={2.1}
        fill="black"
        fillOpacity={1}
        x={3}
        y={dimensions.stickerHeight - 1.6}
      >
        {position2}
      </text>
    )}
  </>
);

StickerPosition.defaultProps = {
  position1: '',
  position2: '',
};

StickerPosition.propTypes = {
  position1: string,
  position2: string,
  yOffset: number.isRequired,
};

export default StickerPosition;
