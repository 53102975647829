import { bool, func } from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  defaultCustomColor,
  defaultStickerColor,
  stickerColorIndex,
} from '../../../constants';
import { updateColor } from '../../../modules/colors';
import { selectColors } from '../../../selectors/colors';
import { ColorShape } from '../../shapes';
import ColorInput from './ColorInput';

const swatchTypes = {
  custom: 'custom',
  empty: 'empty',
  sticker: 'sticker',
};

const parseColor = color => {
  if (color === stickerColorIndex) {
    return swatchTypes.sticker;
  }

  if (typeof color === 'number') {
    return color;
  }

  if (typeof color === 'string') {
    return swatchTypes.custom;
  }

  return swatchTypes.empty;
};

function SwatchColorPicker({
  onChange,
  color,
  allowCustom,
  allowStickerColor,
  allowNull,
}) {
  const selectedInputId = parseColor(color);
  const [lastCustomColorValue, setLastCustomColorValue] = useState(
    selectedInputId === swatchTypes.custom ? color : defaultCustomColor
  );

  const colors = useSelector(selectColors);
  const dispatch = useDispatch();

  const makeHandleChangeSwatch = colorIndex => ({ color: colorValue }) => {
    dispatch(updateColor(colorIndex, colorValue));
    onChange(colorIndex);
  };

  return (
    <div className="qa-swatch-color-picker">
      {allowCustom && (
        <ColorInput
          icon="edit"
          className={`qa-swatch-${swatchTypes.custom}`}
          selected={selectedInputId === swatchTypes.custom}
          colorValue={
            selectedInputId === swatchTypes.custom
              ? color
              : lastCustomColorValue
          }
          onChange={({ color: colorValue }) => {
            setLastCustomColorValue(colorValue);
            onChange(colorValue);
          }}
          onSelect={() => onChange(lastCustomColorValue)}
        />
      )}
      {allowNull && (
        <ColorInput
          icon="lock"
          editable={false}
          className={`qa-swatch-${swatchTypes.empty}`}
          selected={selectedInputId === swatchTypes.empty}
          onSelect={() => onChange(null)}
          colorValue={null}
        />
      )}
      {colors.map((colorValue, colorIndex) => {
        // colors #0 (black) and #1 (white) are fixed and not editable
        const editable = colorIndex > 1;

        const key = `swatch-${colorIndex}`;
        return (
          <ColorInput
            key={key}
            icon={editable ? 'linkify' : 'lock'}
            editable={editable}
            className={`qa-swatch-color-option qa-swatch-${colorIndex}`}
            selected={selectedInputId === colorIndex}
            colorValue={colorValue}
            onChange={makeHandleChangeSwatch(colorIndex)}
            onSelect={() => onChange(colorIndex)}
          />
        );
      })}
      {allowStickerColor && (
        <ColorInput
          icon="user"
          editable={false}
          className={`qa-swatch-${swatchTypes.sticker}`}
          selected={selectedInputId === swatchTypes.sticker}
          colorValue={defaultStickerColor}
          onSelect={() => onChange(stickerColorIndex)}
        />
      )}
    </div>
  );
}

SwatchColorPicker.defaultProps = {
  allowCustom: true,
  allowNull: true,
  allowStickerColor: true,
  color: defaultCustomColor,
};

SwatchColorPicker.propTypes = {
  allowCustom: bool,
  allowNull: bool,
  allowStickerColor: bool,
  color: ColorShape,
  onChange: func.isRequired,
};

export default SwatchColorPicker;
