import { node, string } from 'prop-types';
import React from 'react';

import { dimensions } from '../../../../constants';
import { resolveImage } from '../../../../util/images';
import {
  ImageContextShape,
  ImageObjectShape,
  StickerShape,
} from '../../../shapes';
import GuideRect from '../GuideRect';

function StickerImageSingle({
  sticker,
  imageObject,
  filterDefinitions,
  imageContext,
  clipPath,
}) {
  const hasFilterDefinitions = !!filterDefinitions;

  let src;
  let boundingbox = false;
  let transform = null;
  let actualWidth;
  let actualHeight;

  if (imageObject) {
    const { meta } = imageObject;
    ({ src } = resolveImage(imageObject, imageContext.resolution));
    let angle = 0;

    let orientationcorrection = false;
    if (sticker.face) {
      boundingbox = sticker.face.boundingbox;
      orientationcorrection = sticker.face.orientationcorrection;
      if (orientationcorrection) {
        angle = parseInt(orientationcorrection.split('ROTATE_').join(''), 10);
      }
    } else if (meta.width > meta.height) {
      // correct 90° rotations
      angle = 90;
    }

    // actualWidth / actualHeight: actual image dimensions fitted into a box of 50x70
    let scale;
    if (angle === 90 || angle === 270) {
      scale = Math.min(
        dimensions.stickerWidth / meta.width,
        dimensions.stickerHeight / meta.height
      );
      // flipped dimensions!
      actualHeight = meta.width * scale;
      actualWidth = meta.height * scale;
    } else {
      scale = Math.min(
        dimensions.stickerWidth / meta.width,
        dimensions.stickerHeight / meta.height
      );
      actualWidth = meta.width * scale;
      actualHeight = meta.height * scale;
    }

    // correct angle
    if (angle) {
      let rx = 0;
      let ry = 0;
      switch (angle) {
        default:
          break;
        case 90:
          rx = actualHeight;
          break;
        case 180:
          rx = actualWidth;
          ry = actualHeight;
          break;
        case 270:
          ry = actualWidth;
          break;
      }
      transform = `translate(${rx} ${ry}) rotate(${angle})`;
    }
  }

  return (
    <g>
      <g clipPath={clipPath}>
        <rect
          x={0.5}
          y={0.5}
          width={dimensions.stickerWidth - 1}
          height={dimensions.stickerHeight - 1}
          fill="#fff"
        />

        {imageObject && (
          <g
            transform={`translate(${sticker.cx ?? 0} ${sticker.cy ??
              0}) scale(${sticker.cscale ?? 1}) rotate(${sticker.crotation ??
              0})`}
            className="inside"
            data-id={sticker.id}
          >
            <g>
              <g transform={transform}>
                {hasFilterDefinitions && <defs>{filterDefinitions}</defs>}
                <image
                  xlinkHref={src}
                  width={actualWidth}
                  height={actualHeight}
                  preserveAspectRatio="xMidYMid"
                  filter={
                    hasFilterDefinitions ? `url(#filter-${sticker.id})` : ''
                  }
                />

                {boundingbox && (
                  <GuideRect
                    x={boundingbox.left * actualWidth}
                    y={boundingbox.top * actualHeight}
                    width={boundingbox.width * actualWidth}
                    height={boundingbox.height * actualHeight}
                    opacity={0.3}
                  />
                )}
              </g>
            </g>
          </g>
        )}
      </g>
    </g>
  );
}

StickerImageSingle.defaultProps = {
  filterDefinitions: null,
  imageObject: null,
};

StickerImageSingle.propTypes = {
  sticker: StickerShape.isRequired,
  imageContext: ImageContextShape.isRequired,
  imageObject: ImageObjectShape,
  filterDefinitions: node,
  clipPath: string.isRequired,
};

export default StickerImageSingle;
