import { createContext } from 'react';

const context = createContext({
  viewportRef: { current: null },
  clientToViewportCoordinates: () => ({ x: 0, y: 0 }),
});

context.displayName = 'ViewportContext';

export default context;
