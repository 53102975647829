import React from 'react';
import { Menu } from 'semantic-ui-react';

import ControlsOptions from './ControlsOptions';
import { assetPreviewSizeLabels } from '../../constants';

const ImageMenu = () => {
  return (
    <Menu vertical secondary className="qa-image-list-menu">
      <Menu.Item className="header">Ansicht</Menu.Item>

      <ControlsOptions
        options={assetPreviewSizeLabels}
        controlsStoreKey="imagePreviewSize"
      />
    </Menu>
  );
};

export default ImageMenu;
