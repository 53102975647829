import { GraphQLClient } from 'graphql-request';

import { APP_API_URL } from '../constants';

export const GET_PROJECTS_QUERY = `
  query {
    projectsConnection {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * Fetches projects data from app GQL api.
 */
const fetchProjects = token => {
  const client = new GraphQLClient(`${APP_API_URL}?token=${token}`);

  return new Promise((resolve, reject) => {
    client
      .request(GET_PROJECTS_QUERY)
      .then(({ projectsConnection: { edges } }) => {
        const projects = edges.map(({ node }) => node);
        resolve(projects);
      })
      .catch(err => reject(err));
  });
};

export default fetchProjects;
