import { func, string } from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';
import { List } from 'semantic-ui-react';

import { itemTypes } from '../../constants';
import { emptyRawContentState } from '../../util/draftjs';

function TextItem({ connectDragSource, detail, label, symbol }) {
  return connectDragSource(
    <div
      className={`
        text-item 
        qa-create-symbol-${symbol}
        `}
    >
      <List.Content>
        <List.Header>{label}</List.Header>
        {detail}
      </List.Content>
    </div>
  );
}

TextItem.propTypes = {
  connectDragSource: func.isRequired,
  label: string.isRequired,
  detail: string.isRequired,
  symbol: string.isRequired,
};

const elementSource = {
  beginDrag(props) {
    const element = {
      type: 'Text',
      props: {
        width: 100,
        height: 20,
        rotation: 0,
        symbol: props.symbol,
        text: emptyRawContentState,
      },
    };
    return {
      element,
    };
  },
};

const collect = connect => ({
  connectDragSource: connect.dragSource(),
});

export default DragSource(itemTypes.element, elementSource, collect)(TextItem);
