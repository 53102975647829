import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  List,
  Dimmer,
  Loader,
  Progress,
  Button,
  Icon,
} from 'semantic-ui-react';
import moment from 'moment';

import { API_URL } from '../../../constants';
import { secsToDuration } from '../JobsPanel';

const iconFor = (state, response) => {
  let icon;
  switch (state) {
    default:
    case 'queued':
      icon = 'hourglass half';
      break;
    case 'finished':
      icon = response?.length > 0 ? 'exclamation' : 'check';
      break;
    case 'exists':
      icon = 'save';
      break;
    case 'working':
      icon = 'sync';
      break;
    case 'error':
      icon = 'dont';
      break;
  }
  return icon;
};

const RunningJobs = () => {
  const [jobs, setJobs] = useState(null);
  const pollRef = useRef(null);

  const fetchJobs = () => {
    axios
      .get(`${API_URL}/jobs?task=import`)
      .then(({ data: { jobs: jobsFromServer } }) => setJobs(jobsFromServer));
  };

  const deleteJob = id => {
    axios.delete(`${API_URL}/jobs/${id}`);
  };

  useEffect(() => {
    pollRef.current = setInterval(fetchJobs, 2000);

    return () => clearInterval(pollRef.current);
  }, []);

  return (
    <div>
      <div style={{ height: '400px', overflow: 'auto', padding: '1rem 0' }}>
        {!jobs && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}

        {jobs && (
          <List divided relaxed>
            {jobs.length === 0 && <p>Keine laufenden Imports</p>}
            {jobs.map(
              ({
                id,
                album_title,
                created_at,
                progress,
                eta,
                state,
                response,
              }) => (
                <List.Item as="a" key={id}>
                  <List.Icon name={iconFor(state, response)} />
                  <List.Content>
                    <List.Header className="flex">
                      {album_title}
                      {(state === 'queued' || state === 'working') && (
                        <div style={{ display: 'inline-flex' }}>
                          <Button icon compact onClick={() => deleteJob(id)}>
                            <Icon name="delete" />
                          </Button>
                        </div>
                      )}
                    </List.Header>
                    <List.Description>
                      <div>{moment(created_at).fromNow()}</div>
                      {state === 'working' && progress && (
                        <Progress
                          className="flex"
                          active
                          indicating
                          progress
                          percent={progress}
                          label={secsToDuration(eta)}
                        />
                      )}
                      {response?.length > 0 && (
                        <ul>
                          {response.map(message => (
                            <li>{message}</li>
                          ))}
                        </ul>
                      )}
                    </List.Description>
                  </List.Content>
                </List.Item>
              )
            )}
          </List>
        )}
      </div>
    </div>
  );
};

export default RunningJobs;
