import React from 'react';
import { number } from 'prop-types';

const SpreadHighlight = props => {
  const { height, width, spineLeft, strokeWidth, opacity } = props;

  return (
    <rect
      className="qa-spread-highlight"
      x={-strokeWidth / 2 - spineLeft}
      y={-strokeWidth / 2}
      width={width + strokeWidth + spineLeft}
      height={height + strokeWidth}
      stroke="#a1f6ff"
      fill="none"
      strokeWidth={strokeWidth}
      opacity={opacity}
    />
  );
};

SpreadHighlight.defaultProps = {
  opacity: 1,
};

SpreadHighlight.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
  spineLeft: number.isRequired,
  strokeWidth: number.isRequired,
  opacity: number,
};

export default SpreadHighlight;
