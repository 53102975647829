import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tab, Menu, Icon } from 'semantic-ui-react';

import { getImageUsage } from '../../selectors/legacy';
import BuilderPanel from './BuilderPanel/index.container';
import ElementsPanel from './ElementsPanel';
import GlobalNavigation from './GlobalNavigation';
import ImagesPanel from './ImagesPanel/index.container';
import JobsPanel from './JobsPanel';
import PropertiesPanel from './PropertiesPanel';
import SectionPanel from './SectionPanel';
import TemplatePanel from './TemplatePanel';
import { updateControls } from '../../modules/controls';
import ChecksPanel from './ChecksPanel';

const MenuItem = (title, icon, slug = '') => (
  <Menu.Item key={title} className={`qa-tab-${slug}`}>
    <Icon name={icon} />
    <span className="title">{title}</span>
  </Menu.Item>
);

const TabPane = children => (
  <Tab.Pane className="flex grow">
    <GlobalNavigation editor={this} />
    {children}
  </Tab.Pane>
);

const grid = { paneWidth: 15, tabWidth: 1 };

const tabMenuProps = { fluid: true, vertical: true, tabular: true };

const filterStickerImages = images =>
  images.filter(item => item.model !== 'sticker');

const Sidebar = props => {
  const { activeTab, images, imageUsage, stock, dispatch } = props;

  const imagesWithoutStickerImages = useMemo(
    () => filterStickerImages(images),
    [images]
  );

  const onTabChange = useCallback(
    () => (e, { activeIndex }) =>
      dispatch(updateControls({ activeTab: activeIndex })),
    [dispatch]
  );

  const panes = useMemo(
    () => [
      {
        key: 0,
        menuItem: MenuItem('Sticker', 'group', 'section'),
        render: () => TabPane(<SectionPanel />),
      },
      {
        key: 1,
        menuItem: MenuItem('Lokale Bilder', 'image', 'image'),
        render: () =>
          TabPane(
            <ImagesPanel
              images={imagesWithoutStickerImages}
              imageUsage={imageUsage}
              isStock={false}
            />
          ),
      },
      {
        key: 2,
        menuItem: MenuItem('Stock Bibliothek', 'images', 'stock'),
        render: () =>
          TabPane(
            <ImagesPanel images={stock} imageUsage={imageUsage} isStock />
          ),
      },
      {
        key: 3,
        menuItem: MenuItem('Templates', 'file image outline', 'template'),
        render: () => TabPane(<TemplatePanel />),
      },
      {
        key: 4,
        menuItem: MenuItem('Elemente', 'plus square', 'elements'),
        render: () => TabPane(<ElementsPanel />),
      },
      {
        key: 5,
        menuItem: MenuItem('Eigen- schaften', 'edit', 'properties'),
        render: () => TabPane(<PropertiesPanel />),
      },
      {
        key: 6,
        menuItem: MenuItem('Builder', 'sitemap', 'builder'),
        render: () => TabPane(<BuilderPanel />),
      },
      {
        key: 7,
        menuItem: MenuItem('Export', 'download', 'jobs'),
        render: () => TabPane(<JobsPanel />),
      },
      {
        key: 8,
        menuItem: MenuItem('Checks', 'comment', 'comments'),
        render: () => TabPane(<ChecksPanel />),
      },
    ],
    [imageUsage, stock, imagesWithoutStickerImages]
  );

  return (
    <Tab
      className="tabs qa-tab-navigation"
      grid={grid}
      menu={tabMenuProps}
      defaultActiveIndex={activeTab}
      renderActiveOnly
      panes={panes}
      onTabChange={onTabChange}
    />
  );
};

Sidebar.propTypes = {
  activeTab: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  imageUsage: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  stock: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  images: state.images,
  stock: state.stock,
  imageUsage: getImageUsage(state),
  activeTab: state.controls.activeTab,
});

export default memo(connect(mapStateToProps)(Sidebar));
