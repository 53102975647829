import {
  bool,
  func,
  node,
  number,
  number as numberType,
  string,
} from 'prop-types';
import React, { memo, useContext, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  colorValues,
  defaultStickerColor,
  dimensions,
  itemTypes,
  templateTypes,
} from '../../../../constants';
import { showContextMenu } from '../../../../modules/menu';
import { selectResolvedColor } from '../../../../selectors/colors';
import { getImage } from '../../../../selectors/images';
import {
  getSelectedStickerIds,
  getStickersById,
} from '../../../../selectors/legacy';
import { getStickerSelectionActive } from '../../../../selectors/selection';
import {
  selectStickerNumberById,
  selectStickerSectionNameById,
} from '../../../../selectors/stickers';
import { buildFilters } from '../../../../util/filters';
import { ImageContext } from '../../../ImageContext';
import { ColorShape, ImageObjectShape, StickerShape } from '../../../shapes';
import CustomStickerElement from './CustomStickerElement';
import getStickerLocalTexts from './getStickerLocalTexts';
import StickerFrameDouble from './LegacyClubSticker/StickerFrameDouble';
import StickerFrameSingle from './LegacyClubSticker/StickerFrameSingle';
import StickerCellDouble from './StickerCellDouble';
import StickerCellSingle from './StickerCellSingle';
import StickerImageDouble from './StickerImageDouble';
import StickerImageSingle from './StickerImageSingle';
import StickerLogos from './StickerLogos';
import StickerSubtitle from './StickerSubtitle';

/**
 * The fallback-component is used, when no sticker-template can be found or
 *  the "Standard"-Template is selected
 */
const stickerFrameComponentFallbacks = {
  [templateTypes.sticker]: StickerFrameSingle,
  [templateTypes.sticker_double]: StickerFrameDouble,
  [templateTypes.sticker_slot]: StickerCellSingle,
  [templateTypes.sticker_slot_double]: StickerCellDouble,
};

// Used to determine the image-component based on sticker-props
const stickerImageComponentMap = {
  single: StickerImageSingle,
  double: StickerImageDouble,
};

// Used to determine the template-type based on sticker-props
const templateTypeMap = {
  sticker: {
    single: templateTypes.sticker,
    double: templateTypes.sticker_double,
  },
  cell: {
    single: templateTypes.sticker_slot,
    double: templateTypes.sticker_slot_double,
  },
};

function Sticker(props) {
  const {
    sticker,
    stickerNumber,
    sectionName,
    isSelected,
    doubleStickerOffset,
    stickerSelectionActive,
    x,
    y,
    stickerAreaRotation,
  } = props;

  const dispatch = useDispatch();
  const imageContext = useContext(ImageContext);

  const handleContextMenu = event => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(showContextMenu(event, itemTypes.sticker, sticker.id));
  };

  let offsetX = 0;
  if (sticker.doubleSticker) {
    if (imageContext.stickerRendering) {
      offsetX = doubleStickerOffset;
    } else if (sticker.stickerPlacing === 'grid') {
      offsetX = 0;
    } else {
      offsetX = 2.5;
    }
  }

  // Determine templateType and fallbackComponent
  const showSticker = imageContext.previewSticker;
  const stickerOrCell = showSticker ? 'sticker' : 'cell';
  const singleOrDouble = sticker.doubleSticker ? 'double' : 'single';
  const templateType = templateTypeMap[stickerOrCell][singleOrDouble];
  const fallbackComponent = stickerFrameComponentFallbacks[templateType];

  // Determine sticker-image component
  const StickerImageComponent = stickerImageComponentMap[singleOrDouble];

  // "localTexts" are used by symbol-text components
  const hasSubtitle = sticker.subtitle && !showSticker;
  const localTexts = useMemo(
    () =>
      getStickerLocalTexts(sticker, hasSubtitle, stickerNumber, sectionName),
    [sticker, hasSubtitle, stickerNumber, sectionName]
  );

  const clipPath = `url(#clip-${
    sticker.doubleSticker ? 'doublesticker' : 'sticker'
  }${imageContext.stickerRendering ? '-render' : ''})`;

  const extendedProps = {
    ...props,
    imageContext,
    localTexts,
    stickerNumber,
  };

  return (
    <g
      key={sticker.id}
      transform={`translate(${x + offsetX},${y})`}
      onContextMenu={stickerSelectionActive ? handleContextMenu : null}
      className={`sticker-root qa-sticker-root qa-sticker-root-${sticker.id}`}
      data-id={sticker.id}
    >
      <rect
        className="box"
        width={dimensions.stickerWidth * (sticker.doubleSticker ? 2 : 1)}
        height={dimensions.stickerHeight}
        fill="none"
        stroke="none"
      />
      {showSticker && (
        <StickerImageComponent clipPath={clipPath} {...extendedProps} />
      )}
      <CustomStickerElement
        {...extendedProps}
        contentType={templateType}
        fallbackComponent={fallbackComponent}
        clipPath={clipPath}
      />
      {hasSubtitle && (
        <StickerSubtitle
          stickerId={sticker.id}
          doubleSticker={sticker.doubleSticker}
          text={sticker.name}
          stickerAreaRotation={stickerAreaRotation}
        />
      )}
      {showSticker && <StickerLogos {...extendedProps} />}

      {isSelected && (
        <rect
          x={-1}
          y={-1}
          fill="none"
          stroke={colorValues.selection}
          strokeWidth={2}
          strokeOpacity={1}
          strokeLinecap="square"
          width={
            (dimensions.stickerWidth + 1) * (sticker.doubleSticker ? 2 : 1) +
            (sticker.doubleSticker ? 0 : 1)
          }
          height={dimensions.stickerHeight + 2}
        />
      )}
    </g>
  );
}

Sticker.defaultProps = {
  doubleStickerOffset: 2.5,
  imageObject: null,
  logoObject: null,
  resolvedStickerColor: defaultStickerColor,
  filterDefinitions: null,
  stickerAreaRotation: 0,
};

Sticker.propTypes = {
  x: numberType.isRequired,
  y: numberType.isRequired,
  doubleStickerOffset: numberType,
  sticker: StickerShape.isRequired,
  stickerNumber: number.isRequired,
  sectionName: string.isRequired,
  imageObject: ImageObjectShape,
  logoObject: ImageObjectShape,
  resolvedStickerColor: ColorShape,
  isSelected: bool.isRequired,
  filterDefinitions: node,
  stickerSelectionActive: bool.isRequired,
  dispatch: func.isRequired,
  stickerAreaRotation: number,
};

const MemoSticker = memo(Sticker);

const mapStateToProps = (state, { stickerId }) => {
  const sticker = getStickersById(state)[stickerId];
  return {
    sticker,
    stickerNumber: selectStickerNumberById(state, stickerId),
    sectionName: selectStickerSectionNameById(state, stickerId),
    imageObject: getImage(state, sticker.image),
    logoObject: getImage(state, sticker.logo),
    stickerSelectionActive: getStickerSelectionActive(state),
    resolvedStickerColor: selectResolvedColor(state, sticker.fill),
    isSelected: getSelectedStickerIds(state).includes(stickerId),
    filterDefinitions:
      sticker.filter && state.controls.enableFilter
        ? buildFilters(sticker)
        : null,
  };
};

const ConnectedSticker = connect(mapStateToProps)(MemoSticker);

ConnectedSticker.propTypes = {
  stickerId: string.isRequired,
};

export default ConnectedSticker;
