import React from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import { uploadAndCreateImageObjects } from '../../../actions/images';
import { insertAndSelectElements } from '../../../actions/workspace';
import { itemTypes } from '../../../constants';
import useDispatchChain from '../../../hooks/useDispatchChain';
import useFileUpload from '../../../hooks/useFileUpload';
import { getGridEnabled } from '../../../selectors/controls';
import { getWorkspace } from '../../../selectors/legacy';
import { findTargetSpreadIdOnWorkspace } from '../../../util/generators';
import { satisfyGrid } from '../../../util/geometry';
import { createElementFromImageObject } from '../../../util/images';
import Point from '../../../util/Point';
import { uniquifyIds } from '../../../util/spreads';
import Workspace from './Workspace';

const createElements = ({ item, itemType, imageObjects = null }) => () => {
  if (itemType === itemTypes.image || imageObjects) {
    const elements = (imageObjects || [item]).map(createElementFromImageObject);
    return { elements };
  }

  if (itemType === itemTypes.template) {
    const elements = [item.json];
    return { elements };
  }

  if (itemType === itemTypes.element) {
    const elements = [item.element];
    return { elements };
  }

  throw new Error(`Unknown item type: ${itemType}`);
};

const findPositionOnWorkspace = ({ dropPoint }) => (_, getState) => {
  const state = getState();
  const workspace = getWorkspace(state);
  const { spreadId, positionOnSpread } = findTargetSpreadIdOnWorkspace(
    dropPoint,
    workspace
  );
  return { spreadId, positionOnSpread };
};

const applySatisfyGrid = ({ positionOnSpread }) => () => ({
  positionOnSpread: satisfyGrid(positionOnSpread),
});

const mergeDropPosition = ({ positionOnSpread, elements }) => () => ({
  elements: elements.map((element, index) => ({
    ...element,
    props: {
      ...element.props,
      x: positionOnSpread.x + index * 10,
      y: positionOnSpread.y + index * 10,
    },
  })),
});

const mergeTemplateId = ({ item, elements }) => () => ({
  elements: elements.map(element => ({
    ...element,
    props: {
      ...element.props,
      template: item.id,
    },
  })),
});

const applyUniquifyIds = ({ elements }) => () => ({
  elements: elements.map(uniquifyIds),
});

function WorkspaceWithDnD() {
  const gridEnabled = useSelector(getGridEnabled);
  const dispatchChain = useDispatchChain();
  const { upload } = useFileUpload();

  const [, drop] = useDrop({
    accept: [
      itemTypes.element,
      itemTypes.template,
      itemTypes.image,
      NativeTypes.FILE,
    ],

    drop: (item, monitor) => {
      const itemType = monitor.getItemType();
      const isFileUpload = itemType === NativeTypes.FILE;
      const isTemplate = itemType === itemTypes.template;
      const clientPos = monitor.getClientOffset();
      const dropPoint = new Point(clientPos.x, clientPos.y).screenToSvg();

      dispatchChain(
        [
          isFileUpload ? uploadAndCreateImageObjects : null,
          createElements,
          findPositionOnWorkspace,
          gridEnabled ? applySatisfyGrid : null,
          isTemplate ? mergeTemplateId : mergeDropPosition,
          applyUniquifyIds,
          insertAndSelectElements,
        ],
        { item, itemType, monitor, dropPoint, upload }
      ).catch(error => {
        // eslint-disable-next-line no-alert
        alert(error.message);
      });
    },
  });

  return (
    <div ref={drop}>
      <Workspace />
    </div>
  );
}

export default WorkspaceWithDnD;
