import React from 'react';
import { bool, func, number, string } from 'prop-types';
import _ from 'lodash';
import { Button, Input } from 'semantic-ui-react';

export const DECREASE_VALUE = 'DECREASE_VALUE';
export const INCREASE_VALUE = 'INCREASE_VALUE';

/*
 USAGE EXAMPLES:
 <Form.Field inline control={NumberPicker} name={MULTIPLY_INPUT + ".times"} onChange={this.triggerChange} label="Copies to create" min={1} max={999} placeholder="Repeat ..." />
 <Form.Field width="8" control={NumberPicker} compact label="compact buttons" placeholder="Enter a number" min={-41} max={45} step={1} />
 <Form.Field width="8" control={NumberPicker} circular label="circular buttons" placeholder="Enter a number" min={-41} max={45} step={1} />
 <Form.Field width="8" control={NumberPicker} basic label="basic buttons" placeholder="Enter a number" min={-40} max={40} step={2} />

 */

class NumberPicker extends React.Component {
  constructor(props) {
    super(props);

    this.style = {
      default: {
        input: {
          borderRadius: '0px',
          textAlign: 'right',
          width: 60,
        },
        buttonLeft: {
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          margin: '0px',
        },
        buttonRight: {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
        },
      },
      circular: {
        input: {
          textAlign: 'right',
        },
        buttonLeft: {
          marginRight: '3.5px',
        },
        buttonRight: {
          marginLeft: '3.5px',
        },
      },
    };
    this.handleAction = this.handleAction.bind(this);
    // this.validateInput = this.validateInput.bind(this);

    this.state = {
      focused: false,
      buffer: '',
    };
  }

  parse(setVal) {
    let returnValue = setVal;
    if (returnValue === undefined || returnValue === null) {
      returnValue = '';
    }
    const precision = this.props.truncate ? 1 : 1000;
    if (_.isFinite(parseFloat(setVal))) {
      returnValue = Math.floor(parseFloat(setVal) * precision) / precision;
    }
    return returnValue;
  }

  static focusedInstance = null;

  static setFocused(instance) {
    if (NumberPicker.focusedInstance) {
      NumberPicker.focusedInstance.setState({ focused: false });
    }
    NumberPicker.focusedInstance = instance;
    if (NumberPicker.focusedInstance) {
      NumberPicker.focusedInstance.setState({ focused: true });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.focused) {
      return { ...state, value: state.value };
    }
    return { ...state, value: props.value };
  }

  keyPress = e => {
    if (e.keyCode === 40) {
      this.handleAction(e, DECREASE_VALUE);
    } else if (e.keyCode === 38) {
      this.handleAction(e, INCREASE_VALUE);
    } else {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    // this.inputNode.focus();
    setTimeout(() => {
      this.inputNode.focus();
    }, 0);
  };

  handleAction(event, actionFilter = null) {
    if (!actionFilter) {
      actionFilter = event.currentTarget.name;
    }
    const currentValue = event.currentTarget.value.replace(',', '.');

    let setVal = _.isFinite(parseFloat(this.state.value))
      ? parseFloat(this.state.value)
      : null;
    const stepSize = _.isFinite(parseFloat(this.props.step))
      ? parseFloat(this.props.step)
      : 1;
    switch (actionFilter) {
      case DECREASE_VALUE:
        if (this.state.value - stepSize >= this.props.min) setVal -= stepSize;
        else setVal = this.props.min;
        if (this.props.truncateStep) {
          setVal = Math.round(parseFloat(setVal / stepSize)) * stepSize;
        }
        this.setState({ value: setVal });
        break;
      case INCREASE_VALUE:
        if (setVal + stepSize <= this.props.max) setVal += stepSize;
        else setVal = this.props.max;
        if (this.props.truncateStep) {
          setVal = Math.round(parseFloat(setVal / stepSize)) * stepSize;
        }
        this.setState({ value: setVal });
        break;
      default:
        let parsedVal = parseFloat(currentValue);
        if (isNaN(parsedVal)) {
          if (this.props.min < 0) {
            parsedVal = 0;
          } else {
            parsedVal = this.props.min;
          }
        }
        this.setState({ value: currentValue });

        if (!(parsedVal > this.props.max || parsedVal < this.props.min)) {
          setVal = parsedVal;
        }

        break;
    }

    // let lastChar = ('' + setVal).charAt(this.state.value.length - 1) || '';

    const returnValue = this.parse(setVal);

    /*
    if (setVal === '' || setVal === '-' || lastChar === '.' || lastChar === ',')
      returnValue = setVal;
      */

    setTimeout(this.props.onChange, 1, {
      name: this.props.name,
      value: returnValue,
    });
  }

  /*
    validateInput(event, v) {
        let actionFilter = event.target.name;
        let currentValue = event.target.value;

        var setVal = this.state.value;
        switch (actionFilter) {
            case this.props.name:
                let parsedVal = parseFloat(currentValue);
                setVal = (_.isFinite(parsedVal)) ? parsedVal : null;

                if (parsedVal > this.props.max)
                    setVal = this.props.max;
                break;

            case DECREASE_VALUE:
            case INCREASE_VALUE:
            default:
                break;
        }
    }
    */

  render() {
    const style = this.props.circular
      ? this.style.circular
      : this.style.default;
    const display = {
      circular: this.props.circular,
      basic: this.props.basic,
      compact: this.props.compact,
      size: this.props.size,
    };
    return (
      <Input className={this.props.className} size={this.props.size}>
        {this.props.showButtons && (
          <Button
            {...display}
            type="button"
            icon="minus"
            compact
            onClick={e => this.handleAction(e, DECREASE_VALUE)}
            style={style.buttonLeft}
            disabled={this.state.value <= this.props.min}
            className={this.props.classname_button_minus}
            tabIndex={-1}
          />
        )}
        <input
          autoComplete="off"
          type="text"
          ref={node => (this.inputNode = node)}
          name={this.props.name}
          id={this.props.id}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          className={this.props.classname_inner_input}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          required={this.props.required}
          value={this.parse(this.state.value)}
          onChange={this.handleAction}
          onKeyUp={this.keyPress}
          onFocus={() => NumberPicker.setFocused(this)}
          onBlur={() => NumberPicker.setFocused(null)}
          /*
                      onBlur={
                          this.validateInput
                      }
                       */
          style={style.input}
        />
        {this.props.showButtons && (
          <Button
            {...display}
            type="button"
            compact
            icon="plus"
            onClick={e => this.handleAction(e, INCREASE_VALUE)}
            style={style.buttonRight}
            disabled={this.state.value >= this.props.max}
            className={this.props.classname_button_plus}
            tabIndex={-1}
          />
        )}{' '}
      </Input>
    );
  }
}

NumberPicker.defaultProps = {
  basic: false,
  circular: false,
  classname_button_minus: 'number_picker_button_minus',
  classname_button_plus: 'number_picker_button_plus',
  classname_inner_input: 'number_picker_input',
  classname_outer_input: 'number_picker',
  className: '',
  compact: false,
  id: '',
  maxLength: 10,
  name: undefined,
  placeholder: '',
  required: false,
  showButtons: false,
  size: undefined,
  step: 1,
  truncate: false,
  truncateStep: false,
  value: undefined,

  // Limiting min, max value to 1e10 to prevent javascript to switch into scientific notation
  min: 1e10 * -1,
  max: 1e10,
};

NumberPicker.propTypes = {
  basic: bool,
  circular: bool,
  classname_button_minus: string,
  classname_button_plus: string,
  classname_inner_input: string,
  classname_outer_input: string,
  className: string,
  compact: bool,
  id: string,
  max: number,
  maxLength: number,
  min: number,
  name: string,
  onChange: func.isRequired,
  placeholder: string,
  required: bool,
  showButtons: bool,
  size: string,
  step: number,
  truncate: bool,
  truncateStep: bool,
  value: number,
};

export default NumberPicker;
