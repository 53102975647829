import { useState } from 'react';
import { useEvent } from 'react-use';

export default function useShiftOrMetaPressed() {
  const [toggle, setToggle] = useState(false);
  const onToggle = ({ shiftKey, metaKey }) => {
    setToggle(!!(shiftKey || metaKey));
  };
  useEvent('keydown', onToggle);
  useEvent('keyup', onToggle);
  return toggle;
}
