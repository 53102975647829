import axios from 'axios';

import { API_URL } from '../constants';
import { prepareSpreadsFromServer } from '../util/workspace';
import { addToQueue, removeFromQueue } from './loading';

const CREATE = 'template/CREATE';
const UPDATE = 'template/UPDATE';
const DELETE = 'template/DELETE';
const REPLACE = 'template/REPLACE';

export const initialState = [];

function createUpdateDeleteReplace(state, action) {
  let nextState;
  switch (action.type) {
    case CREATE:
      if (typeof action.payload.id === 'undefined') {
        console.error('id not set during create');
        return state;
      }
      return [...state, action.payload];

    case UPDATE: {
      const updateIndex = state.findIndex(item => item.id === action.id);
      if (updateIndex === -1) {
        console.error('id not found during update');
        return state;
      }
      const nextItem = { ...state[updateIndex], ...action.payload };
      nextState = [
        ...state.slice(0, updateIndex),
        nextItem,
        ...state.slice(updateIndex + 1),
      ];
      return nextState;
    }
    case DELETE: {
      const deleteIndex = state.findIndex(item => item.id === action.id);
      if (deleteIndex === -1) {
        console.error('id not found during delete');
        return state;
      }
      nextState = [
        ...state.slice(0, deleteIndex),
        ...state.slice(deleteIndex + 1),
      ];
      return nextState;
    }
    case REPLACE:
      return action.payload;
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
    case UPDATE:
    case DELETE:
    case REPLACE:
      return createUpdateDeleteReplace(state, action);
    default:
      return state;
  }
};

const prepareTemplateFromServer = template => {
  let details;
  try {
    details = JSON.parse(template.details);
  } catch (e) {
    details = template.details;
  }
  if (typeof details !== 'object') details = {};
  if (!details.type) details.type = 'common';
  if (!details.tags) details.tags = [];

  let svgPreview;
  try {
    svgPreview = JSON.parse(template.svg_preview);
  } catch (e) {
    svgPreview = template.svg_preview;
  }

  return {
    ...template,
    svg_preview: svgPreview,
    details,
    json: prepareSpreadsFromServer(template.json),
  };
};

const prepareTemplateForServer = template => {
  return {
    ...template,
    details: JSON.stringify(template.details),
    json: JSON.stringify(template.json),
  };
};

/**
 * Fetches all templates from the server and stores them to the list.
 */
export const fetchTemplates = (apiKey = null, apiUrl = API_URL) => dispatch => {
  dispatch(addToQueue(`fetchTemplates`));
  const promise = axios.get(
    `${apiUrl}/templates${apiKey ? `?api_key=${apiKey}` : ''}`
  );
  promise
    .then(({ data: { templates } }) => {
      dispatch({
        type: REPLACE,
        payload: templates.map(prepareTemplateFromServer),
      });
      dispatch(removeFromQueue(`fetchTemplates`));
      return promise;
    })
    .catch(e => {
      console.error('error loading templates:', apiUrl, e);
    });

  return promise;
};

/**
 * Saves a new template on the server and appends it to the store.
 * @param {S} title The title of the template.
 * @param {*} json A JSON-Representation of the group element.
 * @param {*} svg An SVG-Preview.
 */
export const createTemplate = item => dispatch =>
  axios
    .post(`${API_URL}/templates`, prepareTemplateForServer(item))
    .then(({ data: { template } }) =>
      dispatch({ type: CREATE, payload: prepareTemplateFromServer(template) })
    );

export const patchTemplate = (id, item) => dispatch => {
  dispatch({ type: UPDATE, id, payload: item });
  return axios
    .patch(`${API_URL}/templates/${id}`, prepareTemplateForServer(item))
    .then(({ data: { template } }) =>
      dispatch({
        type: UPDATE,
        id,
        payload: prepareTemplateFromServer(template),
      })
    );
};

export const tagTemplate = (id, tagId) => dispatch =>
  axios
    .post(`${API_URL}/templates/${id}/tags`, { tag_id: tagId })
    .then(({ data: { template } }) =>
      dispatch({
        type: UPDATE,
        id,
        payload: prepareTemplateFromServer(template),
      })
    );
