import { difference } from 'lodash';

import { resolutions } from '../constants';
import { getImage } from '../selectors/images';
import { getStickersById } from '../selectors/legacy';
import { selectStickerNumberByIdLookup } from '../selectors/stickers';
import {
  selectSyncDirectoryHandle,
  selectSyncIntervalId,
  selectSyncQueue,
  selectSyncTargetedStickerIds,
  selectSyncUnqueuedStickerIds,
} from '../selectors/sync';
import { resolveImage } from '../util/images';
import {
  extractIdFromFilename,
  fileUploadViaDirectUpload,
  formatFilename,
  getFilesInDirectory,
  getLastModifiedObject,
  persistLastModified,
} from '../util/sync';
import { patchExistingImage } from './images';

export const UPDATE = 'sync/update';

const update = payload => ({ type: UPDATE, payload });

const fileExtensionForSupportedContentTypes = {
  'image/svg+xml': 'svg',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
  'application/pdf': 'pdf',
};

/**
 * Creates a download action for the queue
 */
const downloadAction = ({ sticker, url }) => async (_, getState) => {
  const state = getState();
  const directoryHandle = selectSyncDirectoryHandle(state);
  const stickerNumberByIdLookup = selectStickerNumberByIdLookup(state);

  // Download file
  const response = await fetch(url);

  // Determine filename
  const contentType = response.headers.get('content-type');
  const extension = fileExtensionForSupportedContentTypes[contentType];
  if (!extension) {
    throw new Error('Unsupported content type');
  }
  const filename = formatFilename(
    sticker,
    stickerNumberByIdLookup[sticker.id],
    extension
  );

  // Save file
  const blob = await response.blob();
  const fileHandle = await directoryHandle.getFileHandle(filename, {
    create: true,
  });
  const writable = await fileHandle.createWritable();
  await writable.write(blob);
  await writable.close();

  // Save last modified date
  const file = await fileHandle.getFile();
  persistLastModified(sticker.id, file.lastModified);
};

/**
 * Create an upload action for the queue
 */
const uploadAction = ({ sticker, file }) => async dispatch => {
  // Upload file
  const blob = await fileUploadViaDirectUpload(file);

  // Update sticker
  dispatch(patchExistingImage(sticker.image, { blob_id: blob.id }));

  // Save last modified date
  persistLastModified(sticker.id, file.lastModified);
};

/**
 * Return a list of pending download-actions.
 */
const getDownloadActions = (stickerIds, stickersById, files, state) => {
  /**
   * Remove sticker-ids, where a file with a stored last-modified-date
   * is present. In this case, we don't need to download the file again.
   */
  const existingFileIds = files
    .map(file => file.name)
    .map(extractIdFromFilename)
    .filter(Boolean);
  const lastModifiedObject = getLastModifiedObject();
  const existingFileIdsWithKnownLastModified = existingFileIds.filter(
    fileId => !!lastModifiedObject[fileId]
  );
  const downloadIds = difference(
    stickerIds,
    existingFileIdsWithKnownLastModified
  );

  // Convert sticker-ids to download-actions
  const downloadActions = downloadIds
    .map(stickerId => {
      const sticker = stickersById[stickerId];

      if (!sticker) {
        // In case the sticker has been deleted meanwhile
        return null;
      }

      const imageObject = getImage(state, sticker.image);
      const url = resolveImage(imageObject, resolutions.original).src;
      const action = downloadAction({ sticker, url });

      // This is used to exclude this sticker from further actions while it
      // is still queued. See `selectSyncUnqueuedStickerIds`.
      action.stickerId = sticker.id;

      // Used only in unit tests
      action.qaActionType = 'download';

      return action;
    })
    .filter(Boolean);

  return downloadActions;
};

/**
 * Return a list of pending upload-actions, similar to the download-actions.
 */
const getUploadActions = (stickerIds, stickersById, files) => {
  const minimumFileIdleTimeBeforeUpload = 2000; // 2 seconds in milliseconds

  const preventEarlyUploadThreshold =
    new Date().getTime() - minimumFileIdleTimeBeforeUpload;

  const lastModifiedObject = getLastModifiedObject();

  // Convert existing files to upload-actions
  const uploadActions = files
    .map(file => {
      const fileId = extractIdFromFilename(file.name);
      if (!stickerIds.includes(fileId)) {
        // Only process files that are associated with a syncable sticker
        return null;
      }

      if (!fileExtensionForSupportedContentTypes[file.type]) {
        // Only process supported file-types (image files)
        return null;
      }

      const lastModified = lastModifiedObject[fileId];
      if (
        // lastModified is not set, so we wait for the file to be downloaded again
        !lastModified ||
        // File has not been changed
        file.lastModified === lastModified ||
        // File has been changed, but it is too early to upload it
        file.lastModified > preventEarlyUploadThreshold
      ) {
        return null;
      }

      const sticker = stickersById[fileId];
      if (!sticker) {
        // In case the sticker has been deleted meanwhile
        return null;
      }

      const action = uploadAction({ sticker, file });

      // This is used to exclude this sticker from further actions while it
      // is still queued. See `selectSyncUnqueuedStickerIds`.
      action.stickerId = sticker.id;

      // Used only in unit tests
      action.qaActionType = 'upload';

      return action;
    })
    .filter(Boolean);

  return uploadActions;
};

const processNextQueueItem = () => async (dispatch, getState) => {
  const state = getState();
  const queue = selectSyncQueue(state);
  if (queue.length === 0) {
    /**
     * No more items in the queue. Now the chain of the actions is stopped and
     * needs to be "manually" restarted in `syncInterval`.
     */
    return;
  }

  // Basically unshift an item from the queue
  const [action, ...nextQueue] = queue;
  dispatch(update({ queue: nextQueue }));

  /**
   * The upload or download action will start the next queue action
   * automatically after it completes. Errors during the action itself
   * will not stop the sync and only outputted to the console.
   */
  try {
    await dispatch(action);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error.message);
  } finally {
    dispatch(processNextQueueItem());
  }
};

export const syncInterval = () => async (dispatch, getState) => {
  const state = getState();

  // Read state and file-system
  const stickerIds = selectSyncUnqueuedStickerIds(state);
  const directoryHandle = selectSyncDirectoryHandle(state);
  const stickersById = getStickersById(state);
  const files = await getFilesInDirectory(directoryHandle);

  // Get download and upload queue
  const downloadActions = getDownloadActions(
    stickerIds,
    stickersById,
    files,
    state
  );
  const uploadActions = getUploadActions(stickerIds, stickersById, files);

  // Combine queues, prioritizing downloads over uploads
  const lastQueue = selectSyncQueue(state);
  const nextQueue = [...downloadActions, ...lastQueue, ...uploadActions];

  dispatch(update({ queue: nextQueue }));

  /**
   * If there is no download or upload in progress, start the next one.
   * If there is still a download or upload in progress, it will trigger the
   * next one when it completes, so we don't need to do anything here.
   */
  if (lastQueue.length === 0 && nextQueue.length > 0) {
    dispatch(processNextQueueItem());
  }
};

export const syncStop = () => (dispatch, getState) => {
  const state = getState();
  const intervalId = selectSyncIntervalId(state);

  // Stop interval
  if (intervalId !== null) {
    clearInterval(intervalId);
  }

  dispatch(update({ queue: [], intervalId: null, stickerIds: [] }));
};

export const syncStart = () => (dispatch, getState) => {
  const state = getState();
  const lastIntervalId = selectSyncIntervalId(state);
  const stickerIds = selectSyncTargetedStickerIds(state);

  // Stop any running interval
  if (lastIntervalId !== null) {
    clearInterval(lastIntervalId);
  }

  const runIntervalSafely = async () => {
    try {
      await dispatch(syncInterval());
    } catch (error) {
      /**
       * If something goes wrong during `syncInterval` (probably inside
       * `getFilesInDirectory`), the sync should be stopped and the user
       * should be notified.
       */
      dispatch(syncStop());
      // eslint-disable-next-line no-alert
      alert(`Sticker-Syncronisierung unterbrochen: ${error.message}`);
    }
  };

  // Start interval
  const syncIntervalTime = 10000; // 10 seconds in milliseconds
  const nextIntervalId = setInterval(runIntervalSafely, syncIntervalTime);

  dispatch(update({ queue: [], intervalId: nextIntervalId, stickerIds }));

  // Start the syncronization immediately
  runIntervalSafely();
};

export const setSyncDirectoryHandle = directoryHandle => dispatch =>
  dispatch(update({ directoryHandle }));

export const setSyncSelectionOnly = selectionOnly => dispatch =>
  dispatch(update({ selectionOnly }));
