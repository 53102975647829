import React from 'react';
import { func } from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { stickerFieldLabels } from '../../constants';
import ControlsOptions from './ControlsOptions';
import TextReplace from '../ui/TextReplace';

const SectionMenu = ({ setModal }) => {
  return (
    <Menu secondary vertical>
      <Menu.Item className="header">Ansicht</Menu.Item>

      <ControlsOptions
        controlsStoreKey="stickerField"
        options={stickerFieldLabels}
      />

      <Menu.Item className="header">Werkzeuge</Menu.Item>
      <Menu.Item
        key="search-and-replace"
        onClick={() => setModal(<TextReplace />)}
      >
        Suchen & Ersetzen
      </Menu.Item>
    </Menu>
  );
};

SectionMenu.propTypes = {
  setModal: func.isRequired,
};

export default SectionMenu;
