import { connect } from 'react-redux';

import { getImage } from '../../../selectors/images';
import { toggleTrashcan } from '../../../modules/controls';
import { showContextMenu } from '../../../modules/menu';
import ImageItem from './index.dnd';

const mapStateToProps = (state, ownProps) => ({
  imagePreviewSize: state.controls.imagePreviewSize,
  imageObject: getImage(state, ownProps.image),
});

const mapDispatchToProps = {
  toggleTrashcan,
  showContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageItem);
