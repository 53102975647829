import React from 'react';
import { number, bool, func, string } from 'prop-types';
import { getDefaultKeyBinding, RichUtils, Editor } from 'draft-js';
import { connect } from 'react-redux';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

import { setEditorState } from '../../../../modules/draft';
import { ContentContext } from '../../Content';
import { getElementTransform } from '../../../../util/geometry';
import GuideRect from '../GuideRect';
import {
  HANDLED,
  NOT_HANDLED,
  blockRendererFn,
  handleBlockSplit,
} from './textConstants';
import { CustomStyleMapShape, EditorStateShape } from '../../../shapes';
import BaseElement from '../BaseElement';
import { replaceSzLigatureHtml } from '../../../../util/draftjs';

class BaseTextClass extends React.Component {
  state = {
    mounted: false,
    overlayDone: false,
  };

  change = editorState => {
    const { dispatch } = this.props;
    dispatch(setEditorState(editorState));
  };

  editor_onChange = editorState => {
    this.change(editorState);
  };

  editor_handleKeyCommand = command => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.change(newState);
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  editor_handleReturn = e => {
    const { editorState } = this.props;
    if (isSoftNewlineEvent(e)) {
      return NOT_HANDLED;
    }
    const splitState = handleBlockSplit(editorState);
    this.change(splitState);
    return HANDLED;
  };

  editor_keyBindingFn(e) {
    const result = getDefaultKeyBinding(e);
    e.stopPropagation();
    return result;
  }

  keyDown = e => {
    e.stopPropagation();
  };

  keyUp = e => {
    e.stopPropagation();
  };

  createOverlay = () => {
    const { id, width, height } = this.props;

    const transform = getElementTransform(
      this.foreignObject,
      width * 10,
      height * 10
    );
    const html = replaceSzLigatureHtml(this.foreignObject.innerHTML);
    this.contentContext.createTextOverlay({ id, transform, html });
  };

  componentDidUpdate() {
    const { editorState } = this.props;
    const { mounted, overlayDone } = this.state;

    if (editorState && mounted && this.contentContext && !overlayDone) {
      this.setState({ overlayDone: true });
      this.createOverlay();
    }
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    const {
      editorState,
      height,
      opacity,
      readOnly,
      customStyleMap,
      width,
    } = this.props;

    if (!editorState) {
      return <BaseElement {...this.props} />;
    }

    const content = (
      <Editor
        key={JSON.stringify(customStyleMap)}
        editorState={editorState}
        readOnly={readOnly}
        onChange={this.editor_onChange}
        handleKeyCommand={this.editor_handleKeyCommand}
        handleReturn={this.editor_handleReturn}
        keyBindingFn={this.editor_keyBindingFn}
        customStyleMap={customStyleMap}
        blockRendererFn={blockRendererFn}
        preserveSelectionOnBlur
      />
    );

    return (
      <BaseElement {...this.props}>
        <ContentContext.Consumer>
          {contentContext => {
            this.contentContext = contentContext;
            return (
              <g
                opacity={opacity}
                onKeyDown={this.keyDown}
                onKeyUp={this.keyUp}
                className="inside"
              >
                <rect width={width} height={height} fill="none" />
                <GuideRect width={width} height={height} />
                <foreignObject
                  style={{
                    transform: 'scale(0.1)',
                    transformOrigin: 'left top',
                  }}
                  ref={node => {
                    this.foreignObject = node;
                  }}
                  className="qa-draft-root"
                  width={width * 10}
                  height={height * 10}
                >
                  {content}
                </foreignObject>
              </g>
            );
          }}
        </ContentContext.Consumer>
      </BaseElement>
    );
  }
}

BaseTextClass.defaultProps = {
  editingId: null,
  editorState: null,
  opacity: null,
};

BaseTextClass.propTypes = {
  dispatch: func.isRequired,
  editingId: string,
  editorState: EditorStateShape,
  height: number.isRequired,
  id: string.isRequired,
  opacity: number,
  readOnly: bool.isRequired,
  customStyleMap: CustomStyleMapShape.isRequired,
  width: number.isRequired,
};

const mapStateToProps = state => ({
  colors: state.colors,
  editingId: state.draft.editingId,
});

export default connect(mapStateToProps)(BaseTextClass);
