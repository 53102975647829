import axios from 'axios';

import { API_URL } from '../constants';

export const DELETE = 'template/DELETE';

export function deleteTemplate(id) {
  return dispatch => {
    dispatch({ type: DELETE, id });
    axios.delete(`${API_URL}/templates/${id}`);
  };
}
