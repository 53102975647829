import { bool, string } from 'prop-types';

import useBinarySelector from '../../../../hooks/useBinarySelector';
import { makeSelectStickerNumberById } from '../../../../selectors/stickers';
import formatStickerNumber from './formatStickerNumber';

function StickerNumber({ stickerId, isNextNumber }) {
  const stickerNumber = useBinarySelector(
    makeSelectStickerNumberById,
    stickerId
  );
  return formatStickerNumber(stickerNumber + (isNextNumber ? 1 : 0));
}

StickerNumber.defaultProps = {
  isNextNumber: false,
};

StickerNumber.propTypes = {
  stickerId: string.isRequired,
  isNextNumber: bool,
};

export default StickerNumber;
