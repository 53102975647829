import React from 'react';
import { arrayOf, func } from 'prop-types';
import { Menu, Icon } from 'semantic-ui-react';

import { StickerShape, TagShape } from '../../shapes';
import StickerTemplatesMenu from './StickerTemplatesMenu';

function StickerPropItems({
  stickerTags,
  updateSelectedStickers,
  executeWithSelection,
  sticker: {
    doubleSticker,
    textless,
    noStickerstarsLogo,
    subtitle,
    showTeamInPosition,
    tags,
  },
}) {
  return (
    <>
      <Menu.Item className="header">Sticker-Eigenschaften</Menu.Item>
      <Menu.Item
        onClick={() =>
          executeWithSelection(() =>
            updateSelectedStickers({ doubleSticker: !doubleSticker })
          )
        }
      >
        Doppelsticker {doubleSticker && <Icon name="check" />}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          executeWithSelection(() =>
            updateSelectedStickers({ subtitle: !subtitle })
          )
        }
      >
        Untertitel anzeigen {subtitle && <Icon name="check" />}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          executeWithSelection(() =>
            updateSelectedStickers({ textless: !textless })
          )
        }
      >
        Textlos {textless && <Icon name="check" />}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          executeWithSelection(() =>
            updateSelectedStickers({
              noStickerstarsLogo: !noStickerstarsLogo,
            })
          )
        }
      >
        Kein Stickerstars Logo {noStickerstarsLogo && <Icon name="check" />}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          executeWithSelection(() =>
            updateSelectedStickers({
              showTeamInPosition: !showTeamInPosition,
            })
          )
        }
      >
        Team in Position anzeigen {showTeamInPosition && <Icon name="check" />}
      </Menu.Item>
      <StickerTemplatesMenu
        tags={tags}
        stickerTags={stickerTags}
        executeWithSelection={executeWithSelection}
        updateSelectedStickers={updateSelectedStickers}
      />
    </>
  );
}

StickerPropItems.propTypes = {
  sticker: StickerShape.isRequired,
  stickerTags: arrayOf(TagShape).isRequired,
  updateSelectedStickers: func.isRequired,
  executeWithSelection: func.isRequired,
};

export default StickerPropItems;
