import formatStickerNumber from './formatStickerNumber';

function splitStringByFirstDelimeter(s, delimeter) {
  const parts = s.split(delimeter);
  const [line1, ...rest] = parts;
  const line2 = rest.join(delimeter).trim();
  return [line1, line2];
}

const defaultStickerFields = {
  position: '',
};

export default function getStickerLocalTexts(
  sticker,
  hasSubtitle,
  stickerNumber,
  sectionName
) {
  const completeSticker = {
    ...defaultStickerFields,
    ...sticker,
  };
  const { showTeamInPosition, name } = completeSticker;
  let { position } = completeSticker;

  const nameDelimeter = name.indexOf('\\') !== -1 ? '\\' : ' ';
  const line = name.split('\\').join(' ');
  let [line1, line2] = splitStringByFirstDelimeter(name, nameDelimeter);
  if (line2 === '') {
    line2 = line1;
    line1 = '';
  }

  if (showTeamInPosition) {
    position =
      position && sectionName
        ? `${position} ${sectionName}`
        : position || sectionName;
  }
  const positionDelimeter = '\\';
  const [position1, position2] = splitStringByFirstDelimeter(
    position,
    positionDelimeter
  );

  const commonTexts = {
    number: formatStickerNumber(stickerNumber),
    nextnumber: formatStickerNumber(stickerNumber + 1),
    position1,
    position2,
  };

  const metaFieldTexts = Object.keys(sticker.metaFields || {}).reduce(
    (acc, curr) => ({
      ...acc,
      [`meta-value-${curr}`]: sticker.metaFields[curr].value,
      [`meta-key-value-${curr}`]: `${sticker.metaFields[curr].key}: ${sticker.metaFields[curr].value}`,
    }),
    {}
  );

  /**
   * Legacy-behavior: if the sticker has a subtitle, its line-field is
   * set to be empty. Ideally, this should not be needed, since in these
   * cases the sticker should be "textless" anyway (via prop or template)
   */
  const result = hasSubtitle
    ? { ...commonTexts, ...metaFieldTexts, line: '', line1: '', line2: '' }
    : { ...commonTexts, ...metaFieldTexts, line, line1, line2 };

  return result;
}
