import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Card, Checkbox, Comment } from 'semantic-ui-react';
import moment from 'moment';

import {
  fetchComments,
  getCommentsBySpreadId,
} from '../../../modules/comments';
import { getCurrentAlbumId } from '../../../selectors/albums';
import { getSpreadIds } from '../../../selectors/legacy';
import { fetchProfile } from '../../../modules/user';
import {
  resetViewToSpreadIndex,
  setShowDoneComments,
} from '../../../modules/controls';
import { getShowDoneComments } from '../../../selectors/controls';

function ChecksPanel() {
  const dispatch = useDispatch();
  const albumId = useSelector(getCurrentAlbumId);
  const spreadIds = useSelector(getSpreadIds);
  const commentsBySpreadId = useSelector(getCommentsBySpreadId);
  const showDoneComments = useSelector(getShowDoneComments);

  useMount(() => {
    dispatch(fetchComments(albumId));
    dispatch(fetchProfile());
  });

  const handleToggleChange = (e, { checked }) => {
    dispatch(setShowDoneComments(checked));
  };

  return (
    <div className="qa-comments-content">
      <div className="mb-3">
        <Checkbox
          checked={showDoneComments}
          onChange={handleToggleChange}
          label="abgeschlossene anzeigen"
          toggle
          className="qa-toggle-done-comments"
        />
      </div>
      {spreadIds.map(
        (spreadId, index) =>
          commentsBySpreadId[spreadId]?.length > 0 && (
            <div key={spreadId}>
              <b>Doppelseite {index + 1}</b>
              {commentsBySpreadId[spreadId]
                .filter(
                  comment => !comment.done || (comment.done && showDoneComments)
                )
                .map(comment => (
                  <Card
                    key={comment.id}
                    onClick={() => dispatch(resetViewToSpreadIndex(index))}
                    style={{ opacity: comment.done ? 0.5 : 1 }}
                    className="qa-sidebar-comment"
                  >
                    <Card.Content>
                      <Comment.Group key={comment.id}>
                        <Comment>
                          <Comment.Content>
                            <Comment.Author>{comment.user_name}</Comment.Author>
                            <Comment.Metadata>
                              <div>{moment(comment.created_at).fromNow()}</div>
                            </Comment.Metadata>
                            <Comment.Text>{comment.content}</Comment.Text>
                          </Comment.Content>
                        </Comment>
                      </Comment.Group>
                    </Card.Content>
                  </Card>
                ))}
              <hr />
            </div>
          )
      )}
    </div>
  );
}

ChecksPanel.defaultProps = {};

ChecksPanel.propTypes = {};

export default ChecksPanel;
