import rangeParser from 'parse-numeric-range';
import queryString from 'query-string';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';

import { fetchStocks } from '../../actions/images';
import { ImageContext } from '../../components/ImageContext';
import { ReactRouterLocationShape } from '../../components/shapes';
import SpreadContainerRenderer from '../../components/svg/SpreadContainerRenderer';
import StickerRenderer from '../../components/svg/StickerRenderer';
import { renderFormats, renderModes, resolutions } from '../../constants';
import useLoadAllFonts from '../../hooks/useLoadAllFonts';
import { fetchAlbum, setAlbum } from '../../modules/albums';
import { updateControls } from '../../modules/controls';
import { fetchTemplates } from '../../modules/templates';
import { objectMap } from '../../util/index';

// todo move sanitizer, use shared code with api/render/index.js?
function parseRange(range) {
  if (typeof range !== 'string' || range === '') {
    return null;
  }
  return rangeParser.parse(range);
}

function sanitize(values, allowed) {
  return objectMap(values, (value, key) => {
    const allow = allowed[key];
    if (Array.isArray(allow)) {
      if (allow.indexOf(value) === -1) {
        return allow[0];
      }
    } else if (typeof allow === 'function') {
      return allow(value);
    } else if (allow && typeof value !== allow) {
      return null;
    }
    return value;
  });
}

function parseBool(value) {
  const int = parseInt(value, 10);
  if (isNaN(int)) {
    return value === 'true';
  }
  return int === 1;
}

function Renderer({ location }) {
  const dispatch = useDispatch();

  useMount(() => {
    const vars = queryString.parse(location.search);

    const { api_key, album_id, api_url } = sanitize(vars, {
      album_id: 'string',
      api_key: 'string',
      api_url: 'string',
    });

    Promise.all([
      dispatch(fetchStocks(api_key, api_url)),
      dispatch(fetchTemplates(api_key, api_url)),
    ]).then(() => {
      dispatch(updateControls({ stickerMode: false, selectedIds: [] }));
      dispatch(setAlbum(album_id));
      dispatch(fetchAlbum(album_id, api_key, api_url));
    });
  });

  const fontState = useLoadAllFonts();

  if (fontState.loading) {
    return null;
  }

  if (fontState.error) {
    throw fontState.error;
  }

  const vars = queryString.parse(location.search);

  const {
    mode,
    format,
    resolution,
    previewSticker,
    includeCover,
    includeBleed,
    range,
  } = sanitize(vars, {
    mode: Object.keys(renderModes),
    format: Object.keys(renderFormats),
    resolution: Object.keys(resolutions),
    previewSticker: parseBool,
    includeCover: parseBool,
    includeBleed: parseBool,
    range: parseRange,
  });

  if (mode === 'sticker') {
    return (
      <ImageContext.Provider
        value={{
          rendering: true,
          stickerRendering: true,
          printing: true,
          resolution,
          previewSticker,
        }}
      >
        <StickerRenderer
          mode={mode}
          range={range}
          format={format}
          includeBleed={includeBleed}
        />
      </ImageContext.Provider>
    );
  }

  return (
    <ImageContext.Provider
      value={{
        rendering: true,
        stickerRendering: false,
        resolution,
        printing: format !== 'zip',
        previewSticker,
      }}
    >
      <SpreadContainerRenderer
        mode={mode}
        format={format}
        range={range}
        includeCover={includeCover}
        includeBleed={includeBleed}
      />
    </ImageContext.Provider>
  );
}

Renderer.propTypes = {
  location: ReactRouterLocationShape.isRequired,
};

export default Renderer;
