import { number } from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { getStickerCount } from '../../../../selectors/legacy';
import { getTransformationMatrix } from '../../../../selectors/nodes';
import {
  getTocPages,
  getTocSections,
} from '../../../../selectors/tableOfContents';
import {
  applyAutoSize,
  extractStyleFromContentState,
  getEditorStateFromSymbolPropsAndXPos,
} from '../../../../util/draftjs';
import { ImageContext } from '../../../ImageContext';
import { CustomStyleMapShape } from '../../../shapes';
import SpreadContext from '../Spread/SpreadContext';
import BaseText from './BaseText';

const positionOnSpread = matrix => {
  const point = new DOMPoint();
  // TODO: move this function into the matrix class
  return point.matrixTransform(matrix.matrix);
};

function SymbolText(props) {
  const { xPos, width, customStyleMap } = props;
  const spreadContext = useContext(SpreadContext);
  const imageContext = useContext(ImageContext);

  const {
    editorState,
    actualText,
    rawContentState,
  } = getEditorStateFromSymbolPropsAndXPos(
    {
      props,
      xPos,
    },
    spreadContext,
    imageContext
  );

  const { style, activeStyleNames } = extractStyleFromContentState({
    rawContentState,
    customStyleMap,
  });

  const autoSizeActive = activeStyleNames.includes('AUTOSIZE');

  const nextCustomStyleMap = autoSizeActive
    ? applyAutoSize({
        customStyleMap,
        style,
        activeStyleNames,
        autosizeWidth: width,
        actualText,
      })
    : customStyleMap;

  return (
    <BaseText
      {...props}
      editorState={editorState}
      customStyleMap={nextCustomStyleMap}
      readOnly
    />
  );
}

SymbolText.propTypes = {
  customStyleMap: CustomStyleMapShape.isRequired,
  width: number.isRequired,
  xPos: number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  texts: state.texts,
  album: state.albums.albumData.title,
  totalstickers: getStickerCount(state),
  toc_sections: getTocSections(state),
  toc_pages: getTocPages(state),
  xPos: positionOnSpread(getTransformationMatrix(state, ownProps.id)).x,
});

export default connect(mapStateToProps)(SymbolText);
