import { connect } from 'react-redux';

import {
  patchExistingImage,
  uploadAndCreateImageObjects,
} from '../../../actions/images';
import { applyImage } from '../../../actions/workspace';
import {
  getSelectedElements,
  getSelectedImages,
} from '../../../selectors/legacy';
import ImagesPanelWithDnd from './index.dnd';
import { getArchivedTagId, getSortedTags } from '../../../selectors/tags';
import { getFilterTagIds } from '../../../selectors/controls';

const mapDispatchToProps = {
  uploadAndCreateImageObjects,
  patchExistingImage,
  applyImage,
};

const mapStateToProps = state => ({
  tags: getSortedTags(state),
  selectedElements: getSelectedElements(state),
  selectedImages: getSelectedImages(state),
  filterTagIds: getFilterTagIds(state),
  archiveTagId: getArchivedTagId(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesPanelWithDnd);
