import React, { useCallback } from 'react';
import { func, bool } from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';

import useFileUpload from '../../../hooks/useFileUpload';
import { itemTypes } from '../../../constants';
import ImagesPanel from '.';

function ImagesPanelWithDnd(props) {
  const { patchExistingImage, isStock, uploadAndCreateImageObjects } = props;

  const { upload, uploads: imageUploads } = useFileUpload();

  /**
   * When dropping an image element we're changing its "content type" (ad / background / ...).
   * When dropping a file, we're uploading it.
   */
  const handleDrop = useCallback(
    ({ monitor }, contentType) => {
      if (monitor.getItemType() === itemTypes.image) {
        const imageObject = monitor.getItem();
        const { details } = imageObject;

        /** Only patch the image when its content type changed */
        if (details.contentType === contentType) {
          return;
        }

        const delta = { details: { contentType } };

        patchExistingImage(imageObject.id, delta);
      } else if (monitor.getItemType() === NativeTypes.FILE) {
        const item = monitor.getItem();
        uploadAndCreateImageObjects({
          item,
          contentType,
          isStock,
          upload,
        }).catch(error => {
          // eslint-disable-next-line no-alert
          alert(error.message);
        });
      }
    },
    [patchExistingImage, uploadAndCreateImageObjects, isStock, upload]
  );

  return (
    <ImagesPanel {...props} onDrop={handleDrop} imageUploads={imageUploads} />
  );
}

ImagesPanelWithDnd.propTypes = {
  patchExistingImage: func.isRequired,
  isStock: bool.isRequired,
  uploadAndCreateImageObjects: func.isRequired,
};

export default ImagesPanelWithDnd;
