import React, { useRef, useEffect, useCallback } from 'react';
import { func, node } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ClipPaths from '../ClipPaths';
import GridPattern from './GridPattern';
import useViewportState from './useViewportState';
import useSpacebarPan from './useSpacebarPan';
import useWheelPanAndZoom from './useWheelPanAndZoom';
import ViewportContext from './ViewportContext';
import { colorValues } from '../../../constants';
import { restrictPanAndZoomToContentArea } from '../../../util/geometry';
import { exitIsolation } from '../../../modules/controls';
import {
  getIsolation,
  getAlbumStatisticsForPreflight,
  getSpreadsCount,
} from '../../../selectors/legacy';
import clientToViewportCoordinates from './clientToViewportCoordinates';

function Viewport({ children, onClick }) {
  const { spine } = useSelector(getAlbumStatisticsForPreflight);
  const gridEnabled = useSelector(state => state.controls.gridEnabled);
  const isolationActive = !!useSelector(getIsolation);
  const spreadsCount = useSelector(getSpreadsCount);

  const { pan, zoom, setPan, setZoom } = useViewportState();
  const dispatch = useDispatch();

  const viewportRef = useRef(null);
  const { clientWidth, clientHeight } = viewportRef.current || {
    clientWidth: 0,
    clientHeight: 0,
  };

  const clientToViewportCoordinatesCallback = useCallback(
    (point, currentPan = pan, currentZoom = zoom) =>
      clientToViewportCoordinates(point, currentPan, currentZoom, viewportRef),
    [pan, zoom, viewportRef]
  );

  const viewportContextValue = {
    viewportRef,
    clientToViewportCoordinates: clientToViewportCoordinatesCallback,
  };

  const { spacebarPanActive, spacebarPressed } = useSpacebarPan(viewportRef);
  useWheelPanAndZoom(viewportRef, clientToViewportCoordinatesCallback);

  // Limit pan and zoom to the allowed area
  useEffect(() => {
    const [allowedPan, allowedZoom] = restrictPanAndZoomToContentArea(
      pan,
      zoom,
      clientWidth,
      clientHeight,
      spreadsCount
    );
    if (allowedZoom !== zoom) {
      setZoom(allowedZoom);
    }
    if (allowedPan.x !== pan.x || allowedPan.y !== pan.y) {
      setPan(allowedPan);
    }
  }, [pan, zoom, setZoom, setPan, clientWidth, clientHeight, spreadsCount]);

  // Calculate the viewbox (using pan like a scroll-offset)
  const viewBox = [pan.x, pan.y, clientWidth / zoom, clientHeight / zoom].join(
    ' '
  );

  const dispatchExitIsolation = () => dispatch(exitIsolation());

  const grabCursor = spacebarPanActive ? 'grabbing' : 'grab';
  const style = {
    cursor: spacebarPressed ? grabCursor : null,
    background: isolationActive
      ? colorValues.backgroundIsolation
      : colorValues.background,
  };

  function decorateClickEvent(event) {
    const { clientX, clientY } = event;
    const { x: viewportX, y: viewportY } = clientToViewportCoordinatesCallback({
      x: clientX,
      y: clientY,
    });
    return { ...event, viewportX, viewportY };
  }

  return (
    <ViewportContext.Provider value={viewportContextValue}>
      <svg
        ref={viewportRef}
        id="svg"
        className="viewport m-0 p-0 border-0 qa-svg-root"
        shapeRendering="optimizeSpeed"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        onDoubleClick={isolationActive ? dispatchExitIsolation : null}
        onClick={onClick && (e => onClick(decorateClickEvent(e)))}
      >
        <defs>
          <ClipPaths spine={spine} />
          <GridPattern gridEnabled={gridEnabled} zoom={zoom} />
        </defs>
        {children}
      </svg>
    </ViewportContext.Provider>
  );
}

Viewport.propTypes = {
  children: node,
  onClick: func, // used by the album preview component to create comments
};

Viewport.defaultProps = {
  children: null,
  onClick: null,
};

export default Viewport;
