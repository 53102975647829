import { connect } from 'react-redux';

import { resetSpreads, buildSpreads } from '../../../actions/workspace';
import { alignStickersUsingFaceData } from '../../../modules/sections';
import { resetView, updateControls } from '../../../modules/controls';
import {
  copyAlbumJson,
  pasteAlbumJson,
  copyStickerTable,
} from '../../../util/clipboard';
import { buildColorGroups } from '../../../modules/groups';
import BuilderPanel from './index';

const mapStateToProps = state => ({
  tags: state.tags,
  builderTags: state.controls.builderTags,
});

const mapDispatchToProps = {
  resetSpreads,
  alignStickersUsingFaceData,
  copyAlbumJson,
  pasteAlbumJson,
  resetView,
  buildColorGroups,
  updateControls,
  buildSpreads,
  copyStickerTable,
};

function mergeProps(
  stateProps,
  { buildSpreads, updateControls, ...dispatchProps }, // eslint-disable-line no-shadow
  ownProps
) {
  const { builderTags } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onBuildAlbumClick: () => {
      buildSpreads(builderTags);
    },
    onFilterChange: (e, { value }) => {
      updateControls({ builderTags: value });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(BuilderPanel);
