import React from 'react';
import { bool, string, func } from 'prop-types';
import { connect } from 'react-redux';
import { Feed, List } from 'semantic-ui-react';

import { getTargetNodeId, getWorkspace } from '../../../selectors/legacy';
import TreeNode from './TreeNode/index.dnd';
import { IdListShape } from '../../shapes';
import { swapElements } from '../../../actions/workspace';

function TreeRoot({
  visible,
  childIds,
  targetNodeId,
  boundSwapSpreadElements,
}) {
  return (
    visible && (
      <div className="elements-tree">
        <Feed.Extra>
          <List className="versions">
            {childIds
              .slice()
              .reverse()
              .map((id, index) => (
                <TreeNode
                  key={id}
                  id={id}
                  index={childIds.length - index - 1}
                  boundSwapSpreadElements={boundSwapSpreadElements}
                  targetNodeId={targetNodeId}
                  siblingIds={childIds}
                />
              ))}
          </List>
        </Feed.Extra>
      </div>
    )
  );
}

TreeRoot.propTypes = {
  visible: bool.isRequired,
  childIds: IdListShape.isRequired,
  targetNodeId: string.isRequired,
  boundSwapSpreadElements: func.isRequired,
};

const mapStateToProps = state => {
  const {
    controls: { stickerMode, showElementsTree },
  } = state;
  const targetNodeId = getTargetNodeId(state);
  const {
    nodes: {
      [targetNodeId]: { children: childIds },
    },
  } = getWorkspace(state);
  return {
    visible: !stickerMode && showElementsTree && childIds.length > 0,
    childIds,
    targetNodeId,
  };
};

const mapDispatchToProps = {
  boundSwapSpreadElements: swapElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeRoot);
