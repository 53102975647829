// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { Checkbox, Dropdown, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { updateControls, toggleStickerMode } from '../../modules/controls';
import GlobalSwatchColorPicker from './SwatchColorPicker/GlobalSwatchColorPicker';

class GlobalSettings extends React.Component {
  // todo unite into one change function

  changeGrid = (event, { checked }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ gridEnabled: checked }));
  };

  changeResolution = (event, { value }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ previewResolution: value }));
  };

  changeStickerMode = () => {
    const { dispatch } = this.props;

    dispatch(toggleStickerMode());
  };

  changeColorGroupMode = (event, { checked }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ colorGroupMode: checked }));
  };

  changeCropPreview = (event, { checked }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ cropPreview: checked }));
  };

  changeAutoFit = (event, { checked }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ autoFit: checked }));
  };

  changeEnableFilter = (event, { checked }) => {
    const { dispatch } = this.props;
    dispatch(updateControls({ enableFilter: checked }));
  };

  changeShowElementsTree = () => {
    const { dispatch, showElementsTree } = this.props;
    dispatch(updateControls({ showElementsTree: !showElementsTree }));
  };

  render() {
    const {
      autoFit,
      colorGroupMode,
      cropPreview,
      enableFilter,
      gridEnabled,
      previewResolution,
      stickerMode,
      showElementsTree,
    } = this.props;

    return (
      <>
        <Menu.Item fitted="horizontally">
          <GlobalSwatchColorPicker />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          {' Resolution:'}&nbsp;
          <Dropdown
            value={previewResolution}
            onChange={this.changeResolution}
            options={['small', 'medium', 'full'].map(key => ({
              value: key,
              key,
              text: key,
            }))}
            selection
          />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Grid"
            onChange={this.changeGrid}
            checked={gridEnabled}
          />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Auto fit images"
            onChange={this.changeAutoFit}
            checked={autoFit}
          />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Sticker mode"
            onChange={this.changeStickerMode}
            checked={stickerMode}
          />
        </Menu.Item>

        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Farbgruppen"
            onChange={this.changeColorGroupMode}
            checked={colorGroupMode}
          />
        </Menu.Item>

        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Crop preview"
            onChange={this.changeCropPreview}
            checked={cropPreview}
          />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          <Checkbox
            label="Enable filter"
            onChange={this.changeEnableFilter}
            checked={enableFilter}
          />
        </Menu.Item>
        <Menu.Item fitted="horizontally">
          <Checkbox
            className="qa-elements-tree"
            label="Elements tree"
            onChange={this.changeShowElementsTree}
            checked={showElementsTree}
          />
        </Menu.Item>
      </>
    );
  }
}

const mapStateToProps = state => ({
  previewStickers: state.controls.previewStickers,
  previewResolution: state.controls.previewResolution,
  gridEnabled: state.controls.gridEnabled,
  stickerMode: state.controls.stickerMode,
  colorGroupMode: state.controls.colorGroupMode,
  cropPreview: state.controls.cropPreview,
  enableFilter: state.controls.enableFilter,
  autoFit: state.controls.autoFit,
  showElementsTree: state.controls.showElementsTree,
});

export default connect(mapStateToProps)(GlobalSettings);
