import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Header,
  List,
  Input,
  Button,
  Label,
  Menu,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { createTag, deleteTag } from '../../modules/tags';
import { FilterPresetShape, TagShape } from '../shapes';

export const ListEditModal = ({ content, label, onDelete, onCreate }) => {
  const [title, setTitle] = useState('');

  const titleChange = e => {
    const { value } = e.target;
    setTitle(value);
  };

  return (
    <>
      <Header>{label}</Header>
      <Modal.Content>
        <List divided relaxed>
          {content.map(tag => (
            <List.Item key={tag.id}>
              <List.Content>
                <Menu secondary>
                  <Menu.Item>
                    <Label color="green" className="entry">
                      {tag.title}
                    </Label>
                  </Menu.Item>
                  {onDelete && (
                    <Menu.Item position="right">
                      <Button onClick={e => onDelete(e, tag.id)}>
                        Löschen
                      </Button>
                    </Menu.Item>
                  )}
                </Menu>
              </List.Content>
            </List.Item>
          ))}

          {onCreate && (
            <List.Item>
              <List.Content>
                <Menu secondary>
                  <Menu.Item>
                    <Input
                      ref={node => {
                        if (node) {
                          node.focus();
                        }
                      }}
                      placeholder="Neuer Eintrag..."
                      onChange={titleChange}
                      value={title}
                      id="edit-modal-create"
                    />
                  </Menu.Item>
                  <Menu.Item position="right">
                    <Button
                      className="qa-edit-modal-save-btn"
                      onClick={e => onCreate(e, title)}
                    >
                      Speichern
                    </Button>
                  </Menu.Item>
                </Menu>
              </List.Content>
            </List.Item>
          )}
        </List>
      </Modal.Content>
    </>
  );
};

ListEditModal.defaultProps = {
  content: [],
  label: '',
  onCreate: () => {},
  onDelete: () => {},
};

ListEditModal.propTypes = {
  content: PropTypes.arrayOf(FilterPresetShape),
  label: PropTypes.string,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

const TagsModalComp = props => (
  <ListEditModal
    {...props}
    label="Tags verwalten"
    onCreate={(_, title) => props.createTag(title)} // eslint-disable-line react/destructuring-assignment
    onDelete={(_, id) => props.deleteTag(id)} // eslint-disable-line react/destructuring-assignment
  />
);

TagsModalComp.defaultProps = {
  content: [],
  label: '',
  onCreate: () => {},
  onDelete: () => {},
};

TagsModalComp.propTypes = {
  content: PropTypes.arrayOf(TagShape),
  label: PropTypes.string,
  createTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
};

const mapStateToProps = state => ({
  content: state.tags,
});

const mapDispatchToProps = {
  createTag,
  deleteTag,
};

export const TagsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsModalComp);

export default ListEditModal;
