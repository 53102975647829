import { getStickerMode } from './controls';
import { getSelectedStickerIds } from './legacy';

export const getSelectInside = state => state.selection.selectInside;

export const getStickerSelectionActive = state => {
  return getStickerMode(state) || getSelectedStickerIds(state).length > 0;
};

export default getSelectInside;
