import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Header, Modal, Step, Tab } from 'semantic-ui-react';

import { API_URL, importSourceTypes } from '../../../constants';
import useProjects from '../../../hooks/useProjects';
import RunningJobs from './RunningJobs';
import StepContent from './StepContent';

const startImport = ({
  target,
  selectedTypes,
  project,
  albumTitle,
  source,
  album,
}) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/jobs`, {
        task: 'import',
        params: {
          target,
          source,
          selected_data_for_import: selectedTypes,
          app_project_id: project,
          editor_album_id: album,
          album_title: albumTitle,
        },
      })
      .then(() => resolve())
      .catch(err => reject(err));
  });

const ImportModal = ({ albums, token }) => {
  const [step, setStep] = useState(0);
  const [project, setProject] = useState('');
  const [album, setAlbum] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [source, setSource] = useState(importSourceTypes.appProject);
  const [target, setTarget] = useState('new');
  const [submitting, setSubmitting] = useState(false);
  const [activeIndex, setTab] = useState(0);
  const { projects, error } = useProjects(token);

  if (error) {
    return <div>Error loading projects: {error.message}</div>;
  }

  const handleStep = () => {
    if (step < 2) {
      setStep(step + 1);
      return;
    }

    setSubmitting(true);

    const albumTitle =
      source === importSourceTypes.appProject
        ? projects.find(({ id }) => id === project).name
        : albums.find(({ id }) => id === album).title;

    startImport({
      project,
      selectedTypes,
      target,
      source,
      album,
      albumTitle,
    })
      .then(() => {
        setTab(1);
        setStep(0);
        setSubmitting(false);
      })
      .catch(err => {
        console.error('Failed at POST /jobs: ', err);
      });
  };

  const tabChange = (tab, { activeIndex }) => {
    setTab(activeIndex);
  };

  const isProjectSourceEmpty =
    source === importSourceTypes.appProject && isEmpty(project);

  // since the album-id can be an integer, we can't use "isEmpty"
  const isAlbumSourceEmpty =
    source === importSourceTypes.editorAlbum && album === '';

  const disabled =
    (step === 0 && (isProjectSourceEmpty || isAlbumSourceEmpty)) ||
    (step === 1 && selectedTypes.length < 1) ||
    (step === 2 && target === '') ||
    submitting;

  return (
    <>
      <Header>Daten importieren</Header>
      <Modal.Content>
        <Tab
          onTabChange={tabChange}
          activeIndex={activeIndex}
          panes={[
            {
              menuItem: 'Neuer Import',
              render: () => (
                <>
                  <Step.Group unstackable widths={3}>
                    <Step active={step === 0}>
                      <Step.Content>
                        <Step.Title>Source</Step.Title>
                        <Step.Description>Stickerzentrale</Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={step === 1}>
                      <Step.Content>
                        <Step.Title>Data</Step.Title>
                        <Step.Description>
                          Was soll importiert werden?
                        </Step.Description>
                      </Step.Content>
                    </Step>
                    <Step active={step === 2}>
                      <Step.Content>
                        <Step.Title>Target</Step.Title>
                        <Step.Description>Wohin?</Step.Description>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <Container>
                    <StepContent
                      step={step}
                      projects={projects}
                      project={project}
                      albums={albums}
                      album={album}
                      onProjectSelect={setProject}
                      onAlbumSelect={setAlbum}
                      selectedTypes={selectedTypes}
                      onDataSelect={setSelectedTypes}
                      target={target}
                      onTargetSelect={setTarget}
                      source={source}
                      onSetSource={setSource}
                    />
                  </Container>
                  <div className="flex end">
                    <Button primary onClick={handleStep} disabled={disabled}>
                      {step === 2 ? 'importieren' : 'weiter'}
                    </Button>
                  </div>
                </>
              ),
            },
            {
              menuItem: 'Laufende Imports',
              render: () => <RunningJobs />,
            },
          ]}
        />
      </Modal.Content>
    </>
  );
};

ImportModal.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  token: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  albums: state.albums.albums,
  token: state.user.user.data.app_token,
});

export default connect(mapStateToProps)(ImportModal);
