import { connect } from 'react-redux';

import { getSections, getSpreadsCount } from '../../../selectors/legacy';
import {
  updateSection,
  moveSection,
  moveSticker,
  createStickerAfterDirectUpload,
} from '../../../modules/sections';
import { insertElements } from '../../../actions/workspace';
import { historyAnchor } from '../../../modules/history';
import { showContextMenu } from '../../../modules/menu';
import {
  albumUnsaved,
  toggleTrashcan,
  updateControls,
} from '../../../modules/controls';
import SectionInputWithDnd from './index.dnd';
import { itemTypes } from '../../../constants';

const mapStateToProps = state => ({
  albumId: state.albums.currentAlbum,
  isLoading: state.loading.some(({ blocking }) => blocking),
  sections: getSections(state),
  sectionDragEnabled: state.controls.sectionDragEnabled,
  spreadsCount: getSpreadsCount(state),
});

const mapDispatchToProps = {
  albumUnsaved,
  toggleTrashcan,
  updateControls,
  updateSection,
  moveSection,
  moveSticker,
  createStickerAfterDirectUpload,
  showContextMenu,
  historyAnchor,
  insertElements,
};

function mergeProps(
  stateProps,
  { showContextMenu, ...dispatchProps }, // eslint-disable-line no-shadow
  ownProps
) {
  const { id } = ownProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onContextMenu: event => showContextMenu(event, itemTypes.section, id),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SectionInputWithDnd);
