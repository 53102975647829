import React from 'react';
import { arrayOf, string } from 'prop-types';
import { Table } from 'semantic-ui-react';

import StickerInput from '../StickerInput/index.container';
import { StickerShape, FileUploadsShape } from '../../shapes';

function StickersList({ stickers, sectionId, stickerUploads }) {
  return (
    <Table.Body>
      {/* Currently running sticker file uploads */}
      {Object.keys(stickerUploads).map(uploadId => (
        <Table.Row key={uploadId}>
          <Table.Cell>
            {stickerUploads[uploadId].fileName}:{' '}
            {stickerUploads[uploadId].progress} %
          </Table.Cell>
        </Table.Row>
      ))}

      {/* Stickers from props */}
      {stickers.map((sticker, index) => {
        return (
          <Table.Row key={sticker.id}>
            <Table.Cell>
              <StickerInput
                id={sticker.id}
                sectionId={sectionId}
                index={index}
                key={sticker.id}
                name={sticker.name}
                position={sticker.position}
                image={sticker.image}
                doubleSticker={sticker.doubleSticker}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}

      {/* StickerInput to add new stickers */}
      <Table.Row>
        <Table.Cell>
          <StickerInput
            id="new"
            key="new"
            index={stickers.length}
            sectionId={sectionId}
            placeholder
          />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  );
}

StickersList.propTypes = {
  stickers: arrayOf(StickerShape).isRequired,
  sectionId: string.isRequired,
  stickerUploads: FileUploadsShape.isRequired,
};

export default StickersList;
