import { func, string } from 'prop-types';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { imageRatios } from '../../constants';

const customRatio = { value: null, text: 'Benutzerdefiniert' };

function ImageRatioSelect({ value, onChange }) {
  return (
    <Dropdown
      size="mini"
      placeholder="Größe"
      selection
      value={value}
      options={[customRatio, ...imageRatios]}
      onChange={onChange}
      style={{ width: 170 }}
    />
  );
}

ImageRatioSelect.defaultProps = {
  value: null,
};

ImageRatioSelect.propTypes = {
  onChange: func.isRequired,
  value: string,
};

export default ImageRatioSelect;
