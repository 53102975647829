import { clamp } from 'lodash';

import { dimensions } from '../constants';
import computeNativeImageSize from './images/computeNativeImageSize';
import Point from './Point';

export function getBoundingBox(items, targetNode = null) {
  let itemsList = items;

  if (!Array.isArray(items)) {
    itemsList = [itemsList];
  }
  const allPoints = [];

  // TODO can be refactored using getBoundingClientRect

  itemsList.forEach(item => {
    const elementNode = document.getElementById(item.props.id);
    if (elementNode) {
      const { props } = item;
      let points = [
        new Point(0, 0),
        new Point(props.width, 0),
        new Point(0, props.height),
        new Point(props.width, props.height),
      ];
      points = points.map(point => {
        let result = point.localToGlobal(elementNode);
        if (targetNode) {
          result = result.globalToLocal(targetNode);
        }
        return result;
      });
      allPoints.push(...points);
    }
  });

  if (!allPoints.length) return null;
  const xs = allPoints.map(point => point.x);
  const ys = allPoints.map(point => point.y);
  const xMin = Math.min(...xs);
  const xMax = Math.max(...xs);
  const yMin = Math.min(...ys);
  const yMax = Math.max(...ys);

  return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
}

export function getChildrenBoundingBox(group) {
  const targetNode = document.getElementById(group.props.id);
  const allPoints = [];
  group.children.forEach(item => {
    const elementNode = document.getElementById(item.props.id);
    if (elementNode) {
      const { width, height } = item.props;
      const points = [
        new Point(0, 0),
        new Point(width, 0),
        new Point(0, height),
        new Point(width, height),
      ];
      allPoints.push(
        ...points.map(point =>
          point.localToGlobal(elementNode).globalToLocal(targetNode)
        )
      );
    }
  });
  const xs = allPoints.map(point => point.x);
  const ys = allPoints.map(point => point.y);
  const xMin = Math.min(...xs);
  const xMax = Math.max(...xs);
  const yMin = Math.min(...ys);
  const yMax = Math.max(...ys);
  const box = { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
  const allFinite = Object.keys(box).every(key => Number.isFinite(box[key]));
  if (allFinite) {
    return box;
  }
  return false;
}

export function getItemTransformInGroup(props, groupNode) {
  const elementNode = document.getElementById(props.id);
  const points = [new Point(0, 0), new Point(1, 0)].map(point =>
    point.localToGlobal(elementNode).globalToLocal(groupNode)
  );
  const transformed = points[1].minus(points[0]);
  const scale = transformed.length();
  const angle = Math.atan2(transformed.y, transformed.x);
  const rotation = (180 * angle) / Math.PI;

  return { x: points[0].x, y: points[0].y, rotation, scale };
}

export function spreadTransformToGroupTransform(props, spreadNode, groupNode) {
  let { x, y, rotation, scale } = props;
  const points = [new Point(x, y), new Point(x + 1, y)].map(point =>
    point.localToGlobal(spreadNode).globalToLocal(groupNode)
  );
  const transformed = points[1].minus(points[0]);
  scale *= transformed.length();
  const angle = Math.atan2(transformed.y, transformed.x);
  rotation += (180 * angle) / Math.PI;
  x = points[0].x;
  y = points[0].y;
  return { ...props, x, y, rotation, scale };
}

export function getStickerImageBox(sticker) {
  return {
    width: sticker.doubleSticker
      ? dimensions.stickerWidth * 2
      : dimensions.stickerWidth,
    height: dimensions.stickerHeight,
  };
}

const elementImageBox = {
  width: 100,
  height: 100,
};

export function getSizeFromImageObject(imageObject, box = elementImageBox) {
  const { width: boxWidth, height: boxHeight } = box;
  return computeNativeImageSize(imageObject, boxWidth, boxHeight);
}

export function getElementTransform(element, width, height) {
  const svg = element.closest('svg');

  function localToGlobal(x, y) {
    let p = svg.createSVGPoint();
    p.x = x;
    p.y = y;
    p = p.matrixTransform(element.getCTM());
    p = p.matrixTransform(svg.getCTM().inverse());
    return p;
  }

  const topLeft = localToGlobal(0, 0);
  const topRight = localToGlobal(1, 0);

  const xUnitVec = {
    x: topRight.x - topLeft.x,
    y: topRight.y - topLeft.y,
  };

  const rotation = (Math.atan2(xUnitVec.y, xUnitVec.x) * 180) / Math.PI;
  const scale = Math.sqrt(xUnitVec.x * xUnitVec.x + xUnitVec.y * xUnitVec.y);
  const { x, y } = topLeft;

  return { x, y, rotation, scale, width, height };
}

export function boxesIntersect(box1, box2) {
  return !(
    box2.left > box1.right ||
    box2.right < box1.left ||
    box2.top > box1.bottom ||
    box2.bottom < box1.top
  );
}

export function rectsIntersect(rect1, rect2) {
  return !(
    rect2.x > rect1.x + rect1.width ||
    rect2.x + rect2.width < rect1.x ||
    rect2.y > rect1.y + rect1.height ||
    rect2.y + rect2.height < rect1.y
  );
}

export function satisfyGrid(p) {
  return new Point(
    Math.round(p.x / dimensions.gridSize) * dimensions.gridSize,
    Math.round(p.y / dimensions.gridSize) * dimensions.gridSize
  );
}

export function fitRectIntoArea(rect, area, coverWholeArea = false) {
  const scaleStrategy = coverWholeArea ? Math.max : Math.min;
  const scale = scaleStrategy(
    area.width / rect.width,
    area.height / rect.height
  );
  const x = rect.x - (area.width / scale - rect.width) / 2;
  const y = rect.y - (area.height / scale - rect.height) / 2;
  return { x, y, scale };
}

export function axisAlignedBoundingRect(points) {
  const xs = points.map(p => p.x);
  const ys = points.map(p => p.y);
  const xMin = Math.min(...xs);
  const yMin = Math.min(...ys);
  const xMax = Math.max(...xs);
  const yMax = Math.max(...ys);
  return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
}

export function transformRectCorners(matrix, width, height) {
  const corners = [
    { x: 0, y: 0 },
    { x: 0, y: height },
    { x: width, y: 0 },
    { x: width, y: height },
  ];
  return corners.map(p => {
    const { x, y } = matrix.transformPoint(p);
    return { x, y };
  });
}

export function getTransformToElement(sourceElement, targetElement) {
  // "Polyfill" for removed DOM function: https://github.com/xosystem/SVG-getTransformToElement-FIX
  return targetElement
    .getCTM()
    .inverse()
    .multiply(sourceElement.getCTM());
}

export function decomposeMatrix({ a, b, e, f }) {
  // Adapted from https://github.com/deoxxa/transformation-matrix-js/blob/5d0391a169e938c31da6c09f5d4e7dc836fd0ec2/src/matrix.js
  const translate = { x: e, y: f };
  const scale = Math.sqrt(a * a + b * b);
  const radians = b > 0 ? Math.acos(a / scale) : -Math.acos(a / scale);
  const rotation = (radians * 180) / Math.PI;
  return {
    translate,
    scale,
    rotation,
  };
}

const matrixFromSelector = selector => {
  const node = document.querySelector(selector);
  if (!node) {
    return null;
  }
  const svg = node.closest('svg');
  const { a, b, c, d, e, f } = getTransformToElement(node, svg);
  return new DOMMatrixReadOnly([a, b, c, d, e, f]);
};

export const elementMatrix = id => matrixFromSelector(`[data-id='${id}']`);

export const imageMatrix = id =>
  matrixFromSelector(`[data-id='${id}'] .inside`);

export const bounds = (matrix, size) => {
  if (!matrix || !size) {
    return null;
  }
  const { width, height } = size;
  const { translate, scale, rotation } = decomposeMatrix(matrix);
  const { x, y } = translate;
  return {
    rotation,
    x,
    y,
    width: width * scale,
    height: height * scale,
  };
};

export function restrictPanAndZoomToContentArea(
  pan,
  zoom,
  clientWidth,
  clientHeight,
  spreadsCount
) {
  // Calculate content-size
  const { pageWidth, pageHeight, pagePadding } = dimensions;
  const contentWidth = pageWidth * 2;
  const contentHeight = (pageHeight + pagePadding) * spreadsCount - pagePadding;

  // Zoom: minZoom fits all pages twice within the viewport. maxZoom is abitrarily
  // limited.
  const minZoom =
    Math.min(clientWidth / contentWidth, clientHeight / contentHeight) / 2;
  const maxZoom = 100;
  const allowedZoom = clamp(zoom, minZoom, maxZoom);

  // Pan: For each dimension the bounds are calculated indepently. The content-area
  // can move further to the edges, but not completely out of the viewport.
  const edge = 10;
  const viewportWidth = clientWidth / allowedZoom;
  const viewportHeight = clientHeight / allowedZoom;
  const allowedPan = {
    x: clamp(pan.x, -viewportWidth + edge, contentWidth - edge),
    y: clamp(pan.y, -viewportHeight + edge, contentHeight - edge),
  };

  return [allowedPan, allowedZoom];
}
