import { getWorkspace } from './legacy';
import Matrix from '../util/Matrix';

const computeTransformationMatrix = (node, nodes) => {
  if (!node) return new Matrix();

  const { props, parent: parentNodeId } = node;
  const { x = 0, y = 0, rotation = 0, scale = 1 } = props;
  const parentNode = nodes[parentNodeId];

  return computeTransformationMatrix(parentNode, nodes)
    .translate(x, y)
    .scale(scale)
    .rotate(rotation);
};

/*
 * Returns the SVG transformation matrix for the given node relativ to
 * the spread it is located on.
 */
export const getTransformationMatrix = (state, nodeId) => {
  const { nodes } = getWorkspace(state);
  const node = nodes[nodeId];

  return computeTransformationMatrix(node, nodes);
};

export default getTransformationMatrix;
