// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { Image as SemanticImage } from 'semantic-ui-react';

import { resolveImage } from '../../util/images';

const getFileExtension = path =>
  path
    .split('.')
    .pop()
    .toLowerCase();

const Image = props => {
  const { imageObject, ...rest } = props;
  /**
   * TODO: Instead of returning null, we should
   * display some visual indication that the image
   * is broken.
   */
  if (!imageObject) {
    return null;
  }

  /**
   * In a perfect world, all images should bring a URL -
   * this check is to prevent app crashes on edge cases
   * - broken files, imagemagick fails,
   * s3 upload race conditions, ...
   */
  const { src } = resolveImage(imageObject);
  if (!src) {
    return null;
  }

  const isSvg = getFileExtension(src) === 'svg';
  return <SemanticImage src={src} {...rest} className={isSvg ? 'svg' : ''} />;
};

export default Image;
