export default class Point {
  // this needs to be initialized before using
  static svg;

  x;

  y;

  constructor(x = 0, y = 0) {
    this.x = x;
    this.y = y;
  }

  static fromObject(obj) {
    return new Point(obj.x, obj.y);
  }

  screenToSvg() {
    Point.svg = document.getElementById('svg');
    if (!Point.svg) {
      return this;
    }
    let pt = Point.svg.createSVGPoint();
    pt.x = this.x;
    pt.y = this.y;
    pt = pt.matrixTransform(Point.svg.getScreenCTM().inverse());
    return new Point(pt.x, pt.y);
  }

  globalToSvg() {
    Point.svg = document.getElementById('svg');
    let pt = Point.svg.createSVGPoint();
    pt.x = this.x;
    pt.y = this.y;
    pt = pt.matrixTransform(Point.svg.getCTM().inverse());
    return new Point(pt.x, pt.y);
  }

  localToGlobal(sourceElement) {
    let svg;
    if (sourceElement) {
      svg = sourceElement.closest('svg');
    }
    if (svg) {
      let pt = svg.createSVGPoint();
      pt.x = this.x;
      pt.y = this.y;
      pt = pt.matrixTransform(sourceElement.getCTM());
      return new Point(pt.x, pt.y);
    }
    // eslint-disable-next-line no-console
    console.error('no sourceElement for localToGlobal');
    return new Point(0, 0);
  }

  globalToLocal(targetElement) {
    let svg;
    if (targetElement) {
      svg = targetElement.closest('svg');
    }
    if (svg) {
      let pt = svg.createSVGPoint();
      pt.x = this.x;
      pt.y = this.y;
      pt = pt.matrixTransform(targetElement.getCTM().inverse());
      return new Point(pt.x, pt.y);
    }
    // eslint-disable-next-line no-console
    console.error('no sourceElement for localToGlobal');
    return new Point(0, 0);
  }

  add(p) {
    this.x += p.x;
    this.y += p.y;
  }

  subtract(p) {
    this.x -= p.x;
    this.y -= p.y;
  }

  scale(s) {
    this.x *= s;
    this.y *= s;
  }

  scaled = s => new Point(this.x * s, this.y * s);

  minus = p => new Point(this.x - p.x, this.y - p.y);

  plus = p => new Point(this.x + p.x, this.y + p.y);

  multiply = p => new Point(this.x * p.x, this.y * p.y);

  toString = () => `Point [x=${this.x}, y=${this.y}]`;

  length = () => Math.sqrt(this.x * this.x + this.y * this.y);

  angleTo = p => (Math.atan2(p.y - this.y, p.x - this.x) * 180) / Math.PI;

  rotate(radians) {
    const x1 = this.x;
    this.x = this.x * Math.cos(radians) - this.y * Math.sin(radians);
    this.y = x1 * Math.sin(radians) + this.y * Math.cos(radians);
  }

  limitToArea(x1, y1, x2, y2) {
    if (this.x < x1) this.x = x1;
    if (this.y < y1) this.y = y1;
    if (this.x > x2) this.x = x2;
    if (this.y > y2) this.y = y2;
  }
}
