import * as Sentry from '@sentry/browser';
import axios from 'axios';

import { prepareAndFillImages } from '../actions/images';
import { loadWorkspace, replaceSpreads } from '../actions/workspace';
import { API_URL } from '../constants';
import { getLegacySpreads, getSections } from '../selectors/legacy';
import { flatten, generateSpreadsForSections } from '../util/generators';
import { uniquifyIds } from '../util/spreads';
import {
  denormalizeWorkspace,
  prepareSectionsAfterImport,
  prepareSectionsFromServer,
  prepareSpreadsFromServer,
} from '../util/workspace';
import { initialState as colorInitialState, replaceColors } from './colors';
import { albumChanged, historyAnchor } from './history';
import { replaceImages } from './images';
import { addToQueue, removeFromQueue } from './loading';
import { replaceSections } from './sections';
import { clearSelection, elementSelect } from './selection';
import { replaceTexts } from './texts';

/**
 * Action-Types.
 */
export const SET_ALBUM = 'album/set';
export const SET_ALBUM_DATA = 'album/data';
export const MUTATE_ALBUM_SETTINGS = 'album/mutateSettings';
export const SET_ALBUMS = 'album/set_list';
export const ADD_ALBUM = 'album/add';
export const ALBUM_SAVING = 'album/saving';
export const UPDATE = 'album/update';

const initialState = {
  albums: [],
  currentAlbum: null,
  albumData: {
    bookSpine: true,
  },
  saving: false,
  showingCrashSave: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALBUM:
      return {
        ...state,
        currentAlbum: action.payload.id,
      };
    case SET_ALBUMS:
      return {
        ...state,
        albums: action.payload.albums,
        perPage: action.payload.perPage,
        total: action.payload.total,
      };
    case MUTATE_ALBUM_SETTINGS:
      return {
        ...state,
        albumData: {
          ...state.albumData,
          ...action.payload,
        },
      };
    case SET_ALBUM_DATA:
      return {
        ...state,
        albumData: action.payload,
      };
    case ADD_ALBUM:
      return {
        ...state,
        albums: [...state.albums, action.payload],
      };
    case ALBUM_SAVING:
      return {
        ...state,
        saving: action.payload,
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

const updateAlbums = payload => dispatch => dispatch({ type: UPDATE, payload });

/**
 * Set the current album.
 */
export const setAlbum = id => dispatch =>
  dispatch({ type: SET_ALBUM, payload: { id } });

/**
 * Update attributes of the current album.
 * @param {*} data
 */
export const patchAlbumData = data => (dispatch, getState) => {
  dispatch({ type: MUTATE_ALBUM_SETTINGS, payload: data });
  const { albums } = getState();
  axios.patch(`${API_URL}/albums/${albums.currentAlbum}`, data);
};

/**
 * Fetches `paginates_per` albums from the server and stores them to the albums list.
 * It also stores the exposed header fields `per-page` and `total` from the
 * `api-pagination` gem.
 */
export const fetchAlbums = (page = 1, query = '') => dispatch => {
  dispatch(addToQueue('fetchAlbums'));
  return axios
    .get(`${API_URL}/albums?page=${page}&query=${query}`)
    .then(({ data: { albums }, headers: { 'per-page': perPage, total } }) => {
      dispatch({ type: SET_ALBUMS, payload: { albums, perPage, total } });
      dispatch(removeFromQueue('fetchAlbums'));
      return Promise.resolve();
    })
    .catch(e => {
      console.error('error', e);
    });
};

export const deleteAlbum = id => dispatch => {
  return axios
    .delete(`${API_URL}/albums/${id}`)
    .then(() => dispatch(fetchAlbums()));
};

/**
 * Create a new album on the server.
 */
export const addAlbum = data => dispatch => {
  return axios.post(`${API_URL}/albums`, data).then(({ data: { album } }) => {
    dispatch({ type: ADD_ALBUM, payload: album });
    return Promise.resolve(album);
  });
};

/**
 * Fetches sections, spreads and album attributes for given album and saves them to store.
 * @param {*} id The Album id
 */
export const fetchAlbum = (id, apiKey = null, apiUrl = API_URL) => dispatch => {
  dispatch(addToQueue(`fetchAlbum-${id}`));
  const url = `${apiUrl}/albums/${id}${apiKey ? `?api_key=${apiKey}` : ''}`;
  return axios
    .get(url)
    .then(async ({ data: { album } }) => {
      const { images } = album;
      dispatch(replaceImages(await dispatch(prepareAndFillImages(images))));

      let { spreads, sections, colors } = album;
      const { texts, pdfs, title } = album;

      if (colors) {
        if (
          !Array.isArray(colors) ||
          colors.length !== colorInitialState.length
        ) {
          colors = colorInitialState;
        }
        dispatch(replaceColors(colors));
      }
      if (texts) {
        dispatch(
          replaceTexts(
            texts.map(text => ({ ...text, body: text.body ? text.body : '' }))
          )
        );
      }

      dispatch(replaceSections(prepareSectionsFromServer(sections)));

      dispatch({
        type: SET_ALBUM_DATA,
        payload: {
          id,
          title,
          pdfs,
        },
      });

      if (spreads.length === 0) {
        // first load of imported album
        sections = prepareSectionsAfterImport(sections);
        dispatch(replaceSections(sections));
        spreads = generateSpreadsForSections(sections);
      }

      dispatch(clearSelection());
      dispatch(
        replaceSpreads(prepareSpreadsFromServer(spreads), {
          resetHistory: true,
        })
      );

      // this disables the save button. since we just loaded an album, there are not changes to save yet
      dispatch(historyAnchor());
      dispatch(albumChanged(false));

      dispatch(testForDuplicateIds(true));
      dispatch(removeFromQueue(`fetchAlbum-${id}`));
    })
    .catch(e => {
      console.error(`error during load: ${e.toString()}`);
      throw e;
    });
};

export const fetchAlbumAndMergeCrashSave = (id, crashSave) => dispatch => {
  dispatch(addToQueue(`fetchAlbum-${id}`));
  const url = `${API_URL}/albums/${id}}`;
  axios
    .get(url)
    .then(async ({ data: { album } }) => {
      const { images } = album;
      dispatch(replaceImages(await dispatch(prepareAndFillImages(images))));

      const { texts, pdfs, title } = album;
      const { workspace, sections, colors } = crashSave;

      dispatch(replaceSections(sections));

      dispatch(replaceColors(colors));

      if (texts) {
        dispatch(
          replaceTexts(
            texts.map(text => ({ ...text, body: text.body ? text.body : '' }))
          )
        );
      }

      dispatch({
        type: SET_ALBUM_DATA,
        payload: {
          id,
          title,
          pdfs,
        },
      });

      dispatch(loadWorkspace(workspace));
      dispatch(historyAnchor());
      dispatch(albumChanged());
      dispatch(updateAlbums({ showingCrashSave: true }));

      dispatch(removeFromQueue(`fetchAlbum-${id}`));
    })
    .catch(e => {
      console.error(`error during loading autosave: ${e.toString()}`);
    });
};

/**
 * Stores albums spreads and sections on the server.
 */
export const saveAlbum = () => (dispatch, getState) => {
  dispatch(elementSelect([]));

  return new Promise((resolve, reject) => {
    dispatch({ type: ALBUM_SAVING, payload: true });

    const state = getState();

    const {
      albums: { currentAlbum, showingCrashSave },
      workspaceAndSections: {
        present: { workspace, sections },
      },
      colors,
    } = state;

    const data = {
      colors,
      sections,
      spreads: denormalizeWorkspace(workspace),
    };

    axios
      .patch(`${API_URL}/albums/${currentAlbum}`, data)
      .then(() => {
        dispatch({ type: ALBUM_SAVING, payload: false });
        dispatch(albumChanged(false));
        if (showingCrashSave) {
          localStorage.removeItem(`crashsave_${currentAlbum}`);
        }
        resolve();
      })
      .catch(() => {
        dispatch({ type: ALBUM_SAVING, payload: false });
        alert('Error during save');
        reject();
      });
  });
};

export const testForDuplicateIds = (fix = false) => (dispatch, getState) => {
  const state = getState();
  const spreads = getLegacySpreads(state);
  const sections = getSections(state);
  const {
    albums: { currentAlbum },
  } = state;

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const extractElementIds = children =>
    children
      .map(element => {
        let id = null;
        if (element && element.props) {
          id = element.props.id;
        }
        if (Array.isArray(element.children)) {
          return [id, ...extractElementIds(element.children)];
        }
        return id;
      })
      .filter(item => item);

  const spreadIds = flatten(extractElementIds(spreads));
  const sectionIds = sections.map(section => section.id);
  const stickerIds = flatten(
    sections.map(section => section.stickers.map(sticker => sticker.id))
  );

  function test(ids, name) {
    const a = ids.length;
    const b = ids.filter(onlyUnique).length;
    if (a !== b) {
      if (name === 'spreads' && fix) {
        dispatch(fixDuplicateSpreadIds());
      }
      try {
        Sentry.captureException(
          new Error(`duplicate id: ${name} in album ${currentAlbum}`)
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  test([...spreadIds], 'spreads');
  test([...sectionIds], 'sections');
  test([...stickerIds], 'sticker');
  test([...stickerIds, ...sectionIds, ...spreadIds], 'all');
};

export const fixDuplicateSpreadIds = () => (dispatch, getState) => {
  let spreads = getLegacySpreads(getState());
  spreads = spreads.map(spread => ({
    ...spread,
    children: spread.children.map(uniquifyIds),
  }));
  dispatch(clearSelection());
  dispatch(replaceSpreads(spreads));
};
