import { bool, func } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Dropdown, Menu } from 'semantic-ui-react';

import { appearanceInputModes as Modes, blendModes } from '../../../constants';
import { historyAnchor } from '../../../modules/history';
import { AppearanceInputModeShape } from '../../shapes';
import NumberSlider from '../NumberSlider';
import SwatchColorPicker from '../SwatchColorPicker';

export const parseProps = props => {
  const { mode } = props;

  switch (mode) {
    case Modes.fill:
      return {
        swatch: props.fill,
        fillOpacity:
          props.fillOpacity !== undefined ? props.fillOpacity * 100 : 100,
      };
    case Modes.fillAndBlend:
      return {
        swatch: props.fill,
        fillOpacity:
          props.fillOpacity !== undefined ? props.fillOpacity * 100 : 100,
        blend: props.blend,
        grayscale: !!props.grayscale,
        gradientMap: !!props.gradientMap,
        gradientMapIntensity:
          props.gradientMapIntensity !== undefined
            ? props.gradientMapIntensity * 100
            : 100,
      };
    case Modes.stroke:
      return {
        swatch: props.stroke,
        thickness: props.strokeWidth !== undefined ? props.strokeWidth : 0,
      };
    case Modes.opacity:
      return {
        opacity: props.opacity !== undefined ? props.opacity * 100 : 100,
      };
    default:
      return {};
  }
};

export const parseState = (state, mode) => {
  switch (mode) {
    default:
    case Modes.fill:
      return {
        fill: state.swatch,
        fillOpacity: state.fillOpacity / 100,
      };
    case Modes.fillAndBlend:
      return {
        fill: state.swatch,
        fillOpacity: state.fillOpacity / 100,
        blend: state.blend,
        grayscale: state.grayscale,
        gradientMap: state.gradientMap,
        gradientMapIntensity: state.gradientMapIntensity / 100,
      };
    case Modes.stroke:
      return {
        stroke: state.swatch,
        strokeWidth: state.thickness,
      };
    case Modes.opacity:
      return {
        opacity: state.opacity / 100,
      };
  }
};

function AppearanceInputForm(props) {
  const { onChange, gradientMap, grayscale, mode } = props;
  const parsedProps = parseProps(props);
  const {
    blend,
    fillOpacity,
    gradientMapIntensity,
    opacity,
    thickness,
    swatch,
  } = parsedProps;
  const dispatch = useDispatch();

  const handleSetProp = (prop, value) => {
    if (!onChange) {
      return;
    }

    const delta = { [prop]: value };
    onChange(parseState({ ...parsedProps, ...delta }, mode));
    dispatch(historyAnchor());
  };

  return (
    <Menu vertical secondary>
      {[Modes.fill, Modes.stroke, Modes.fillAndBlend].includes(mode) && (
        <Menu.Item>
          <SwatchColorPicker
            color={swatch}
            onChange={value => handleSetProp('swatch', value)}
          />
        </Menu.Item>
      )}

      {mode === Modes.fillAndBlend && swatch !== -1 && (
        <Menu.Item>
          Blend:{' '}
          <Dropdown
            placeholder="Blend"
            options={[
              { key: 'off', text: '(off)', value: null },
              ...blendModes.map(option => ({
                key: option,
                text: option,
                value: option,
              })),
            ]}
            value={blend}
            selection
            onChange={(_, { value }) => handleSetProp('blend', value)}
          />
        </Menu.Item>
      )}

      {(mode === Modes.fill || (mode === Modes.fillAndBlend && blend)) && (
        <Menu.Item>
          <NumberSlider
            min={0}
            max={100}
            reset={100}
            value={fillOpacity}
            onChange={value => handleSetProp('fillOpacity', value)}
            step={1}
          />
        </Menu.Item>
      )}

      {mode === Modes.fillAndBlend && (
        <Menu.Item>
          <Checkbox
            label="Graustufen"
            checked={grayscale}
            onChange={(e, { checked }) => handleSetProp('grayscale', checked)}
          />
        </Menu.Item>
      )}

      {mode === Modes.fillAndBlend && (
        <Menu.Item>
          <Checkbox
            label="Gradient Map (letzte 3 Farbfelder)"
            checked={gradientMap}
            onChange={(_, { checked }) => handleSetProp('gradientMap', checked)}
          />
        </Menu.Item>
      )}

      {mode === Modes.fillAndBlend && gradientMap && (
        <Menu.Item>
          <NumberSlider
            min={0}
            max={100}
            reset={100}
            value={gradientMapIntensity}
            onChange={value => handleSetProp('gradientMapIntensity', value)}
            step={1}
          />
        </Menu.Item>
      )}

      {mode === Modes.stroke && (
        <Menu.Item>
          <NumberSlider
            min={0}
            max={10}
            reset={0}
            value={thickness}
            onChange={value => handleSetProp('thickness', value)}
            step={0.25}
          />
        </Menu.Item>
      )}

      {mode === Modes.opacity && (
        <Menu.Item style={{ minWidth: 400 }}>
          <NumberSlider
            min={0}
            max={100}
            reset={100}
            value={opacity}
            onChange={value => handleSetProp('opacity', value)}
            step={1}
          />
        </Menu.Item>
      )}
    </Menu>
  );
}

AppearanceInputForm.defaultProps = {
  gradientMap: false,
  grayscale: false,
};

AppearanceInputForm.propTypes = {
  onChange: func.isRequired,
  mode: AppearanceInputModeShape.isRequired,
  gradientMap: bool,
  grayscale: bool,
};

export default AppearanceInputForm;
