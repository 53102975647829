import flow from 'lodash/flow';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';

import { itemTypes } from '../../../constants';
import StickerInput from './index';

const stickerSource = {
  beginDrag({ id, index, sectionId, toggleTrashcan }) {
    toggleTrashcan(true);
    return { id, index, sectionId };
  },

  endDrag({ toggleTrashcan }) {
    toggleTrashcan(false);
  },
};

const stickerTarget = {
  hover(props, monitor, component) {
    const {
      index: hoverIndex,
      sectionId: targetSectionId,
      moveSticker,
    } = props;
    const dragIndex = monitor.getItem().index;
    const sourceSectionId = monitor.getItem().sectionId;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex && sourceSectionId === targetSectionId) {
      return;
    }

    // Determine rectangle on screen
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    if (sourceSectionId === targetSectionId) {
      moveSticker(monitor.getItem().id, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
    } else {
      moveSticker(monitor.getItem().id, hoverIndex, targetSectionId);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.

      // eslint-disable-next-line no-param-reassign
      monitor.getItem().index = hoverIndex;
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().sectionId = targetSectionId;
    }
  },

  drop({ historyAnchor }) {
    historyAnchor();
  },
};

export default flow(
  DragSource(itemTypes.sticker, stickerSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget(itemTypes.sticker, stickerTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(StickerInput);
