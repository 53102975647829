import { connect } from 'react-redux';

import { makeLoadingSelector } from '../../selectors/legacy';
import handleKeyboardEvent from '../../actions/handleKeyboardEvent';
import EditorWithErrorBoundary from './EditorWithErrorBoundary';

const mapStateToProps = state => ({
  cropPreview: state.controls.cropPreview,
  loading: makeLoadingSelector(true)(state),
  modal: state.menu.modal,
  previewStickers: state.controls.previewStickers,
  previewResolution: state.controls.previewResolution,
  title: state.albums.albumData.title,
});

const mapDispatchToProps = {
  handleKeyboardEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorWithErrorBoundary);
