import { node } from 'prop-types';
import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import NestedOverlayContext from './NestedOverlayContext';

function NestedOverlayTrigger({ children, overlay }) {
  const [rootClose, setRootClose] = useState(true);
  return (
    <NestedOverlayContext.Provider value={{ setRootClose }}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        transition={false}
        rootClose={rootClose}
        overlay={
          <Popover>
            <Popover.Content>{overlay}</Popover.Content>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    </NestedOverlayContext.Provider>
  );
}

NestedOverlayTrigger.propTypes = {
  children: node.isRequired,
  overlay: node.isRequired,
};

export default NestedOverlayTrigger;
