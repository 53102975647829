import get from 'lodash/get';
import some from 'lodash/some';

const makeWidthGetter = image => key => get(image, `${key}.width`);

const imageHasSizeMeta = image => {
  const hasWidthAtKey = makeWidthGetter(image);

  // Dimensions (`width` and `height`) can be stored under two different keys: `meta` or `details`
  return some(['meta', 'details'], hasWidthAtKey);
};

export default imageHasSizeMeta;
