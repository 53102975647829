import React, { useRef } from 'react';
import { string, bool, func, arrayOf, shape } from 'prop-types';
import { Button } from 'semantic-ui-react';

import Scrubber from './Scrubber';
import { gradient, defaultColors } from './utils';
import { FilterValueShape } from '../../shapes';
import './sliders.scss';

const MultiSlider = ({
  onChange,
  onChanged,
  onReset,
  values,
  defaultValues,
  colors,
  labels,
  connectMid,
  extendedScale,
}) => {
  const trackRef = useRef(null);

  const change = delta => {
    // eslint-disable-next-line prefer-const
    let { min, mid, max } = { ...values, ...delta };
    if (connectMid) {
      if (delta.max !== undefined) {
        mid = (values.mid - values.min) / (values.max - values.min); // relative
        mid = (max - values.min) * mid + values.min; // new absolute;
      } else if (delta.min !== undefined) {
        mid = (values.mid - values.min) / (values.max - values.min); // relative
        mid = (values.max - min) * mid + min; // new absolute;
      }
    }
    onChange({ min, mid, max });
  };

  const reset = () => {
    onChange(defaultValues);
    onChanged();
    onReset();
  };

  const quant = 1 / 256;

  return (
    <div className="slider">
      <div className="control">
        <div
          className="track qa-multi-slider-track"
          style={{ background: gradient(colors) }}
          ref={trackRef}
        />
        <Scrubber
          label={labels.min}
          value={values.min}
          min={extendedScale ? -0.5 : 0}
          max={values.max - quant}
          trackRef={trackRef}
          onChange={min => change({ min })}
          onChanged={onChanged}
          color="#000"
        />
        <Scrubber
          label={labels.mid}
          value={values.mid}
          min={values.min}
          max={values.max}
          trackRef={trackRef}
          onChange={mid => change({ mid })}
          onChanged={onChanged}
          color="#666"
        />
        <Scrubber
          label={labels.max}
          value={values.max}
          min={values.min + quant}
          max={extendedScale ? 1.5 : 1}
          trackRef={trackRef}
          onChange={max => change({ max })}
          onChanged={onChanged}
          color="#aaa"
        />
      </div>
      <Button icon="undo" onClick={reset} />
    </div>
  );
};

MultiSlider.defaultProps = {
  values: { min: 0.0, mid: 0.5, max: 1.0 },
  defaultValues: { min: 0.0, mid: 0.5, max: 1.0 },
  colors: defaultColors,
  labels: { min: null, mid: null, max: null },
  connectMid: true,
  extendedScale: false,
};

MultiSlider.propTypes = {
  values: FilterValueShape,
  defaultValues: FilterValueShape,
  colors: arrayOf(string),
  labels: shape({ min: string, mid: string, max: string }),
  connectMid: bool,
  extendedScale: bool,
  onChange: func.isRequired,
  onChanged: func.isRequired,
  onReset: func.isRequired,
};

export default MultiSlider;
