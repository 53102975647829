import React, { useState, useRef, useEffect } from 'react';
import { number, string } from 'prop-types';

import { FillShape } from '../../shapes';

const SizedText = props => {
  const {
    children,
    fontSize,
    maxWidth,
    fontFamily,
    fontStyle,
    fill,
    fillOpacity,
    x,
    y,
  } = props;

  const [scale, setScale] = useState(1);
  const textEl = useRef(null);
  const textProps = { fontFamily, fontStyle, fill, fillOpacity, x, y };

  const adjustedFontSize = fontSize * scale;

  useEffect(() => {
    const { width } = textEl.current.getBBox();
    let newScale = maxWidth / (width / scale);
    if (newScale > 1) {
      newScale = 1;
    }
    const diff = Math.abs(newScale - scale);
    if (diff > 0.01) {
      setScale(newScale);
    }
  }, [children, fontSize, maxWidth, scale]);

  return (
    <text {...textProps} ref={textEl} fontSize={adjustedFontSize}>
      {children}
    </text>
  );
};

SizedText.defaultProps = {
  children: '',
  fontFamily: '',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fill: null,
  fillOpacity: null,
  x: null,
  y: null,
  textAnchor: null,
  dominantBaseline: null,
};

SizedText.propTypes = {
  fontSize: number.isRequired,
  maxWidth: number.isRequired,
  children: string,
  fontFamily: string,
  fontStyle: string,
  fontWeight: string,
  fill: FillShape,
  fillOpacity: number,
  x: number,
  y: number,
  textAnchor: string,
  dominantBaseline: string,
};

export default SizedText;
