import React from 'react';
import { func } from 'prop-types';
import { Menu, Icon } from 'semantic-ui-react';
import { DirectUpload } from 'activestorage';

import { UPLOADS_URL, resolutions, itemTypes } from '../../../constants';
import { downloadFile } from '../../../util/index';
import { ImageObjectShape } from '../../shapes';
import TagsSubmenu from './TagsSubmenu/index.container';

function ImageActionItems({
  setStickerLogo,
  convertToStock,
  patchExistingImage,
  imageObject,
}) {
  const { id, stock } = imageObject;

  const fileChange = e => {
    Array.from(e.target.files).forEach(file => {
      const upload = new DirectUpload(file, UPLOADS_URL);
      upload.create((_error, blob) => {
        patchExistingImage(id, { blob_id: blob.id });
      });
    });
  };

  const startImageUpload = () => {
    const input = document.createElement('INPUT');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', false);
    input.addEventListener('change', fileChange);
    input.click();
  };

  const startImageDownload = () => {
    let src;
    let filename;
    if (typeof imageObject.blob === 'string') {
      src = imageObject.blob;
      filename = src.split('/').pop();
    } else {
      src = imageObject.blob[resolutions.original];
      filename = imageObject.blob.filename;
    }
    downloadFile(src, filename);
  };

  return (
    <>
      <Menu.Item className="header qa-context-menu-item">
        {stock ? 'Stock-Bild' : 'Bild'}
      </Menu.Item>
      <TagsSubmenu itemType={itemTypes.image} itemId={id} />
      <Menu.Item onClick={() => setStickerLogo(id)}>
        Als Sticker-Logo festlegen
      </Menu.Item>
      <Menu.Item onClick={startImageUpload}>
        Bild ersetzen <Icon name="upload" />
      </Menu.Item>
      <Menu.Item onClick={startImageDownload}>
        Bild herunterladen <Icon name="download" />
      </Menu.Item>
      {!stock && (
        <Menu.Item onClick={() => convertToStock(id)}>
          In Stock-Bibliothek verschieben <Icon name="sign-in" />
        </Menu.Item>
      )}
    </>
  );
}

ImageActionItems.propTypes = {
  imageObject: ImageObjectShape.isRequired,
  setStickerLogo: func.isRequired,
  convertToStock: func.isRequired,
  patchExistingImage: func.isRequired,
};

export default ImageActionItems;
