import React from 'react';
import { Input, Icon, Button } from 'semantic-ui-react';
import { func, number, string, bool } from 'prop-types';

import { stickerFieldLabels } from '../../../constants';
import Image from '../Image';
import { generateSpread } from '../../../util/generators';
import { generateId } from '../../../util/index';
import { IdShape, ImageObjectShape } from '../../shapes';

class StickerInput extends React.PureComponent {
  changed = false;

  constructor(props) {
    super(props);
    let name = '';
    if (!props.placeholder && props[props.stickerField]) {
      name = props[props.stickerField];
    }
    this.state = {
      editing: false,
      uploading: false,
      name,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let name;
    if (props.placeholder) {
      name = state.name;
    } else {
      name = props[props.stickerField] ? props[props.stickerField] : '';
    }
    return { ...state, name };
  }

  click = () => {
    this.setState({ editing: true });
  };

  nameChange = e => {
    const { id, placeholder, stickerField, updateSticker } = this.props;
    if (!placeholder) {
      updateSticker(id, {
        [stickerField]: e.target.value,
      });
      this.changed = true;
    } else {
      this.setState({ name: e.target.value });
    }
  };

  nameBlur = () => {
    const { updateControls, historyAnchor } = this.props;
    this.setState({ editing: false });
    updateControls({ sectionDragEnabled: true });
    if (this.changed) {
      historyAnchor();
    }
  };

  formSubmit = e => {
    const {
      sectionId,
      placeholder,
      stickerField,
      spreadsCount,
      createSection,
      insertElements,
      createSticker,
      historyAnchor,
    } = this.props;
    const { name } = this.state;

    e.preventDefault();
    e.stopPropagation();

    if (placeholder && name !== '') {
      const newSticker = {
        id: generateId(),
        name: '',
        position: '',
        [stickerField]: name,
      };

      if (!sectionId) {
        // section is also a placeholder
        const newSection = {
          id: generateId(),
          name: 'Unbenanntes Team',
          stickers: [],
        };

        // Insert a spread for the new section before the last spread
        const index = spreadsCount - 1;
        createSection(newSection);
        insertElements([generateSpread(newSection.id)], 'root', index);
        createSticker(newSection.id, newSticker);
      } else {
        createSticker(sectionId, newSticker);
      }
      this.setState({ name: '' });
      historyAnchor();
    }
  };

  renderComponent(opacity = null) {
    const {
      id,
      imageObject,
      stickerNumber,
      placeholder,
      stickerField,
      onContextMenu,
      doubleSticker,
    } = this.props;
    const { editing, name } = this.state;

    const slug = name.replace(/ /g, '-').toLowerCase();

    return (
      <form
        onSubmit={this.formSubmit}
        style={{ opacity }}
        className={`sticker-input qa-sticker-${
          placeholder ? 'placeholder' : 'item'
        } qa-sticker-${slug} qa-sticker-${id}`}
      >
        {placeholder && (
          <span>
            <Icon name="plus" />
          </span>
        )}
        <button type="button" onClick={this.click} className="flex grow">
          {!placeholder && (
            <>
              <span className="number">{stickerNumber}</span>
              <span className={`image${doubleSticker ? ' double' : ''}`}>
                {imageObject ? (
                  <Image imageObject={imageObject} />
                ) : (
                  <div className="background-none" />
                )}
              </span>
            </>
          )}
          <span className="name">
            {editing || placeholder ? (
              <Input
                placeholder={stickerFieldLabels[stickerField]}
                name="new_sticker_name"
                autoFocus={editing}
                onChange={this.nameChange}
                onBlur={this.nameBlur}
                value={name}
              />
            ) : (
              name
            )}
          </span>
        </button>
        <input type="submit" value="Submit" style={{ display: 'none' }} />
        {!placeholder && (
          <Button
            className="qa-context-menu-btn"
            basic
            compact
            onClick={onContextMenu}
            floated="right"
            icon="content"
          />
        )}
      </form>
    );
  }

  render() {
    const { sectionDragEnabled } = this.props;

    if (sectionDragEnabled) {
      const { isDragging, connectDragSource, connectDropTarget } = this.props;
      const opacity = isDragging ? 0 : 1;

      return (
        connectDragSource &&
        connectDropTarget &&
        connectDragSource(connectDropTarget(this.renderComponent(opacity)))
      );
    }
    return this.renderComponent();
  }
}

StickerInput.defaultProps = {
  id: null,
  placeholder: false,
  imageObject: null,
  sectionId: null,
  doubleSticker: false,
  createSection: null,
  stickerNumber: null,
};

StickerInput.propTypes = {
  connectDragSource: func.isRequired,
  connectDropTarget: func.isRequired,

  createSection: func,
  createSticker: func.isRequired,
  historyAnchor: func.isRequired,
  insertElements: func.isRequired,
  updateSticker: func.isRequired,
  onContextMenu: func.isRequired,
  updateControls: func.isRequired,

  id: IdShape,
  imageObject: ImageObjectShape,
  isDragging: bool.isRequired,
  stickerNumber: number,
  sectionId: string,
  doubleSticker: bool,
  placeholder: bool,
  sectionDragEnabled: bool.isRequired,
  spreadsCount: number.isRequired,
  stickerField: string.isRequired,
};

export default StickerInput;
