import * as workspaceActions from './workspace';
import * as sectionActions from '../modules/sections';
import * as selectionActions from '../modules/selection';
import * as historyActions from '../modules/history';
import * as controlActions from '../modules/controls';

const executeActionByName = actionName => dispatch => {
  const func =
    workspaceActions[actionName] ||
    sectionActions[actionName] ||
    selectionActions[actionName] ||
    historyActions[actionName] ||
    controlActions[actionName];
  dispatch(func());
};

export default executeActionByName;
