import { arrayOf, func, oneOf, string } from 'prop-types';
import React from 'react';
import { Form, Checkbox, Container } from 'semantic-ui-react';

import { importSourceTypes } from '../../../constants';

const dataTypes = [
  { id: 'stickers', label: 'Stickers', source: importSourceTypes.appProject },
  { id: 'logos', label: 'Logos', source: importSourceTypes.appProject },
  { id: 'ads', label: 'Anzeigen', source: importSourceTypes.appProject },
  {
    id: 'photographer_ad',
    label: 'Fotografen-Anzeige',
    source: importSourceTypes.appProject,
  },
  {
    id: 'background_images',
    label: 'Hintergrundbilder',
    source: importSourceTypes.appProject,
  },
  { id: 'texts', label: 'Texte', source: importSourceTypes.appProject },
  {
    id: 'sections_and_stickers',
    label: 'Teams + Sticker (werden zu bestehenden Stickern angefügt)',
    source: importSourceTypes.editorAlbum,
  },
];

const OverwriteWarning = () => (
  <p>
    <b>Achtung:</b> Wenn Du Sticker-Daten in ein bestehendes Album importierst,
    werden dort alle vorhandenen Sticker (und Stickerfotos) gelöscht.
  </p>
);

const SetData = ({ onDataSelect, selectedTypes, source }) => {
  const matchingDataTypes = dataTypes.filter(
    dataType => dataType.source === source
  );

  const handleSelect = type => {
    const nextSelectedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter(str => str !== type)
      : [...selectedTypes, type];
    onDataSelect(nextSelectedTypes);
  };

  return (
    <Container>
      {source === importSourceTypes.appProject && <OverwriteWarning />}
      <Form>
        {matchingDataTypes.map(({ id, label }) => (
          <Form.Field key={id}>
            <Checkbox
              label={label}
              onChange={() => handleSelect(id)}
              checked={selectedTypes.indexOf(id) !== -1}
            />
          </Form.Field>
        ))}
      </Form>
    </Container>
  );
};

SetData.defaultProps = {
  source: null,
};

SetData.propTypes = {
  source: oneOf(Object.values(importSourceTypes)),
  onDataSelect: func.isRequired,
  selectedTypes: arrayOf(string).isRequired,
};

export default SetData;
