import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/browser';

import App from './containers/app';
import store from './store';

import './api'; // initialize API client

import './sass/_.scss';

const sentryDSN =
  process.env.NODE_ENV === 'production'
    ? 'https://fe84f748e9ea415989074ef02c38e6e6@sentry.io/1482264'
    : undefined;

Sentry.init({
  dsn: sentryDSN,
});

const target = document.querySelector('#root');

render(
  <div id="app">
    <Provider store={store}>
      <BrowserRouter>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </BrowserRouter>
    </Provider>
  </div>,
  target
);
