import storage from './storage';

const userStorageKey = 'user';

export function getUser() {
  return storage.get(userStorageKey, null);
}

export function setUser(user) {
  return storage.set(userStorageKey, user);
}

export function clearUser() {
  return storage.rm(userStorageKey);
}

const headersStorageKey = 'headers';

export function getHeaders() {
  return storage.get(headersStorageKey, {});
}

export function setHeaders(headers) {
  return storage.set(headersStorageKey, headers);
}

export function clearHeaders() {
  return storage.rm(headersStorageKey);
}
