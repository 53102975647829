import { connect } from 'react-redux';

import { itemTypes } from '../../../../constants';
import { setModal } from '../../../../modules/menu';
import { applyTag } from '../../../../modules/tags';
import { getImage } from '../../../../selectors/images';
import { getSortedTags } from '../../../../selectors/tags';
import { getTemplateById } from '../../../../selectors/templates';
import TagsSubmenu from './index';

const makeGetActiveTagIds = itemType => {
  const itemSelector =
    itemType === itemTypes.template ? getTemplateById : getImage;

  return (state, itemId) => {
    const tags = itemSelector(state, itemId)?.tags || [];
    return tags.map(tag => tag.id);
  };
};

function mapStateToProps(state, { itemType, itemId }) {
  const getActiveTagIds = makeGetActiveTagIds(itemType);

  return {
    tags: getSortedTags(state),
    activeTagIds: getActiveTagIds(state, itemId),
  };
}

const mapDispatchToProps = {
  setModal,
  applyTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsSubmenu);
