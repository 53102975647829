import { arrayOf, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Dimmer, Input, List, Loader } from 'semantic-ui-react';

import { IdShape } from '../../shapes';

const FilterableList = ({ items, onSelect, activeItemId }) => {
  const [filter, setFilter] = useState('');

  const filteredItems = items.filter(
    ({ name, title }) =>
      (name || title).toLowerCase().indexOf(filter.toLowerCase()) !== -1
  );

  return (
    <>
      {items.length === 0 && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      <Input
        icon="search"
        placeholder="Suchen..."
        onChange={({ target: { value } }) => setFilter(value)}
        fluid
      />
      <div style={{ height: '400px', overflow: 'auto', padding: '1rem 0' }}>
        <List divided relaxed>
          {filteredItems.map(({ id, name, title }) => (
            <List.Item
              as="a"
              key={id}
              onClick={() => onSelect(id)}
              active={activeItemId === id}
            >
              <List.Content>
                <List.Header>{name || title}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    </>
  );
};

FilterableList.defaultProps = {
  activeItemId: null,
};

FilterableList.propTypes = {
  items: arrayOf(
    shape({
      id: IdShape.isRequired,
      name: string,
      title: string,
    })
  ).isRequired,
  onSelect: func.isRequired,
  activeItemId: IdShape,
};

export default FilterableList;
