// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';

import Login from '../login';
import Editor from '../editor';
import Start from '../start';
import Test from '../test';
import Renderer from '../renderer';
import AlbumPreview from '../album_preview';
import ContextMenu from '../../components/menu/ContextMenu/index.container';

const App = memo(({ authenticated }) => {
  const publicRoutes = useMemo(
    () => [
      // public routes
      <Route exact key={1} path="/" component={Login} />,
      <Route exact key={2} path="/test" component={Test} />,

      // render-routes are also public, need api key if not authenticated
      <Route
        exact
        key={3}
        path="/render/:albumId?"
        render={props => <Renderer {...props} mode="album" />}
      />,

      // the album preview route is also "public" though it requires a valid SZ-JWT.
      <Route
        exact
        key={4}
        path="/preview/:albumId?"
        render={props => <AlbumPreview {...props} />}
      />,
    ],
    []
  );

  const privateRoutes = useMemo(
    () => [
      <Route
        exact
        key={4}
        path="/start"
        render={props => <Start {...props} />}
      />,
      <Route
        exact
        key={5}
        path="/album/:albumId?"
        render={props => <Editor {...props} />}
      />,
    ],
    []
  );

  // catchall-route
  const catchAll = (
    <Route key={6}>
      <Redirect to={authenticated ? '/start' : '/'} />
    </Route>
  );

  let routes = authenticated
    ? [...publicRoutes, ...privateRoutes]
    : [...publicRoutes];
  routes = [...routes, catchAll];

  return (
    <main>
      <Switch>{routes}</Switch>
      <ContextMenu />
    </main>
  );
});

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
});

export default withRouter(connect(mapStateToProps)(App));
