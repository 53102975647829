import { difference } from 'lodash';
import { createSelector } from 'reselect';

import {
  getSelectedStickerIds,
  getStickerIds,
  getStickersById,
} from './legacy';

// Directory-handle of the local directory
export const selectSyncDirectoryHandle = state => state.sync.directoryHandle;

// Interval of a running sync, if any
export const selectSyncIntervalId = state => state.sync.intervalId;

// Is a sync currently running?
export const selectSyncRunning = state => selectSyncIntervalId(state) !== null;

// Sticker-Ids that are targeted by the sync
export const selectSyncStickerIds = state => state.sync.stickerIds;

// Target only selected stickers
export const selectSyncSelectionOnly = state => state.sync.selectionOnly;

// Determine Sticker-Ids to be syncronized
export const selectSyncTargetedStickerIds = createSelector(
  [
    selectSyncSelectionOnly,
    getSelectedStickerIds,
    getStickerIds,
    getStickersById,
  ],
  (selectionOnly, selectedStickerIds, allStickerIds, stickersById) => {
    const targetedStickerIds = selectionOnly
      ? selectedStickerIds
      : allStickerIds;

    // Limit to stickers with images
    const targetedStickerIdsWithAnImage = targetedStickerIds.filter(
      stickerId => !!stickersById[stickerId].image
    );

    return targetedStickerIdsWithAnImage;
  }
);

// Number of targeted stickers
export const selectSyncTargetedStickerCount = createSelector(
  [selectSyncTargetedStickerIds],
  targetedStickerIds => targetedStickerIds.length
);

// Queue of actions representing the scheduled up- and downloads
export const selectSyncQueue = state => state.sync.queue;

// Sticker-Ids that are targeted by the sync but are not in the queue
export const selectSyncUnqueuedStickerIds = state => {
  const queue = selectSyncQueue(state);
  const syncStickerIds = selectSyncStickerIds(state);

  // Remove all stickers that are already in the queue
  const queueIds = queue.map(item => item.stickerId);
  const stickerIds = difference(syncStickerIds, queueIds);

  return stickerIds;
};
