import { DirectUpload } from 'activestorage';

import { UPLOADS_URL } from '../constants';
import store from '../store';
import {
  getSelectedElements,
  getSelectedElementIds,
  getTargetNodeId,
  getWorkspace,
  getSections,
} from '../selectors/legacy';
import { elementSelect } from '../modules/selection';
import {
  insertElements,
  deleteElements,
  loadWorkspace,
} from '../actions/workspace';
import { replaceSections } from '../modules/sections';
import {
  patchExistingImage,
  createAndPlaceImageAfterDirectUpload,
} from '../actions/images';
import { inputElementFocused } from './index';
import { historyAnchor } from '../modules/history';
import {
  getItemTransformInGroup,
  spreadTransformToGroupTransform,
} from './geometry';
import { uniquifyIds } from './spreads';
import { denormalizeElement } from './workspace';
import { selectStickerNumberByIdLookup } from '../selectors/stickers';

// TODO: refactor this whole file as a redux-module

function uploadAndCreateImageElement(file, callback) {
  const upload = new DirectUpload(file, UPLOADS_URL);
  upload.create((error, blob) => {
    if (error) {
      console.error(error);
    } else {
      callback(blob.id);
    }
  });
}

// *** CLIPBOARD ***

const clipboardSignature = 'sts-you-client/1.0';

export const ClipboardActions = {};

ClipboardActions.copyItems = event => {
  if (inputElementFocused()) return;
  event.preventDefault();
  const state = store.getState();
  const ids = getSelectedElementIds(state);
  const { nodes } = getWorkspace(state);

  const elements = ids.map(id => {
    const element = denormalizeElement({ nodes, root: id });
    const spreadNode = document.getElementById(id).closest('.spread');
    return {
      ...element,
      props: {
        ...element.props,
        ...getItemTransformInGroup(element.props, spreadNode),
      },
    };
  });

  const wrapper = { format: clipboardSignature, elements };
  const svgCode = ids
    .map(id => document.getElementById(id).outerHTML)
    .join(' ');
  const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">${svgCode}</svg>`;

  event.clipboardData.setData('text/plain', svg);
  event.clipboardData.setData('application/json', JSON.stringify(wrapper));
};

ClipboardActions.cutItems = event => {
  if (inputElementFocused()) return;
  ClipboardActions.copyItems(event);
  const selectedElementIds = getSelectedElementIds(store.getState());
  store.dispatch(deleteElements(selectedElementIds));
  store.dispatch(historyAnchor());
};

ClipboardActions.pasteItems = event => {
  if (inputElementFocused()) return;
  const targetNodeId = getTargetNodeId(store.getState());

  const list = (event.clipboardData || event.originalEvent.clipboardData).items;

  const formats = ['application/json', 'text/plain', 'image/png']; // formats in priority
  const items = [];
  for (let i = 0; i < formats.length; i += 1) {
    for (let j = 0; j < list.length; j += 1) {
      if (list[j].type === formats[i]) {
        items.push(list[j]);
      }
    }
  }
  if (items.length === 0) {
    return;
  }

  const item = items[0];

  switch (item.type) {
    case 'text/plain':
      event.preventDefault();
      item.getAsString(text => {
        // mac illustrator adds a "0x00" char at the end
        const nullPos = text.indexOf('\0');
        if (nullPos > -1) {
          text = text.substr(0, nullPos);
        }

        if (text.indexOf('<svg') !== -1) {
          const file = new File([text], 'clipboard.svg', {
            type: 'image/svg+xml',
          });

          const items = getSelectedElements(store.getState());

          // TODO: this block is identical to the one below (line 204 etc.)
          // BLOCK START
          if (items.length === 1 && items[0].type === 'Image') {
            const { image, id } = items[0].props;
            uploadAndCreateImageElement(file, blob_id => {
              store.dispatch(patchExistingImage(image, { blob_id }));

              // needed to update the image in spreadContainer ?!
              setTimeout(() => {
                store.dispatch(elementSelect([id]));
              }, 500);
            });
          } else {
            store.dispatch(deleteElements(items.map(item => item.props.id)));
            uploadAndCreateImageElement(file, blob_id => {
              store.dispatch(
                createAndPlaceImageAfterDirectUpload(
                  { blob_id },
                  { spreadId: targetNodeId }
                )
              );
            });
          }
          // BLOCK END
        }
      });
      break;

    case 'application/json':
      event.preventDefault();
      item.getAsString(json => {
        let data;
        try {
          data = JSON.parse(json);
        } catch (e) {
          console.error(e.message);
        }
        if (data.format === clipboardSignature) {
          const state = store.getState();
          let { elements } = data;

          const { isolation } = state.controls;
          if (isolation) {
            const groupNode = document
              .getElementById(isolation)
              .closest('.container');
            const spreadNode = groupNode.closest('.spread');
            elements = elements.map(item => ({
              ...item,
              props: spreadTransformToGroupTransform(
                item.props,
                spreadNode,
                groupNode
              ),
            }));
          }

          elements = elements.map(uniquifyIds);
          store.dispatch(insertElements(elements, targetNodeId));
          store.dispatch(elementSelect(elements.map(item => item.props.id)));
        } else {
          console.error('wrong json format');
        }
      });
      break;

    case 'image/png':
      event.preventDefault();
      const file = item.getAsFile();
      if (!file) {
        console.error('file object is null');
        return;
      }

      const items = getSelectedElements(store.getState());

      // TODO: this block is identical to the one in above
      // BLOCK START
      if (items.length === 1 && items[0].type === 'Image') {
        const { image, id } = items[0].props;
        uploadAndCreateImageElement(file, blob_id => {
          store.dispatch(patchExistingImage(image, { blob_id }));

          // needed to update the image in spreadContainer ?!
          setTimeout(() => {
            store.dispatch(elementSelect([id]));
          }, 500);
        });
      } else {
        store.dispatch(deleteElements(items.map(item => item.props.id)));
        uploadAndCreateImageElement(file, blob_id => {
          store.dispatch(
            createAndPlaceImageAfterDirectUpload(
              { blob_id },
              { spreadId: targetNodeId }
            )
          );
        });
      }
      // BLOCK END
      break;

    default:
      console.error('no supported format in clipboard');
  }
  store.dispatch(historyAnchor());
};

const setClipboardText = content => {
  navigator.permissions
    .query({ name: 'clipboard-write' })
    .then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(content).then(
          () => window.alert('Successfully copied'), // eslint-disable-line no-alert
          () => window.alert('Copy error') // eslint-disable-line no-alert
        );
      }
    })
    .catch(() => window.alert('Permission error')); // eslint-disable-line no-alert
};

export const copyAlbumJson = () => (dispatch, getState) => {
  const state = getState();
  const workspace = getWorkspace(state);
  const sections = getSections(state);
  const json = JSON.stringify({ workspace, sections });
  setClipboardText(json);
};

export const pasteAlbumJson = () => dispatch => {
  const json = window.prompt('Paste album JSON', ''); // eslint-disable-line no-alert
  try {
    const { workspace, sections } = JSON.parse(json);
    dispatch(loadWorkspace(workspace));
    dispatch(replaceSections(sections));
    // eslint-disable-next-line no-alert
    window.alert(
      `Successfully loaded: ${Math.round(json.length / 1024)} KB loaded`
    );
  } catch (e) {
    window.alert('Wrong format'); // eslint-disable-line no-alert
  }
};

export const copyStickerTable = () => (_, getState) => {
  const state = getState();
  const sections = getSections(state);
  const stickerNumberByIdLookup = selectStickerNumberByIdLookup(state);
  const header = `${['Nummer', 'Typ', 'Team', 'Name', 'Position'].join(
    '\t'
  )}\n`;
  const csvText = sections.reduce((sectionText, section) => {
    return (
      sectionText +
      section.stickers.reduce((stickerText, sticker) => {
        const values = [
          stickerNumberByIdLookup[sticker.id],
          sticker.doubleSticker ? 'Doppel' : 'Einzel',
          section.name,
          sticker.name,
          sticker.position,
        ];
        return `${stickerText +
          values
            .map(value =>
              value
                .toString()
                .split('\\')
                .join(' ')
                .split('\t')
                .join(' ')
            )
            .join('\t')}\n`;
      }, '')
    );
  }, header);
  setClipboardText(csvText);
};
