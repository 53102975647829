import { oneOf } from 'prop-types';
import React from 'react';

import { getAppearanceFromProps } from '../../../../selectors/legacy';
import BaseElement from '../BaseElement';
import * as symbols from './symbols';

function Symbol(props) {
  const { symbol } = props;
  const appearance = getAppearanceFromProps(props);
  const Component = symbols[symbol];
  if (!Component) {
    return null;
  }

  return (
    <BaseElement {...props}>
      <Component {...props} {...appearance} />
    </BaseElement>
  );
}

Symbol.defaultProps = {
  symbol: null,
};

Symbol.propTypes = {
  symbol: oneOf(Object.keys(symbols)),
};

export default Symbol;
