import { useSelector, useDispatch } from 'react-redux';

import getStickerIdBelowCursor from './getStickerIdBelowCursor';
import {
  getSingleSelectedElement,
  getIsolation,
} from '../../../selectors/legacy';
import { setIsolation } from '../../../modules/controls';
import {
  setSelectInside,
  stickerSelect,
  clearSelection,
} from '../../../modules/selection';

export default function useHandleDoubleClick() {
  const singleSelectedElement = useSelector(getSingleSelectedElement);
  const isolationActive = !!useSelector(getIsolation);
  const dispatch = useDispatch();
  if (!singleSelectedElement) {
    return null;
  }
  const {
    type,
    props: { id, image, symbol },
  } = singleSelectedElement;
  if ((type === 'Image' && !!image) || (type === 'Text' && !symbol)) {
    return event => {
      event.stopPropagation();
      dispatch(setSelectInside(true));
    };
  }
  if (type === 'Group' && !isolationActive) {
    return event => {
      event.stopPropagation();
      dispatch(clearSelection());
      dispatch(setIsolation(id));
    };
  }
  if (type === 'StickerArea') {
    return event => {
      event.stopPropagation();
      const stickerId = getStickerIdBelowCursor(event);
      if (stickerId) {
        dispatch(stickerSelect([stickerId]));
        dispatch(setSelectInside(true));
      }
    };
  }
  return null;
}
