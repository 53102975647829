import React from 'react';
import { number } from 'prop-types';

import Point from '../../util/Point';
import AbstractOperation from './AbstractOperation';

const RotateOperation = ({ width, height, zoom }) => {
  function update(e, { rotation }, { parentStart, pivot, parentPointer }) {
    const va = parentStart.minus(pivot);
    const vb = parentPointer.minus(pivot);
    const p = new Point(1, 0);
    const delta = vb.angleTo(p) - va.angleTo(p);
    let newRotation = rotation + delta;
    if (e.shiftKey) {
      newRotation = Math.round(newRotation / 45) * 45;
    }
    return { rotation: newRotation };
  }

  const handleSize = 40 / zoom;

  return (
    <AbstractOperation
      onUpdate={update} // eslint-disable-line react/jsx-no-bind
      render={({ onStart }) => (
        <>
          <rect
            width={handleSize}
            height={handleSize}
            x={-handleSize}
            y={-handleSize}
            className="handle rotate top left qa-handle-rotate-top-left"
            onMouseDown={e => onStart(e)}
          />
          <rect
            width={handleSize}
            height={handleSize}
            x={width}
            y={-handleSize}
            className="handle rotate top right qa-handle-rotate-top-right"
            onMouseDown={e => onStart(e)}
          />
          <rect
            width={handleSize}
            height={handleSize}
            x={-handleSize}
            y={height}
            className="handle rotate bottom left"
            onMouseDown={e => onStart(e)}
          />
          <rect
            width={handleSize}
            height={handleSize}
            x={width}
            y={height}
            className="handle rotate bottom right"
            onMouseDown={e => onStart(e)}
          />
        </>
      )}
    />
  );
};

RotateOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  zoom: number.isRequired,
};

export default RotateOperation;
