import { createSelector } from 'reselect';

export const selectColors = state => state.colors;

export const selectResolvedColor = createSelector(
  [selectColors, (_, colorValueOrIndex) => colorValueOrIndex],
  (colors, colorValueOrIndex) => {
    if (typeof colorValueOrIndex === 'number') {
      return colors[colorValueOrIndex];
    }

    return colorValueOrIndex;
  }
);
