import React, { useContext, useEffect, useRef, useState } from 'react';
import { xor, flow, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from 'react-use';

import {
  elementSelect,
  spreadSelect,
  stickerSelect,
  clearSelection,
} from '../../../modules/selection';
import {
  getSelectedElementIds,
  getSelectedStickerIds,
} from '../../../selectors/legacy';
import {
  rectsIntersect,
  axisAlignedBoundingRect,
} from '../../../util/geometry';
import { ViewportContext } from '../Viewport';
import { updateControls } from '../../../modules/controls';
import getAvailableElementAreas from './getAvailableElementAreas';
import getAvailableStickerAreas from './getAvailableStickerAreas';
import useShiftOrMetaPressed from '../../../hooks/useShiftOrMetaPressed';
import useViewportState from '../Viewport/useViewportState';

function Selector() {
  const { viewportRef, clientToViewportCoordinates } = useContext(
    ViewportContext
  );
  const { zoom } = useViewportState();
  const editingId = useSelector(state => state.draft.editingId);
  const textEditingActive = !!editingId;
  const stickerMode = useSelector(state => state.controls.stickerMode);

  const selectedElementIds = useSelector(getSelectedElementIds);
  const selectedStickerIds = useSelector(getSelectedStickerIds);
  const stickerSelection = stickerMode || selectedStickerIds.length > 0;
  const selection = stickerSelection ? selectedStickerIds : selectedElementIds;
  const itemSelect = stickerSelection ? stickerSelect : elementSelect;
  const dispatch = useDispatch();

  const shiftOrMetaPressed = useShiftOrMetaPressed();

  const [mouseMovePointer, setMouseMovePointer] = useState(null);
  const [mouseDownPointer, setMouseDownPointer] = useState(null);
  const initialSelection = useRef(selection);

  const elementAreas = useSelector(state =>
    // If the move-pointer is not set fall back to down-pointer
    getAvailableElementAreas(state, mouseMovePointer || mouseDownPointer)
  );
  const stickerAreas = useSelector(getAvailableStickerAreas);
  const itemAreas = stickerSelection ? stickerAreas : elementAreas;
  const itemIds = Object.keys(itemAreas);

  // Perform selection
  const invertIfKeyPressed = ids =>
    shiftOrMetaPressed ? xor(initialSelection.current, ids) : ids;
  const limitToAvailable = ids => ids.filter(id => itemIds.includes(id));
  const dispatchIfDifferent = ids =>
    !isEqual(ids, selection) && dispatch(itemSelect(ids));
  const performSelection = flow([
    invertIfKeyPressed,
    limitToAvailable,
    dispatchIfDifferent,
  ]);

  // Lasso selection: Calculate element ids that match the lasso area
  const lassoArea =
    mouseDownPointer &&
    mouseMovePointer &&
    axisAlignedBoundingRect([mouseDownPointer, mouseMovePointer]);
  const lassoAreaSizeMin = 5 / zoom;
  const lassoActive =
    lassoArea &&
    (lassoArea.width > lassoAreaSizeMin || lassoArea.height > lassoAreaSizeMin);
  const lassoIds = itemIds
    .filter(id => lassoActive && rectsIntersect(itemAreas[id], lassoArea))
    .sort();
  if (lassoActive) {
    performSelection(lassoIds);
  }

  useEffect(() => {
    dispatch(updateControls({ lassoActive }));
  }, [dispatch, lassoActive]);

  const handleMouseDown = event => {
    // Only react to left mouse button
    if (event.button !== 0) {
      return;
    }
    if (textEditingActive) {
      /*
      When selecting text, the user often starts the selection inside the text element 
      and pulls the cursor out of the text-element in order to get every letter. In this 
      case, we would catch the last mouse-up and change the selection accordingly. 
      Consequently, this woult abort the text-editing, which is most likely not intended. 
      Here we prevent this by not setting `mouseDownPointer` if the mouse-down occured over 
      an editing text-element. 
       */
      const matchingElement = event.target.closest('.element');
      const id = matchingElement && matchingElement.dataset.id;
      if (id === editingId) {
        return;
      }
    }
    setMouseDownPointer(
      clientToViewportCoordinates({
        x: event.clientX,
        y: event.clientY,
      })
    );
    initialSelection.current = selection;
  };

  const handleMouseMove = event => {
    setMouseMovePointer(
      clientToViewportCoordinates({
        x: event.clientX,
        y: event.clientY,
      })
    );
  };

  const handleMouseUp = event => {
    if (event.button !== 0) {
      return;
    }
    setMouseDownPointer(null);
    setMouseMovePointer(null);
    if (!lassoActive) {
      // Point selection, "click" behavior
      const matchingTarget = event.target.closest(
        `${stickerSelection ? '.sticker-root' : '.element'}, .spread`
      );
      if (!matchingTarget) {
        // Outside click
        dispatch(clearSelection());
        return;
      }
      const { id } = matchingTarget.dataset;
      const isSpread = matchingTarget.matches('.spread');
      if (isSpread) {
        // Spread click
        dispatch(clearSelection());
        dispatch(spreadSelect([id]));
        return;
      }
      // Element or sticker click
      performSelection([id]);
    }
  };

  useEvent('mousedown', handleMouseDown, viewportRef.current);
  useEvent('mousemove', mouseDownPointer && handleMouseMove);
  useEvent('mouseup', mouseDownPointer && handleMouseUp);
  return lassoActive && <rect className="selector" {...lassoArea} />;
}

export default Selector;
