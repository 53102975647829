import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { updateColor } from '../../../modules/colors';
import { selectColors } from '../../../selectors/colors';
import NestedOverlayTrigger from '../NestedOverlayTrigger';
import ColorInput from './ColorInput';

function GlobalSwatchColors() {
  const colors = useSelector(selectColors);
  const dispatch = useDispatch();

  const makeHandleChangeSwatch = colorIndex => ({ color: colorValue }) =>
    dispatch(updateColor(colorIndex, colorValue));

  return colors.map((colorValue, colorIndex) => {
    // colors #0 (black) and #1 (white) are fixed and not editable
    if (colorIndex < 2) {
      return null;
    }

    const key = `swatch-${colorIndex}`;
    return (
      <ColorInput
        icon="linkify"
        key={key}
        colorValue={colorValue}
        className={`qa-swatch-${colorIndex}`}
        onChange={makeHandleChangeSwatch(colorIndex)}
      />
    );
  });
}

function GlobalSwatchColorPicker() {
  return (
    <NestedOverlayTrigger overlay={<GlobalSwatchColors />}>
      <Button compact>
        <Icon className="customicon customicon-fill" />
      </Button>
    </NestedOverlayTrigger>
  );
}

export default GlobalSwatchColorPicker;
