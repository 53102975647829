// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import './NumberSlider.css';
import { Button } from 'semantic-ui-react';

import NumberInput from './NumberInput';

export default class NumberSlider extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    reset: PropTypes.number,
  };

  static defaultProps = {
    value: 0,
    min: -100,
    max: 100,
    step: 0,
    reset: 0,
  };

  componentWillUnmount() {
    this.removeListeners();
  }

  onMouseDown = e => {
    this.pointMoveTo(e.clientX);
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.onDragEnd);
  };

  onDrag = e => {
    this.pointMoveTo(e.clientX);
  };

  onDragEnd = e => {
    this.pointMoveTo(e.clientX);
    this.removeListeners();
  };

  pointMoveTo = x => {
    const rect = this.node.getBoundingClientRect();
    const { width } = rect;
    let left = x - rect.left;

    left = Math.max(0, left);
    left = Math.min(left, width);

    let value =
      (left / width) * (this.props.max - this.props.min) + this.props.min;
    console.log('value', value);
    if (this.props.step) {
      value = Math.round(value / this.props.step) * this.props.step;
    }
    if (this.props.onChange) this.props.onChange(value);
  };

  removeListeners = () => {
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.onDragEnd);
  };

  inputChange = ({ value }) => {
    if (this.props.onChange) this.props.onChange(value);
  };

  reset = () => {
    if (this.props.onChange) this.props.onChange(this.props.reset);
  };

  parse = value => {
    if (this.props.step) {
      value = Math.round(value / this.props.step) * this.props.step;
    }
    return value;
  };

  render() {
    return (
      <div className="ui range flex vertical">
        {this.props.children}
        <NumberInput
          value={this.parse(this.props.value)}
          showButtons
          min={this.props.min}
          max={this.props.max}
          truncateStep={!this.props.step}
          step={this.props.step}
          onChange={this.inputChange}
          compact
        />
        <div className="inner grow">
          <div className="track" ref={node => (this.node = node)}>
            <div
              style={{
                left: `${((this.props.value - this.props.min) /
                  (this.props.max - this.props.min)) *
                  100}%`,
                marginLeft: -10,
              }}
              className="thumb"
              onMouseDown={this.onMouseDown}
            />
          </div>
        </div>
        <div>
          <Button icon="undo" onClick={this.reset} />
        </div>
      </div>
    );
  }
}
