import React from 'react';
import { func, string, bool } from 'prop-types';
import { Table, Input } from 'semantic-ui-react';

import StickerInput from '../StickerInput/index.container';
import { RefShape } from '../../shapes';

function SectionPlaceholder({
  onHeaderClick,
  onNameChange,
  onNameBlur,
  onNameKeyPress,
  name,
  editing,
  titleInputRef,
  createSection,
}) {
  return (
    <Table celled padded className="qa-section-new">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            onClick={onHeaderClick}
            className="qa-new-team qa-section-header-new"
          >
            {editing ? (
              <Input
                fluid
                name="new_section_name"
                placeholder="Neues Team"
                autoFocus
                onChange={onNameChange}
                onBlur={onNameBlur}
                onKeyPress={onNameKeyPress}
                value={name}
                ref={titleInputRef}
              />
            ) : (
              'Neues Team'
            )}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <StickerInput
              index={0}
              sectionId={null}
              placeholder
              createSection={createSection}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

SectionPlaceholder.defaultProps = {};

SectionPlaceholder.propTypes = {
  onHeaderClick: func.isRequired,
  titleInputRef: RefShape.isRequired,
  onNameChange: func.isRequired,
  onNameBlur: func.isRequired,
  onNameKeyPress: func.isRequired,
  name: string.isRequired,
  editing: bool.isRequired,
  createSection: func.isRequired,
};

export default SectionPlaceholder;
