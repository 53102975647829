import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { node, func } from 'prop-types';

function PopupMenuButton({ children, onMenuOpen, onMenuClose }) {
  /* This component relies on its children to bubble up the click-event, in order to
  close it on a menu item click (catched by `Popup.onClick`) */
  const [open, setOpen] = useState(false);

  return (
    <Popup
      on="click"
      open={open}
      onClick={() => {
        onMenuClose();
        setOpen(false);
      }}
      onClose={() => {
        onMenuClose();
        setOpen(false);
      }}
      onOpen={() => {
        onMenuOpen();
        setOpen(true);
      }}
      position="bottom right"
      trigger={<Button className="qa-popup-menu-btn" icon="content" compact />}
    >
      {children}
    </Popup>
  );
}

PopupMenuButton.defaultProps = {
  onMenuOpen: () => {},
  onMenuClose: () => {},
};

PopupMenuButton.propTypes = {
  children: node.isRequired,
  onMenuOpen: func,
  onMenuClose: func,
};

export default PopupMenuButton;
