import { string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { showContextMenu } from '../../modules/menu';
import {
  getInteractiveParentIds,
  getIsolation,
  getSelectedElementIds,
  getVisibleSpreadIds,
  getWorkspace,
} from '../../selectors/legacy';
import { ElementTypeShape, IdListShape } from '../shapes';
import * as elements from './elements';

export function NodeComponent(props) {
  const { type, childrenIds, isolation } = props;

  // Sometimes, when a spread is deleted, for some reason its elements are still rendered (nested-delete-crash). In this case, `type` is not defined.
  // As a quick fix we exit here. In the long run the real reason for this behavior should be investigated...
  // TODO: Avoid workaround
  if (!type) {
    return null;
  }

  const reference = elements[type];

  // In isolation mode, draw isolated element on top of all other elements
  // TODO: sort drawing order to reflect pdf order restrictions: PDFs elements above all, text element above normal elements
  let sortedChildrenIds = childrenIds;
  if (isolation) {
    const isolationNodeIndex = childrenIds.indexOf(isolation);

    if (isolationNodeIndex !== -1) {
      sortedChildrenIds = [
        ...childrenIds.slice(0, isolationNodeIndex),
        ...childrenIds.slice(isolationNodeIndex + 1),
        childrenIds[isolationNodeIndex],
      ];
    }
  }

  const children = sortedChildrenIds.map((childId, index) => (
    <ConnectedNode
      id={childId}
      key={childId}
      nodeIndex={index}
      nodeSiblingCount={sortedChildrenIds.length}
    />
  ));

  return React.createElement(reference, props, children);
}

NodeComponent.defaultProps = {
  isolation: null,
};

NodeComponent.propTypes = {
  childrenIds: IdListShape.isRequired,
  isolation: string,
  type: ElementTypeShape.isRequired,
};

export function mapStateToProps(state, { id }) {
  const { nodes } = getWorkspace(state);

  // This is part of a the nested-delete-crash workaround (see line #40 above)
  // TODO: Avoid workaround
  const { type, props, parent, children } = nodes[id] || {};

  const selectedElementIds = getSelectedElementIds(state);

  let isElementInteractive;
  const {
    controls: { stickerMode },
  } = state;
  if (!stickerMode) {
    isElementInteractive = getInteractiveParentIds(state).includes(parent);
  } else {
    // Don't allow element manipulation in sticker-mode
    isElementInteractive = false;
  }

  return {
    id,
    type,
    ...props,
    childrenIds: children,

    // These props are not used by the NodeComponent but required by Spread, Text, BaseElement, StickerArea and Sticker components
    parentId: parent,
    colors: state.colors,
    stickerMode: state.controls.stickerMode,
    isolation: getIsolation(state),
    selectInside: state.selection.selectInside,
    visibleSpreadCount: getVisibleSpreadIds(state).length,

    // Highlight selected elements, if there is more than one selected element
    isElementHighlighted: selectedElementIds.includes(id),

    // Toggle interactive events of the BaseElement
    isElementInteractive,
  };
}

const mapDispatchToProps = {
  showContextMenu, // used in BaseElement
};

const ConnectedNode = connect(
  mapStateToProps,
  mapDispatchToProps
)(NodeComponent);

export default ConnectedNode;
