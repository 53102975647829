import { imageRatios, imageTypes, resolutions } from '../constants';
import computeNativeImageSize from './images/computeNativeImageSize';

export const fitImageObjectIntoElement = (imageObject, element, isCover) => {
  const { width: nativeWidth, height: nativeHeight } = computeNativeImageSize(
    imageObject
  );
  const { width, height } = element.props;
  // NOTE: will eventually overflow the spread
  const scaleStrategy = isCover ? Math.max : Math.min;
  const cscale = scaleStrategy(width / nativeWidth, height / nativeHeight);
  const cx = (width - nativeWidth * cscale) / 2;
  const cy = (height - nativeHeight * cscale) / 2;
  return { cx, cy, cscale, crotation: 0 };
};

/**
 * Resolves a given image to the src URL for the requested resolution and its
 * filename.
 * For SVG images, the filename is extracted from the original path on the
 * server and the resolution ignored.
 */
export const resolveImage = (imageObject, resolution = resolutions.small) => {
  let src;
  let filename;
  if (typeof imageObject.blob === 'string') {
    src = imageObject.blob;
    filename = src.split('/').pop();

    return { src, filename };
  }
  src = imageObject.blob[resolution];
  filename = imageObject.blob.filename;
  return { src, filename };
};

/**
 * Format an image object to be sent to the API.
 */
export const prepareImageForServer = image => ({
  ...image,
  details: JSON.stringify(image.details),
  sync: true,
});

/* Accidental `image.details` in some albums contains corrupted data (propbably the 
  effect of some Object-spreading that was applied to a String instead). 
  This function "cleans" the details by allowing only non-numeric keys. */
function filterDetails(details) {
  return Object.keys(details).reduce((acc, cur) => {
    // eslint-disable-next-line no-restricted-properties
    if (window.isFinite(cur)) {
      return acc;
    }
    acc[cur] = details[cur];
    return acc;
  }, {});
}

export const prepareImageFromServer = originalImage => {
  const image = originalImage;
  let details;
  try {
    details = JSON.parse(image.details);
  } catch (e) {
    details = image.details;
  }
  if (!details || typeof details !== 'object') {
    details = {};
  }
  if (!details.contentType) {
    details.contentType = 'common';
  }
  const filteredDetails = filterDetails(details);
  const needsPatch =
    Object.keys(filteredDetails).length !== Object.keys(details).length;

  if (needsPatch) {
    image.details = filteredDetails;
  } else {
    image.details = details;
  }

  if (!image.tags) image.tags = [];

  if (!image.meta.width || !image.meta.height) {
    if (image.dimensions && image.dimensions.width && image.dimensions.height) {
      image.meta.width = image.dimensions.width;
      image.meta.height = image.dimensions.height;
    } else if (image.details && image.details.width && image.details.height) {
      image.meta.width = image.details.width;
      image.meta.height = image.details.height;
    }
  }

  return image;
};

export const prepareResponseFromServer = ({ data: { image } }) => {
  return { data: { image: prepareImageFromServer(image) } };
};

export function getRealSvgSize(image) {
  let {
    meta: { width, height },
  } = image;
  const factor = 230 / 652;
  width *= factor;
  height *= factor;
  return { width, height };
}

export function getImageExtension(image) {
  const { blob } = image;
  let ext;
  if (typeof blob === 'object') {
    const { filename } = blob;
    ext = filename
      .split('.')
      .pop()
      .toLowerCase();
  } else if (typeof blob === 'string') {
    ext = blob
      .split('.')
      .pop()
      .toLowerCase();
  } else {
    ext = null;
  }
  return ext;
}

export function getImageSizeLabel(imageObject) {
  if (imageObject.details.size) {
    return `1/${imageObject.details.size} Seite, ${
      imageObject.meta.width > imageObject.meta.height ? 'quer' : 'hoch'
    }`;
  }
  return 'Größe unbekannt';
}

export function getRatioFromImageObject(imageObject) {
  const label = getImageSizeLabel(imageObject);
  return imageRatios.find(({ text }) => text.indexOf(label) !== -1);
}

export function createElementFromImageObject(imageObject) {
  const { contentType } = imageObject.details;

  /**
   * If the image has a defined ratio, merge the ratios props.
   */
  const ratioObject = getRatioFromImageObject(imageObject);
  const ratioProps = ratioObject ? ratioObject.props : {};

  /**
   * Either fit the image into the ad-ratio frame or the default frame.
   */
  const fitImageProps = ratioObject
    ? fitImageObjectIntoElement(imageObject, ratioObject, false)
    : computeNativeImageSize(imageObject);

  return {
    type: 'Image',
    props: {
      contentType,
      image: imageObject.id,
      ...ratioProps,
      ...fitImageProps,
    },
  };
}

/**
 * When setting image display option to `detail`, we're displaying some information
 * about the image - size and ad format.
 * @param {Object} imageObject
 */
export const getImageDescription = imageObject => {
  let detail = '';
  if (imageObject.details.contentType === imageTypes.ad) {
    detail = `${getImageSizeLabel(imageObject)} `;
  }
  detail += `(${imageObject.meta.width}x${imageObject.meta.height})`;
  return detail;
};
