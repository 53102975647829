import {
  array,
  elementType,
  number,
  object,
  objectOf,
  string,
} from 'prop-types';
import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';

import useBinarySelector from '../../../../hooks/useBinarySelector';
import { selectColors } from '../../../../selectors/colors';
import {
  getSelectedElementIds,
  getVisibleSpreadIds,
} from '../../../../selectors/legacy';
import { makeGetTemplatesForContentTypeAndTagId } from '../../../../selectors/templates';
import { ColorsShape, IdListShape, StickerShape } from '../../../shapes';
import Circle from '../Circle';
import Group from '../Group';
import Image from '../Image';
import Line from '../Line';
import Rectangle from '../Rectangle';
import Symbol from '../Symbol';
import Text from '../Text';

const elements = {
  Circle,
  Group,
  Image,
  Line,
  Rectangle,
  Text,
  Symbol,
};

const RecurseObjects = props => {
  const { objects, globalProps } = props;

  if (!objects) {
    return null;
  }

  return objects.map(({ type, props: childProps, children }) => {
    const reference = elements[type];
    const prefixedId = `${globalProps.stickerId}_${childProps.id}`;
    return React.createElement(
      reference,
      {
        key: prefixedId,
        type,
        ...childProps,
        ...globalProps,
        id: prefixedId, // Overwrite element id from template to avoid collisions
      },
      <RecurseObjectsMemo globalProps={globalProps} objects={children} />
    );
  });
};

RecurseObjects.propTypes = {
  objects: array.isRequired, // eslint-disable-line react/forbid-prop-types
  globalProps: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const RecurseObjectsMemo = memo(RecurseObjects);

const CustomStickerElement = props => {
  const {
    sticker,
    contentType,
    localTexts,
    selectedElementIds,
    visibleSpreadCount,
    colors,
    resolvedStickerColor,
    fallbackComponent,
    clipPath,
  } = props;

  const [tagId] = sticker.tags || [];

  const contentTypeAndTagId = useMemo(() => ({ contentType, tagId }), [
    contentType,
    tagId,
  ]);

  const [template] = useBinarySelector(
    makeGetTemplatesForContentTypeAndTagId,
    contentTypeAndTagId
  );

  const json = template?.json;

  /**
   * TODO: Maybe find a way to do this in the 'normalized way', providing some local 'workspace'.
   *
   * This is rendering an isolated element which is not part of the workspace. So it needs to render it recursivly
   * for now. But this should done after finding a final form for the whole "Custom sticker template" functionality...
   */

  const objects = useMemo(() => [json], [json]);

  const globalProps = useMemo(
    () => ({
      stickerId: sticker.id,
      localTexts,
      selectedElementIds,
      visibleSpreadCount,
      colors,
      resolvedStickerColor,
      isElementHighlighted: false,
      isElementInteractive: false,
      clipPath,
    }),
    [
      sticker.id,
      localTexts,
      selectedElementIds,
      visibleSpreadCount,
      colors,
      resolvedStickerColor,
      clipPath,
    ]
  );

  if (!json) {
    return React.createElement(fallbackComponent, props);
  }

  return (
    <g clipPath={clipPath}>
      <RecurseObjectsMemo objects={objects} globalProps={globalProps} />
    </g>
  );
};

CustomStickerElement.propTypes = {
  contentType: string.isRequired,
  localTexts: objectOf(string).isRequired,
  selectedElementIds: IdListShape.isRequired,
  visibleSpreadCount: number.isRequired,
  colors: ColorsShape.isRequired,
  resolvedStickerColor: string.isRequired,
  sticker: StickerShape.isRequired,
  fallbackComponent: elementType.isRequired,
  clipPath: string.isRequired,
};

const mapStateToProps = state => ({
  selectedElementIds: getSelectedElementIds(state),
  visibleSpreadCount: getVisibleSpreadIds(state).length,
  colors: selectColors(state),
});

export default connect(mapStateToProps)(CustomStickerElement);
