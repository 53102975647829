import React from 'react';
import { func, string } from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { ApplicableActionsShape, ItemTypeShape } from '../../shapes';
import ActionItem from './ActionItem';
import { actionsMeta } from '../../../constants';

function ActionItems({
  header,
  itemType,
  applicableActions,
  historyAnchor,
  executeActionByName,
  executeWithSelection,
  hideContextMenu,
}) {
  const applicableActionsMeta = actionsMeta.filter(
    ([actionItemType, action]) => {
      return actionItemType === itemType && applicableActions.includes(action);
    }
  );

  return (
    <>
      <Menu.Item className="header qa-context-menu-item">{header}</Menu.Item>
      {applicableActionsMeta.map(([, action, label, hotkeys, iconName]) => (
        <ActionItem
          key={action}
          label={label}
          hotkeys={hotkeys}
          iconName={iconName}
          onClick={() => {
            hideContextMenu();
            executeWithSelection(() => {
              executeActionByName(action);
            });
            historyAnchor();
          }}
          className="qa-context-menu-item"
        />
      ))}
    </>
  );
}

ActionItems.propTypes = {
  header: string.isRequired,
  itemType: ItemTypeShape.isRequired,
  applicableActions: ApplicableActionsShape.isRequired,
  historyAnchor: func.isRequired,
  executeWithSelection: func.isRequired,
  executeActionByName: func.isRequired,
  hideContextMenu: func.isRequired,
};

export default ActionItems;
