import { getStickerIds } from '../../../selectors/legacy';
import { getStickerBoundingBox } from '../../../selectors/bounds';

export default function getAvailableStickerAreas(state) {
  const availableIds = getStickerIds(state);
  return availableIds.reduce((acc, id) => {
    const result = getStickerBoundingBox(state, id);
    if (result) {
      acc[id] = result;
    }
    return acc;
  }, {});
}
