import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Comment, Form, Icon } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';

import UnzoomedContent from '../../components/svg/UnzoomedContent';
import { deleteComment, updateComment } from '../../modules/comments';
import { getCurrentUser } from '../../modules/user';
import { getShowDoneComments } from '../../selectors/controls';
import { ImageContext } from '../../components/ImageContext';

function AlbumComment({ comment, spreadX, spreadY }) {
  const dispatch = useDispatch();
  const [editedContent, setEditedContent] = useState(null);
  const { identifier, agent: isAgent } = useSelector(getCurrentUser);
  const showDoneComments = useSelector(getShowDoneComments);
  const { rendering: readOnly } = useContext(ImageContext);
  const [isVisible, setIsVisible] = useState(true);

  if (!showDoneComments && comment.done) {
    return null;
  }

  function handleTrash() {
    dispatch(deleteComment(comment.id));
  }

  function handleEdit() {
    setEditedContent(comment.content);
  }

  async function handleUpdate() {
    if (editedContent === comment.content) {
      setEditedContent(null);
      return;
    }

    await dispatch(
      updateComment(comment.id, {
        content: editedContent,
      })
    );
    setEditedContent(null);
  }

  function handleDone() {
    dispatch(
      updateComment(comment.id, {
        done: true,
      })
    );
  }

  function handleClose() {
    setIsVisible(false);
  }

  function handleOpen(e) {
    e.stopPropagation();
    setIsVisible(true);
  }

  return (
    <g
      key={comment.id}
      transform={`translate(${spreadX + comment.x} ${spreadY + comment.y})`}
    >
      <UnzoomedContent>
        <foreignObject x={0} y={0} width="300">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/control-has-associated-label */}
          <div
            className="pulsating-circle qa-pulsating-circle"
            onClick={handleOpen}
            role="button"
            tabIndex={0}
          />
          {isVisible && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className="comment-wrapper qa-comment"
              onClick={e => e.stopPropagation()}
              style={{ opacity: comment.done ? 0.5 : 1, position: 'relative' }}
            >
              <Comment.Group>
                <Comment>
                  <Comment.Content>
                    <div className="comment-header">
                      <div className="comment-author">
                        <Comment.Author as="a">
                          {comment.user_name}
                        </Comment.Author>
                      </div>
                      <div className="comment-close">
                        <Icon
                          name="close"
                          className="comment-close-button qa-comment-close-button"
                          onClick={() => handleClose()}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <Comment.Metadata>
                      <div>{moment(comment.created_at).fromNow()}</div>
                    </Comment.Metadata>
                    <div className="comment-text-divider" />
                    <Comment.Text>
                      {editedContent ? (
                        <Form>
                          <Form.Field>
                            <textarea
                              name="comment_content"
                              id="comment_content"
                              value={editedContent}
                              onChange={e => setEditedContent(e.target.value)}
                            />
                          </Form.Field>
                        </Form>
                      ) : (
                        <>
                          <div className="my-3">{comment.content}</div>
                          {comment.created_at !== comment.updated_at && (
                            <div className="mb-3 text-muted">
                              <small>
                                bearbeitet{' '}
                                {moment(comment.updated_at).fromNow()}
                              </small>
                            </div>
                          )}
                        </>
                      )}
                    </Comment.Text>

                    <Comment.Actions className="comment-actions">
                      <div className="comment-action-buttons">
                        {identifier === comment.user_identifier && !readOnly && (
                          <>
                            <Button
                              basic
                              icon={editedContent ? 'check' : 'pencil'}
                              size="mini"
                              onClick={() =>
                                editedContent ? handleUpdate() : handleEdit()
                              }
                              className="qa-comment-edit-button"
                            />
                            {!editedContent && (
                              <Button
                                basic
                                icon="trash"
                                size="mini"
                                onClick={() => handleTrash()}
                                className="qa-comment-delete-button"
                              />
                            )}
                          </>
                        )}
                        {isAgent && !editedContent && !comment.done && (
                          <Button
                            basic
                            icon="check"
                            size="mini"
                            onClick={() => handleDone()}
                            color="green"
                            className="comment-done-button qa-comment-done-button"
                          />
                        )}
                      </div>
                    </Comment.Actions>
                  </Comment.Content>
                </Comment>
              </Comment.Group>
            </div>
          )}
        </foreignObject>
      </UnzoomedContent>
    </g>
  );
}

AlbumComment.defaultProps = {};

AlbumComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
    user_identifier: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    done: PropTypes.bool.isRequired,
  }).isRequired,
  spreadX: PropTypes.number.isRequired,
  spreadY: PropTypes.number.isRequired,
};

export default AlbumComment;
