import React from 'react';
import { arrayOf, func } from 'prop-types';
import { Button, Form, Dropdown, Icon } from 'semantic-ui-react';

import FpsCounter from './FpsCounter';
import { isDevelopment } from '../../../util';
import { IdListShape, TagShape } from '../../shapes';

function BuilderPanel({
  tags,
  builderTags,
  resetSpreads,
  alignStickersUsingFaceData,
  copyAlbumJson,
  pasteAlbumJson,
  buildColorGroups,
  resetView,
  onFilterChange,
  onBuildAlbumClick,
  copyStickerTable,
}) {
  const renderLabel = label => ({
    color: 'green',
    content: label.text,
  });

  const tagsOptions = tags.map(tag => {
    return { key: tag.id, value: tag.id, text: tag.title };
  });

  return (
    <div className="qa-builder-content">
      <Form className="builder">
        <Form.Field inline>
          <span>Stil</span>
          <Dropdown
            multiple
            selection
            options={tagsOptions}
            value={builderTags}
            placeholder="Tags..."
            renderLabel={renderLabel}
            onChange={onFilterChange}
          />
        </Form.Field>
        <Form.Field inline>
          <Button color="green" icon onClick={onBuildAlbumClick}>
            <Icon name="play" />
            Album erzeugen
          </Button>
        </Form.Field>
      </Form>

      <Form.Field>
        <Button onClick={resetSpreads}>Reset Spreads</Button>
        <Button onClick={resetView} className="qa-reset-view-btn">
          Reset View
        </Button>
        <Button onClick={alignStickersUsingFaceData}>Sticker ausrichten</Button>
        <Button onClick={buildColorGroups}>Farbgruppen analysieren</Button>
        <Button onClick={copyAlbumJson}>Copy album</Button>
        <Button onClick={pasteAlbumJson}>Paste album</Button>
        <Button onClick={copyStickerTable}>Copy sticker</Button>

        {isDevelopment() && <FpsCounter />}
      </Form.Field>
    </div>
  );
}

BuilderPanel.propTypes = {
  // mapStateToProps
  builderTags: IdListShape.isRequired,
  tags: arrayOf(TagShape).isRequired,

  // mapDispatchToProps
  resetSpreads: func.isRequired,
  alignStickersUsingFaceData: func.isRequired,
  resetView: func.isRequired,
  copyAlbumJson: func.isRequired,
  pasteAlbumJson: func.isRequired,
  buildColorGroups: func.isRequired,
  copyStickerTable: func.isRequired,

  // mergeProps
  onFilterChange: func.isRequired,
  onBuildAlbumClick: func.isRequired,
};

export default BuilderPanel;
