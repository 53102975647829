import { get, set } from 'idb-keyval';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';

import {
  setSyncDirectoryHandle,
  setSyncSelectionOnly,
  syncStart,
  syncStop,
} from '../../../actions/sync';
import {
  selectSyncDirectoryHandle,
  selectSyncRunning,
  selectSyncSelectionOnly,
  selectSyncTargetedStickerCount,
} from '../../../selectors/sync';
import { verifyPermission } from '../../../util/sync';

const indexDbKey = 'sts-you-sync-handle';

function SyncControl() {
  const dispatch = useDispatch();
  const directoryHandle = useSelector(selectSyncDirectoryHandle);
  const running = useSelector(selectSyncRunning);
  const selectionOnly = useSelector(selectSyncSelectionOnly);
  const targetedStickerCount = useSelector(selectSyncTargetedStickerCount);

  async function requestAndStoreDirectoryHandle() {
    try {
      const nextDirectoryHandle = await window.showDirectoryPicker({
        id: 'sts-you-sticker-folder',
        startIn: 'downloads',
      });

      const allowed = await verifyPermission(nextDirectoryHandle);
      if (!allowed) {
        return null;
      }

      // Store it in the redux store
      dispatch(setSyncDirectoryHandle(nextDirectoryHandle));

      // Store it in IndexedDB for future sessions (after the browser is closed)
      await set(indexDbKey, nextDirectoryHandle);

      return nextDirectoryHandle;
    } catch (error) {
      // Probably the user aborted the dialog
      return null;
    }
  }

  async function handleStartSync() {
    // Either use the stored directory handle or request a new one
    const someDirectoryHandle =
      directoryHandle || (await requestAndStoreDirectoryHandle());
    if (!someDirectoryHandle) {
      return;
    }

    /*
     * The permissions for this a stored directory-handle must be verified for
     * every session. This verification must be done in a user-initated handler,
     * e.g. a button click.
     */
    const allowed = await verifyPermission(someDirectoryHandle);
    if (!allowed) {
      // eslint-disable-next-line no-alert
      alert(
        'Zum Synchronisieren muss die Berechtigung zum Lesen und Schreiben erteilt werden.'
      );
      return;
    }

    dispatch(syncStart());
  }

  function handleStopSync() {
    dispatch(syncStop());
  }

  function handleBrowseDirectory() {
    requestAndStoreDirectoryHandle();
  }

  function handleSelectionOnlyClick() {
    dispatch(setSyncSelectionOnly(!selectionOnly));
  }

  useMount(async () => {
    /**
     * Directory (and file) handles can be persisted in IndexDB and reused
     * between sessions. Local storage does not work for this...
     * This allows the user to pick a sync-directory once and reuse later.
     */
    const directoryHandleFromStorage = await get(indexDbKey);
    if (directoryHandleFromStorage) {
      dispatch(setSyncDirectoryHandle(directoryHandleFromStorage));
    }
  });

  const directoryTooltip = directoryHandle
    ? `Ordner: ${directoryHandle.name}`
    : '(Kein Ordner gewählt)';

  return (
    <div className="sync-control qa-sync-control">
      <Form className="d-flex justify-content-space-between align-items-center">
        <Form.Check>
          <Form.Check.Input
            className="qa-sync-toggle"
            id="running"
            name="running"
            type="checkbox"
            checked={running}
            disabled={targetedStickerCount === 0}
            onChange={running ? handleStopSync : handleStartSync}
          />
          <Form.Check.Label htmlFor="running">
            {targetedStickerCount} Sticker lokal spiegeln
          </Form.Check.Label>
        </Form.Check>
        <Form.Check>
          <Form.Check.Input
            id="selectionOnly"
            name="selectionOnly"
            type="checkbox"
            disabled={running}
            checked={selectionOnly}
            onChange={handleSelectionOnlyClick} // eslint-disable-line react/jsx-no-bind
          />
          <Form.Check.Label htmlFor="selectionOnly">
            Nur Auswahl
          </Form.Check.Label>
        </Form.Check>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{directoryTooltip}</Tooltip>}
        >
          <Button
            className="qa-sync-pick-directory"
            onClick={handleBrowseDirectory} // eslint-disable-line react/jsx-no-bind
          >
            <Icon name="folder" />
          </Button>
        </OverlayTrigger>
      </Form>
    </div>
  );
}

export default SyncControl;
