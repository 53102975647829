import axios from 'axios';
import flow from 'lodash/flow';
import get from 'lodash/get';

import { API_URL } from '../constants';
import { createImage, deleteImage, updateImage } from '../modules/images';
import { addToQueue, removeFromQueue } from '../modules/loading';
import { elementSelect } from '../modules/selection';
import {
  createStock,
  deleteStock,
  replaceStocks,
  updateStock,
} from '../modules/stock';
import { getCurrentAlbumId } from '../selectors/albums';
import { generateId } from '../util';
import {
  getImageExtension,
  getRealSvgSize,
  prepareImageForServer,
  prepareResponseFromServer,
  fitImageObjectIntoElement,
} from '../util/images';
import computeNativeImageSize from '../util/images/computeNativeImageSize';
import imageHasSizeMeta from '../util/images/imageHasSizeMeta';
import { insertElements } from './workspace';

export const convertToStock = id => dispatch =>
  axios
    .patch(`${API_URL}/images/${id}`, { album_id: null })
    .then(prepareResponseFromServer)
    .then(({ data: { image } }) => {
      dispatch(createStock(image));
      dispatch(deleteImage(id));
    });

export function postImage({ image, isStock = false, albumId }) {
  const url = isStock
    ? `${API_URL}/images`
    : `${API_URL}/albums/${albumId}/images`;
  return axios
    .post(url, prepareImageForServer(image))
    .then(prepareResponseFromServer);
}

export function updateLocalOrStockImage(id, payload) {
  return (dispatch, getState) => {
    const { stock } = getState();
    const isStock = stock.findIndex(image => image.id === id) !== -1;
    if (isStock) {
      dispatch(updateStock(id, payload));
    } else {
      dispatch(updateImage(id, payload));
    }
  };
}

export const tagImage = (id, tagId) => dispatch => {
  const jobId = `tag-image-${id}`;
  dispatch(addToQueue(jobId, false));
  return axios
    .post(`${API_URL}/images/${id}/tags`, { tag_id: tagId })
    .then(prepareResponseFromServer)
    .then(({ data: { image } }) => {
      dispatch(updateLocalOrStockImage(image.id, image));
      dispatch(removeFromQueue(jobId));
    });
};

export function deleteImageOrStock(id) {
  return (dispatch, getState) => {
    const { stock } = getState();
    const isStock = stock.findIndex(image => image.id === id) !== -1;
    if (isStock) {
      dispatch(deleteStock(id));
    } else {
      dispatch(deleteImage(id));
    }
    axios.delete(`${API_URL}/images/${id}`);
  };
}

function fetchImage(id) {
  return axios.get(`${API_URL}/images/${id}`).then(prepareResponseFromServer);
}

const deleteCorruptedStickerImage = image => (_, getState) => {
  if (image.model !== 'sticker') {
    return;
  }
  const state = getState();
  const stickers = (state.workspaceAndSections?.sections || []).flatMap(
    section => section.stickers
  );
  const sticker = stickers.find(_sticker => _sticker.image === image.id);
  if (sticker) {
    // eslint-disable-next-line no-alert
    alert(`Sticker-Bild fehlerhaft: ${sticker.name}`);
  } else {
    axios.delete(`${API_URL}/images/${image.id}`);
  }
};

/**
 * When uploading PDF / AI we're getting the image dimensions
 * via JS and PATCH the image on the server.
 * @param {Object} image
 */
export const fillImageDimensionsFromRepresentation = image => dispatch =>
  new Promise((resolve, reject) => {
    const mergeDimensions = ({ width, height }) => ({
      ...image,
      details: { ...image.details, width, height },
      meta: { ...image.meta, width, height },
    });

    const resolveAsResponse = img => {
      resolve({ data: { image: img } });
      return img;
    };

    const updateImageDetails = ({ details }) =>
      axios.patch(`${API_URL}/images/${image.id}`, { details });

    const workflow = flow(
      mergeDimensions,
      resolveAsResponse,
      prepareImageForServer,
      updateImageDetails
    );

    const deleteCorruptedImage = () => {
      if (image.model === 'sticker') {
        dispatch(deleteCorruptedStickerImage(image));
      } else {
        axios.delete(`${API_URL}/images/${image.id}`);
      }
    };

    const imageUrl = get(image, 'blob.full', null) || get(image, 'blob', null);

    /** If the full blob URL is null we delete the image, since there
     * is no representation we can load. The image is probably corrupted.
     */
    if (typeof imageUrl !== 'string') {
      deleteCorruptedImage();
      reject(new Error('No image representation found'));
      return;
    }

    const imgNode = new Image();
    imgNode.onload = () => workflow(imgNode);
    imgNode.onerror = () => {
      deleteCorruptedImage();
      reject(new Error('Error loading image representation'));
    };
    imgNode.src = imageUrl;
  });

const responseHasSizeMeta = ({ data: { image } }) => imageHasSizeMeta(image);

/**
 * Calculating the image size on the server is an asynchronous operation -
 * e. g. when uploading a PDF, imagemagick will have to generate the thumbnail first.
 * So after uploading an image, we don't have its dimensions available immediatly, but
 * have to check periodically until they're there.
 *
 * Returns a promise that will resolve to either 1) the image object
 * *with* width/height inside `meta` OR the image without dimensions after
 * 10 unsuccessful attempts.
 *
 * @param {string} imageId image ID
 */
export const fillImageDimensionsFromServer = image => () => {
  const { id } = image;
  return new Promise((resolve, reject) => {
    let pollCount = 0;
    const maxPollCount = 10;
    const initialDelay = 1000;
    const retryDelay = 3000;

    const poll = () => {
      const pollCountExceeded = pollCount === maxPollCount;

      fetchImage(id)
        .then(response => {
          if (responseHasSizeMeta(response) || pollCountExceeded) {
            resolve(response);
          } else {
            pollCount += 1;
            // Polling every 3 seconds if the first request failed
            setTimeout(poll, retryDelay);
          }
        })
        .catch(reject);
    };

    // We're initially polling after 1 sec (this one usually already succeeds)
    setTimeout(poll, initialDelay);
  });
};

export const fillImageDimensions = response => dispatch => {
  if (responseHasSizeMeta(response)) {
    return response;
  }
  const {
    data: { image },
  } = response;
  const ext = getImageExtension(image);
  if (!(ext === 'jpg' || ext === 'jpeg' || ext === 'png')) {
    return dispatch(fillImageDimensionsFromRepresentation(image));
  }
  return dispatch(fillImageDimensionsFromServer(image));
};

export const prepareAndFillImages = images => dispatch => {
  return Promise.all(
    images.map(async image => {
      const response = { data: { image } };
      const result = await dispatch(
        fillImageDimensions(prepareResponseFromServer(response))
      );
      return result.data.image;
    })
  );
};

/**
 * Stores an image on the server after a successful direct-upload and appends
 * it to the image list -- image needs a `blob_id` and `model`.
 * @param {*} imageUploadObject: blob_id,  model: "sticker" or "image",
 *   isStock: create image as stock image
 * @return Promise<imageObject>
 */
export const createImageAfterDirectUpload = (
  imageUploadObject,
  isStock = false
) => async (dispatch, getState) => {
  const jobId = `image-${imageUploadObject.blob_id}`;
  const state = getState();

  try {
    await dispatch(addToQueue(jobId, false));

    const image = await postImage({
      image: imageUploadObject,
      albumId: getCurrentAlbumId(state),
      isStock,
    });

    const imageWithDimensions = await dispatch(fillImageDimensions(image));

    const createAction = isStock ? createStock : createImage;
    const imageObject = imageWithDimensions.data.image;
    dispatch(createAction(imageObject));

    return imageObject;
  } finally {
    dispatch(removeFromQueue(jobId, false));
  }
};

/**
 * Completes and image-upload and returns an array of corresponding image
 * objects.
 * @return Promise<{ imageObjects: [imageObject] }>
 */
export const uploadAndCreateImageObjects = ({
  item,
  contentType,
  upload,
  isStock,
}) => async dispatch => {
  const imageObjects = await Promise.all(
    Array.from(item.files).map(async file => {
      const blob = await upload(file, {
        contentType,
      });

      const imageUploadObject = {
        model: 'image',
        blob_id: blob.id,
        details: { contentType },
      };

      return dispatch(createImageAfterDirectUpload(imageUploadObject, isStock));
    })
  );

  return { imageObjects };
};

/**
 * Updates an existing image on the server after a successful direct-upload and updates it in the image list.
 * @param id = id of the image element to update
 * @param {*} image { blob_id, model }
 * */
export const patchExistingImage = (id, originalImage) => (
  dispatch,
  getState
) => {
  const { images } = getState();
  const existingImage = images.find(item => item.id === id);
  let image;
  if (existingImage) {
    image = {
      ...originalImage,
      details: { ...existingImage.details, ...originalImage.details },
    };
  } else {
    image = originalImage;
  }
  const jobId = `image-${id}`;
  dispatch(addToQueue(jobId, false));

  return axios
    .patch(`${API_URL}/images/${id}`, prepareImageForServer(image))
    .then(prepareResponseFromServer)
    .then(response => dispatch(fillImageDimensions(response)))
    .then(({ data: { image: imageFromResponse } }) => {
      dispatch(updateLocalOrStockImage(id, imageFromResponse));
      dispatch(removeFromQueue(jobId, false));
    });
};

/**
 * Stores an image on the server after a direct upload *and* places it on a spread.
 * @param image { blob_id, model }
 * @param positionInfo - the result of findTargetSpreadIdOnWorkspace
 */
export const createAndPlaceImageAfterDirectUpload = (
  image,
  { spreadId, positionOnSpread = null }
) => (dispatch, getState) => {
  const jobId = `image-${image.id}`;
  dispatch(addToQueue(jobId, false));

  // Provide a fallback value when the position is missing (ie. when copying an image from the clipboard)
  const { x, y } = positionOnSpread || { x: 100, y: 100 };

  const {
    albums: { currentAlbum: albumId },
  } = getState();

  postImage({ image, albumId })
    .then(response => dispatch(fillImageDimensions(response)))
    .then(({ data: { image: imageFromResponse } }) => {
      dispatch(createImage(imageFromResponse));
      const id = `Image-${generateId()}`;
      const ext = getImageExtension(imageFromResponse);

      let width = 100;
      let height = 100;
      if (ext === 'svg') {
        const size = getRealSvgSize(imageFromResponse);
        width = size.width;
        height = size.height;
      }

      let element = {
        type: 'Image',
        props: {
          id,
          image: imageFromResponse.id,
          x,
          y,
          cx: 0,
          cy: 0,
          width,
          height,
        },
      };

      if (ext === 'svg') {
        element = {
          ...element,
          props: {
            ...element.props,
            ...fitImageObjectIntoElement(imageFromResponse, element, false),
          },
        };
      } else {
        ({ width, height } = imageFromResponse.meta);
        element = {
          ...element,
          props: {
            ...element.props,
            ...computeNativeImageSize(imageFromResponse),
          },
        };
      }

      dispatch(insertElements([element], spreadId));
      dispatch(elementSelect([id]));
      dispatch(removeFromQueue(jobId, false));
    });
};

export const fetchStocks = (apiKey = null, apiUrl = API_URL) => dispatch => {
  dispatch(addToQueue(`fetchStocks`));
  return axios
    .get(`${apiUrl}/images${apiKey ? `?api_key=${apiKey}` : ''}`)
    .then(async ({ data: { images } }) => {
      dispatch(replaceStocks(await dispatch(prepareAndFillImages(images))));
      dispatch(removeFromQueue(`fetchStocks`));
    });
};
