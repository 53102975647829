import React, { useCallback, useRef, useState } from 'react';
import { node, string } from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { useEvent } from 'react-use';

const Submenu = ({ text, children, className }) => {
  const ref = useRef();
  const [left, setLeft] = useState(0);
  const open = left !== 0;

  const handleClick = useCallback(
    e => {
      // Only intercept click-event if its on the submenu-button
      const submenu = e.target.closest('.submenu-button');
      if (!submenu) {
        return;
      }

      if (left === 0) {
        // The submenu is currently closed - open it

        // Stop the parent menu from closing on click
        e.preventDefault();
        e.stopPropagation();

        // Position the submenu relative to its button
        const rect = ref.current.getBoundingClientRect();
        const nextLeft =
          rect.left < window.innerWidth / 2
            ? rect.left + rect.width
            : rect.left - 300;
        setLeft(nextLeft);
      } else {
        // The submenu is currently open - close it
        setLeft(0);
      }
    },
    [left, ref]
  );
  useEvent('click', handleClick, document, { capture: true });

  return (
    <>
      <div ref={ref} className={`submenu-button item ${className}`}>
        {text}
        <i aria-hidden="true" className="dropdown icon" />
      </div>
      {open && (
        <div className="submenu-content" style={{ left }}>
          <Menu vertical>{children}</Menu>
        </div>
      )}
    </>
  );
};

Submenu.defaultProps = {
  className: null,
};

Submenu.propTypes = {
  text: string.isRequired,
  children: node.isRequired,
  className: string,
};

export default Submenu;
