import React, { useMemo } from 'react';
import { bool, func, number, string, node, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { dimensions, colorValues, itemTypes } from '../../../../constants';
import { ImageContext } from '../../../ImageContext';
import { generateSpread } from '../../../../util/generators';
import { ImageContextShape } from '../../../shapes';
import SpreadHighlight from './SpreadHighlight';
import SpreadGuides from './SpreadGuides';
import InsertSpreadButton from './InsertSpreadButton';
import SpreadContext from './SpreadContext';
import useBinarySelector from '../../../../hooks/useBinarySelector';
import { makeSelectSectionById } from '../../../../selectors/stickers';
import { getCommentsBySpreadId } from '../../../../modules/comments';
import AlbumComment from '../../../../containers/album_preview/AlbumComment';

export function calculateSpreadPositionAndSize(
  nodeIndex,
  isCover,
  isFrontCover
) {
  const { pageWidth, pageHeight, pagePadding } = dimensions;
  return {
    x: isFrontCover ? pageWidth : 0,
    y: (pageHeight + pagePadding) * nodeIndex,
    width: isCover ? pageWidth : pageWidth * 2,
    height: pageHeight,
  };
}

export function Spread(props) {
  const {
    id,
    children,
    nodeIndex,
    nodeSiblingCount,
    spine,
    spreadVisible,
    targetSpreadId,
    cropPreview,
    stickerMode,
    renderingSpreadPositionAndSize,
    additionalSpreadNeededForSectionId,
    insertElements,
    imageContext,
    showContextMenu,
    sectionId,
  } = props;

  const onSpreadContextMenu = e => {
    const { printing } = imageContext;
    if (printing) {
      return;
    }

    showContextMenu(e, itemTypes.spread, id);
  };

  const onInsertSpread = e => {
    e.stopPropagation();
    const spread = generateSpread(additionalSpreadNeededForSectionId);
    insertElements([spread], 'root', nodeIndex + 1);
  };

  const { rendering, printing } = imageContext;
  const { pagePadding, spreadHighlightSize, imageBleed } = dimensions;

  // Show updatedChildren during rendering or when its spread is visible
  // otherwise, only render the empty Spread rect, but no its children
  const updatedChildren = rendering || spreadVisible ? children : [];

  const showGuides = !(rendering || printing);
  const showHighlight = showGuides && id === targetSpreadId;
  const isFrontCover = nodeIndex === 0;
  const isBackCover = nodeIndex === nodeSiblingCount - 1;
  const isCover = isFrontCover || isBackCover;

  const { x, y, width, height } =
    renderingSpreadPositionAndSize ||
    calculateSpreadPositionAndSize(nodeIndex, isCover, isFrontCover);

  const spineLeft = isFrontCover ? spine : 0;

  let clipPath = null;
  if (cropPreview) {
    clipPath = isCover
      ? 'url(#clip-page-preview)'
      : 'url(#clip-spread-preview)';
  } else {
    clipPath = isCover ? 'url(#clip-cover)' : 'url(#clip-spread)';
  }

  const spreadColor = stickerMode
    ? colorValues.spreadInStickerMode
    : colorValues.spread;

  const sectionName = useBinarySelector(makeSelectSectionById, sectionId)?.name;

  const memoizedSpreadContextValue = useMemo(
    () => ({
      id,
      nodeIndex,
      nodeSiblingCount,
      sectionName,
      sectionId,
    }),
    [id, nodeIndex, nodeSiblingCount, sectionName, sectionId]
  );

  const commentsBySpreadId = useSelector(getCommentsBySpreadId);
  const comments = commentsBySpreadId?.[id] || [];

  return (
    <SpreadContext.Provider value={memoizedSpreadContextValue}>
      <g
        id={id}
        data-id={id}
        transform={`translate(${x},${y})`}
        clipPath={clipPath}
        className={`spread container qa-spread-root qa-spread-${nodeIndex}`}
      >
        <rect
          className="qa-spread-rect"
          onContextMenu={onSpreadContextMenu}
          x={-imageBleed - spineLeft}
          y={-imageBleed}
          width={width + imageBleed * 2 + spineLeft}
          height={height + imageBleed * 2}
          fill={spreadColor}
        />
        {showHighlight && (
          <SpreadHighlight
            height={height}
            width={width}
            spineLeft={spineLeft}
            strokeWidth={spreadHighlightSize}
          />
        )}
        {updatedChildren}
        {/*
         * We render `SpreadHighlight` twice because dragging
         * children towards the border of the spread (and beyond)
         * leads to the first hightlight being hidden.
         * The second highlight, with opacity: 0.2, creates a
         * semi-transparent overlay that will be visible at all times.
         */}
        {showHighlight && (
          <SpreadHighlight
            height={height}
            width={width}
            spineLeft={spineLeft}
            strokeWidth={spreadHighlightSize}
            opacity={0.2}
          />
        )}
        {showGuides && (
          <SpreadGuides
            height={height}
            width={width}
            spineLeft={spineLeft}
            isFrontCover={isFrontCover}
            isBackCover={isBackCover}
          />
        )}
      </g>
      {additionalSpreadNeededForSectionId && (
        <InsertSpreadButton
          height={height}
          width={width}
          onClick={e => onInsertSpread(e)}
          pagePadding={pagePadding}
          y={y}
          isFrontCover={isFrontCover}
          spreadIdx={nodeIndex}
        />
      )}
      {comments.map(comment => (
        <AlbumComment
          key={comment.id}
          comment={comment}
          spreadX={x}
          spreadY={y}
        />
      ))}
    </SpreadContext.Provider>
  );
}

Spread.defaultProps = {
  spreadVisible: true,
  targetSpreadId: null,
  cropPreview: false,
  spine: 0,
  children: null,
  renderingSpreadPositionAndSize: null,
  additionalSpreadNeededForSectionId: null,
  sectionId: null,
};

Spread.propTypes = {
  // React-element children, not ids
  children: node,

  // from Node
  id: string.isRequired,
  nodeIndex: number.isRequired,
  nodeSiblingCount: number.isRequired,
  sectionId: string,

  // mapStateToProps
  spreadVisible: bool,
  targetSpreadId: string,
  cropPreview: bool,
  additionalSpreadNeededForSectionId: string,
  spine: number,

  // mapStateToProps in Node
  stickerMode: bool.isRequired,

  // From mapDispatchToProps
  insertElements: func.isRequired,

  // From mapDispatchToProps in Node
  showContextMenu: func.isRequired,

  // from SpreadContainerRenderer
  renderingSpreadPositionAndSize: shape({
    x: number.isRequired,
    y: number.isRequired,
    width: number.isRequired,
    height: number.isRequired,
  }),

  // From ImageContext
  imageContext: ImageContextShape.isRequired,
};

const SpreadComponentWithImageContext = props => (
  <ImageContext.Consumer>
    {imageContext => <Spread {...props} imageContext={imageContext} />}
  </ImageContext.Consumer>
);
export default SpreadComponentWithImageContext;
