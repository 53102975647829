import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Button,
  Dropdown,
  Checkbox,
  Input,
  Form,
  Menu,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'query-string';

import {
  renderModes,
  renderFormats,
  API_URL,
  resolutions,
} from '../../constants';
import presets from '../../exportPresets';
import { getTargetSpreadIndex } from '../../selectors/legacy';
import {
  updateControls,
  fillJobSettingsRangeWithStickerNumbers,
} from '../../modules/controls';
import { saveAlbum } from '../../modules/albums';
import ExportPreflight from './ExportPreflight';
import PopupMenuButton from '../menu/PopupMenuButton';
import { getJobSettings } from '../../selectors/controls';

const CreateJobClass = ({
  currentAlbum,
  jobSettings,
  onJobCreate,
  saveAlbum, // eslint-disable-line no-shadow
  saving,
  targetSpreadIndex,
  updateControls, // eslint-disable-line no-shadow
  fillJobSettingsRangeWithStickerNumbers, // eslint-disable-line no-shadow
}) => {
  const { custom, mode, preset } = jobSettings;
  let { range } = jobSettings;

  if (preset === 'current_spread') {
    range = String(targetSpreadIndex + 1);
  }

  const startJob = () => {
    const params = {
      ...jobSettings,
      album_id: currentAlbum,
      range: String(range).replace(' ', ''),
      dpi: String(jobSettings.dpi).replace(' ', ''),
    };
    ['label', 'preset', 'custom'].forEach(key => delete params[key]);

    const data = {
      job: {
        params,
        task: 'render',
      },
    };

    console.log(qs.stringify(params)); // eslint-disable-line

    axios
      .post(`${API_URL}/exports/jobs`, data)
      .then(({ data: { job } }) => onJobCreate(job))
      .catch(e => {
        window.alert(e.toString());
      });
  };

  const handleSaveAndExport = () => {
    saveAlbum().then(startJob);
  };

  const handleExportWithoutSave = () => {
    startJob();
  };

  const setValue = (key, value) => {
    let { previewSticker } = jobSettings;
    if (key === 'mode' && value === 'sticker') {
      previewSticker = true;
    }
    updateControls({
      jobSettings: { ...jobSettings, previewSticker, [key]: value },
    });
  };

  const setPreset = key => {
    const newJobSettings = { ...jobSettings, ...presets[key], preset: key };
    updateControls({ jobSettings: newJobSettings });
  };

  let rangeLabel;
  if (mode === 'spread') {
    rangeLabel = 'Doppelseiten';
  } else if (mode === 'sticker') {
    rangeLabel = 'Sticker';
  } else {
    rangeLabel = 'Seiten';
  }

  return (
    <Form className="create-job">
      <Form.Field inline>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Voreinstellung</label>
        <Dropdown
          className="qa-create-job-dd"
          value={jobSettings.preset}
          onChange={(_event, { value }) => setPreset(value)}
          options={Object.keys(presets).map(key => ({
            value: key,
            text: presets[key].label,
            key,
          }))}
          selection
          style={{ width: 180 }}
        />
      </Form.Field>

      {custom && (
        <>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Mode</label>
            <Dropdown
              className="qa-create-job-form--mode-dd"
              value={jobSettings.mode}
              onChange={(_event, { value }) => setValue('mode', value)}
              options={Object.keys(renderModes).map(key => ({
                value: key,
                text: renderModes[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Format</label>
            <Dropdown
              className="qa-create-job-form--format-dd"
              value={jobSettings.format}
              onChange={(_event, { value }) => setValue('format', value)}
              options={Object.keys(renderFormats).map(key => ({
                value: key,
                text: renderFormats[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>
          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Auflösung</label>
            <Dropdown
              className="qa-create-job-form--resolution-dd"
              value={jobSettings.resolution}
              onChange={(_event, { value }) => setValue('resolution', value)}
              options={Object.keys(resolutions).map(key => ({
                value: key,
                text: resolutions[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[dpi]">DPI</label>
            <Input
              id="create-job-form[dpi]"
              onChange={(_event, { value }) => setValue('dpi', value)}
              value={jobSettings.dpi}
            />
          </Form.Field>

          <Form.Field inline disabled={mode === 'sticker'}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[preview-sticker]">
              Sticker anzeigen
            </label>
            <Checkbox
              id="create-job-form[preview-sticker]"
              checked={jobSettings.previewSticker}
              onClick={(_event, { checked }) =>
                setValue('previewSticker', checked)
              }
            />
          </Form.Field>

          <Form.Field inline disabled={mode !== 'album'}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[include-cover]">
              Cover einschliessen
            </label>
            <Checkbox
              id="create-job-form[include-cover]"
              checked={jobSettings.includeCover}
              onClick={(_event, { checked }) =>
                setValue('includeCover', checked)
              }
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[include-bleed]">
              Beschnittzugabe + Schnittmarken
            </label>
            <Checkbox
              id="create-job-form[include-bleed]"
              checked={jobSettings.includeBleed}
              onClick={(_event, { checked }) =>
                setValue('includeBleed', checked)
              }
            />
          </Form.Field>

          <Form.Field inline>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="create-job-form[compress-pdf]">
              PDF komprimieren
            </label>
            <Checkbox
              id="create-job-form[compress-pdf]"
              checked={jobSettings.compress}
              onClick={(_event, { checked }) => setValue('compress', checked)}
            />
          </Form.Field>
        </>
      )}

      <Form.Field inline>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="create-job-form[range]">
          {rangeLabel} (z.B. 1-3,5)
        </label>
        <div className="d-inline-block">
          <Input
            onChange={(_event, { value }) => setValue('range', value)}
            id="create-job-form[range]"
            value={range}
            type="text"
          />
          {mode === 'sticker' && (
            <PopupMenuButton>
              <Menu secondary vertical>
                <Menu.Item
                  onClick={() => fillJobSettingsRangeWithStickerNumbers(false)}
                >
                  Einzelsticker einfügen
                </Menu.Item>
                <Menu.Item
                  onClick={() => fillJobSettingsRangeWithStickerNumbers(true)}
                >
                  Doppelsticker einfügen
                </Menu.Item>
              </Menu>
            </PopupMenuButton>
          )}
        </div>
      </Form.Field>

      {mode !== 'sticker' && (
        <Form.Field inline>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Preflight</label>
          <ExportPreflight />
        </Form.Field>
      )}

      <Form.Field inline>
        <Button.Group color="teal">
          <Button
            className="qa-start-job-btn"
            disabled={saving}
            color="green"
            icon
            onClick={handleSaveAndExport}
          >
            <Icon name="save" />
            Speichern &amp; Export starten
          </Button>
          <Dropdown className="button icon" floating trigger={[]}>
            <Dropdown.Menu>
              <Dropdown.Item
                text="Exportieren ohne Speichern"
                icon="play"
                onClick={handleExportWithoutSave}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      </Form.Field>
    </Form>
  );
};

CreateJobClass.propTypes = {
  currentAlbum: PropTypes.string.isRequired,
  jobSettings: PropTypes.object.isRequired, // eslint-disable-line
  onJobCreate: PropTypes.func.isRequired,
  saveAlbum: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  targetSpreadIndex: PropTypes.number.isRequired,
  updateControls: PropTypes.func.isRequired,
  fillJobSettingsRangeWithStickerNumbers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentAlbum: state.albums.currentAlbum,
  jobSettings: getJobSettings(state),
  saving: state.albums.saving,
  targetSpreadIndex: getTargetSpreadIndex(state),
});

const mapDispatchToProps = {
  saveAlbum,
  updateControls,
  fillJobSettingsRangeWithStickerNumbers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobClass);
