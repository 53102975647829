const exportPresets = {
  album: {
    label: 'Album für Druck',
    mode: 'album',
    format: 'pdf',
    resolution: 'full',
    dpi: 200,
    previewSticker: false,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'album',
  },
  cover: {
    label: 'Cover für Druck',
    mode: 'cover',
    format: 'pdf',
    resolution: 'full',
    dpi: 600,
    previewSticker: false,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'cover',
  },
  sticker: {
    label: 'Sticker für Druck',
    mode: 'sticker',
    format: 'pdf',
    resolution: 'full',
    dpi: 300,
    previewSticker: true,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'sticker',
  },
  album_check: {
    label: 'Album-Check',
    mode: 'album',
    format: 'jpg',
    resolution: 'full',
    dpi: 200,
    previewSticker: false,
    includeCover: true,
    includeBleed: false,
    compress: true,
    range: '',
    custom: false,
    preset: 'album_check',
  },
  sticker_check: {
    label: 'Sticker-Check',
    mode: 'sticker',
    format: 'jpg',
    resolution: 'full',
    dpi: 200,
    previewSticker: true,
    includeCover: false,
    includeBleed: false,
    compress: true,
    range: '',
    custom: false,
    preset: 'sticker_check',
  },
  current_spread: {
    label: 'Aktuelle Doppelseite',
    mode: 'spread',
    format: 'pdf',
    resolution: 'full',
    dpi: 300,
    previewSticker: false,
    includeCover: true,
    includeBleed: false,
    compress: true,
    custom: false,
    preset: 'current_spread',
  },
  custom: { label: 'Custom...', custom: true, preset: 'custom' },
};

export default exportPresets;
