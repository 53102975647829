import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';

import { defaultInlineStyles } from '../components/svg/elements/Text/textConstants';
import calculateTextScale from './calculateTextScale';
import { calculatePageNumber } from './spreads';

export const createSelectionWithAllContentSelected = contentState => {
  const firstBlock = contentState.getBlockMap().first();
  const lastBlock = contentState.getBlockMap().last();

  return new SelectionState({
    anchorKey: firstBlock.getKey(),
    anchorOffset: 0,
    focusKey: lastBlock.getKey(),
    focusOffset: lastBlock.getLength(),
  });
};

function applyInitialStylesToContentState(contentState) {
  const selection = createSelectionWithAllContentSelected(contentState);
  return defaultInlineStyles.reduce(
    (nextContentState, style) =>
      Modifier.applyInlineStyle(nextContentState, selection, style),
    contentState
  );
}

/**
 * Texts/Symbols are be styleable by the user. In order to store these style
 * values, at least one character (' ') is needed in order to have some place
 * for draft-js to save these styles.
 */
export const emptyRawContentState = convertToRaw(
  applyInitialStylesToContentState(ContentState.createFromText(' '))
);

export const newTextElementContentState = convertToRaw(
  applyInitialStylesToContentState(ContentState.createFromText('Neuer Text...'))
);

export function createEditorStateFromJson(json) {
  let contentState = false;
  if (typeof json !== 'object') {
    try {
      contentState = convertFromRaw(json);
    } catch (e) {
      contentState = false;
    }
  } else {
    contentState = convertFromRaw(json);
  }
  let initStyles = false;
  if (!contentState && typeof json !== 'undefined') {
    if (typeof json !== 'string') json = json.toString();
    contentState = ContentState.createFromText(json);
    initStyles = true;
  }
  let editorState;

  if (contentState) {
    if (initStyles) {
      contentState = applyInitialStylesToContentState(contentState);
    }
    const selection = createSelectionWithAllContentSelected(contentState);
    editorState = EditorState.createWithContent(contentState);
    editorState = EditorState.acceptSelection(editorState, selection);
  } else {
    editorState = EditorState.createEmpty();
  }

  return editorState;
}

const getSymbolTextFromPropsAndXPos = (
  { props, xPos },
  spreadContext,
  imageContext = null
) => {
  const {
    symbol,
    localTexts,
    texts,
    album,
    totalstickers,
    toc_sections,
    toc_pages,
  } = props;

  const {
    sectionName,
    nodeSiblingCount: spreadCount,
    nodeIndex: spreadIndex,
  } = spreadContext;

  const defaultText = imageContext?.stickerRendering ? '' : `[${symbol}]`;

  switch (symbol) {
    case 'sticker-line':
    case 'sticker-line1':
    case 'sticker-line2':
    case 'sticker-position1':
    case 'sticker-position2':
    case 'sticker-number':
    case 'sticker-nextnumber':
      if (localTexts) {
        const value = localTexts[symbol.replace('sticker-', '')];
        const isValueEmpty = value === null || value === undefined;
        return isValueEmpty ? defaultText : value;
      }

      return defaultText;

    case 'greeting_title':
    case 'greeting_text':
    case 'market_presentation_text':
    case 'market_presentation_profile':
    case 'market_presentation_did_you_know': {
      const text = texts.find(t => t.identifier === symbol);
      if (text) {
        return text.body;
      }
      return defaultText;
    }

    case 'section':
      return sectionName || defaultText;

    case 'pagenum':
      return String(calculatePageNumber(spreadIndex, xPos));

    case 'totalpages':
      return (spreadCount - 2) * 2 + 2;

    case 'album':
      return album;
    case 'totalstickers':
      return totalstickers;
    case 'toc_pages':
      return toc_pages;
    case 'toc_sections':
      return toc_sections;

    default:
      /**
       * There might be meta field texts inside `localTexts`,
       * so we're attempting to return that before the default text.
       */
      const isMetaSymbol = symbol.indexOf('meta-') > -1;

      if (isMetaSymbol) {
        /**
         * We're returning an empty string for missing meta field values
         * to not break sticker / sticker cell layouts
         */
        return localTexts?.[symbol] || '';
      }

      return defaultText;
  }
};

export const getEditorStateFromSymbolPropsAndXPos = (
  params,
  spreadContext,
  imageContext = null
) => {
  let { text: json } = params.props;
  const { symbol } = params.props;
  let actualText = '';
  let rawContentState = null;

  if (symbol) {
    actualText = getSymbolTextFromPropsAndXPos(
      params,
      spreadContext,
      imageContext
    );

    if (json) {
      try {
        rawContentState = json;
        rawContentState.blocks = [rawContentState.blocks[0]];
        rawContentState.blocks[0].text = String(actualText);
        rawContentState.blocks[0].inlineStyleRanges = rawContentState.blocks[0].inlineStyleRanges.map(
          item => ({
            ...item,
            length: actualText.length,
          })
        );
        if (rawContentState.blocks[0].inlineStyleRanges[0]) {
          rawContentState.blocks[0].inlineStyleRanges[0].length =
            actualText.length;
        } else {
          console.error(`style range undefined: ${actualText}`, symbol);
        }
        json = rawContentState;
      } catch (e) {
        console.error('text error', e, 'when parsing', json);
        json = actualText;
      }
    } else {
      json = actualText;
    }
  }

  return {
    rawContentState,
    editorState: createEditorStateFromJson(json),
    actualText,
  };
};

export const getEditorStateFromTextPropsAndDraftState = ({ props, state }) => {
  const { id, text } = props;
  const { editingId, editorState } = state;
  if (editingId === id) {
    return editorState;
  }
  return createEditorStateFromJson(text);
};

/**
 * This prevents the browser from changing ß to SS when the uppercase-style
 * is applied. Since we are mutating the HTML, this fix can only be applied
 * during PDF-rendering of the text-overlay. In the browser preview, the
 * undesired change from ß to SS will still be visible.
 */
export const replaceSzLigatureHtml = html =>
  html.replace(/ß/g, '<span style="text-transform: none">ß</span>');

export function extractStyleFromContentState({
  rawContentState,
  customStyleMap,
}) {
  const [firstBlock] = rawContentState.blocks;
  const { inlineStyleRanges } = firstBlock;

  // Determine relevant font styles
  const activeStyleNames = inlineStyleRanges.map(range => range.style);

  const style = activeStyleNames.reduce(
    (acc, cur) => ({ ...acc, ...customStyleMap[cur] }),
    {}
  );

  return { style, activeStyleNames };
}

export function applyAutoSize({
  customStyleMap,
  style,
  activeStyleNames,
  autosizeWidth,
  actualText,
}) {
  const { fontSize, fontFamily } = style;

  const subStyles = ['BOLD', 'ITALIC'].filter(
    subStyle => activeStyleNames.indexOf(subStyle) !== -1
  );
  const text =
    activeStyleNames.indexOf('UPPERCASE') !== -1
      ? actualText.toLocaleUpperCase()
      : actualText;

  // Calculate scale to fit into width
  const fontStyle = `${subStyles.join(' ')} ${fontSize}pt ${fontFamily}`;
  const scale = calculateTextScale(text, fontStyle, autosizeWidth);

  if (scale === 1) {
    return customStyleMap;
  }

  // Add autosize CSS style to customStyleMap
  return {
    ...customStyleMap,
    AUTOSIZE: {
      fontSize: fontSize * scale,
      whiteSpace: 'nowrap',
    },
  };
}
