import React from 'react';

export default class Test extends React.Component {
  componentDidMount() {
    const ctx = this.canvas.getContext('2d');
    const img = new Image();
    img.onload = function() {
      const size = 10;
      const scale = Math.min(size / img.width, size / img.height);
      const w = Math.floor(img.width * scale);
      const h = Math.floor(img.height * scale);

      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w, h); // Or at whatever offset you like
      const imgd = ctx.getImageData(0, 0, w, h);
      const { data } = imgd;

      /*
             Work out the Mean (the simple average of the numbers)
             Then for each number: subtract the Mean and square the result.
             Then work out the mean of those squared differences.
             Take the square root of that and we are done!


             */
      /*
            let getSD = function (data) {
                let m = getMean(data);
                return Math.sqrt(data.reduce(function (sq, n) {
                        return sq + Math.pow(n - m, 2);
                    }, 0) / (data.length - 1));
            };
            */

      const n = Math.floor(data.length / 4);

      const stat = [];

      // compute the sampling standard deviation in 1 pass

      for (let i = 0; i < n; i += 1) {
        for (let c = 0; c <= 2; c += 1) {
          if (i === 0) {
            const init = {
              max: Number.NEGATIVE_INFINITY,
              min: Number.POSITIVE_INFINITY,
              mean: 0,
              sq_sum: 0,
            };
            stat.push(init);
          }

          const pos = 1 / (i + 1);
          const val = data[i * 4 + c];
          const s = stat[c];

          s.sq_sum += val * val;

          s.mean = s.mean * (1 - pos) + val * pos;
          if (val > s.max) {
            s.max = val;
          }
          if (val < s.min) {
            s.min = val;
          }
        }
      }

      for (let c = 0; c <= 2; c += 1) {
        const s = stat[c];
        const variance = s.sq_sum / n - s.mean * s.mean;
        s.deviation = Math.sqrt(variance);
      }
    };
    // const url = "https://api.stickerstars.app/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBamxSIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9e57e050edc34e50a7ad9e83144887402c1f0cf5/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MY21WemFYcGxTU0lNTVRBd2VERXdNQVk2QmtWVU9oQmhkWFJ2WDI5eWFXVnVkRlE9IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--3312adbfbfd0a3814f65779d5f3229de17f91d75/15.jpg";
    const url = '/placeholder/red.png';
    img.src = url;
  }

  render() {
    return (
      <>
        <h1>TEST</h1>
        <canvas width={100} height={100} ref={node => (this.canvas = node)} />
      </>
    );
  }
}
