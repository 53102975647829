import uniqBy from 'lodash/uniqBy';

/**
 * Compares the array of all placed sections to the array of all sections
 * to determine which sections have not yet been (fully) placed.
 */
export const getIncompleteSectionIds = (placedSectionIds, sectionIds) => {
  return sectionIds.filter(sectionId => !placedSectionIds.includes(sectionId));
};

/**
 * Finds the last placed section before a target section (passed via `sectionId`).
 */
export const getPreviousPlacedSectionId = (
  sectionId,
  placedSectionIds,
  sectionIds
) => {
  const targetIndex = sectionIds.indexOf(sectionId);

  let previousPlacedSectionId = null;
  for (let i = 1; i < targetIndex + 1; i += 1) {
    const tempSectionId = sectionIds[targetIndex - i];
    if (placedSectionIds.includes(tempSectionId)) {
      previousPlacedSectionId = tempSectionId;
      break;
    }
  }
  return previousPlacedSectionId;
};

/**
 * Finds the first placed section after a target section (passed via `sectionId`).
 */
export const getNextPlacedSectionId = (
  sectionId,
  placedSectionIds,
  sectionIds
) => {
  const targetIndex = sectionIds.indexOf(sectionId);

  let nextPlacedSectionId = null;
  for (let i = 1; i <= targetIndex + 1; i += 1) {
    const tempSectionId = sectionIds[targetIndex + i];
    if (placedSectionIds.includes(tempSectionId)) {
      nextPlacedSectionId = tempSectionId;
      break;
    }
  }

  return nextPlacedSectionId;
};

/**
 * Finds last spread in an album that belongs to a given section (passed via `sectionId`).
 */
export const getLastSpreadIdForSectionId = (sectionId, spreads) => {
  const sectionSpreads = spreads.filter(
    spread => spread.props.sectionId === sectionId
  );
  if (sectionSpreads.length === 0) return null;
  return sectionSpreads[sectionSpreads.length - 1].props.id;
};

/**
 * Finds the first spread in an album that belongs to a given section (passed via `sectionId`).
 */
export const getFirstSpreadIdForSectionId = (sectionId, spreads) => {
  const sectionSpreads = spreads.filter(
    spread => spread.props.sectionId === sectionId
  );
  if (sectionSpreads.length === 0) return null;
  return sectionSpreads[0].props.id;
};

/**
 * To determine where to place buttons to add new spreads for unplaced sections, we look at
 * each missing section and define an area (of spread IDs) that the given section could
 * be placed before or after. Since the order of the sections is pre-defined, we look for two
 * spreads:
 * - the left bound is defined by the last spread of the section before our target section
 * - the right bound is defined by the first spread of the section after our target section
 */
export const getSpreadButtonRangesForMissingSectionIds = (
  fullyPlacedSectionIds,
  sections,
  spreads
) => {
  const sectionIds = sections.map(section => section.id);
  const incompleteSectionIds = getIncompleteSectionIds(
    fullyPlacedSectionIds,
    sectionIds
  );
  const insertSpreadButtonPositions = incompleteSectionIds.map(sectionId => {
    const prevSectionId = getPreviousPlacedSectionId(
      sectionId,
      fullyPlacedSectionIds,
      sectionIds
    );
    const nextSectionId = getNextPlacedSectionId(
      sectionId,
      fullyPlacedSectionIds,
      sectionIds
    );

    // Use the first spread as fallback left bound if no previous section is placed
    let leftBoundSpreadId;
    if (!prevSectionId) {
      leftBoundSpreadId = spreads[0].props.id;
    } else {
      leftBoundSpreadId = getLastSpreadIdForSectionId(prevSectionId, spreads);
    }

    // Use the last non-cover spread as fallback right bound if no next section is placed
    let rightBoundSpreadId;
    if (!nextSectionId) {
      rightBoundSpreadId = spreads[spreads.length - 1].props.id;
    } else {
      rightBoundSpreadId = getFirstSpreadIdForSectionId(nextSectionId, spreads);
    }

    return { sectionId, leftBoundSpreadId, rightBoundSpreadId };
  });

  // If multiple sections could be placed below the same spread, we
  // only set a flag for the first of them to prevent rendering multiple
  // overlapping buttons.
  return uniqBy(insertSpreadButtonPositions, 'leftBoundSpreadId');
};
