// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';
import debounce from 'lodash/debounce';

import { patchAlbumData } from '../../modules/albums';

export class TextPanel extends React.Component {
  state = {
    title: null,
  };

  static getDerivedStateFromProps(props, state) {
    return { title: state.title ? state.title : props.title };
  }

  constructor(props) {
    super(props);
    this.debouncedChange = debounce(this.updateName, 700);
  }

  handleNameChange = e => {
    const { value } = e.target;
    this.setState({ title: value }, () => this.debouncedChange(value));
  };

  updateName(value) {
    const { dispatch } = this.props;
    dispatch(patchAlbumData({ title: value }));
  }

  render() {
    const { title } = this.state;
    return (
      <Input
        fluid
        className="qa-album-title"
        placeholder="Name des Albums"
        onChange={this.handleNameChange}
        value={title}
      />
    );
  }
}

TextPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  title: state.albums.albumData.title,
});

export default connect(mapStateToProps)(TextPanel);
