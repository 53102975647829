import React from 'react';
import { number } from 'prop-types';

import AbstractOperation from './AbstractOperation';

const ScaleOperation = ({ width, height, zoom }) => {
  function update(e, { scale }, { parentStart, pivot, parentPointer }) {
    const currentLength = parentPointer.minus(pivot).length();
    const startLength = parentStart.minus(pivot).length();
    const scaleDelta = currentLength / startLength;
    const scaleNew = scale * scaleDelta;
    return { scale: scaleNew };
  }

  const handleRadius = 5 / zoom;

  return (
    <AbstractOperation
      onUpdate={update} // eslint-disable-line react/jsx-no-bind
      render={({ onStart }) => (
        <>
          <circle
            r={handleRadius}
            cx={0}
            cy={0}
            className="handle scale"
            onMouseDown={e => onStart(e, 'bottomRight')}
            style={{ cursor: 'nw-resize' }}
          />
          <circle
            r={handleRadius}
            cx={width}
            cy={0}
            className="handle scale qa-handle-top-right-scale"
            onMouseDown={e => onStart(e, 'bottomLeft')}
            style={{ cursor: 'ne-resize' }}
          />
          <circle
            r={handleRadius}
            cx={0}
            cy={height}
            className="handle scale"
            onMouseDown={e => onStart(e, 'topRight')}
            style={{ cursor: 'sw-resize' }}
          />
          <circle
            r={handleRadius}
            cx={width}
            cy={height}
            className="handle scale"
            onMouseDown={e => onStart(e, 'topLeft')}
            style={{ cursor: 'se-resize' }}
          />
        </>
      )}
    />
  );
};

ScaleOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  zoom: number.isRequired,
};

export default ScaleOperation;
