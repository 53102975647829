import { createSelector } from 'reselect';

import { placeSectionsOnSpread } from '../util/generators';
import { getSpreadButtonRangesForMissingSectionIds } from '../util/sticker_placement';
import { getLegacySpreads, getSections, getStickers } from './legacy';
import { getStickerSlotTemplates } from './templates';

export const getLandscapeStickerIds = createSelector(
  [getStickers, getStickerSlotTemplates],
  (stickers, stickerSlotTemplates) =>
    stickers.reduce((acc, sticker) => {
      const [tagId] = sticker.tags || [];
      if (!tagId) {
        return acc;
      }

      const [matchingTemplate] = stickerSlotTemplates.filter(template =>
        template.tags.some(tag => tag.id === tagId)
      );
      if (!matchingTemplate) {
        return acc;
      }

      const { width, height } = matchingTemplate.json.props;
      if (height > width) {
        return acc;
      }

      return [...acc, sticker.id];
    }, [])
);

/**
 * Iterates over all spreads, tries to place sections on each using
 * `placeSectionsOnSpread` and returns an object with sticker area IDs
 * as keys and arrays of sticker positions as values.
 */
export const getStickerPositionsByArea = createSelector(
  [getLegacySpreads, getSections, getLandscapeStickerIds],
  (spreads, sections, landscapeStickerIds) => {
    let params = {
      sectionIndex: 0,
      stickerOffset: 0,
      landscapeStickerIds,
    };
    return spreads.reduce((acc, spread, spreadIndex) => {
      const {
        stickerPositionsByStickerArea,
        ...nextParams
      } = placeSectionsOnSpread(sections, spread, spreadIndex, params);
      params = nextParams;
      return { ...acc, ...stickerPositionsByStickerArea };
    }, {});
  }
);

/**
 * Returns an object of positions of all placed stickers, referenced by sticker-id
 */
export const getPlacedStickerPositionInfoById = createSelector(
  [getStickerPositionsByArea],
  stickerPositionsByArea =>
    Object.values(stickerPositionsByArea).reduce((areas, area) => {
      const reducedArea = area.reduce((acc, position) => {
        acc[position.id] = position;
        return acc;
      }, {});
      return { ...areas, ...reducedArea };
    }, {})
);

/**
 * Returns an array of IDs of all placed stickers.
 */
export const getPlacedStickerIds = createSelector(
  [getPlacedStickerPositionInfoById],
  placedStickerInfoById =>
    Object.values(placedStickerInfoById).map(position => position.id)
);

/**
 * Returns an array of IDs of all fully placed sections.
 */
export const getFullyPlacedSections = createSelector(
  [getSections, getPlacedStickerIds],
  (sections, placedStickerIds) => {
    return sections
      .filter(({ stickers }) =>
        stickers.every(({ id }) => placedStickerIds.includes(id))
      )
      .map(section => section.id);
  }
);

/**
 * Returns an object with spread IDs after which an `InsertSpreadButton` should be placed as keys
 * and the sections' IDs for which a spread is needed as values.
 */
export const getSpreadButtonPositions = createSelector(
  [getLegacySpreads, getSections, getFullyPlacedSections],
  (spreads, sections, fullyPlacedSections) => {
    // Each unplaced section has a defined range of spreads that a corresponding
    // `InsertSpreadButton` will be placed after: from the last spread belonging to the
    // section before the current to the first spread belonging to the section after it.
    // These ranges are calculated using `getSpreadButtonRangesForMissingSectionIds`,
    // then looped over to add an `addAdditionalSpreadNeededForSectionId` flag to each
    // of the `spreadId`s inside these ranges.
    const spreadButtonRanges = getSpreadButtonRangesForMissingSectionIds(
      fullyPlacedSections,
      sections,
      spreads
    );
    const spreadIds = spreads.map(spread => spread.props.id);
    const spreadButtonPositions = {};

    spreadButtonRanges.forEach(position => {
      const { sectionId, leftBoundSpreadId, rightBoundSpreadId } = position;

      // Get the index inside `spreads` for the left and right bounding spreads
      const leftBoundIdx = spreadIds.indexOf(leftBoundSpreadId);
      const rightBoundIdx = spreadIds.indexOf(rightBoundSpreadId);

      // Get all spread IDs within the range between left and right bounding spreads
      // and attach the `sectionId` for which a spread is needed as values in the
      // returned object.
      const spreadIdsInRange = spreadIds.slice(leftBoundIdx, rightBoundIdx);
      spreadIdsInRange.forEach(spreadId => {
        spreadButtonPositions[spreadId] = sectionId;
      });
    });

    return spreadButtonPositions;
  }
);

/**
 * Returns a boolean indicating whether all sections have been fully placed.
 */
export const getAllStickersPlaced = createSelector(
  [getSections, getFullyPlacedSections],
  (sections, fullyPlacedSections) => {
    return sections.length === fullyPlacedSections.length;
  }
);

export const selectSectionById = createSelector(
  [getSections, (_, sectionId) => sectionId],
  (sections, sectionId) => sections.find(section => section.id === sectionId)
);

export const makeSelectSectionById = () => selectSectionById;

const selectStickerSectionNameByIdLookup = createSelector(
  [getSections],
  sections =>
    sections.reduce(
      (sectionsAcc, section) => ({
        ...sectionsAcc,
        ...section.stickers.reduce(
          (stickersAcc, sticker) => ({
            ...stickersAcc,
            [sticker.id]: section.name,
          }),
          {}
        ),
      }),
      {}
    )
);

export const selectStickerSectionNameById = createSelector(
  [selectStickerSectionNameByIdLookup, (_, id) => id],
  (stickerSectionNameById, id) => stickerSectionNameById[id]
);

export const selectStickerNumberByIdLookup = createSelector(
  [getStickers],
  stickers => {
    let number = 1;
    return stickers.reduce((acc, sticker) => {
      acc[sticker.id] = number;
      number += sticker.doubleSticker ? 2 : 1;
      return acc;
    }, {});
  }
);
export const selectStickerNumberById = createSelector(
  [selectStickerNumberByIdLookup, (_, id) => id],
  (stickerNumberById, id) => stickerNumberById[id]
);

export const makeSelectStickerNumberById = () => selectStickerNumberById;

/**
 * Reduces all available sticker meta fields from sticker props.
 * Return value has the following structure:
 *
 * ```
 * {
 *   meta-value-field-key: 'Sticker (Wert): Meta Field Key',
 *   meta-key-value-field-key: 'Sticker (Feld:Wert): Meta Field Key',
 * }
 * ```
 */
export const selectAvailableStickerMetaFields = createSelector(
  [getStickers],
  stickers =>
    stickers.reduce(
      (acc, { metaFields }) => ({
        ...acc,
        ...Object.keys(metaFields || {}).reduce(
          (fields, key) => ({
            ...fields,
            [`meta-value-${key}`]: `Sticker: ${metaFields[key].key} (Wert)`,
            [`meta-key-value-${key}`]: `Sticker: ${metaFields[key].key} (Feld: Wert)`,
          }),
          {}
        ),
      }),
      {}
    )
);
