import React from 'react';
import { func, number, bool } from 'prop-types';

const InsertSpreadButton = props => {
  const {
    height,
    width,
    onClick,
    pagePadding,
    y,
    isFrontCover,
    spreadIdx,
  } = props;

  // We are hardcoding `- 7` here to "center-align" the button horizontally (has 14px width)
  const x = isFrontCover ? width + width / 2 - 7 : width / 2 - 7;

  return (
    <text
      className={`qa-add-spread-btn qa-add-spread-btn-below-${spreadIdx}`}
      x={x}
      y={y + height + pagePadding / 2 - 7}
      style={{ cursor: 'pointer' }}
      fill="red"
      fontFamily="Icons"
      onClick={onClick}
    >
      &#xf055;
    </text>
  );
};

InsertSpreadButton.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
  pagePadding: number.isRequired,
  y: number.isRequired,
  onClick: func.isRequired,
  isFrontCover: bool.isRequired,
  spreadIdx: number.isRequired,
};

export default InsertSpreadButton;
