import { useSelector, useDispatch } from 'react-redux';

import { setZoom, setPan } from '../../../modules/controls';

export default function useViewportState() {
  const dispatch = useDispatch();
  const pan = useSelector(state => state.controls.pan);
  const zoom = useSelector(state => state.controls.zoom);
  return {
    pan,
    zoom,
    setPan: value => dispatch(setPan(value)),
    setZoom: value => dispatch(setZoom(value)),
  };
}
