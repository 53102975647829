import React from 'react';

import ErrorBoundary from '../../components/ErrorBoundary';
import { ReactRouterMatchShape } from '../../components/shapes';
import Editor from './Editor';

function EditorWithErrorBoundary(props) {
  const {
    match: {
      params: { albumId },
    },
    ...rest
  } = props;

  return (
    <ErrorBoundary albumId={albumId}>
      <Editor {...rest} />
    </ErrorBoundary>
  );
}

EditorWithErrorBoundary.propTypes = {
  match: ReactRouterMatchShape.isRequired,
};

export default EditorWithErrorBoundary;
