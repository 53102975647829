import React from 'react';

import { dimensions } from '../../../../../constants';
import {
  ColorShape,
  StickerLocalTextsShape,
  StickerShape,
} from '../../../../shapes';
import StickerNumber from '../StickerNumber';
import StickerName from './StickerName';
import StickerPosition from './StickerPosition';

function StickerFrameSingle({
  sticker,
  localTexts: { line1, line2, position1, position2 },
  resolvedStickerColor,
}) {
  const hasPosition2 = !!position2;

  return (
    <g>
      {sticker.textless && (
        <g>
          <path
            fill={resolvedStickerColor}
            d="M2.01,60.67c7.69,1.25,15.38,3.48,23.07,4.73c7.65-1.25,22.97-4.75,22.97-4.75l-22.97,2.4L2.01,60.67z"
          />
          <path
            fill="#fff"
            d="M0,0v70h50V0H0z M2,2.1h46.0l-0,58.56l-23,4l-23-4L2,2.1z"
          />
        </g>
      )}

      {!sticker.textless && (
        <g>
          <polygon
            fill={resolvedStickerColor}
            points="48.29,52.33 22.96,56.44 48.29,61.74"
          />
          <path fill="#fff" d="M0,0v70h50V0H0z M2,52.4V2.1h45.8v57.7L2,52.4z" />
          <g>
            <StickerName
              line1={line1}
              line2={line2}
              yOffset={hasPosition2 ? -1 : 0}
            />
            <StickerPosition
              position1={position1}
              position2={position2}
              yOffset={hasPosition2 ? -1.5 : 0}
            />
          </g>
        </g>
      )}

      <text
        fontFamily="Gilroy"
        fontSize={3.4}
        fill="black"
        fillOpacity={1}
        x={dimensions.stickerWidth - 3}
        y={dimensions.stickerHeight - 2}
      >
        <tspan textAnchor="end">
          <StickerNumber stickerId={sticker.id} />
        </tspan>
      </text>
    </g>
  );
}

StickerFrameSingle.propTypes = {
  sticker: StickerShape.isRequired,
  resolvedStickerColor: ColorShape.isRequired,
  localTexts: StickerLocalTextsShape.isRequired,
};

export default StickerFrameSingle;
