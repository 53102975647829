import { UNDO, REDO } from 'redux-live-undo';

export const HISTORY_ANCHOR = 'history/ANCHOR';
export const HISTORY_SET_CHANGED = 'history/HISTORY_SET_CHANGED';

export const initialState = { changed: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case HISTORY_SET_CHANGED:
      return {
        ...state,
        changed: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Tell the store that the album has been updated.
 */
export const albumChanged = (changed = true) => dispatch => {
  dispatch({ type: HISTORY_SET_CHANGED, payload: changed });
};

export const historyAnchor = () => dispatch => {
  dispatch(albumChanged()); // TODO: Check why this is here
  dispatch({ type: HISTORY_ANCHOR, undoableHistoryCheckpoint: true });
};

export const historyUndo = () => (dispatch, getState) => {
  /* The first state in this list is an empty album (containing the initial state, before the album was loaded)
   and the second/last state is a reference to the "present" state, so it does not count as an undoable state
   (this is related to the undox-internal mechanisms). Since we don't want the empty state to be reachable via
   undo, so we need to check if there are at least 3 states in the history. A similar check is done in
   the `GlobalNavigation.mapStateToProps`. */
  const canUndo = getState().workspaceAndSections.past.length > 2;
  if (!canUndo) {
    return;
  }
  dispatch({ type: UNDO });
  dispatch(albumChanged());
};

export const historyRedo = () => (dispatch, getState) => {
  const canRedo = getState().workspaceAndSections.future.length > 0;
  if (!canRedo) {
    return;
  }
  dispatch({ type: REDO });
  dispatch(albumChanged());
};
