import React from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Icon } from 'semantic-ui-react';
import { bool } from 'prop-types';

import { itemTypes } from '../../constants';
import { historyAnchor } from '../../modules/history';
import { deleteImageOrStock } from '../../actions/images';
import { deleteSection } from '../../actions/sections';
import { deleteSticker } from '../../actions/stickers';
import { deleteTemplate } from '../../actions/templates';

export const handleDrop = (item, monitor, props) => {
  const {
    removeImage,
    removeTemplate,
    removeSection,
    removeSticker,
    setHistoryAnchor,
  } = props;
  const itemId = item.id;
  switch (monitor.getItemType()) {
    case itemTypes.template:
      removeTemplate(itemId);
      break;
    case itemTypes.image:
      removeImage(itemId);
      break;
    case itemTypes.sticker:
      removeSticker(itemId);
      break;
    case itemTypes.section:
      removeSection(itemId);
      break;
    default:
      return;
  }
  setHistoryAnchor();
};

const dragSources = [
  itemTypes.element,
  itemTypes.template,
  itemTypes.image,
  itemTypes.section,
  itemTypes.sticker,
];

const style = { position: 'fixed', right: 0, bottom: 0 };

const DropTrashcan = props => {
  const { showTrashcan } = props;

  const [, dropRef] = useDrop({
    accept: dragSources,
    drop: (item, monitor) => handleDrop(item, monitor, props),
  });

  if (!showTrashcan) return null;

  return (
    <div className="qa-trashcan" ref={dropRef} style={style}>
      <Icon name="trash" size="huge" />
    </div>
  );
};

DropTrashcan.propTypes = {
  showTrashcan: bool.isRequired,
};

const mapStateToProps = state => ({
  showTrashcan: state.controls.showTrashcan,
});

const mapDispatchToProps = {
  removeImage: deleteImageOrStock,
  removeTemplate: deleteTemplate,
  removeSection: deleteSection,
  removeSticker: deleteSticker,
  setHistoryAnchor: historyAnchor,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropTrashcan);
