import { dimensions, resolutions, assetPreviewSizeLabels } from '../constants';
import exportPresets from '../exportPresets';
import { findElement } from '../util/spreads';
import { updateElements, deleteElements } from '../actions/workspace';
import { getChildrenBoundingBox, fitRectIntoArea } from '../util/geometry';
import Point from '../util/Point';
import {
  getLegacySpreads,
  getIsolation,
  getSpreadsCount,
  getSelectedSpreadIds,
  getVisibleSpreadIds,
  getWorkspace,
  getSections,
  getTargetSpreadIndex,
} from '../selectors/legacy';
import { clearSelection, setSelectInside } from './selection';
import { getJobSettings } from '../selectors/controls';
import { selectStickerNumberByIdLookup } from '../selectors/stickers';

export const ALBUM_UNSAVED_CHANGE = 'controls/ALBUM_UNSAVED_CHANGE';
export const UPDATE = 'controls/UPDATE';
export const TOGGLE = 'controls/TOGGLE';

const defaultState = {
  pan: {
    x: 0,
    y: 0,
  },
  zoom: 1,

  hoveredInstance: null,
  previewStickers: false,
  gridEnabled: true,
  stickerMode: false,
  colorGroupMode: false,
  cropPreview: false,
  previewResolution: resolutions.small,
  showTrashcan: false,
  operationActive: false,
  lassoActive: false,

  imagePreviewSize: assetPreviewSizeLabels.small,
  templatePreviewSize: assetPreviewSizeLabels.detail,
  textListView: assetPreviewSizeLabels.detail,

  imageListPreview: true,
  viewCenter: { x: 0, y: 0 },
  autoFit: true,

  alignTo: 'page',
  alignUseMargins: false,
  pageMargins: { left: 0, top: 0, bottom: 0, right: 0 },

  isolation: null,
  isolationSpreadIndex: null,

  activeTab: 0,
  stickerField: 'name',
  filterPresets: [],
  sectionDragEnabled: true,
  enableFilter: true,
  showElementsTree: true,
  showDoneComments: false,

  jobSettings: exportPresets.album,

  searchSettings: { search: '', replace: '', scope: 'spreads' },
  renderAllSpreads: false,

  filterTagIds: [],
  builderTags: [],
};

let initialState;
try {
  const parsedState = JSON.parse(localStorage.getItem('controls'));
  initialState = { ...defaultState, ...parsedState };
  ['zoom', 'isolation', 'isolationSpreadIndex'].forEach(key => {
    if (typeof defaultState[key] !== typeof initialState[key]) {
      initialState[key] = defaultState[key];
    }
  });
} catch (e) {
  initialState = defaultState;
}
if (!initialState) {
  initialState = defaultState;
}
initialState.panStartedAt = null;

let ivSave;

export default (state = initialState, action) => {
  const result = (() => {
    switch (action.type) {
      case UPDATE: {
        return {
          ...state,
          ...action.payload,
        };
      }
      case TOGGLE: {
        return {
          ...state,
          [action.payload]: !state[action.payload],
        };
      }
      default:
        return state;
    }
  })();

  if (ivSave) clearTimeout(ivSave);

  ivSave = setTimeout(() => {
    if (ivSave) {
      clearTimeout(ivSave);
      ivSave = false;
    }
    localStorage.setItem(
      'controls',
      JSON.stringify({
        ...result,
        lasso: null,
        contextMenu: null,
      })
    );
  }, 100);
  return result;
};

const setPanOrZoom = (key, value) => (dispatch, getState) => {
  const state = getState();
  const {
    controls: { pan, zoom, isolation },
  } = state;

  // This is to get the pan/zoom pair after the desired update
  const payload = { pan, zoom, [key]: value };

  const workspace = getWorkspace(state);
  const visibleSpreadIds = getVisibleSpreadIds(state);

  if (isolation) {
    // If isolation is active, exit isolation if we the spread containing the isolated element is panned out of view
    const isolationNode = workspace.nodes[isolation];
    if (!visibleSpreadIds.includes(isolationNode.parent)) {
      payload.isolation = null;
      payload.isolationSpreadIndex = -1;
    }
  }

  const selectedSpreadIds = getSelectedSpreadIds(state);
  if (selectedSpreadIds.length > 0) {
    // If selection is active, clear selection if we the spread containing the selected element is panned out of view
    if (!visibleSpreadIds.includes(selectedSpreadIds[0])) {
      dispatch(clearSelection());
    }
  }

  dispatch({
    type: UPDATE,
    payload,
  });
};

export const setZoom = newZoom => setPanOrZoom('zoom', newZoom);

export const setPan = newPan => setPanOrZoom('pan', newPan);

export const albumUnsavedChange = value => dispatch => {
  dispatch({
    type: ALBUM_UNSAVED_CHANGE,
    payload: value,
  });
};

/**
 * Toggles the flag enabling/disabling the save-button. Soon to be replaced: this can be derived from the undo/redo
 * timeline - if the timeline is not empty, the album was changed and the save-button should be enabled.
 */
export const albumUnsaved = (value = true) => dispatch => {
  dispatch(albumUnsavedChange(value));
};

export const updateControls = data => dispatch => {
  dispatch({
    type: UPDATE,
    payload: data,
  });
};

export const toggleTrashcan = showTrashcan => dispatch => {
  dispatch({
    type: UPDATE,
    payload: { showTrashcan },
  });
};

const applyPaddingToRectOrBounds = (rectOrBounds, padding) => {
  const { x, y, width, height } = rectOrBounds;
  return {
    x: x - padding,
    y: y - padding,
    width: width + padding * 2,
    height: height + padding * 2,
  };
};

// Reset the viewport to show all existing spreads
export const resetView = () => (dispatch, getState) => {
  const spreadsCount = getSpreadsCount(getState());
  const { clientWidth, clientHeight } = document.querySelector('.viewport');
  const contentArea = {
    x: 0,
    y: 0,
    width: dimensions.pageWidth * 2,
    height:
      dimensions.pageHeight * spreadsCount +
      dimensions.pagePadding * (spreadsCount - 1),
  };

  const { scale, x, y } = fitRectIntoArea(
    applyPaddingToRectOrBounds(contentArea, 50),
    {
      width: clientWidth,
      height: clientHeight,
    }
  );
  dispatch(setPan({ x, y }));
  dispatch(setZoom(scale));
};

export const resetViewToSpreadIndex = (spreadIndex = -1) => (
  dispatch,
  getState
) => {
  dispatch(updateControls({ activeAlbumPreviewSpreadIndex: spreadIndex }));
  const viewportClientRect = document
    .querySelector('.viewport')
    .getBoundingClientRect();

  if (!viewportClientRect) {
    return;
  }

  const targetSpreadIndex = getTargetSpreadIndex(getState());
  const resetIndex = spreadIndex === -1 ? targetSpreadIndex : spreadIndex;
  const contentArea = {
    x: 0,
    y: (dimensions.pageHeight + dimensions.pagePadding) * resetIndex,
    width: dimensions.pageWidth * 2,
    height: dimensions.pageHeight,
  };
  const paddedContentArea = applyPaddingToRectOrBounds(contentArea, 50);
  const { scale, x, y } = fitRectIntoArea(
    paddedContentArea,
    viewportClientRect
  );
  dispatch(setPan({ x, y }));
  dispatch(setZoom(scale));
};

export const exitIsolation = () => (dispatch, getState) => {
  const state = getState();
  const {
    controls: { isolation },
  } = state;
  const spreads = getLegacySpreads(state);

  const { spreadIndex, elementIndex } = findElement(spreads, isolation);
  if (spreadIndex !== -1 && elementIndex !== -1) {
    const spread = spreads[spreadIndex];
    const element = spread.children[elementIndex];
    if (element.children.length === 0) {
      // all group contens have been deleted
      dispatch(deleteElements([isolation]));
    } else {
      const box = getChildrenBoundingBox(element);
      if (box) {
        const { x, y, width, height } = box;
        // move all elements to origin inside group
        const deltas = element.children.reduce((acc, cur) => {
          acc[cur.props.id] = {
            x: cur.props.x - x,
            y: cur.props.y - y,
          };
          return acc;
        }, {});

        // move group origin accordingly
        const origin = new Point(x, y)
          .localToGlobal(document.getElementById(element.props.id))
          .globalToLocal(document.getElementById(spread.props.id));
        dispatch(updateControls({ isolation: null }));
        dispatch(
          updateElements({
            ...deltas,
            [isolation]: {
              x: origin.x,
              y: origin.y,
              width,
              height,
            },
          })
        );
      }
    }
  }

  // This fixes a bug when exiting isolation but the user is currently editing a text-frame
  dispatch(setSelectInside(false));
};

export const setIsolation = (isolation = null) => (dispatch, getState) => {
  let isolationSpreadIndex;
  if (isolation) {
    const spreads = getLegacySpreads(getState());
    isolationSpreadIndex = findElement(spreads, isolation).spreadIndex;
  } else {
    isolationSpreadIndex = null;
    dispatch(exitIsolation());
  }
  dispatch(updateControls({ isolation, isolationSpreadIndex }));
};

export const assertTargetSpreadIndex = targetSpreadIndex => (
  dispatch,
  getState
) => {
  const state = getState();
  const isolation = getIsolation(state);
  if (isolation) {
    const {
      controls: { isolationSpreadIndex },
    } = state;
    if (isolationSpreadIndex !== targetSpreadIndex) {
      dispatch(setIsolation(null));
    }
  }
};

/**
 * Toggling inside/outside sticker-mode requires additional changes to the store –
 * clearing the selection, disabling select-inside, etc. Might be cleaner to put this
 * inside the reducer.
 */
export const toggleStickerMode = () => (dispatch, getState) => {
  const {
    controls: { stickerMode },
  } = getState();

  dispatch(
    updateControls({
      previewStickers: !stickerMode,
      stickerMode: !stickerMode,
      isolation: null,
    })
  );
  dispatch(setSelectInside(!stickerMode));
  dispatch(clearSelection());
};

const toggleControl = key => dispatch => {
  dispatch({
    type: TOGGLE,
    payload: key,
  });
};

export const toggleEnableFilter = () => toggleControl('enableFilter');
export const toggleCropPreview = () => toggleControl('cropPreview');
export const toggleGridEnabled = () => toggleControl('gridEnabled');
export const toggleAutoFit = () => toggleControl('autoFit');
export const toggleShowElementsTree = () => toggleControl('showElementsTree');

export const fillJobSettingsRangeWithStickerNumbers = targetDoubleStickers => (
  dispatch,
  getState
) => {
  const state = getState();
  const sections = getSections(state);
  const jobSettings = getJobSettings(state);
  const stickerNumberLookup = selectStickerNumberByIdLookup(state);

  /**
   * Extract sticker-numbers of either single- or double-stickers in a
   * comma-separated list.
   */
  const range = sections
    .flatMap(section =>
      section.stickers
        .filter(({ doubleSticker }) => targetDoubleStickers === !!doubleSticker)
        .flatMap(({ id }) => {
          const number = stickerNumberLookup[id];

          // Pick both stickers for double-stickers
          return targetDoubleStickers ? [number, number + 1] : [number];
        })
    )
    .join(',');

  dispatch(updateControls({ jobSettings: { ...jobSettings, range } }));
};

export const setFilterTagIds = filterTagIds => dispatch =>
  dispatch(updateControls({ filterTagIds }));

export const setShowDoneComments = showDoneComments => dispatch =>
  dispatch(updateControls({ showDoneComments }));
