import React from 'react';
import { number, bool } from 'prop-types';

export const computeGuideLineFolding = (isFrontCover, isBackCover, width) => {
  if (isFrontCover) return 0;
  if (isBackCover) return width;
  return width / 2;
};

const SpreadGuides = props => {
  const { height, width, spineLeft, isFrontCover, isBackCover } = props;

  const guideLineFolding = computeGuideLineFolding(
    isFrontCover,
    isBackCover,
    width
  );

  return (
    <g>
      <rect
        className="spread-grid"
        x={0}
        y={0}
        width={width}
        height={height}
        fill="url(#grid)"
      />

      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="none"
        stroke="#000"
        vectorEffect="non-scaling-stroke"
        strokeWidth="0.1"
      />

      {spineLeft && (
        <rect
          x={-spineLeft}
          y={0}
          width={spineLeft}
          height={height}
          fill="none"
          stroke="red"
          vectorEffect="non-scaling-stroke"
          strokeWidth="0.5"
        />
      )}

      <line
        x1={guideLineFolding}
        y1={0}
        x2={guideLineFolding}
        y2={height}
        stroke="#000"
        vectorEffect="non-scaling-stroke"
        strokeWidth="0.4"
      />

      <line
        x1={guideLineFolding}
        y1={0}
        x2={guideLineFolding}
        y2={height}
        stroke="#fff"
        strokeDasharray="2 2"
        strokeLinecap="square"
        vectorEffect="non-scaling-stroke"
        strokeWidth="0.4"
      />
    </g>
  );
};

SpreadGuides.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
  isFrontCover: bool.isRequired,
  isBackCover: bool.isRequired,
  spineLeft: number.isRequired,
};

export default SpreadGuides;
