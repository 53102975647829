import { useState, useEffect } from 'react';

import fetchProjects from '../util/fetchProjects';

/**
 * A hook to access the list of projects from the main
 * stickerstars database. Clients will have to provide a token.
 */
const useProjects = token => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchProjects(token)
      .then(data => setProjects(data))
      .catch(err => setError(err));
  }, [token]);

  return {
    projects,
    error,
  };
};

export default useProjects;
