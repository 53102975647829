import React from 'react';
import { node } from 'prop-types';

import useViewportState from './Viewport/useViewportState';

function UnzoomedContent({ children }) {
  const { zoom } = useViewportState();

  return <g transform={`scale(${1 / zoom})`}>{children}</g>;
}

UnzoomedContent.defaultProps = {};

UnzoomedContent.propTypes = {
  children: node.isRequired,
};

export default UnzoomedContent;
