import imageHasSizeMeta from './imageHasSizeMeta';

/**
 * Returns the width and height of an image fit into a given frame.
 * The frame size defaults to 100 x 100. Dimensions can live in either
 * .meta or .details /shrug.
 */
const computeNativeImageSize = (
  imageObject = {},
  frameWidth = 100,
  frameHeight = 100
) => {
  if (!imageHasSizeMeta(imageObject)) {
    return { width: frameWidth, height: frameHeight };
  }

  const { meta, details } = imageObject;
  const width = meta.width || details.width;
  const height = meta.height || details.height;

  const scale = Math.min(frameWidth / width, frameHeight / height);
  return { width: width * scale, height: height * scale };
};

export default computeNativeImageSize;
