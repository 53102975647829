import { arrayOf, func, object } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Menu } from 'semantic-ui-react';

import { updateSelectedElements } from '../../actions/workspace';
import { updateControls } from '../../modules/controls';
import { historyAnchor } from '../../modules/history';
import { setModal } from '../../modules/menu';
import {
  updateSelectedStickers,
  updateStickersByObject,
} from '../../modules/sections';
import {
  getSelectedElements,
  getSelectedStickers,
  getSingleSelectedElement,
} from '../../selectors/legacy';
import { calcFilters, defaultFilter } from '../../util/filters';
import { generateId } from '../../util/index';
import PopupMenuButton from '../menu/PopupMenuButton';
import {
  FilterPresetShape,
  FilterValuesShape,
  NodeShape,
  StickerShape,
} from '../shapes';
import { ListEditModal } from './ListEditModal';
import MultiSlider from './sliders/MultiSlider';
import SingleSlider from './sliders/SingleSlider';

const limit = (val, min = 0, max = 1) => {
  if (val < min) return min;
  if (val > max) return max;
  return val;
};

const mergeFilters = (prevFilter, state) => {
  let currentFilter = prevFilter;
  if (!currentFilter) currentFilter = {};
  const nextFilter = { ...defaultFilter, ...currentFilter };

  return Object.keys(defaultFilter).reduce((acc, cur) => {
    if (cur === 'hue' || cur === 'sat') {
      acc[cur] = limit(nextFilter[cur] + (state[cur] - defaultFilter[cur]));
    } else {
      acc[cur] = {
        min: limit(
          nextFilter[cur].min + (state[cur].min - defaultFilter[cur].min)
        ),
        mid: limit(
          nextFilter[cur].mid + (state[cur].mid - defaultFilter[cur].mid)
        ),
        max: limit(
          nextFilter[cur].max + (state[cur].max - defaultFilter[cur].max)
        ),
      };
    }
    if (cur === 'rgb' && acc[cur].mid > 0.5) acc[cur].mid = 0.5;
    return acc;
  }, {});
};

class FilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: { ...defaultFilter },
      cache: {},
      signature: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let { filter, cache, signature } = state;
    const { selectedStickers, singleSelectedElement } = props;
    const newSignature = selectedStickers.map(sticker => sticker.id).join(',');

    if (newSignature !== signature) {
      signature = newSignature;
      filter = { ...defaultFilter };
      cache = selectedStickers.reduce((acc, cur) => {
        acc[cur.id] = { ...cur.filter };
        return acc;
      }, {});
    }

    if (props.filter) {
      filter = props.filter;
    } else if (selectedStickers.length === 1) {
      filter = selectedStickers[0].filter;
    } else if (singleSelectedElement) {
      filter = singleSelectedElement.props.filter;
    }
    if (!filter) filter = {};

    filter = { ...defaultFilter, ...filter };
    return { ...state, filter, cache, signature };
  }

  update(nextFilter, merge) {
    const {
      selectedStickers,
      updateStickersByObject,
      updateSelectedStickers,
      updateSelectedElements,
    } = this.props;
    const { cache } = this.state;
    if (selectedStickers.length > 1) {
      // multi-edit
      const deltas = selectedStickers.reduce((acc, cur) => {
        const filter = merge
          ? mergeFilters(cache[cur.id], nextFilter)
          : nextFilter;
        acc[cur.id] = { filter };
        return acc;
      }, {});
      updateStickersByObject(deltas);
    } else if (selectedStickers.length > 0) {
      updateSelectedStickers({ filter: nextFilter });
    } else {
      updateSelectedElements({ filter: nextFilter });
    }
  }

  change(delta) {
    const { filter } = this.state;
    const nextFilter = { ...filter, ...delta };
    this.update(nextFilter, true);
    this.setState({ filter: nextFilter });
  }

  reset(id) {
    const { selectedStickers, updateStickersByObject } = this.props;

    const deltas = selectedStickers.reduce((acc, cur) => {
      acc[cur.id] = { filter: { ...cur.filter, [id]: defaultFilter[id] } };
      return acc;
    }, {});

    updateStickersByObject(deltas);
  }

  applyPreset = ({ filter }) => {
    this.update(filter, false);
  };

  onCreate(title) {
    const { filterPresets, setModal, updateControls } = this.props;
    const { filter } = this.state;
    const id = generateId();
    const newFilterPresets = [
      ...filterPresets,
      {
        title,
        id,
        filter: { ...filter },
      },
    ];

    updateControls({ filterPresets: newFilterPresets });
    setModal(null);
  }

  onDelete(id) {
    const { filterPresets, setModal, updateControls } = this.props;
    const newFilterPresets = [...filterPresets.filter(item => item.id !== id)];

    updateControls({ filterPresets: newFilterPresets });
    setModal(
      <ListEditModal
        label="Filter Presets"
        onDelete={(_, eid) => this.onDelete(eid)}
        content={newFilterPresets}
      />
    );
  }

  render() {
    const {
      selectedStickers,
      style,
      historyAnchor,
      filterPresets,
      setModal,
      onMenuOpen,
      onMenuClose,
    } = this.props;
    const { filter } = this.state;

    const height = 50;
    const {
      rgb,
      red,
      green,
      blue,
      hue,
      sat,
      tone,
      tone_red,
      tone_green,
      tone_blue,
    } = calcFilters(filter);

    const popupMenu = (
      <Menu vertical secondary className="qa-filter-popup-menu">
        {filterPresets.map((preset, index) => (
          <Menu.Item key={index} onClick={() => this.applyPreset(preset)}>
            <Label className="entry qa-filter-preset" color="grey">
              {preset.title}
            </Label>{' '}
          </Menu.Item>
        ))}

        <Menu.Item
          className="qa-save-filters-as-preset"
          onClick={() =>
            setModal(
              <ListEditModal
                label="Filter Presets"
                onDelete={(_, id) => this.onDelete(id)}
                onCreate={(_, title) => this.onCreate(title)}
                content={filterPresets}
              />
            )
          }
        >
          Preset speichern...
        </Menu.Item>

        <Menu.Item
          className="qa-open-preset-menu-btn"
          onClick={() =>
            setModal(
              <ListEditModal
                label="Filter Presets"
                onCreate={null}
                onDelete={(_, id) => this.onDelete(id)}
                content={filterPresets}
              />
            )
          }
        >
          Presets verwalten...
        </Menu.Item>
      </Menu>
    );

    return (
      <Menu
        vertical
        secondary
        style={{ width: '100%', minWidth: 300, ...style }}
        className="qa-filter-controls"
      >
        <Menu.Item className="header field flex">
          <span className="grow">Filter Preset</span>

          <PopupMenuButton onMenuOpen={onMenuOpen} onMenuClose={onMenuClose}>
            {popupMenu}
          </PopupMenuButton>
        </Menu.Item>

        <Menu.Item className="flex qa-filter-control-hue" style={{ height }}>
          <span className="menu field">Hue</span>
          <SingleSlider
            value={filter.hue}
            label={`Hue: ${Math.round(hue)}°`}
            onChange={hue => this.change({ hue })}
            onChanged={historyAnchor}
            onReset={() => this.reset('hue')}
            defaultValue={0.5}
            background="linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00)"
          />
        </Menu.Item>
        <Menu.Item className="flex qa-filter-control-sat" style={{ height }}>
          <span className="menu field">Sat.</span>
          <SingleSlider
            value={filter.sat}
            label={`Sat.: ${Math.round(sat * 100)}%`}
            onReset={() => this.reset('sat')}
            onChange={sat => this.change({ sat })}
            onChanged={historyAnchor}
            defaultValue={0.5}
            colors={['#888', '#F00']}
          />
        </Menu.Item>

        <Menu.Item className="header field">Levels</Menu.Item>

        <Menu.Item className="flex qa-filter-control-rgb" style={{ height }}>
          <span className="menu field">RGB</span>
          <MultiSlider
            values={filter.rgb}
            labels={rgb.labels}
            onChange={rgb => this.change({ rgb })}
            onChanged={historyAnchor}
            onReset={() => this.reset('rgb')}
            colors={['#FFF', '#000']}
            extendedScale={selectedStickers.length > 1}
          />
        </Menu.Item>
        <Menu.Item className="flex qa-filter-control-red" style={{ height }}>
          <span className="menu field">Red</span>
          <MultiSlider
            values={filter.red}
            labels={red.labels}
            onChange={red => this.change({ red })}
            onChanged={historyAnchor}
            onReset={() => this.reset('red')}
            colors={['#F00', '#0FF']}
          />
        </Menu.Item>
        <Menu.Item className="flex qa-filter-control-green" style={{ height }}>
          <span className="menu field">Green</span>
          <MultiSlider
            values={filter.green}
            labels={green.labels}
            onChange={green => this.change({ green })}
            onChanged={historyAnchor}
            onReset={() => this.reset('green')}
            colors={['#0F0', '#F0F']}
          />
        </Menu.Item>
        <Menu.Item className="flex qa-filter-control-blue" style={{ height }}>
          <span className="menu field">Blue</span>
          <MultiSlider
            values={filter.blue}
            labels={blue.labels}
            onChange={blue => this.change({ blue })}
            onChanged={historyAnchor}
            onReset={() => this.reset('blue')}
            colors={['#00F', '#FF0']}
          />
        </Menu.Item>

        <Menu.Item className="header field">Tones</Menu.Item>

        <Menu.Item className="flex qa-filter-control-tone" style={{ height }}>
          <span className="menu field">RGB</span>
          <MultiSlider
            values={filter.tone}
            labels={tone.labels}
            onChange={tone => this.change({ tone })}
            onChanged={historyAnchor}
            onReset={() => this.reset('tone')}
            defaultValues={defaultFilter.tone}
            colors={['#000', '#FFF']}
            connectMid={false}
          />
        </Menu.Item>

        <Menu.Item
          className="flex qa-filter-control-tone_red"
          style={{ height }}
        >
          <span className="menu field">Red</span>
          <MultiSlider
            values={filter.tone_red}
            labels={tone_red.labels}
            onChange={tone_red => this.change({ tone_red })}
            onChanged={historyAnchor}
            onReset={() => this.reset('tone_red')}
            defaultValues={defaultFilter.tone}
            colors={['#F00', '#FFF']}
            connectMid={false}
          />
        </Menu.Item>

        <Menu.Item
          className="flex qa-filter-control-tone_green"
          style={{ height }}
        >
          <span className="menu field">Green</span>
          <MultiSlider
            values={filter.tone_green}
            labels={tone_green.labels}
            onChange={tone_green => this.change({ tone_green })}
            onChanged={historyAnchor}
            onReset={() => this.reset('tone_green')}
            defaultValues={defaultFilter.tone}
            colors={['#0F0', '#FFF']}
            connectMid={false}
          />
        </Menu.Item>

        <Menu.Item
          className="flex qa-filter-control-tone_blue"
          style={{ height }}
        >
          <span className="menu field">Blue</span>
          <MultiSlider
            values={filter.tone_blue}
            labels={tone_blue.labels}
            onChange={tone_blue => this.change({ tone_blue })}
            onChanged={historyAnchor}
            onReset={() => this.reset('tone_blue')}
            defaultValues={defaultFilter.tone}
            colors={['#00F', '#FFF']}
            connectMid={false}
          />
        </Menu.Item>
      </Menu>
    );
  }
}

FilterMenu.defaultProps = {
  filter: null,
  filterPresets: [],
  selectedStickers: [],
  singleSelectedElement: null,
  style: null,
  onMenuOpen: () => {},
  onMenuClose: () => {},
};

FilterMenu.propTypes = {
  filter: FilterValuesShape,
  filterPresets: arrayOf(FilterPresetShape),
  selectedStickers: arrayOf(StickerShape),
  setModal: func.isRequired,
  singleSelectedElement: NodeShape,
  style: arrayOf(object), // eslint-disable-line react/forbid-prop-types
  updateControls: func.isRequired,
  updateSelectedElements: func.isRequired,
  updateSelectedStickers: func.isRequired,
  updateStickersByObject: func.isRequired,
  historyAnchor: func.isRequired,
  onMenuOpen: func,
  onMenuClose: func,
};

const mapStateToProps = state => ({
  filterPresets: state.controls.filterPresets,
  selectedElements: getSelectedElements(state),
  selectedStickers: getSelectedStickers(state),
  singleSelectedElement: getSingleSelectedElement(state),
});

const mapDispatchToProps = {
  setModal,
  updateControls,
  updateSelectedElements,
  updateSelectedStickers,
  updateStickersByObject,
  historyAnchor,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
