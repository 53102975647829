import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import store from './store';
import * as session from './util/session';
import { logout } from './modules/user';

function applyHeaders(client, headers) {
  if (isEmpty(headers['access-token'])) return;

  /* eslint-disable no-param-reassign */
  client.defaults.headers.common.uid = headers.uid;
  client.defaults.headers.common['access-token'] = headers['access-token'];
  client.defaults.headers.common['token-type'] = headers['token-type'];
  client.defaults.headers.common.expiry = headers.expiry;
  client.defaults.headers.common.client = headers.client;
  /* eslint-enable no-param-reassign */

  session.setHeaders(headers);
}

function initialize() {
  /* check if url params contain a token from Stickerzentrale */
  const urlParams = new URLSearchParams(window.location.search);
  const sstToken = urlParams.get('sst_token');

  if (sstToken) {
    localStorage.setItem('sst_token', sstToken);
    urlParams.delete('sst_token');

    axios.defaults.headers.common['X-SST-App-Auth'] = sstToken;

    return axios;
  }

  const userFromLocalStorage = session.getUser();

  if (!userFromLocalStorage) {
    store.dispatch(logout());
  }

  const headersFromStorage = session.getHeaders();

  if (headersFromStorage) {
    applyHeaders(axios, headersFromStorage);
  } else {
    session.clearHeaders();
  }

  if (userFromLocalStorage) {
    const { headers } = userFromLocalStorage;

    if (headers) {
      applyHeaders(axios, headers);
    }
  }

  // TODO: We should trigger API requests through a middleware/thunks.
  axios.interceptors.response.use(
    res => {
      const { headers } = res;

      applyHeaders(axios, headers);

      return Promise.resolve(res);
    },
    err => {
      if (err.response && err.response.status === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(err);
    }
  );

  // TODO: Export a configured axios instance and use it instead of the global instance.
  return axios;
}

export default initialize();
