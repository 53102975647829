import { arrayOf, bool, number } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { dimensions } from '../../constants';
import { getSections } from '../../selectors/legacy';
import Content from './Content';
import Sticker from './elements/Sticker';
import Page from './Page';

function StickerRenderer({ includeBleed, range }) {
  const sections = useSelector(getSections);
  const { bleed, trim, marks } = includeBleed
    ? Page.defaultProps
    : { bleed: 0, trim: 0, marks: 'none' };

  let num = 1;

  const pages = [];
  sections.forEach((section, i) =>
    section.stickers.forEach((sticker, j) => {
      const count = sticker.doubleSticker ? 2 : 1;
      for (let page = 0; page < count; page += 1) {
        const key = `section${i}_sticker${j}_page${page}`;

        const contentX = 0;
        const contentY = 0;

        const contentWidth = dimensions.stickerWidth;
        const contentHeight = dimensions.stickerHeight;

        const pageWidth = dimensions.stickerWidth;
        const pageHeight = dimensions.stickerHeight;

        if (!range || range.indexOf(num) !== -1) {
          pages.push(
            <Page
              key={key}
              id={key}
              width={pageWidth}
              height={pageHeight}
              bleed={bleed}
              trim={trim}
              marks={marks}
            >
              <Content
                key={key}
                id={key}
                x={contentX}
                y={contentY}
                width={contentWidth}
                height={contentHeight}
                bleed={bleed}
              >
                <Sticker
                  stickerId={sticker.id}
                  x={0}
                  y={0}
                  doubleStickerOffset={
                    page === 0 ? 0 : -dimensions.stickerWidth
                  }
                />
              </Content>
            </Page>
          );
        }

        num += 1;
      }
    })
  );

  return (
    <div>
      {pages}
      {pages.length && <div className="render-complete" />}
    </div>
  );
}

StickerRenderer.defaultProps = {
  range: null,
};

StickerRenderer.propTypes = {
  range: arrayOf(number),
  includeBleed: bool.isRequired,
};

export default StickerRenderer;
