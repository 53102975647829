import React from 'react';
import { number } from 'prop-types';

import { ImageContext } from '../../ImageContext';

const GuideRect = ({ x, y, width, height }) => (
  <ImageContext.Consumer>
    {imageContext => {
      if (imageContext.rendering || imageContext.printing) {
        return (
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill="rgba(0,0,0,0.0)"
          />
        );
      }
      return (
        <>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            stroke="white"
            strokeWidth={0.4}
            strokeLinecap="square"
            fill="none"
            vectorEffect="non-scaling-stroke"
          />
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            stroke="black"
            strokeDasharray="2 2"
            strokeWidth={0.4}
            strokeLinecap="square"
            fill="rgba(0,0,0,0.0)"
            vectorEffect="non-scaling-stroke"
          />
        </>
      );
    }}
  </ImageContext.Consumer>
);

GuideRect.defaultProps = {
  x: 0,
  y: 0,
};

GuideRect.propTypes = {
  x: number,
  y: number,
  width: number.isRequired,
  height: number.isRequired,
};

export default GuideRect;
