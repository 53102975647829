import { bool, func, oneOf } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'semantic-ui-react';

import { applyTemplate } from '../../../actions/workspace';
import {
  itemTypes,
  templateTypeLabels,
  templateTypes,
} from '../../../constants';
import useBinarySelector from '../../../hooks/useBinarySelector';
import { patchTemplate } from '../../../modules/templates';
import {
  getSelectedTemplateId,
  makeGetFilteredTemplatesForContentType,
} from '../../../selectors/templates';
import Folder from '../Folder';
import TemplateItem from '../TemplateItem/index.container';

function TemplatePanelFolder({ templateType, onCollapseClick, collapsed }) {
  const dispatch = useDispatch();
  const selectedTemplateId = useSelector(getSelectedTemplateId);
  const templates = useBinarySelector(
    makeGetFilteredTemplatesForContentType,
    templateType
  );

  const handleDrop = useCallback(
    ({ monitor }) => {
      if (monitor.getItemType() === itemTypes.template) {
        const item = monitor.getItem();
        dispatch(
          patchTemplate(item.id, { details: { contentType: templateType } })
        );
      }
    },
    [dispatch, templateType]
  );

  const makeHandleTemplateClick = id => () => dispatch(applyTemplate(id));

  const isFolderHighlighted = templates.some(
    template => template.id === selectedTemplateId
  );

  return (
    <Folder
      key={templateType}
      title={templateTypeLabels[templateType]}
      dropTypes={[itemTypes.element]}
      onDrop={handleDrop}
      selected={isFolderHighlighted}
      className={`qa-folder-${templateType}`}
      onCollapseClick={onCollapseClick}
      collapsed={collapsed}
    >
      {templates.map(template => (
        <TemplateItem
          key={template.id}
          template={template}
          label={
            <div>
              {template.tags.map(tag => (
                <Label key={tag.id} color="green">
                  {tag.title}
                </Label>
              ))}
            </div>
          }
          detail={
            templateType === templateTypes.sticker_placement
              ? `${template.details.capacity} stickers`
              : null
          }
          active={template.id === selectedTemplateId}
          onClick={makeHandleTemplateClick(template.id)}
        />
      ))}
    </Folder>
  );
}

TemplatePanelFolder.defaultProps = {
  onCollapseClick: null,
  collapsed: false,
};

TemplatePanelFolder.propTypes = {
  templateType: oneOf(Object.values(templateTypes)).isRequired,

  /**
   * These two props are passed by the Folder component via React.Children.map
   * and React.cloneElement
   */
  onCollapseClick: func,
  collapsed: bool,
};

export default TemplatePanelFolder;
