import { EditorState } from 'draft-js';
import PropTypes, { bool } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import { fonts, fontSizes, lineHeights } from '../../constants';
import { setInlineStyle } from '../../modules/draft';
import { getSingleSelectedElement } from '../../selectors/legacy';
import {
  availableStyles,
  defaultBlockStyle,
  styles,
  textAligns,
} from '../svg/elements/Text/textConstants';
import NestedOverlayTrigger from './NestedOverlayTrigger/NestedOverlayTrigger';
import NumberInput from './NumberInput';
import SwatchColorPicker from './SwatchColorPicker';

export class RichTextToolbarClass extends React.Component {
  dispatchSetInlineStyle = inlineStyle => {
    const { dispatch } = this.props;
    dispatch(setInlineStyle(inlineStyle));
  };

  changeFont = (e, { value }) => {
    const inlineStyle = `FONT-${value.toUpperCase()}`;
    this.dispatchSetInlineStyle(inlineStyle);
  };

  changeSize = (e, { value }) => {
    this.dispatchSetInlineStyle(`SIZE-${value}`);
  };

  changeLineHeight = (e, { value }) => {
    this.dispatchSetInlineStyle(`LINEHEIGHT-${value}`);
  };

  changeLetterSpacing = ({ value }) => {
    this.dispatchSetInlineStyle(`LETTERSPACING-${value}`);
  };

  changeColor = valueOrIndex => {
    this.dispatchSetInlineStyle(`COLOR-${valueOrIndex}`);
  };

  getDefaultStyle(prefix, block = null) {
    if (block) {
      const style = block.getData().get(prefix);
      if (style) {
        return style;
      }
    }
    return defaultBlockStyle.get(prefix);
  }

  getListStyleValue(inlineStyle, block, prefix, listItems) {
    let activeValue = null;
    if (inlineStyle) {
      activeValue = listItems.find(item =>
        inlineStyle.has(`${prefix}-${item}`.toUpperCase())
      );
    }
    if (activeValue) {
      return activeValue;
    }
    const style = this.getDefaultStyle(prefix, block);
    return listItems.find(item => style === `${prefix}-${item}`.toUpperCase());
  }

  getNumericStyleValue(inlineStyle, block, prefix) {
    let activeStyle = inlineStyle.find(style => style.indexOf(prefix) === 0);
    if (!activeStyle) {
      activeStyle = this.getDefaultStyle(prefix, block);
    }
    if (activeStyle) {
      return activeStyle.split('-').pop();
    }
    return null;
  }

  getColorValueOrIndex(inlineStyle, block) {
    const colorValueOrIndex = this.getNumericStyleValue(
      inlineStyle,
      block,
      'COLOR'
    );
    if (typeof colorValueOrIndex === 'string' && colorValueOrIndex[0] === '#') {
      return colorValueOrIndex;
    }
    const colorIndex = parseInt(colorValueOrIndex, 10);
    if (Number.isNaN(colorIndex)) {
      return '#000000';
    }
    return colorIndex;
  }

  render() {
    const { editorState, isSymbolText } = this.props;

    if (!editorState) return null;

    // block settings
    const selection = editorState.getSelection();
    const block = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());

    const currentAlign = this.getDefaultStyle('ALIGN', block);

    // inline style settings, which also fallback to block settings (in case of an empty block)
    const currentStyle = editorState.getCurrentInlineStyle();

    // dropdown styles
    const currentFont = this.getListStyleValue(
      currentStyle,
      block,
      'FONT',
      fonts
    );
    const currentSize = this.getListStyleValue(
      currentStyle,
      block,
      'SIZE',
      fontSizes
    );
    const currentLineHeight = this.getListStyleValue(
      currentStyle,
      block,
      'LINEHEIGHT',
      lineHeights
    );

    // numeric styles
    const currentLetterSpacing = this.getNumericStyleValue(
      currentStyle,
      block,
      'LETTERSPACING'
    );

    const currentColor = this.getColorValueOrIndex(currentStyle, block);

    let menuStyles = styles;
    if (availableStyles[currentFont]) {
      const avail = availableStyles[currentFont];
      menuStyles = styles.filter(style => avail.indexOf(style.style) !== -1);
    } else {
      menuStyles = styles;
    }

    return (
      <div className="richtext">
        <Dropdown
          value={currentFont}
          onChange={this.changeFont}
          options={fonts.map(font => {
            return { value: font, text: font, key: font };
          })}
          selection
          style={{ width: 180 }}
          openOnFocus={false}
        />

        <Icon name="text height" />
        <Dropdown
          size="mini"
          compact
          value={currentSize}
          options={fontSizes.map(size => {
            return { value: size, text: size, key: size };
          })}
          placeholder="Size"
          selection
          onChange={this.changeSize}
          className="qa-font-size"
          openOnFocus={false}
        />

        <Dropdown
          size="mini"
          compact
          value={currentLineHeight}
          options={lineHeights.map(height => ({
            value: height,
            text: height,
            key: height,
          }))}
          placeholder="LineHeight"
          selection
          onChange={this.changeLineHeight}
          className="qa-line-height"
          openOnFocus={false}
        />

        <Icon name="resize horizontal" />
        <NumberInput
          size="mini"
          value={currentLetterSpacing}
          placeholder="Spacing"
          selection
          step={0.5}
          onChange={this.changeLetterSpacing}
        />

        <Button.Group size="mini">
          {isSymbolText && (
            <Button
              size="mini"
              active={currentStyle.has('AUTOSIZE')}
              onClick={() => this.dispatchSetInlineStyle('AUTOSIZE')}
              className="qa-font-style-AUTOSIZE"
            >
              <Icon name="text width" />
            </Button>
          )}
          {menuStyles.map(type => (
            <Button
              size="mini"
              key={type.style}
              active={currentStyle.has(type.style)}
              onClick={() => this.dispatchSetInlineStyle(type.style)}
              className={`qa-font-style-${type.style}`}
            >
              <Icon name={type.icon} />
            </Button>
          ))}
        </Button.Group>

        <NestedOverlayTrigger
          overlay={
            <SwatchColorPicker
              color={currentColor}
              allowNull={false}
              allowCustom
              onChange={this.changeColor}
            />
          }
        >
          <Button className="qa-font-color">
            <Icon className="customicon customicon-fill" />
          </Button>
        </NestedOverlayTrigger>

        <Button.Group size="mini">
          {textAligns.map(align => (
            <Button
              size="mini"
              key={align}
              active={`ALIGN-${align}`.toUpperCase() === currentAlign}
              onClick={() =>
                this.dispatchSetInlineStyle(`ALIGN-${align}`.toUpperCase())
              }
              className={`qa-align-${align}`}
            >
              <Icon name={`align ${align}`} />
            </Button>
          ))}
        </Button.Group>
      </div>
    );
  }
}

const editorStateShape = PropTypes.instanceOf(EditorState);

RichTextToolbarClass.defaultProps = {
  editorState: null,
  isSymbolText: false,
};

RichTextToolbarClass.propTypes = {
  dispatch: PropTypes.func.isRequired,
  editorState: editorStateShape,
  isSymbolText: bool,
};

const mapStateToProps = state => ({
  selectInside: state.selection.selectInside,
  selectedTextInstance: state.controls.selectedTextInstance,
  colors: state.colors,
  editorState: state.draft.editorState,
  isSymbolText: !!getSingleSelectedElement(state)?.props.symbol,
});

export default connect(mapStateToProps)(RichTextToolbarClass);
