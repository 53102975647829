import React from 'react';
import { number } from 'prop-types';

import SetSource from './SetSource';
import SetData from './SetData';
import SetTarget from './SetTarget';

const StartedNotice = () => (
  <div>
    <b>Import-Job erfolgreich gestartet!</b>
  </div>
);

const stepComponents = [SetSource, SetData, SetTarget, StartedNotice];

const StepContent = props => {
  const { step } = props;
  const Component = stepComponents[step];
  return <Component {...props} />;
};

StepContent.propTypes = {
  step: number.isRequired,
};

export default StepContent;
