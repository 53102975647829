import { DragSource } from 'react-dnd';

import { itemTypes } from '../../../constants';
import TemplateItem from './index';

const elementSource = {
  beginDrag({ template, toggleTrashcan }) {
    toggleTrashcan(true);
    return template;
  },
  endDrag({ toggleTrashcan }) {
    toggleTrashcan(false);
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export default DragSource(
  itemTypes.template,
  elementSource,
  collect
)(TemplateItem);
