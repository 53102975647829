import React, { useState } from 'react';
import { Form, Container, List, Radio } from 'semantic-ui-react';
import moment from 'moment';
import { arrayOf, func } from 'prop-types';

import { AlbumInfoShape, IdShape } from '../../shapes';

const SetTarget = ({ albums, target, onTargetSelect }) => {
  const [createAlbum, setCreateAlbum] = useState(true);

  return (
    <Container>
      <Form>
        <Form.Field>
          <Radio
            label="Neues Album erstellen"
            name="radioGroup"
            checked={createAlbum}
            onChange={() => {
              onTargetSelect('new');
              setCreateAlbum(true);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="In bestehendes Album importieren"
            name="radioGroup"
            checked={!createAlbum}
            onChange={() => {
              onTargetSelect('');
              setCreateAlbum(false);
            }}
          />
        </Form.Field>
      </Form>
      {!createAlbum && (
        <div style={{ height: '400px', overflow: 'auto', padding: '1rem 0' }}>
          <List divided relaxed>
            {albums.map(({ id, title, created_at }) => (
              <List.Item
                as="a"
                key={id}
                onClick={() => onTargetSelect(id)}
                active={id === target}
              >
                <List.Content>
                  <List.Header>{title}</List.Header>
                  <List.Description>
                    {moment(created_at).fromNow()}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </div>
      )}
    </Container>
  );
};

SetTarget.defaultProps = {
  target: null,
};

SetTarget.propTypes = {
  target: IdShape,
  albums: arrayOf(AlbumInfoShape).isRequired,
  onTargetSelect: func.isRequired,
};

export default SetTarget;
