import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * This hook ensures proper memoization of a selector used multiple times
 * with a second parameter. It follows the pattern described here in the last
 * example: https://react-redux.js.org/api/hooks#useselector-examples
 */
export default function useBinarySelector(
  selectorMaker,
  secondSelectorParameter
) {
  const selector = useMemo(selectorMaker, []); // eslint-disable-line react-hooks/exhaustive-deps
  return useSelector(state => selector(state, secondSelectorParameter));
}
