import React from 'react';
import { useSelector } from 'react-redux';
import { bool, number, string } from 'prop-types';

import { dimensions } from '../../../../constants';
import { getLandscapeStickerIds } from '../../../../selectors/stickers';

const StickerSubtitle = ({
  stickerId,
  doubleSticker,
  text,
  stickerAreaRotation,
}) => {
  /**
   * If parent area is rotated, we're adjusting position and size. The `60` and `300` deg values
   * are the angles from which we consider the area to be rotated in a way that the user would
   * expect the subtitle to be rotated as well.
   */
  const isLandscapeThroughAreaRotation =
    stickerAreaRotation > 60 && stickerAreaRotation < 300;

  // Optionally swap sizes to adapt to landscape stickers
  const isLandscape =
    useSelector(getLandscapeStickerIds).includes(stickerId) ||
    isLandscapeThroughAreaRotation;

  let { stickerWidth, stickerHeight } = dimensions;
  if (isLandscape) {
    [stickerWidth, stickerHeight] = [stickerHeight, stickerWidth];
  }

  const lines = text.split('\\');

  const { subtitleRotation, y, size } = isLandscapeThroughAreaRotation
    ? {
        subtitleRotation: 90,
        y: 0,
        size: 1, // rotated double sticker will still have the "width" of a single sticker
      }
    : {
        subtitleRotation: 0,
        y: stickerHeight,
        size: doubleSticker ? 2 : 1,
      };

  return (
    <g transform={`rotate(${subtitleRotation})`}>
      <rect
        x={0.5}
        y={y - 0.25}
        stroke="white"
        strokeWidth={0.5}
        strokeLinecap="square"
        fill="white"
        width={stickerWidth * size - 1}
        height={lines.length * 5 + 2.5}
      />

      {lines.map((line, i) => (
        <text
          key={i} // eslint-disable-line react/no-array-index-key
          fontFamily="Gilroy"
          fontSize={4}
          fill="black"
          fillOpacity={1}
          x={(stickerWidth * size) / 2}
          y={y + i * 5 + 4}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {line}
        </text>
      ))}
    </g>
  );
};

StickerSubtitle.defaultProps = {
  doubleSticker: false,
  stickerAreaRotation: 0,
};

StickerSubtitle.propTypes = {
  stickerId: string.isRequired,
  doubleSticker: bool,
  text: string.isRequired,
  stickerAreaRotation: number,
};

export default StickerSubtitle;
