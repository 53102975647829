import { useSelector } from 'react-redux';
import { useEvent } from 'react-use';

import useViewportState from './useViewportState';
import { getSpreadsCount } from '../../../selectors/legacy';
import { restrictPanAndZoomToContentArea } from '../../../util/geometry';

export default function useWheelPanAndZoom(
  viewportRef,
  clientToViewportCoordinates
) {
  const spreadsCount = useSelector(getSpreadsCount);
  const { pan, zoom, setPan, setZoom } = useViewportState();

  const onWheel = event => {
    /**
     * Wheel events are handled depending on which modifier key is used:
     *  - `altKey`: zooming in/out
     *  - `ctrlKey`: scrolling (panning) horizontally
     *
     * Without either of these modifier keys, wheel events trigger the default vertical scrolling (panning).
     */
    event.preventDefault(); // prevent browser history navigation on swipe

    const { altKey, ctrlKey } = event;
    let { deltaX, deltaY } = event;

    if (altKey) {
      // Use the mouse pointer as a pivot
      const pivot = clientToViewportCoordinates({
        x: event.clientX,
        y: event.clientY,
      });

      // Calculate next zoom
      const zoomSensitivity = 0.001;
      let zoomFactor = 1 + Math.abs(deltaY) * zoomSensitivity;
      if (deltaY > 0) {
        zoomFactor = 1 / zoomFactor;
      }
      const wantedZoom = zoom * zoomFactor;

      const { clientWidth, clientHeight } = viewportRef.current || {
        clientWidth: 0,
        clientHeight: 0,
      };
      const [, nextZoom] = restrictPanAndZoomToContentArea(
        pan,
        wantedZoom,
        clientWidth,
        clientHeight,
        spreadsCount
      );

      // This ensures that the pivot stays fixed after the operation
      const updatedPivot = clientToViewportCoordinates(
        { x: event.clientX, y: event.clientY },
        pan,
        nextZoom
      );
      const nextPan = {
        x: pan.x - (updatedPivot.x - pivot.x),
        y: pan.y - (updatedPivot.y - pivot.y),
      };

      setPan(nextPan);
      setZoom(nextZoom);
    } else {
      if (ctrlKey) {
        // Invert scroll direction
        [deltaX, deltaY] = [deltaY, deltaX];
      }

      // Calculate next pan
      const nextPan = {
        x: pan.x + deltaX / zoom,
        y: pan.y + deltaY / zoom,
      };
      setPan(nextPan);
    }
  };

  useEvent('wheel', onWheel, viewportRef.current, {
    passive: false,
  });
}
