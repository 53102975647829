export const mapIconToElementType = type => {
  switch (type) {
    case 'Group':
      return 'code branch';
    case 'Image':
      return 'image';
    case 'Text':
      return 'font';
    case 'StickerArea':
      return 'th';
    case 'Rectangle':
      return 'square full';
    case 'Circle':
      return 'circle';
    default:
      return 'question';
  }
};

export const getNodeDescription = (type, text, symbol) => {
  if (type !== 'Text') {
    return type;
  }

  if (symbol) {
    return `<${symbol}>`;
  }

  try {
    return text.blocks[0].text;
  } catch {
    return text;
  }
};
