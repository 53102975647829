// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Card,
  Grid,
  Icon,
  Image,
  Input,
  Menu,
  Responsive,
  Segment,
  Dimmer,
  Loader,
  Modal,
  Button,
  Form,
} from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { generateSpreadsForSections } from '../../util/generators';
import { fetchAlbums, addAlbum, deleteAlbum } from '../../modules/albums';
import ImportModal from '../../components/ui/ImportModal/index';

class Start extends React.Component {
  state = {
    loading: false,
    filter: '',
    currentPage: 1,
  };

  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(_prevProps, prevState) {
    const { currentPage } = this.state;

    if (prevState.currentPage !== currentPage) {
      this.load();
    }
  }

  load() {
    const { currentPage, filter } = this.state;
    const { dispatch } = this.props;
    dispatch(fetchAlbums(currentPage, filter));
  }

  handleFilter(e) {
    const {
      target: { value },
    } = e;
    this.setState({ filter: value });
  }

  erase = id => {
    const { dispatch } = this.props;
    const result = window.confirm('Are you sure?');

    if (result) {
      this.setState({ loading: true });
      dispatch(deleteAlbum(id)).then(() => {
        this.setState({ loading: false });
      });
    }
  };

  add = () => {
    const { dispatch, history } = this.props;

    this.setState({ loading: true });

    dispatch(
      addAlbum({
        title: 'Neues Album',
        spreads: JSON.stringify(generateSpreadsForSections()),
      })
    ).then(album => {
      this.setState({ loading: false });

      if (album) {
        history.push(`/album/${album.id}`);
      }
    });
  };

  render() {
    const { albums, perPage, total } = this.props;
    const { filter, loading, currentPage } = this.state;

    const handlePrevPage = () => {
      this.setState({ currentPage: currentPage - 1 });
    };

    const handleNextPage = () => {
      this.setState({ currentPage: currentPage + 1 });
    };

    const handleSubmit = () => {
      this.setState({ currentPage: 1 }, () => {
        this.load();
      });
    };

    const resetFilter = () => {
      this.setState({ filter: '' }, () => {
        this.load(filter);
      });
    };

    const hasNextPage = perPage * currentPage < total;

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Segment textAlign="center" vertical>
          <Menu size="large" secondary>
            <Container>
              <Menu.Item as="a" active>
                Home
              </Menu.Item>
              <Menu.Item
                as="a"
                className="qa-new-album-button"
                onClick={this.add}
              >
                Neues Album
              </Menu.Item>

              <Modal
                trigger={
                  <Menu.Item as="a" className="qa-pz-import-button">
                    <Icon name="cloud download" />
                    PZ-Import
                  </Menu.Item>
                }
                size="tiny"
              >
                <ImportModal />
              </Modal>
            </Container>
          </Menu>

          <Segment vertical inverted>
            <Container style={{ marginTop: '1rem' }}>
              <Form onSubmit={handleSubmit}>
                <Input
                  className="qa-album-search"
                  value={filter}
                  name="album_search"
                  onChange={this.handleFilter}
                  icon="search"
                  placeholder="Album suchen..."
                  fluid
                />
                <Input type="submit" value="search" hidden />
              </Form>
              <Grid columns={4} divided>
                {albums.map(album => (
                  <Grid.Column key={album.id}>
                    <Card className={`qa-album-tile-${album.id}`}>
                      <Link
                        to={`/album/${album.id}`}
                        className={`album-link qa-album-link qa-album-link-${album.id}`}
                      >
                        <Image src="https://via.placeholder.com/350x150" />
                      </Link>
                      <Card.Content>
                        <Card.Header>
                          {album.title}
                          <Icon
                            className={`qa-destroy-album-${album.id}`}
                            name="times"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.erase(album.id)}
                          />
                        </Card.Header>
                        <Card.Meta>
                          <span className="date">
                            {moment(album.created_at).calendar()}
                          </span>
                        </Card.Meta>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                ))}
                {isEmpty(albums) && !!filter && (
                  <div style={{ padding: '1rem' }}>
                    Zu deinem Suchbegriff gibt es leider keine passenden Alben.
                    <br />
                    <Button compact onClick={resetFilter}>
                      Zurück
                    </Button>
                  </div>
                )}
              </Grid>
              {loading && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              )}
              <Button
                className="qa-prev-btn"
                compact
                type="submit"
                onClick={handlePrevPage}
                disabled={currentPage < 2}
                icon="angle left"
              />
              <span style={{ padding: '8px' }}>{currentPage}</span>
              <Button
                className="qa-next-btn"
                compact
                type="submit"
                onClick={handleNextPage}
                disabled={!hasNextPage}
                icon="angle right"
              />
            </Container>
          </Segment>
        </Segment>
      </Responsive>
    );
  }
}

const mapStateToProps = state => ({
  albums: state.albums.albums,
  perPage: state.albums.perPage,
  total: state.albums.total,
});

export default connect(mapStateToProps)(Start);
