import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useEvent } from 'react-use';
import { Dimmer, Loader, Menu } from 'semantic-ui-react';

import { ImageContext } from '../../components/ImageContext';
import Workspace from '../../components/svg/Workspace';
import DropTrashcan from '../../components/ui/DropTrashcan';
import ElementsTree from '../../components/ui/ElementsTree';
import ElementToolbar from '../../components/ui/ElementToolbar';
import Sidebar from '../../components/ui/Sidebar';
import useLoadAllFonts from '../../hooks/useLoadAllFonts';
import { forceWindowRedraw } from '../../util';

function Editor({
  cropPreview,
  loading,
  modal,
  previewResolution,
  previewStickers,
  title,
  handleKeyboardEvent,
}) {
  const ref = useRef(null);

  useEffect(() => {
    document.title = title ? `${title} - Stickerstars Editor` : 'Laden...';
    if (ref.current) {
      ref.current.focus();
    }
  }, [title, ref]);

  const imageContextValue = useMemo(
    () => ({
      resolution: previewResolution,
      printing: cropPreview,
      rendering: false,
      previewSticker: previewStickers,
      debug: false,
    }),
    [previewResolution, cropPreview, previewStickers]
  );

  const fontState = useLoadAllFonts();

  if (fontState.error) {
    throw fontState.error;
  }

  /**
   * This is a (hopefully) temporary workaround to prevent a visual bug from
   * appearing on the begin of every drag operation
   */
  useEvent('dragstart', forceWindowRedraw);

  return (
    <ImageContext.Provider value={imageContextValue}>
      <div
        onKeyDown={handleKeyboardEvent}
        onKeyUp={handleKeyboardEvent}
        ref={ref}
        tabIndex="0"
        className="qa-editor"
        role="button"
      >
        {(loading || fontState.loading) && (
          <Dimmer active inverted className="qa-dimmer">
            <Loader />
          </Dimmer>
        )}

        <ElementsTree />

        <div className="workspace-wrapper">
          <Menu className="toolbar qa-toolbar">
            <ElementToolbar />
          </Menu>

          <Workspace />
        </div>

        <Sidebar />

        <DropTrashcan />
        {modal}
      </div>
    </ImageContext.Provider>
  );
}

Editor.defaultProps = {
  cropPreview: false,
  modal: null,
  previewResolution: 'small',
  previewStickers: false,
  title: '',
};

Editor.propTypes = {
  cropPreview: bool,
  loading: bool.isRequired,
  modal: object, // eslint-disable-line react/forbid-prop-types
  previewResolution: string,
  previewStickers: bool,
  title: string,
  handleKeyboardEvent: func.isRequired,
};

export default Editor;
