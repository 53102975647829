import React from 'react';

import { dimensions } from '../../../../../constants';
import { ColorShape, StickerShape } from '../../../../shapes';
import StickerNumber from '../StickerNumber';

function StickerFrameDouble({ sticker, resolvedStickerColor }) {
  return (
    <g>
      <path
        fill={resolvedStickerColor}
        d="M2,60c16,2.6,32,5.2,48,7.8C65.93,65.2,97.8,60,97.8,60L50,65L2,60z"
      />
      <path
        fill="#fff"
        d="M0,0v70h100V0H0z M50,67.8L2,60V2.1h95.8V60L50,67.8z"
      />
      <text
        fontFamily="Gilroy"
        fontSize={3.4}
        fill="black"
        fillOpacity={1}
        x={2}
        y={dimensions.stickerHeight - 2}
      >
        <tspan>
          <StickerNumber stickerId={sticker.id} />
        </tspan>
      </text>

      <text
        fontFamily="Gilroy"
        fontSize={3.4}
        fill="black"
        fillOpacity={1}
        x={dimensions.stickerWidth * 2 - 2}
        y={dimensions.stickerHeight - 2}
      >
        <tspan textAnchor="end">
          <StickerNumber stickerId={sticker.id} isNextNumber />
        </tspan>
      </text>
    </g>
  );
}

StickerFrameDouble.propTypes = {
  sticker: StickerShape.isRequired,
  resolvedStickerColor: ColorShape.isRequired,
};

export default StickerFrameDouble;
