import React from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';

export const UPDATE = 'menu/UPDATE';

export const initialState = {
  contextMenuData: null,
  modal: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const update = payload => dispatch =>
  dispatch({ type: UPDATE, payload });

export const setModal = content => {
  return dispatch => {
    let modal;
    if (content) {
      modal = (
        <Modal
          open
          style={{ maxWidth: 500 }}
          onClose={() => dispatch(setModal(null))}
        >
          {content}
        </Modal>
      );
    } else {
      modal = null;
    }
    return dispatch(update({ modal }));
  };
};

export const showMessage = (text, error = false) => dispatch => {
  dispatch(
    setModal(
      <Message negative={error}>
        <Message.Header>{error ? 'Error' : 'Message'}</Message.Header>
        <p>{text}</p>
        <Button onClick={() => setModal(null)}>OK</Button>
      </Message>
    )
  );
};

export const showContextMenu = (event, itemType, id) => dispatch => {
  event.preventDefault();
  const { pageX, pageY } = event || {};
  dispatch(
    update({
      contextMenuData: { itemType, id, clickX: pageX, clickY: pageY },
    })
  );
};

export const hideContextMenu = () => dispatch => {
  dispatch(update({ contextMenuData: null }));
};
