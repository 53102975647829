import React from 'react';

import { dimensions } from '../../../../constants';
import { StickerShape } from '../../../shapes';
import StickerNumber from './StickerNumber';

function StickerCellDouble({ sticker }) {
  return (
    <g>
      <rect
        x={0.5}
        y={0.5}
        stroke="white"
        strokeWidth={0.5}
        strokeLinecap="square"
        fill="black"
        fillOpacity={0.2}
        width={dimensions.stickerWidth * 2 - 1}
        height={dimensions.stickerHeight - 1}
        className="inside"
      />

      <line
        x1={dimensions.stickerWidth}
        y1={0.5}
        x2={dimensions.stickerWidth}
        y2={dimensions.stickerHeight - 1}
        stroke="white"
        strokeDasharray="1"
        strokeWidth={0.5}
      />

      <text
        fontSize={8}
        fontFamily="Gilroy"
        fill="white"
        fillOpacity={1}
        x={dimensions.stickerWidth / 2}
        y={dimensions.stickerWidth / 2}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <StickerNumber stickerId={sticker.id} />
      </text>

      <text
        fontFamily="Gilroy"
        fontSize={8}
        fill="white"
        fillOpacity={1}
        x={dimensions.stickerWidth / 2 + dimensions.stickerWidth}
        y={dimensions.stickerWidth / 2}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <StickerNumber stickerId={sticker.id} isNextNumber />
      </text>
    </g>
  );
}

StickerCellDouble.propTypes = {
  sticker: StickerShape.isRequired,
};

export default StickerCellDouble;
