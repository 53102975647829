import { useAsync } from 'react-use';

function checkFontState(font) {
  if (font.status === 'loaded') {
    return;
  }

  throw new Error(`Font could not be loaded: ${font.family}`);
}

/**
 * Loads all referenced fonts explicitly (if no prefetch is specified,
 * fonts are usually just loaded when they are visible).
 *
 * Returns { loading: true } while the loading is in progress and
 * { loading: true } if the loading as soon as the loading is complete.
 *
 * This was introduced to prevent a problem with the autosize-feature
 * of symbol-texts. In order to measure the text-size correctly, the used
 * font needs to be loaded, otherwise `measureText` returns an incorrect
 * value based on a fallback-font.
 */
export default function useLoadAllFonts() {
  const state = useAsync(async () => {
    const fonts = Array.from(await document.fonts.ready);

    const promises = fonts.map(async font => {
      if (font.status === 'unloaded') {
        return checkFontState(await font.load());
      }

      if (font.status === 'loading') {
        return checkFontState(await font.loaded);
      }

      return checkFontState(font);
    });

    return Promise.all(promises);
  }, []);

  return state;
}
