import React from 'react';
import ContentLoader from 'react-content-loader';

const SectionLoader = props => (
  <ContentLoader
    height={340}
    width={470}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <circle
      cx="2470.1556443707464"
      cy="31.355644370746372"
      r="20.155644370746373"
    />
    <rect x="14.5" y="13.2" rx="0" ry="0" width="443" height="56" />
    <rect x="14.5" y="79.2" rx="0" ry="0" width="443" height="56" />
    <rect x="13.5" y="144.2" rx="0" ry="0" width="443" height="56" />
    <rect x="13.5" y="210.2" rx="0" ry="0" width="443" height="56" />
    <rect x="12.5" y="276.2" rx="0" ry="0" width="443" height="56" />
  </ContentLoader>
);

export default SectionLoader;
