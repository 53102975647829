import { string } from 'prop-types';
import React from 'react';
import { useDrag } from 'react-dnd';
import { Button, Icon } from 'semantic-ui-react';

import { itemTypes } from '../../constants';
import { ElementTemplateShape } from '../shapes';

function ElementItem({ template, icon }) {
  const [, drag] = useDrag({
    item: {
      type: itemTypes.element,
      element: template,
    },
  });

  return (
    <div className="element-button" ref={drag}>
      <Button
        icon
        labelPosition="left"
        className={`qa-create-${template.type}`}
      >
        <Icon name={icon} />
        {template.type}
      </Button>
    </div>
  );
}

ElementItem.propTypes = {
  template: ElementTemplateShape.isRequired,
  icon: string.isRequired,
};

export default ElementItem;
