import React from 'react';
import some from 'lodash/some';
import { useSelector, useDispatch } from 'react-redux';
import { useEvent } from 'react-use';

import Viewport from '../Viewport';
import Node from '../Node';
import Selector from '../Selector';
import Handles from '../Handles';
import { isTextFrame } from '../../../util/index';
import { historyAnchor } from '../../../modules/history';
import { getSelectedElements } from '../../../selectors/legacy';
import { ClipboardActions } from '../../../util/clipboard';

function useClipboardEvents() {
  const selectedElements = useSelector(getSelectedElements);
  const selectInside = useSelector(state => state.selection.selectInside);
  const dispatch = useDispatch();

  const handleClipboardAction = (action, event, setHistoryAnchor) => {
    /**
     * Let draftjs handle clipboard actions when inside a text element,
     * otherwise dispatch own clipboard action.
     */
    if (some(selectedElements, isTextFrame) && selectInside) {
      return;
    }
    ClipboardActions[action](event, selectedElements);
    if (setHistoryAnchor) {
      dispatch(historyAnchor());
    }
  };

  useEvent('cut', event => handleClipboardAction('cutItems', event, true));
  useEvent('copy', event => handleClipboardAction('copyItems', event, false));
  useEvent('paste', event => handleClipboardAction('pasteItems', event, true));
}

const Workspace = () => {
  useClipboardEvents();
  const operationActive = useSelector(state => state.controls.operationActive);
  const lassoActive = useSelector(state => state.controls.lassoActive);
  const spacebarPanActive = useSelector(
    state => state.controls.spacebarPanActive
  );
  return (
    <div role="presentation" className="workspace qa-workspace">
      <Viewport>
        <Node id="root" nodeIndex={0} nodeSiblingCount={1} />
        {!(spacebarPanActive || operationActive) && <Selector />}
        {!(spacebarPanActive || lassoActive) && <Handles />}
      </Viewport>
    </div>
  );
};

export default Workspace;
