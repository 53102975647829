export const getZoom = state => state.controls.zoom;

export const getStickerMode = state => state.controls.stickerMode;

export const getJobSettings = state => state.controls.jobSettings;

export const getAutoFit = state => state.controls.autoFit;

export const getGridEnabled = state => state.controls.gridEnabled;

export const getFilterTagIds = state => state.controls.filterTagIds;

export const getTemplatePreviewSize = state =>
  state.controls.templatePreviewSize;

export const getShowDoneComments = state => state.controls.showDoneComments;
