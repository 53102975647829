import executeActionByName from './executeActionByName';
import { actionsMeta, keys, dimensions, alignDirection } from '../constants';
import { historyAnchor } from '../modules/history';
import { nudgeElements, alignElements } from './workspace';
import { nudgeStickers } from '../modules/sections';
import { inputElementFocused } from '../util';

/**
 * Our hotkey options match events on `ctrlKey: true`, not `metaKey: true`.
 * To serve Mac users, we "normalize" events by setting `ctrlKey` to
 * `true` whenever `event.metaKey` is `true`.
 */
export const normalizeEvent = event => {
  const { metaKey } = event;
  if (!metaKey) return event;
  return { ...event, ctrlKey: true };
};

/**
 * In order to check whether a user `event` maps to a pre-defined
 * hotkey, we loop through an action's hotkeys (arr of objects) and
 * compare them to the event's key(s). If the `key` values match, this
 * function returns `true`, else `false`.
 */
export const isHotkeyEvent = (hotkeys, event) => {
  const defaultKey = {
    ctrlKey: false,
    shiftKey: false,
  };
  return hotkeys.some(hotkey => {
    const completeHotkey = { ...defaultKey, ...hotkey };
    return Object.keys(completeHotkey).every(objectKey => {
      return completeHotkey[objectKey] === event[objectKey];
    });
  });
};

/**
 * We loop through all passed actions (inside `actionRefs`), each with
 * or without one or more defined hotkey(s), compare them to an `event`
 * and dispatch an associated action on match. If an action was dispatched,
 * i. e. the event was handled, this function returns `true`, else `false`.
 */
const handleKeyboardEvent = event => (dispatch, getState) => {
  if (inputElementFocused()) {
    return;
  }

  const {
    controls: { stickerMode },
  } = getState();

  if (event.type === 'keyup' && event.key !== '.') {
    return;
  }

  const nudge = (x, y) => {
    // Default distance without modifier keys
    let nudgeDistance = 1;

    // Coarse distance
    if (event.shiftKey) {
      nudgeDistance = dimensions.gridSize;
    }

    // Fine distance
    if (event.ctrlKey) {
      nudgeDistance = 0.1;
    }

    if (stickerMode) {
      dispatch(nudgeStickers(x * nudgeDistance, y * nudgeDistance));
    } else {
      dispatch(nudgeElements(x * nudgeDistance, y * nudgeDistance));
    }

    event.preventDefault();
  };

  const align = alignMap => {
    /* alignMap maps the axis to the direction of a the align, dispatching one or more `alignElements` actions. 
    E.g. `{ x: alignDirection.start, y: alignDirection.start }` would allign elements in the upper left corner */
    Object.keys(alignMap).forEach(axis => {
      dispatch(alignElements(alignMap[axis], axis));
    });
    dispatch(historyAnchor());
  };

  const normalizedEvent = normalizeEvent(event);
  switch (event.key) {
    // Keys in the corners of the numpad: align elements to the corners
    case '1':
      align({ x: alignDirection.start, y: alignDirection.end });
      break;
    case '3':
      align({ x: alignDirection.end, y: alignDirection.end });
      break;
    case '5':
      align({ x: alignDirection.center, y: alignDirection.center });
      break;
    case '7':
      align({ x: alignDirection.start, y: alignDirection.start });
      break;
    case '9':
      align({ x: alignDirection.end, y: alignDirection.start });
      break;

    // Keys in the edges of the numpad: align elements to the edges (in only one axis)
    case '2':
      align({ y: alignDirection.end });
      break;
    case '4':
      align({ x: alignDirection.start });
      break;
    case '6':
      align({ x: alignDirection.end });
      break;
    case '8':
      align({ y: alignDirection.start });
      break;

    // Cursor-keys: move elements
    case keys.left:
      nudge(-1, 0);
      break;
    case keys.right:
      nudge(+1, 0);
      break;
    case keys.up:
      nudge(0, -1);
      break;
    case keys.down:
      nudge(0, +1);
      break;
    default:
      actionsMeta.forEach(actionMeta => {
        const [, action, , hotkeys] = actionMeta; // [itemType, action, label, hotkeys]
        if (!hotkeys || !isHotkeyEvent(hotkeys, normalizedEvent)) {
          return;
        }
        dispatch(executeActionByName(action));
        dispatch(historyAnchor());
        event.preventDefault();
      });
  }
};

export default handleKeyboardEvent;
