import { getWorkspace, getIsolation } from '../../../selectors/legacy';
import { pointToSpreadIndex } from '../../../util/generators';
import { getElementBoundingBox } from '../../../selectors/bounds';

export default function getAvailableElementAreas(state, pointer) {
  if (!pointer) {
    return {};
  }

  const { nodes, root } = getWorkspace(state);

  // Limit available ids to all elements the same spread (or inside the isolated group), to prevent cross-spread selections
  let availableIds;
  const isolationId = getIsolation(state);
  if (isolationId) {
    availableIds = nodes[isolationId].children;
  } else {
    const spreadIds = nodes[root].children;
    const spreadIndex = pointToSpreadIndex(pointer, spreadIds.length);
    const spreadId = spreadIds[spreadIndex];
    availableIds = spreadId ? nodes[spreadId].children : [];
  }

  // Convert available ids to their bounding boxes
  return availableIds.reduce((acc, id) => {
    const result = getElementBoundingBox(state, id);
    if (result) {
      acc[id] = result;
    }
    return acc;
  }, {});
}
