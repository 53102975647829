import { dimensions, imageTypes } from '../constants';
import { fitImageObjectIntoElement } from './images';
import { generateId } from './index';
import recursiveMap from './recursiveMap';

export const guessContentTypeFromGroup = root => {
  const testPattern = (element, pattern) => {
    if (element == null) return false;
    if (typeof pattern === 'object') {
      return Object.keys(pattern).every(key => {
        return testPattern(element[key], pattern[key]);
      });
    }
    return element == pattern; // eslint-disable-line
  };

  const recurseElement = (element, pattern) => {
    if (element == null) return false;
    let result = testPattern(element, pattern);
    if (result) return true;
    if (Array.isArray(element.children)) {
      element.children.every(item => {
        result = recurseElement(item, pattern);
        return !result;
      });
    }
    return result;
  };

  const patterns = {
    sponsorpage: { type: 'Text', props: { contentType: 'sponsortext' } },
    imprint: { type: 'Text', props: { contentType: 'imprint' } },
    toc: { type: 'Text', props: { contentType: 'greet' } },
    front: { type: 'Image', props: { contentType: 'title' } },
    ad_full: [
      { type: 'Image', props: { contentType: 'ad_xl' } },
      { type: 'Image', props: { contentType: 'ad_xxl' } },
    ],
    ad_combi: [
      { type: 'Image', props: { contentType: 'ad_s_p' } },
      {
        type: 'Image',
        props: { contentType: 'ad_m_p' },
      },
      { type: 'Image', props: { contentType: 'ad_l_p' } },
      { type: 'Image', props: { contentType: 'ad_s_l' } },
      {
        type: 'Image',
        props: { contentType: 'ad_m_l' },
      },
      { type: 'Image', props: { contentType: 'ad_l_l' } },
    ],
    sticker_placement: { type: 'StickerArea' },
    sticker_background: { type: 'Image', props: { contentType: 'background' } },
    sticker: [
      { type: 'Text', props: { symbol: 'sticker-team' } },
      { type: 'Text', props: { symbol: 'sticker-line1' } },
      { type: 'Text', props: { symbol: 'sticker-line2' } },
      { type: 'Text', props: { symbol: 'sticker-number' } },
    ],
    pagenums: { type: 'Text', props: { symbol: 'pagenum' } },
  };

  const name = Object.keys(patterns).find(key => {
    let a = patterns[key];
    if (!Array.isArray(a)) a = [a];
    for (let i = 0; i < a.length; i += 1) {
      const pattern = a[i];
      const result = recurseElement(root, pattern);
      if (result) {
        return key;
      }
    }
    return false;
  });
  if (name) {
    return name;
  }
  return 'common';
};

export const getStickerCapacityFromGroup = root => {
  function getAreaCapacity(element) {
    const { width, height } = element.props;

    const numCols = Math.floor(
      (width + dimensions.stickerPadding) /
        (dimensions.stickerWidth + dimensions.stickerPadding)
    );
    const numRows = Math.floor(
      (height + dimensions.stickerPadding) /
        (dimensions.stickerHeight + dimensions.stickerPadding)
    );

    return numCols * numRows;
  }

  let count = 0;

  const recurseElement = element => {
    if (element.type === 'StickerArea') {
      count += getAreaCapacity(element);
    } else if (Array.isArray(element.children)) {
      element.children.forEach(recurseElement);
    }
  };

  recurseElement(root);
  return count;
};

export const uniquifyIds = element => {
  return recursiveMap(element, item => ({
    ...item,
    props: { ...item.props, id: generateId() },
  }));
};

export function findElement(spreads, elementId) {
  let elementIndex = -1;
  const spreadIndex = spreads.findIndex(spread => {
    elementIndex = spread.children.findIndex(
      element => element.props.id === elementId
    );
    return elementIndex !== -1;
  });
  return { spreadIndex, elementIndex };
}

export function findSpreadIndexRecursive(spreads, elementId) {
  function recurse(element) {
    if (element.props.id === elementId) {
      return true;
    }
    if (element.children) {
      return element.children.find(element => recurse(element));
    }
    return false;
  }

  return spreads.findIndex(spread => {
    return recurse(spread);
  });
}

export function replaceTemplateImages(
  group,
  spreadIndex,
  sectionId,
  imagesCopy,
  stock,
  sections,
  adIndex = 0,
  stockIndex = 0
) {
  // Select only "image"-type images. There are also "sticker"-images that should not be used here
  // Since this function deletes also images from the array, create a copy first to not mutate the store
  // Todo: this should be changed - find a better way to use images only once
  // Todo: maybe we could create a selector for this?
  const images = imagesCopy.filter(item => item.model === 'image');

  const newElement = recursiveMap(group, element => {
    let autoFit = null;
    let allowReplace = true;
    let imageObject;

    if (element.props.contentType !== imageTypes.background) {
      const index = stock.findIndex(item => item.id === element.props.image);
      allowReplace = index === -1;
    }

    if (element.props.contentType === imageTypes.common) {
      allowReplace = false;
    }

    if (element.props.contentType && allowReplace) {
      if (element.props.contentType === imageTypes.ad) {
        const matches = images.filter(
          item => item.details.contentType === element.props.contentType
        );
        if (matches.length) {
          imageObject = matches[adIndex % matches.length];
          element.props.image = imageObject.id;
          adIndex += 1;
          autoFit = 'contain';
        }
      } else if (element.props.contentType === imageTypes.background) {
        const section = sections.find(section => section.id === sectionId);
        let originalTeamId;
        if (section && section.details) {
          originalTeamId = section.details.originalTeamId;
        }
        let matches = images.filter(
          item => item.details.contentType === element.props.contentType
        );
        if (matches.length) {
          const teamMatches = matches.filter(
            item => item.details.originalTeamId === originalTeamId
          );
          if (teamMatches.length) {
            matches = teamMatches;
            const index = spreadIndex % matches.length;
            imageObject = matches[index];
            element.props.image = imageObject.id;
            images.splice(
              images.findIndex(item => item.id === imageObject.id),
              1
            );
          } else {
            // fill up with stock images
            let matches = stock.filter(
              item => item.details.contentType === element.props.contentType
            );
            if (!matches.length) {
              matches = stock;
            }
            const index = spreadIndex % matches.length;
            imageObject = matches[index];
            element.props.image = imageObject.id;
          }
        } else {
          // fill up with stock images
          const matches = images.filter(
            item => item.details.contentType === imageTypes.stock
          );
          if (matches.length) {
            imageObject = matches[stockIndex % matches.length];
            element.props.image = imageObject.id;
            stockIndex += 1;
          }
        }
        autoFit = 'cover';
      } else {
        // other
        const matches = images.filter(
          item => item.details.contentType === element.props.contentType
        );
        if (matches.length) {
          [imageObject] = matches;
          element.props.image = imageObject.id;
        }
        if (element.props.contentType === imageTypes.title) {
          autoFit = 'cover';
        }
      }
    }

    if (autoFit && imageObject) {
      const delta = fitImageObjectIntoElement(
        imageObject,
        element,
        autoFit === 'cover'
      );
      element = { ...element, props: { ...element.props, ...delta } };
    }
    return element;
  });

  return [newElement, adIndex, stockIndex];
}

export function calculatePageNumber(spreadIndex, xPos) {
  return spreadIndex === 0
    ? 1 // the first spread is a single page
    : spreadIndex * 2 + (xPos <= dimensions.pageWidth ? 0 : 1);
}
