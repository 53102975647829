import { bool, number, string } from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getStickerPositionsByArea } from '../../../selectors/stickers';
import BaseElement from './BaseElement';
import GuideRect from './GuideRect';
import Sticker from './Sticker';

function StickerArea(props) {
  const {
    id,
    width,
    height,
    stickerMode,
    visibleSpreadCount,
    rotation,
  } = props;
  const stickerPositions = useSelector(getStickerPositionsByArea)[id] || [];

  if (visibleSpreadCount > 3 && !stickerMode) {
    return null;
  }
  return (
    <BaseElement {...props}>
      <GuideRect width={width} height={height} />
      {stickerPositions.map(({ id: stickerId, x, y }) => {
        return (
          <Sticker
            key={stickerId}
            stickerId={stickerId}
            x={x}
            y={y}
            stickerAreaRotation={rotation}
          />
        );
      })}
    </BaseElement>
  );
}

StickerArea.defaultProps = {
  rotation: 0,
};

StickerArea.propTypes = {
  id: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  selectInside: bool.isRequired,
  stickerMode: bool.isRequired,
  visibleSpreadCount: number.isRequired,
  rotation: number,
};

export default memo(StickerArea);
