/* eslint-disable no-console */
const CREATE = 'images/CREATE';
const UPDATE = 'images/UPDATE';
const REPLACE = 'images/REPLACE';
export const DELETE = 'images/DELETE';

export const initialState = [];

function createUpdateDeleteReplace(state, action) {
  switch (action.type) {
    case CREATE: {
      if (typeof action.payload.id === 'undefined') {
        console.error('id not set during create');
        return state;
      }
      return [...state, action.payload];
    }
    case UPDATE: {
      const updateIndex = state.findIndex(item => item.id === action.id);
      if (updateIndex === -1) {
        console.error('id not found during update');
        return state;
      }
      const nextItem = { ...state[updateIndex], ...action.payload };
      return [
        ...state.slice(0, updateIndex),
        nextItem,
        ...state.slice(updateIndex + 1),
      ];
    }
    case DELETE: {
      const deleteIndex = state.findIndex(item => item.id === action.id);
      if (deleteIndex === -1) {
        console.error('id not found during delete');
        return state;
      }
      return [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];
    }
    case REPLACE:
      return action.payload;
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE:
    case UPDATE:
    case DELETE:
    case REPLACE:
      return createUpdateDeleteReplace(state, action);
    default:
      return state;
  }
};

export function createImage(payload) {
  return { type: CREATE, payload };
}

export function updateImage(id, payload) {
  return { type: UPDATE, id, payload };
}

export function deleteImage(id) {
  return { type: DELETE, id };
}

export function replaceImages(payload) {
  return { type: REPLACE, payload };
}
