// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import {
  Icon,
  Button,
  Dropdown,
  Input,
  Form,
  Modal,
  Header,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { updateControls } from '../../modules/controls';
import { replaceSectionText } from '../../modules/sections';

const ReplaceScopes = {
  // "spreads": "Album",
  sticker_section: 'Team-Namen',
  sticker_name: 'Sticker-Namen',
  sticker_position: 'Sticker-Position',
};

const TextReplaceClass = ({
  dispatch,
  searchSettings: { search, replace, scope },
}) => {
  const startReplace = () => {
    dispatch(replaceSectionText(search, replace, scope));
  };

  const setValue = (key, value) => {
    const searchSettings = { search, replace, scope, [key]: value };
    dispatch(updateControls({ searchSettings }));
  };

  return (
    <>
      <Header>Suchen und Ersetzen</Header>
      <Modal.Content>
        <Form className="text-replace">
          <Form.Field inline>
            <label>Suchen</label>
            <Input
              onChange={(event, { value }) => setValue('search', value)}
              defaultValue={search}
              style={{ width: 180 }}
            />
          </Form.Field>

          <Form.Field inline>
            <label>Bereich</label>
            <Dropdown
              value={scope}
              onChange={(event, { value }) => setValue('scope', value)}
              options={Object.keys(ReplaceScopes).map(key => ({
                value: key,
                text: ReplaceScopes[key],
                key,
              }))}
              selection
              style={{ width: 180 }}
            />
          </Form.Field>

          <Form.Field inline>
            <label>Ersetzen</label>
            <Input
              onChange={(event, { value }) => setValue('replace', value)}
              defaultValue={replace}
              style={{ width: 180 }}
            />
          </Form.Field>

          <Form.Field inline>
            <label> </label>
            <Button color="green" icon onClick={startReplace}>
              <Icon name="exchange" />
              Ersetzen
            </Button>
          </Form.Field>
        </Form>
      </Modal.Content>
    </>
  );
};

const mapStateToProps = state => ({
  searchSettings: state.controls.searchSettings,
  currentAlbum: state.albums.currentAlbum,
});

export default connect(mapStateToProps)(TextReplaceClass);
