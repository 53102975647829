import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { number } from 'prop-types';

import { dimensions } from '../../constants';
import Point from '../../util/Point';
import AbstractOperation from './AbstractOperation';

let gridOffsetX = 0;
let gridOffsetY = 0;

const MoveOperation = ({ width, height }) => {
  const gridEnabled = useSelector(state => state.controls.gridEnabled);

  /* `early` is a flag indicating that the mouseDown-event happend recently. It is cleared by a timeout set in
   `onMouseDown`. It is used to identify "unwanted" movement of elements (e.g. when the element is just clicked
      in order to be selected, but the user does not want to move it. */
  const [early, setEarly] = useState(false);
  const [initialClickPosition, setInitialClickPosition] = useState({});

  function update(
    e,
    { x, y },
    { parentDelta, selectInside, parentNode, spreadNode },
    index
  ) {
    const distance =
      Math.abs(initialClickPosition.x - e.clientX) +
      Math.abs(initialClickPosition.y - e.clientY);
    if (early && distance < 10) {
      return {};
    }
    if (e.shiftKey) {
      if (Math.abs(parentDelta.x) > Math.abs(parentDelta.y)) {
        parentDelta.y = 0;
      } else {
        parentDelta.x = 0;
      }
    }
    x += parentDelta.x;
    y += parentDelta.y;
    if (gridEnabled !== e.altKey && !selectInside) {
      if (index === 0) {
        // init grid-offset with the first element
        let p = new Point(x, y)
          .localToGlobal(parentNode)
          .globalToLocal(spreadNode);
        p.x = Math.round(p.x / dimensions.gridSize) * dimensions.gridSize;
        p.y = Math.round(p.y / dimensions.gridSize) * dimensions.gridSize;
        p = p.localToGlobal(spreadNode).globalToLocal(parentNode);
        gridOffsetX = p.x - x;
        gridOffsetY = p.y - y;
      }
      x += gridOffsetX;
      y += gridOffsetY;
    }
    return { x, y };
  }

  function mouseDown(e, onStart) {
    setEarly(true);
    setInitialClickPosition({ x: e.clientX, y: e.clientY });
    setTimeout(() => setEarly(false), 200);
    onStart(e, null, true);
  }

  return (
    <AbstractOperation
      onUpdate={update} // eslint-disable-line react/jsx-no-bind
      render={({ onStart }) => (
        <rect
          className="handle move qa-handle-move"
          width={width}
          height={height}
          onMouseDown={e => mouseDown(e, onStart)}
        />
      )}
    />
  );
};

MoveOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

export default MoveOperation;
