const REPLACE = 'text/REPLACE';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case REPLACE:
      return action.payload;
    default:
      return state;
  }
};

export const replaceTexts = payload => dispatch => {
  dispatch({ type: REPLACE, payload });
};
