import React, { useState } from 'react';
import { node, number } from 'prop-types';

import ClipPaths from './ClipPaths';

export const ContentContext = React.createContext(null);

const mmToPixel = 3.78;

function Content({ children, x, y, width, height, bleed, spine }) {
  const [imageOverlays, setImageOverlays] = useState([]);
  const createImageOverlay = overlay =>
    setImageOverlays(overlays => [...overlays, overlay]);

  const [textOverlays, setTextOverlays] = useState([]);
  const createTextOverlay = overlay =>
    setTextOverlays(overlays => [...overlays, overlay]);

  const bleedWidth = width + bleed * 2;
  const bleedHeight = height + bleed * 2;

  const contentOffset = { x, y };

  const contentArea = {
    x: x - bleed,
    y: y - bleed,
    width: bleedWidth,
    height: bleedHeight,
  };

  const contextValue = {
    createTextOverlay,
    createImageOverlay,
    contentOffset,
    contentArea,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      <div
        className="content"
        data-image-overlays={JSON.stringify(imageOverlays)}
        style={{
          position: 'absolute',
          left: `${x}mm`,
          top: `${y}mm`,
          width: `${bleedWidth}mm`,
          height: `${bleedHeight}mm`,
          overflow: 'hidden',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.2"
          viewBox={[-bleed, -bleed, bleedWidth, bleedHeight].join(' ')}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: `${bleedWidth}mm`,
            height: `${bleedHeight}mm`,
          }}
        >
          <defs>
            <ClipPaths spine={spine} />
          </defs>
          {children}
        </svg>
        <div
          className="overlay"
          style={{
            position: 'absolute',
            width: `${bleedWidth}mm`,
            height: `${bleedHeight}mm`,
            left: `${bleed}mm`,
            top: `${bleed}mm`,
          }}
        >
          {textOverlays.map(({ id, html, transform }) => (
            <div
              key={id}
              className="text"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: html,
              }}
              style={{
                position: 'absolute',
                transformOrigin: 'top left',
                transform: `translate(${transform.x}mm,${
                  transform.y
                }mm) rotate(${transform.rotation}deg) scale(${transform.scale *
                  mmToPixel})`,
                width: transform.width,
                height: transform.height,
              }}
            />
          ))}
        </div>
      </div>
    </ContentContext.Provider>
  );
}

Content.defaultProps = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  bleed: 3,
  spine: 0,
};

Content.propTypes = {
  children: node.isRequired,
  x: number,
  y: number,
  width: number,
  height: number,
  bleed: number,
  spine: number,
};

export default Content;
