import React from 'react';
import { number } from 'prop-types';

import { dimensions } from '../../constants';

function ClipPaths({ spine }) {
  return (
    <>
      <clipPath id="clip-workspace" key={0}>
        <rect
          x={-dimensions.workspacePaddingX}
          y={-dimensions.pagePadding / 2}
          width={dimensions.pageWidth * 2 + dimensions.workspacePaddingX * 2}
          height={dimensions.pageHeight + dimensions.pagePadding}
        />
      </clipPath>

      <clipPath id="clip-spread-preview" key={1}>
        <rect
          x={0}
          y={0}
          width={dimensions.pageWidth * 2}
          height={dimensions.pageHeight}
        />
      </clipPath>

      <clipPath id="clip-page-preview" key={2}>
        <rect
          x={0}
          y={0}
          width={dimensions.pageWidth}
          height={dimensions.pageHeight}
        />
      </clipPath>

      <clipPath id="clip-spread" key={3}>
        <rect
          x={-dimensions.pageBleed}
          y={-dimensions.pageBleed}
          width={dimensions.pageWidth * 2 + dimensions.pageBleed * 2}
          height={dimensions.pageHeight + dimensions.pageBleed * 2}
        />
      </clipPath>

      <clipPath id="clip-cover" key={4}>
        <rect
          x={-spine}
          y={-dimensions.pageBleed}
          width={dimensions.pageWidth + dimensions.pageBleed + spine}
          height={dimensions.pageHeight + dimensions.pageBleed * 2}
        />
      </clipPath>

      <clipPath id="clip-page" key={5}>
        <rect
          x={-dimensions.pageBleed}
          y={-dimensions.pageBleed}
          width={dimensions.pageWidth + dimensions.pageBleed * 2}
          height={dimensions.pageHeight + dimensions.pageBleed * 2}
        />
      </clipPath>

      <clipPath id="clip-sticker" key={6}>
        <rect
          x={0}
          y={0}
          width={dimensions.stickerWidth}
          height={dimensions.stickerHeight}
        />
      </clipPath>

      <clipPath id="clip-doublesticker" key={7}>
        <rect
          x={0}
          y={0}
          width={dimensions.stickerWidth * 2}
          height={dimensions.stickerHeight}
        />
      </clipPath>

      <clipPath id="clip-sticker-render" key="clip-sticker-render">
        <rect
          x={-dimensions.stickerBleed}
          y={-dimensions.stickerBleed}
          width={dimensions.stickerWidth + dimensions.stickerBleed * 2}
          height={dimensions.stickerHeight + dimensions.stickerBleed * 2}
        />
      </clipPath>

      <clipPath id="clip-doublesticker-render" key="clip-doublesticker-render">
        <rect
          x={-dimensions.stickerBleed}
          y={-dimensions.stickerBleed}
          width={dimensions.stickerWidth * 2 + dimensions.stickerBleed * 2}
          height={dimensions.stickerHeight + dimensions.stickerBleed * 2}
        />
      </clipPath>
    </>
  );
}

ClipPaths.defaultProps = {
  spine: 0,
};

ClipPaths.propTypes = {
  spine: number,
};

export default ClipPaths;
