import { DragSource } from 'react-dnd';

import { itemTypes } from '../../../constants';
import ImageItem from './index';

const elementSource = {
  beginDrag({ imageObject, toggleTrashcan }) {
    toggleTrashcan(true);
    return imageObject;
  },
  endDrag({ toggleTrashcan }) {
    toggleTrashcan(false);
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export default DragSource(itemTypes.image, elementSource, collect)(ImageItem);
