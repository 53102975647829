import React from 'react';
import { Menu } from 'semantic-ui-react';

import ControlsOptions from './ControlsOptions';
import { assetPreviewSizeLabels } from '../../constants';

const TemplateMenu = () => {
  return (
    <Menu vertical secondary>
      <Menu.Item className="header">Ansicht</Menu.Item>

      <ControlsOptions
        options={assetPreviewSizeLabels}
        controlsStoreKey="templatePreviewSize"
      />
    </Menu>
  );
};

export default TemplateMenu;
