import axios from 'axios';

import { API_BASE_URL, API_URL } from '../constants';
import * as session from '../util/session';

export const SET_USER = 'user/set';
export const AUTHENTICATION_ERROR = 'user/error';
export const SET_HEADERS = 'user/headers';
export const LOGOUT = 'user/logout';

const initialState = {
  identifier: '',
  authenticated: false,
  token: '',
  user: {},
  headers: {},
  errors: null,
};

/**
 * Login a user with { email, password } - stores server response and headers in localStorage, sets axios common authentication headers.
 * @param {} user
 */
export const login = user => {
  return dispatch => {
    axios
      .post(`${API_BASE_URL}/auth/sign_in`, user)
      .then(res => {
        const payload = {
          user: res.data,
          authenticated: true,
          errors: null,
        };

        // TODO: Move this side-effect to a Redux middleware
        session.setUser(payload);

        dispatch({ type: SET_USER, payload });
      })
      .catch(err =>
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: err.response ? err.response.data : err.toString(),
        })
      );
  };
};

/**
 * Logout the current user - resets localStorage and user store.
 */
export const logout = () => dispatch => {
  // TODO: Move these side-effects to a Redux middleware
  session.clearUser();
  session.clearHeaders();

  dispatch({ type: LOGOUT });
};

export const fetchProfile = () => async dispatch => {
  const { data } = await axios.get(`${API_URL}/users/me`);
  dispatch({ type: SET_USER, payload: { user: data } });
};

// TODO: Add explicit initialization, e.g. higher-order function accepting the user and returning the current reducer function.
const userFromLocalStorage = session.getUser();

export default (state = userFromLocalStorage || initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_HEADERS:
      return {
        ...state,
        headers: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

/**
 * SELECTORS
 */

export const getCurrentUser = state => state.user.user;
