import axios from 'axios';

import { API_URL, itemTypes } from '../constants';
import { addToQueue, removeFromQueue } from './loading';
import { tagTemplate } from './templates';
import { tagImage } from '../actions/images';

const REPLACE = 'tags/REPLACE';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case REPLACE:
      return action.payload;
    default:
      return state;
  }
};

export const fetchTags = () => dispatch => {
  dispatch(addToQueue(`fetchTags`));
  axios.get(`${API_URL}/tags`).then(({ data: tags }) => {
    if (!Array.isArray(tags)) {
      tags = tags.tags;
    }
    dispatch({ type: REPLACE, payload: tags });
    dispatch(removeFromQueue(`fetchTags`));
  });
};

export const createTag = title => dispatch => {
  axios.post(`${API_URL}/tags`, { title }).then(() => dispatch(fetchTags()));
};

export const deleteTag = id => dispatch => {
  axios.delete(`${API_URL}/tags/${id}`).then(() => dispatch(fetchTags()));
};

export const applyTag = (tagId, itemType, itemId) => dispatch => {
  if (itemType === itemTypes.image) {
    dispatch(tagImage(itemId, tagId));
  } else if (itemType === itemTypes.template) {
    dispatch(tagTemplate(itemId, tagId));
  }
};
