import assert from 'assert';

import { convertSnakeCaseToLowerCase, generateId, mapDraftBlockData } from '.';
import { emptyRawContentState } from './draftjs';
import recursiveMap from './recursiveMap';

function flatElementTree(element, list = [], parent = null) {
  let children;
  if (Array.isArray(element.children)) {
    list.push(
      ...element.children.reduce(
        (acc, child) => flatElementTree(child, acc, element.props.id),
        []
      )
    );
    children = element.children.map(child => child.props.id);
  } else {
    children = [];
  }
  list.push({ ...element, children, parent });
  return list;
}

export function normalizeElement(element) {
  const nodes = flatElementTree(element).reduce((acc, node) => {
    acc[node.props.id] = node;
    return acc;
  }, {});

  return {
    nodes,
    root: element.props.id,
  };
}

// Converts a spreads/elements-structure into a normalized workspace
export function buildWorkspace(spreads) {
  const rootElement = {
    type: 'Root',
    props: { id: 'root' },
    children: spreads,
  };
  return normalizeElement(rootElement);
}

export function denormalizeElement({ nodes, root }) {
  assert(!!root, 'The key for the root node is missing');

  // Freeze the given `nodes` to avoid accidental mutations
  const frozenNodes = Object.freeze(nodes);

  function buildElement(id) {
    const node = frozenNodes[id];
    return {
      ...node,
      children: node.children.map(childId => buildElement(childId)),
    };
  }
  return buildElement(root);
}

// Converts a normalized workspace-structure into a tree (it returns only the children)
export function denormalizeWorkspace({ nodes, root }) {
  return denormalizeElement({ nodes, root }).children;
}

export const prepareSectionsAfterImport = createdSections => {
  let insertLogoSection = false;
  let sections = createdSections;

  if (sections.length === 0) {
    insertLogoSection = true;
  } else if (!sections[0].name) {
    insertLogoSection = true;
  } else if (sections[0].name.toLowerCase().indexOf('logo') !== 0) {
    insertLogoSection = true;
  }

  if (insertLogoSection) {
    sections = [
      {
        id: generateId(),
        name: 'Logos',
        stickers: [
          { id: generateId(), name: 'Logo', subtitle: true, textless: true },
        ],
      },
      ...sections,
    ];
  }

  sections = sections.map(section => ({
    ...section,
    stickers: section.stickers.map(s => {
      const sticker = { ...s };

      if (typeof sticker.showTeamInPosition === 'undefined') {
        sticker.showTeamInPosition = true;
      }
      if (sticker.face && sticker.face.bounding_box) {
        sticker.face = {
          ...sticker.face,
          boundingbox: sticker.face.bounding_box,
        };
        delete sticker.face.bounding_box;
      }

      if (sticker.doubleSticker) {
        sticker.noStickerstarsLogo = true;
        sticker.name = sticker.name.replace(/^[\\]+|[\\|]+$/g, '');
      }

      return sticker;
    }),
  }));

  return sections;
};

export function prepareSectionsFromServer(sections) {
  return sections.map(section => {
    return {
      ...section,
      stickers: section.stickers.map(sticker => {
        return {
          ...sticker,
          face: convertSnakeCaseToLowerCase(sticker.face),
          name: sticker.name ? sticker.name : '',
          position: sticker.position ? sticker.position : '',
        };
      }),
    };
  });
}

function parseTextProp(text) {
  if (!text) {
    // Fallback to empty content
    return emptyRawContentState;
  }

  // Savely parse the JSON-string
  let result;
  if (typeof text === 'string') {
    try {
      result = JSON.parse(text);
    } catch {
      return emptyRawContentState;
    }
  } else {
    result = text;
  }

  // If this happens, something is severly wrong...
  if (typeof result !== 'object') {
    throw new Error('wrong text object');
  }
  return result;
}

/**
 * Here two things are done with text-elements:
 *
 * - Preserve line-spacing on old albums:
 * If a text block does not have any lineheight-relevant styling, it used to
 * fallback to "4px" via css. Since the newly introduced changes would reset
 * this height to the default height (11px) it would alter the look of the
 * albums. so we add a special style "LINEHEIGHT-MIN", which resolves to
 * lineHeight:4px.
 *
 * - Apply `parseTextProp` to every text-prop: This convert the
 * JSON.stringify'd version of the text to a regular object, saving much
 * conversion that was done before/after editing and updating. The resulting
 * object is a Draft-JS raw content-state, ready to be used by the draft
 * editor.
 */
export function prepareSpreadsFromServer(spreads) {
  return recursiveMap(spreads, element => {
    // map only text elements
    if (element.type !== 'Text') {
      return element;
    }
    try {
      let { text } = element.props;
      text = parseTextProp(text);
      // set `LINEHEIGHT-MIN` for text blocks in old albums
      const newText = mapDraftBlockData(text, data => ({
        ...data,
        LINEHEIGHT: data.LINEHEIGHT || 'LINEHEIGHT-MIN',
      }));
      return {
        ...element,
        props: { ...element.props, text: newText },
      };
    } catch (e) {
      return element;
    }
  });
}
