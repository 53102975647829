import React from 'react';
import { bool, func, arrayOf, number, objectOf } from 'prop-types';
import { Label } from 'semantic-ui-react';
import { NativeTypes } from 'react-dnd-html5-backend';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';

import { imageTypes, imageTypeLabels, itemTypes } from '../../../constants';
import FolderList from '../FolderList';
import Folder from '../Folder';
import ImageMenu from '../../menu/ImageMenu';
import ImageItem from '../ImageItem/index.container';
import {
  ImageObjectShape,
  FileUploadsShape,
  IdShape,
  IdListShape,
} from '../../shapes';
import { getImageDescription } from '../../../util/images';

export const getImageUsage = (imageId, imageUsage) => {
  if (!imageUsage) {
    return null;
  }

  return imageUsage[imageId] || 0;
};

function ImagesPanel({
  isStock,
  applyImage,
  filterTagIds,
  selectedImages,
  images,
  imageUsage,
  onDrop,
  imageUploads,
  archiveTagId,
}) {
  const filterArchivedImages = image =>
    !image.tags.find(tag => tag.id === archiveTagId);

  const filterImagesByActiveTags = image =>
    intersection(
      image.tags.map(tag => tag.id),
      filterTagIds
    ).length > 0;

  const filteredImages = isEmpty(filterTagIds)
    ? images.filter(filterArchivedImages)
    : images.filter(filterImagesByActiveTags);

  return (
    <div
      className={`flex grow qa-${
        isStock ? 'stock' : 'image'
      }-content folder-list-image`}
    >
      <FolderList menu={<ImageMenu />}>
        {Object.keys(imageTypeLabels).map(contentType => {
          const matchingImages = filteredImages.filter(item => {
            if (contentType === imageTypes.common) {
              return (
                item.details.contentType === imageTypes.common ||
                !item.details.contentType
              );
            }
            return item.details.contentType === contentType;
          });
          const selected = !matchingImages.every(
            image => selectedImages.indexOf(image.id) === -1
          );

          return (
            <Folder
              key={contentType}
              title={imageTypeLabels[contentType]}
              filesUploading={Object.values(imageUploads)
                .filter(({ metaData }) => metaData.contentType === contentType)
                .map(({ fileName }) => ({ name: fileName }))}
              dropTypes={[itemTypes.image, NativeTypes.FILE]}
              onDrop={e => onDrop(e, contentType)}
              selected={selected}
              className={`qa-folder-${contentType}`}
              contentType={contentType}
            >
              {matchingImages.map(item => {
                const detail = getImageDescription(item);
                const usage = getImageUsage(item.id, imageUsage);

                return (
                  <ImageItem
                    key={item.id}
                    className={`qa-image-${item.id}`}
                    image={item.id}
                    contentType={contentType}
                    label={item.blob.filename}
                    detail={detail}
                    tags={item.tags.map(tag => (
                      <Label key={tag.id} color="green">
                        {tag.title}
                      </Label>
                    ))}
                    active={selectedImages.indexOf(item.id) !== -1}
                    onClick={() => applyImage(item.id)}
                    usage={usage}
                  />
                );
              })}
            </Folder>
          );
        })}
      </FolderList>
    </div>
  );
}

ImagesPanel.defaultProps = {
  archiveTagId: null,
};

ImagesPanel.propTypes = {
  isStock: bool.isRequired,
  applyImage: func.isRequired,
  filterTagIds: IdListShape.isRequired,
  selectedImages: IdListShape.isRequired,
  images: arrayOf(ImageObjectShape).isRequired,
  imageUsage: objectOf(number).isRequired,
  onDrop: func.isRequired,
  imageUploads: FileUploadsShape.isRequired,
  archiveTagId: IdShape,
};

export default ImagesPanel;
