import React, { useState } from 'react';
import { Popup, Icon, Button } from 'semantic-ui-react';

import FilterMenu from './FilterMenu';

function FilterInput() {
  const [open, setOpen] = useState(false);
  const [childrenOpen, setChildrenOpen] = useState(false);
  return (
    <Popup
      on="click"
      open={open}
      onClose={() => {
        if (childrenOpen) return;
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      position="bottom left"
      trigger={
        <Button size="mini">
          <Icon name="adjust" />
        </Button>
      }
    >
      <FilterMenu
        onMenuOpen={() => setChildrenOpen(true)}
        onMenuClose={() => setChildrenOpen(false)}
      />
    </Popup>
  );
}

export default FilterInput;
