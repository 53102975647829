import React from 'react';

import { dimensions } from '../../../../constants';
import { resolveImage } from '../../../../util/images';
import {
  ImageContextShape,
  ImageObjectShape,
  StickerShape,
} from '../../../shapes';

function StickerLogos({
  logoObject,
  imageContext,
  sticker: { noStickerstarsLogo, doubleSticker },
}) {
  const result = [];
  let y = 4;

  if (!noStickerstarsLogo) {
    result.push(
      <image
        key="stickerstars-logo"
        xlinkHref="/assets/stickerstars-outline.svg"
        x="3.5"
        y="3.5"
        width="8"
        height="4"
        preserveAspectRatio="xMidYMid"
      />
    );
    if (doubleSticker) {
      result.push(
        <image
          key="stickerstars-logo2"
          xlinkHref="/assets/stickerstars-outline.svg"
          x={dimensions.stickerWidth * 2 - 11.75}
          y="3.5"
          width="8"
          height="4"
          preserveAspectRatio="xMidYMid"
        />
      );
    }
    y = 9;
  }

  if (logoObject) {
    const logo = resolveImage(logoObject, imageContext.resolution);

    result.push(
      <image
        key="custom-logo"
        xlinkHref={logo.src}
        x="3.5"
        y={y}
        width="8"
        height="7"
        preserveAspectRatio="xMidYMid"
      />
    );

    if (doubleSticker) {
      result.push(
        <image
          key="custom-logo2"
          xlinkHref={logo.src}
          x={dimensions.stickerWidth * 2 - 11.75}
          y={y}
          width="8"
          height="7"
          preserveAspectRatio="xMidYMid"
        />
      );
    }
  }
  return result;
}

StickerLogos.defaultProps = {
  logoObject: null,
};

StickerLogos.propTypes = {
  imageContext: ImageContextShape.isRequired,
  sticker: StickerShape.isRequired,
  logoObject: ImageObjectShape,
};

export default StickerLogos;
