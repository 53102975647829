import { createSelector } from 'reselect';
import { intersection, isEmpty } from 'lodash';

import { templateTypes } from '../constants';
import { getFilterTagIds } from './controls';
import { getSingleSelectedElement } from './legacy';
import { getArchivedTagId, getSortedTags } from './tags';

export const getTemplates = state => state.templates;

export const getTemplateById = createSelector(
  [getTemplates, (_, templateId) => templateId],
  (templates, templateId) =>
    templates.find(template => template.id === templateId)
);

export const getTemplatePreviewSize = state =>
  state.controls.templatePreviewSize;

export const getTemplatesForContentTypes = createSelector(
  [getTemplates, (_, contentTypes) => contentTypes],
  (templates, contentTypes) =>
    templates.filter(template =>
      contentTypes.includes(template.details.contentType)
    )
);

export const getSelectedTemplateId = createSelector(
  [getSingleSelectedElement],
  singleSelectedElement => singleSelectedElement?.props.template
);

export const getFilteredTemplates = createSelector(
  [getTemplates, getFilterTagIds, getArchivedTagId],
  (templates, filterTagIds, archiveTagId) => {
    const filterArchivedTemplates = template =>
      !template.tags.some(tag => tag.id === archiveTagId);

    const filterTemplateByActiveTags = template =>
      intersection(
        template.tags.map(tag => tag.id),
        filterTagIds
      ).length > 0;

    return templates.filter(
      isEmpty(filterTagIds)
        ? filterArchivedTemplates
        : filterTemplateByActiveTags
    );
  }
);

const compareStickerCapacities = (a, b) =>
  a.details.capacity - b.details.capacity;

export const makeGetFilteredTemplatesForContentType = () =>
  createSelector(
    [getFilteredTemplates, (_, contentType) => contentType],
    (filteredTemplates, contentType) => {
      const matchingTemplates = filteredTemplates.filter(
        template =>
          template.details.contentType === contentType ||
          (!template.details.contentType &&
            contentType === templateTypes.common)
      );

      const sortedTemplates =
        contentType === templateTypes.sticker_placement
          ? matchingTemplates.sort(compareStickerCapacities)
          : matchingTemplates;

      return sortedTemplates;
    }
  );

const stickerContentTypes = [
  templateTypes.sticker,
  templateTypes.sticker_slot,
  templateTypes.sticker_double,
  templateTypes.sticker_slot_double,
];

const getStickerTemplates = state =>
  getTemplatesForContentTypes(state, stickerContentTypes);

const getStickerTagIds = createSelector(
  [getStickerTemplates, getArchivedTagId],
  (stickerTemplates, archiveTagId) => {
    const nonArchivedStickerTemplates = stickerTemplates.filter(
      template => !template.tags.some(tag => tag.id === archiveTagId)
    );

    const nonArchivedTags = nonArchivedStickerTemplates.flatMap(
      template => template.tags
    );

    const nonArchivedTagIds = nonArchivedTags.map(tag => tag.id);

    const uniqueStickerTagIds = [...new Set(nonArchivedTagIds)];

    return uniqueStickerTagIds;
  }
);

/**
 * Returns all tags that can be assigned to a sticker. It would usually be in
 * the tags module, but since it is based on templates, it would create a
 * circular dependency if it was.
 */
export const getStickerTags = createSelector(
  [getSortedTags, getStickerTagIds],
  (sortedTags, stickerTagIds) =>
    sortedTags.filter(tag => stickerTagIds.includes(tag.id))
);

export const getStickerSlotTemplates = state =>
  getTemplatesForContentTypes(state, [templateTypes.sticker_slot]);

export const makeGetTemplatesForContentTypeAndTagId = () =>
  createSelector(
    [getTemplates, (_, contentTypeAndTags) => contentTypeAndTags],
    (templates, { contentType, tagId }) => {
      if (!tagId) {
        return [];
      }

      return templates.filter(
        template =>
          template.details.contentType === contentType &&
          template.tags.some(tag => tag.id === tagId)
      );
    }
  );
