/**
 * Wrapper around the DOMMatrixReadOnly interface.
 * See https://developer.mozilla.org/en-US/docs/Web/API/DOMMatrixReadOnly for details.
 */
class Matrix {
  constructor(...args) {
    this.matrix = new DOMMatrixReadOnly(...args);
  }

  translate(x, y) {
    this.matrix = this.matrix.translate(x, y);
    return this;
  }

  scale(factor) {
    this.matrix = this.matrix.scale(factor);
    return this;
  }

  rotate(angle) {
    this.matrix = this.matrix.rotate(angle);
    return this;
  }
}

export default Matrix;
