/**
 * The Root component is used to render the `root` node in the `workspace` reducer.
 */
import { element, arrayOf } from 'prop-types';

function Root({ children }) {
  return children;
}

Root.defaultProps = {
  children: null,
};

Root.propTypes = {
  children: arrayOf(element),
};

export default Root;
