/* eslint-disable import/prefer-default-export */
import { templateTypes } from '../constants';
import recursiveMap from './recursiveMap';
import { getStickerCapacityFromGroup } from './spreads';

export function buildTemplateObject(node, contentType) {
  // Calculate sticker capacity
  const capacity =
    contentType === templateTypes.sticker_placement
      ? getStickerCapacityFromGroup(node)
      : null;

  // Extract preview HTML
  const elementNode = document.getElementById(node.props.id);
  elementNode.setAttribute('clip-path', 'url(#clip-spread)');
  const svgPreview = elementNode.outerHTML;
  elementNode.removeAttribute('clip-path');

  // Remove all template instances that may be contained in the group
  const nodeWithoutTemplateInstances = {
    ...node,
    children: recursiveMap(node.children, item =>
      item.props.template ? null : item
    ),
  };

  // Template object as expected by the server
  return {
    title: '(untitled)',
    json: nodeWithoutTemplateInstances,
    svg_preview: svgPreview,
    details: { contentType, capacity },
  };
}
