import { useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * Allows to chain multiple redux-thunks together, similar to lodash/flow but with
 * some differences:
 * - It expects all parameters/results to be objects and merges reults
 *   into parameters, so later thunks have access to all previous parameters,
 *   even if they were not returned by a previous thunk.
 * - If the thunk returns a promise, it will be resolved before continuation.
 * - If the thunk is falsy, it will be skipped.
 * - The chain is executed in a batch-call.
 *
 * Example usage:
 *   const dispatchChain = useDispatchChain();
 *   dispatchChain([ thunkA, thunkB, [...] ], { initialParam, [...] });
 */

const chainThunks = (thunks, initialParams = {}) => dispatch =>
  batch(() =>
    thunks.reduce(async (paramPromise, thunk) => {
      if (!thunk) {
        return paramPromise;
      }

      const param = await Promise.resolve(paramPromise);
      const result = await dispatch(thunk(param));
      return { ...param, ...result };
    }, initialParams)
  );

export default function useDispatchChain() {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(chainThunks, dispatch), [dispatch]);
}
