import omit from 'lodash/omit';

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 127,
        g: 127,
        b: 127,
      };
}

export function relativeLuminance(hex) {
  // from http://www.w3.org/TR/WCAG20/#relativeluminancedef / https://gist.github.com/jfsiii/5641126
  const rgb = hexToRgb(hex);
  const r1 = rgb.r / 255;
  const g1 = rgb.g / 255;
  const b1 = rgb.b / 255;

  const r2 = r1 <= 0.03928 ? r1 / 12.92 : ((r1 + 0.055) / 1.055) ** 2.4;
  const g2 = g1 <= 0.03928 ? g1 / 12.92 : ((g1 + 0.055) / 1.055) ** 2.4;
  const b2 = b1 <= 0.03928 ? b1 / 12.92 : ((b1 + 0.055) / 1.055) ** 2.4;

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  return 0.2126 * r2 + 0.7152 * g2 + 0.0722 * b2;
}

export function isDevelopment() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    return true;
  }
  // production code
  return false;
}

// TODO: move to generators.js?
export const generateId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line
    return v.toString(16);
  });
};

// TODO: Not used, can be deleted
export function findReferenceInTree(parent, object) {
  if (Array.isArray(parent)) {
    for (let i = 0; i < parent.length; i += 1) {
      const child = parent[i];
      if (child === object) {
        return { parent, keyOrIndex: i };
      }
      if (Array.isArray(child) || child instanceof Object) {
        const result = findReferenceInTree(child, object);
        if (result !== null) return result;
      }
    }
  } else if (parent instanceof Object) {
    Object.keys(parent).forEach(key => {
      const child = parent[key];

      if (child === object) {
        return { parent, keyOrIndex: key };
      }
      if (Array.isArray(child) || child instanceof Object) {
        const result = findReferenceInTree(child, object);
        if (result !== null) return result;
      }
    });
  }
  return null;
}

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [key]: mapFn(object[key], key),
    }),
    {}
  );
}

// TODO: Not used, can be deleted
export const removeIds = node => {
  if (node === null) return null;
  if (node.id) {
    return omit(node, 'id');
  }
  if (Array.isArray(node)) {
    return node.map(s => removeIds(s));
  }
  if (typeof node === 'object') {
    return Object.keys(node).reduce(
      (obj, key) => ({
        ...obj,
        [key]: removeIds(node[key]),
      }),
      {}
    );
  }
  return node;
};

export const isMac = navigator.platform.toLowerCase().indexOf('mac') !== -1;

// https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click
export function downloadFile(url, fileName = null) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  xhr.onload = () => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(xhr.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export function inputElementFocused() {
  const node = document.activeElement;
  if (!node) {
    return false;
  }
  return (
    (node.tagName.toLowerCase() === 'input' &&
      node.getAttribute('type').toLowerCase() === 'text') ||
    node.getAttribute('contenteditable')
  );
}

export function convertSnakeCaseToLowerCase(obj) {
  if (!obj) {
    return obj;
  }
  if (typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).reduce((result, key) => {
    const newKey = key
      .split('_')
      .join('')
      .toLowerCase();
    return {
      ...result,
      [newKey]: convertSnakeCaseToLowerCase(obj[key]),
    };
  }, {});
}

export const objectToOptions = obj =>
  Object.keys(obj).map(key => ({ key, value: key, text: obj[key] }));

export const mapDraftBlockData = (rawContentState, blockDataFn) => ({
  ...rawContentState,
  blocks: rawContentState.blocks.map(block => ({
    ...block,
    data: blockDataFn(block.data),
  })),
});

export const isTextFrame = ({ type }) => type === 'Text';

export const valueBetween = (value, min, max) =>
  Math.max(Math.min(value, max), min);

export function forceWindowRedraw() {
  const element = document.querySelector('body');
  element.style.display = 'none';
  // eslint-disable-next-line no-unused-expressions
  element.offsetHeight;
  element.style.display = '';
}
