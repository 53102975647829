import { arrayOf, bool, number, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { resolutions } from '../../../constants';
import { getImage } from '../../../selectors/images';
import { getAppearanceFromProps } from '../../../selectors/legacy';
import { buildFilters } from '../../../util/filters';
import { getElementTransform } from '../../../util/geometry';
import { resolveImage } from '../../../util/images';
import computeNativeImageSize from '../../../util/images/computeNativeImageSize';
import { ImageContext } from '../../ImageContext';
import { FilterValuesShape, ImageObjectShape } from '../../shapes';
import { ContentContext } from '../Content';
import BaseElement from './BaseElement';
import GuideRect from './GuideRect';

export class ImageClass extends React.PureComponent {
  isPdf = false;

  imageElement;

  componentDidMount() {
    if (!(this.isPdf && this.contentContext)) {
      return;
    }

    const {
      contentOffset,
      contentArea,
      createImageOverlay,
    } = this.contentContext;

    // Calculate the transforms of the frame and the image inside the frame
    const { width, height, imageObject } = this.props;
    const imageSize = computeNativeImageSize(imageObject);
    const transformLocal = getElementTransform(this.groupNode, width, height);
    const transformContentLocal = getElementTransform(
      this.imageElement,
      imageSize.width,
      imageSize.height
    );

    // Append the content-offset to the transforms
    const transform = {
      ...transformLocal,
      x: transformLocal.x + contentOffset.x,
      y: transformLocal.y + contentOffset.y,
    };
    const transformContent = {
      ...transformContentLocal,
      x: transformContentLocal.x + contentOffset.x,
      y: transformContentLocal.y + contentOffset.y,
    };

    // URL of the PDF file
    const { src } = resolveImage(imageObject, resolutions.original);

    createImageOverlay({
      transform,
      transformContent,
      src,
      contentArea,
    });
  }

  render() {
    const {
      blend,
      colors,
      crotation,
      cscale,
      cx,
      cy,
      fillOpacity,
      filter,
      flip,
      gradientMap,
      gradientMapIntensity,
      grayscale,
      height: heightFromProps,
      id,
      imageObject,
      width: widthFromProps,
    } = this.props;

    let { fill, stroke, strokeWidth, opacity } = getAppearanceFromProps(
      this.props
    );

    const filters = buildFilters(
      {
        blend,
        fill,
        fillOpacity,
        filter,
        gradientMap,
        gradientMapIntensity,
        grayscale,
        id,
      },
      colors
    );
    const isPlaceholder = !imageObject;
    const placeholderImage = { src: null };

    return (
      <BaseElement {...this.props}>
        <ContentContext.Consumer>
          {contentContext => {
            this.contentContext = contentContext;
            return (
              <ImageContext.Consumer>
                {imageContext => {
                  const { resolution } = imageContext;
                  const { src } = isPlaceholder
                    ? placeholderImage
                    : resolveImage(imageObject, resolution);
                  const { src: previewSrc } = isPlaceholder
                    ? placeholderImage
                    : resolveImage(imageObject);
                  const { width, height } = computeNativeImageSize(imageObject);

                  // todo save pdf flag in image
                  if (src && typeof src === 'string') {
                    const ext = src
                      .split('.')
                      .pop()
                      .toLowerCase();
                    if (ext === 'pdf' || ext === 'ai') {
                      this.isPdf = true;
                      fill = '#fff';
                    }
                  }

                  let image;
                  if (imageContext.rendering && this.isPdf) {
                    image = <g ref={node => (this.imageElement = node)} />;
                  } else {
                    image = (
                      <g>
                        {!imageContext.rendering && previewSrc && (
                          <image
                            width={width}
                            height={height}
                            preserveAspectRatio="none"
                            xlinkHref={previewSrc}
                            filter={filters ? `url(#filter-${id})` : ''}
                          />
                        )}

                        <image
                          width={width}
                          height={height}
                          preserveAspectRatio="none"
                          xlinkHref={src}
                          filter={filters ? `url(#filter-${id})` : ''}
                        />
                      </g>
                    );
                  }

                  if (flip) {
                    image = (
                      <g transform={`scale(-1,1) translate(${-width},0)`}>
                        {image}
                      </g>
                    );
                  }

                  return (
                    <g opacity={opacity} ref={node => (this.groupNode = node)}>
                      <defs>
                        <clipPath id={`clip-${id}`}>
                          <rect
                            x={0}
                            y={0}
                            width={widthFromProps}
                            height={heightFromProps}
                          />
                        </clipPath>
                        {filters}
                      </defs>

                      {stroke !== 'none' && (
                        <rect
                          width={widthFromProps}
                          height={heightFromProps}
                          stroke={stroke}
                          strokeWidth={strokeWidth}
                          fill="none"
                          strokeLinecap="square"
                        />
                      )}

                      <rect
                        width={widthFromProps}
                        height={heightFromProps}
                        fill={fill}
                      />

                      <g clipPath={`url(#clip-${id})`}>
                        <g
                          transform={`translate(${cx},${cy}) scale(${cscale}) rotate(${crotation})`}
                          className="inside qa-raw-image"
                        >
                          {image}
                        </g>
                      </g>

                      <GuideRect
                        width={widthFromProps}
                        height={heightFromProps}
                      />
                    </g>
                  );
                }}
              </ImageContext.Consumer>
            );
          }}
        </ContentContext.Consumer>
      </BaseElement>
    );
  }
}

ImageClass.defaultProps = {
  blend: null,
  cx: 0,
  cy: 0,
  cscale: 1,
  crotation: 0,
  fillOpacity: null,
  filter: null,
  flip: null,
  gradientMap: null,
  gradientMapIntensity: null,
  grayscale: null,
  imageObject: null,
};

ImageClass.propTypes = {
  blend: string,
  crotation: number,
  cscale: number,
  cx: number,
  cy: number,
  fillOpacity: number,
  filter: FilterValuesShape,
  flip: bool,
  gradientMap: bool,
  gradientMapIntensity: number,
  grayscale: bool,
  height: number.isRequired,
  width: number.isRequired,
  id: string.isRequired,
  colors: arrayOf(string).isRequired,
  imageObject: ImageObjectShape,
};

const mapStateToProps = (state, ownProps) => ({
  imageObject: getImage(state, ownProps.image),
});

export default connect(mapStateToProps)(ImageClass);
