import React, { useRef } from 'react';
import { arrayOf, func, string, bool } from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import SectionInput from './index';
import { itemTypes } from '../../../constants';
import { SectionShape } from '../../shapes';
import useFileUpload from '../../../hooks/useFileUpload';
import { generateId } from '../../../util/index';

function SectionInputWithDnd(props) {
  const {
    id,
    albumId,
    sections,
    toggleTrashcan,
    moveSection,
    moveSticker,
    sectionDragEnabled,
    historyAnchor,
    createStickerAfterDirectUpload,
  } = props;

  const { upload, uploads: stickerUploads } = useFileUpload();

  const index = sections.findIndex(section => section.id === id);

  const elementRef = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: itemTypes.section,
      id,
      index,
    },
    begin() {
      toggleTrashcan(true);
    },
    end() {
      toggleTrashcan(false);
    },
    canDrag() {
      return sectionDragEnabled;
    },
  });

  /**
   * Drop handler to allow sorting sections and moving stickers between
   * sections via DnD. When dropping a file, the image is uploaded
   * and a sticker created.
   */
  const [{ isOver }, drop] = useDrop({
    accept: [itemTypes.sticker, itemTypes.section, NativeTypes.FILE],
    drop(_, monitor) {
      // Return early if any nested drop target has already handled the drop event (ie. `StickerInput`)
      if (monitor.didDrop()) {
        return;
      }

      historyAnchor();

      if (monitor.getItemType() !== NativeTypes.FILE) {
        return;
      }
      const { files } = monitor.getItem();

      Array.from(files).forEach(file => {
        upload(file).then(({ id: blobId, filename }) => {
          createStickerAfterDirectUpload({
            sectionId: id,
            albumId,
            blobId,
            sticker: {
              id: `sticker-${generateId()}`,
              name: (filename && filename.split('.')[0]) || 'new sticker',
            },
          });
        });
      });
    },
    hover(item, monitor) {
      // Return early if the hovered element is a nested drop target (ie. `StickerInput`)
      if (!monitor.isOver({ shallow: true })) {
        return;
      }

      if (!elementRef.current) {
        return;
      }

      const itemType = monitor.getItemType();

      if (itemType === itemTypes.sticker) {
        // drop sticker
        const sectionId = id;
        if (sectionId !== monitor.getItem().sectionId) {
          /**
           * We're mutating the item for performance reasons - see:
           * https://react-dnd.github.io/react-dnd/examples/sortable/simple
           */
          item.sectionId = sectionId; // eslint-disable-line no-param-reassign
          moveSticker(item.id, 0, sectionId);

          return;
        }
      }

      if (itemType === itemTypes.section) {
        const dragId = item.id;
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = elementRef.current.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        if (hoverIndex === undefined) {
          return;
        }

        moveSection(dragId, hoverIndex);
        item.index = hoverIndex; // eslint-disable-line no-param-reassign
      }
    },
    collect: el => ({
      isOver:
        el.isOver() &&
        (el.getItemType() === NativeTypes.FILE ||
          el.getItemType() === itemTypes.sticker),
    }),
  });

  if (sectionDragEnabled) {
    drag(drop(elementRef));
  }

  return (
    <SectionInput
      dragRef={elementRef}
      isOver={isOver}
      isDragging={isDragging}
      stickerUploads={stickerUploads}
      {...props}
    />
  );
}

SectionInputWithDnd.defaultProps = {
  id: null,
  albumId: null,
};

SectionInputWithDnd.propTypes = {
  id: string,
  albumId: string,
  sections: arrayOf(SectionShape).isRequired,
  toggleTrashcan: func.isRequired,
  moveSection: func.isRequired,
  moveSticker: func.isRequired,
  sectionDragEnabled: bool.isRequired,
  historyAnchor: func.isRequired,
  createStickerAfterDirectUpload: func.isRequired,
};

export default SectionInputWithDnd;
