import { shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getSelectedElementIds } from '../../../../selectors/legacy';
import { getEditorStateFromTextPropsAndDraftState } from '../../../../util/draftjs';
import { EditorStateShape } from '../../../shapes';
import BaseText from './BaseText';

class FreeText extends React.Component {
  state = {
    editorState: null,
  };

  static getDerivedStateFromProps(props) {
    let state = props.draftState;
    if (!state) {
      state = {};
    }
    const editorState = getEditorStateFromTextPropsAndDraftState({
      props,
      state,
    });
    return { editorState };
  }

  render() {
    const { draftState, id } = this.props;
    const { editorState } = this.state;

    const isEditing = draftState.editingId === id;

    return (
      <BaseText
        {...this.props}
        editorState={editorState}
        readOnly={!isEditing}
      />
    );
  }
}

FreeText.propTypes = {
  draftState: shape({
    editorState: EditorStateShape,
    editingId: string,
  }).isRequired,
  id: string.isRequired,
};

const mapStateToProps = state => ({
  selectedElementIds: getSelectedElementIds(state),
  draftState: state.draft,
});

export default connect(mapStateToProps)(FreeText);
