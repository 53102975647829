import {
  axisAlignedBoundingRect,
  transformRectCorners,
  elementMatrix,
  imageMatrix,
  bounds,
} from '../util/geometry';
import computeNativeImageSize from '../util/images/computeNativeImageSize';
import { getImage } from './images';
import {
  getStickerById,
  getWorkspace,
  getSingleSelectedElement,
  getSingleSelectedSticker,
  getSelectedElementIds,
} from './legacy';
import { dimensions } from '../constants';

const imageSize = (state, props) => {
  const { image } = props;
  const imageObject = getImage(state, image);
  return computeNativeImageSize(imageObject, 100, 100);
};

const stickerBox = (state, id) => {
  const { doubleSticker } = getStickerById(state, id);
  return {
    width: doubleSticker
      ? dimensions.stickerWidth * 2
      : dimensions.stickerWidth,
    height: dimensions.stickerHeight,
  };
};

const stickerSize = (state, id) => {
  const { width, height } = stickerBox(state, id);
  const { image } = getStickerById(state, id);
  const imageObject = getImage(state, image);
  if (!imageObject) {
    return null;
  }
  return computeNativeImageSize(imageObject, width, height);
};

const elementProps = (state, id) => {
  const { nodes } = getWorkspace(state);
  // Todo: During grouping/ungrouping sometimes requested ids are not present.
  // This is a known issue which needs investigation...
  // Temporary workaround:
  const { props: result } = nodes[id] || {};
  return result || {};
};

const elementCorners = (state, id) => {
  const matrix = elementMatrix(id);
  if (!matrix) {
    return [];
  }
  const { width, height } = elementProps(state, id);
  return transformRectCorners(matrix, width, height);
};

const getElementBounds = (state, id) =>
  bounds(elementMatrix(id), elementProps(state, id));

const getElementImageBounds = (state, id) =>
  bounds(imageMatrix(id), imageSize(state, elementProps(state, id)));

const getStickerImageBounds = (state, id) =>
  bounds(imageMatrix(id), stickerSize(state, id));

export const getElementBoundingBox = (state, id) =>
  axisAlignedBoundingRect(elementCorners(state, id));

const getElementMergedBoundingBoxes = (state, ids) =>
  axisAlignedBoundingRect(ids.flatMap(id => elementCorners(state, id)));

export const getStickerBoundingBox = (state, id) =>
  bounds(elementMatrix(id), stickerBox(state, id));

export function getHandlesBounds(state) {
  const selectedElementIds = getSelectedElementIds(state);
  const singleSelectedElement = getSingleSelectedElement(state);
  const singleSelectedSticker = getSingleSelectedSticker(state);
  const { stickerMode } = state.controls;
  const { selectInside } = state.selection;

  let frameBounds = null;
  let operationBounds = null;
  if (singleSelectedElement) {
    const { props, type } = singleSelectedElement;
    if (!selectInside) {
      // Single element
      operationBounds = getElementBounds(state, props.id);
    } else if (type === 'Image') {
      // Single Image inside
      operationBounds = getElementImageBounds(state, props.id);
      frameBounds = getElementBounds(state, props.id);
    } else if (type === 'Text') {
      // Single Text inside
      operationBounds = getElementBounds(state, props.id);
    }
  } else if (stickerMode && singleSelectedSticker) {
    // Single sticker
    operationBounds = getStickerImageBounds(state, singleSelectedSticker.id);
  } else if (selectedElementIds.length > 0) {
    // Multiple elements
    operationBounds = getElementMergedBoundingBoxes(state, selectedElementIds);
  }
  return {
    frameBounds,
    operationBounds,
  };
}
