import React from 'react';
import { Label, Menu } from 'semantic-ui-react';
import { func, arrayOf } from 'prop-types';

import { ItemTypeShape, TagShape, IdShape, IdListShape } from '../../../shapes';
import { TagsModal } from '../../../ui/ListEditModal';
import Submenu from '../Submenu';

function TagsSubmenu({
  itemType,
  itemId,
  setModal,
  applyTag,
  tags,
  activeTagIds,
}) {
  return (
    <>
      <Submenu
        text="Tags"
        className={`link item qa-${itemType}-${itemId}-tag-dd`}
      >
        {tags.map(({ id, title }) => (
          <Menu.Item
            key={id}
            style={{ width: 300 }}
            onClick={() => applyTag(id, itemType, itemId)}
          >
            <Label
              className={`entry qa-${itemType}-${itemId}-tag-dd--${title}`}
              color={activeTagIds.includes(id) ? 'green' : 'grey'}
            >
              {title}
            </Label>
          </Menu.Item>
        ))}
      </Submenu>
      <Menu.Item onClick={() => setModal(<TagsModal />)}>
        Tags verwalten
      </Menu.Item>
    </>
  );
}

TagsSubmenu.defaultProps = {
  itemId: null,
  itemType: null,
  activeTagIds: [],
};

TagsSubmenu.propTypes = {
  itemId: IdShape,
  itemType: ItemTypeShape,

  // mapStateToProps
  activeTagIds: IdListShape,
  tags: arrayOf(TagShape).isRequired,

  // mapDispatchToProps
  setModal: func.isRequired,
  applyTag: func.isRequired,
};

export default TagsSubmenu;
