import { arrayOf, func, number, string } from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, Input } from 'semantic-ui-react';

import { resolutions, stickerFieldLabels } from '../../../constants';
import { setModal } from '../../../modules/menu';
import { createSection } from '../../../modules/sections';
import { getSections, getStickerCount } from '../../../selectors/legacy';
import { selectStickerNumberByIdLookup } from '../../../selectors/stickers';
import { ImageContext } from '../../ImageContext';
import PopupMenuButton from '../../menu/PopupMenuButton';
import SectionMenu from '../../menu/SectionMenu';
import { SectionShape } from '../../shapes';
import SectionInput from '../SectionInput/index.container';
import SyncControl from './SyncControl';

const imageContextWithSmallResolution = { resolution: resolutions.small };

const SectionPanel = ({
  sections,
  stickerField,
  stickerCount,
  createSection, // eslint-disable-line
  ...dispatch
}) => {
  const [filter, setFilter] = useState('');
  const stickerNumberByIdLookup = useSelector(selectStickerNumberByIdLookup);

  const handleFilter = e => {
    const { value } = e.target;
    setFilter(value);
  };

  const allSectionIds = sections.map(section => section.id);

  const [openSectionIds, setOpenSectionIds] = useState([]);

  const collapseNone = () => {
    setOpenSectionIds(allSectionIds);
  };

  const collapseAll = () => {
    setOpenSectionIds([]);
  };

  const toggleCollapse = id => {
    if (openSectionIds.includes(id)) {
      setOpenSectionIds(openSectionIds.filter(item => item !== id));
    } else {
      setOpenSectionIds([...openSectionIds, id]);
    }
  };

  const createSectionAndOpenIt = section => {
    setOpenSectionIds([...openSectionIds, section.id]);
    createSection(section);
  };

  const renderSections = () => {
    let parsedFilter = filter
      .toLowerCase()
      .split('\\')
      .join(' ');

    if (parsedFilter === '') {
      parsedFilter = null;
    }

    return sections
      .map((section, index) => {
        let { stickers } = section;

        let open = false;
        if (parsedFilter) {
          if (Number.isNaN(Number(parsedFilter))) {
            stickers = stickers.filter(
              sticker =>
                sticker[stickerField]
                  .split('\\')
                  .join(' ')
                  .toLowerCase()
                  .indexOf(parsedFilter) !== -1
            );
          } else {
            parsedFilter = Number(parsedFilter);
            stickers = stickers.filter(
              sticker => stickerNumberByIdLookup[sticker.id] === parsedFilter
            );
          }

          if (stickers.length === 0) {
            return null;
          }
          open = true;
        } else if (openSectionIds.includes(section.id)) {
          open = true;
        }

        return (
          <SectionInput
            index={index}
            id={section.id}
            key={section.id}
            name={section.name}
            stickers={stickers}
            open={open}
            onCollapseClick={() => toggleCollapse(section.id)}
            createSection={createSectionAndOpenIt}
          />
        );
      })
      .filter(item => item);
  };

  return (
    <ImageContext.Provider value={imageContextWithSmallResolution}>
      <div className="flex vertical qa-section-header">
        <Button
          className="qa-open-all-sections"
          icon="plus square outline"
          compact
          onClick={collapseNone}
        />
        <Button
          className="qa-close-all-sections"
          icon="minus square outline"
          compact
          onClick={collapseAll}
        />
        <Input
          icon="search"
          name="sticker_search"
          placeholder={`${stickerCount} Sticker nach ${stickerFieldLabels[stickerField]} durchsuchen...`}
          onChange={handleFilter}
          value={filter}
          style={{ flexGrow: 1 }}
        />
        <PopupMenuButton>
          <SectionMenu setModal={dispatch.setModal} />
        </PopupMenuButton>
      </div>
      <div className="flex grow scrolling section-panel ui list qa-section-content qa-sections-list">
        {renderSections()}
        <SectionInput placeholder createSection={createSectionAndOpenIt} />
      </div>
      <SyncControl />
    </ImageContext.Provider>
  );
};

SectionPanel.defaultProps = {
  stickerField: 'name',
  stickerCount: 0,
};

SectionPanel.propTypes = {
  setModal: func.isRequired,
  createSection: func.isRequired,
  sections: arrayOf(SectionShape).isRequired,
  stickerField: string,
  stickerCount: number,
};

const mapStateToProps = state => ({
  sections: getSections(state),
  stickerCount: getStickerCount(state),
  stickerField: state.controls.stickerField,
});

const mapDispatchToProps = {
  setModal,
  createSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionPanel);
