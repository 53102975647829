import { dimensions, stickerPlacings, templateTypes } from '../constants';
import { generateId, valueBetween } from './index';
import Point from './Point';
import {
  calculatePageNumber,
  replaceTemplateImages,
  uniquifyIds,
} from './spreads';

// TODO move
export function flatten(a) {
  return a.reduce((flat, i) => {
    if (Array.isArray(i)) {
      return flat.concat(flatten(i));
    }
    return flat.concat(i);
  }, []);
}

export function pointToSpreadIndex(point, spreadsCount) {
  const spreadIndex = Math.floor(
    (point.y + dimensions.pagePadding / 2) /
      (dimensions.pageHeight + dimensions.pagePadding)
  );

  // Limit value to the existing spreads range
  return valueBetween(spreadIndex, 0, spreadsCount - 1);
}

export function findTargetSpreadIdOnWorkspace(dropPoint, { root, nodes }) {
  const rootChildren = nodes[root].children;
  const spreadIndex = pointToSpreadIndex(dropPoint, rootChildren.length);

  // first page offset
  let x = 0;
  if (spreadIndex === 0) {
    x = dimensions.pageWidth;
  }

  const y = (dimensions.pageHeight + dimensions.pagePadding) * spreadIndex;

  return {
    spreadId: rootChildren[spreadIndex],
    positionOnSpread: new Point(dropPoint.x - x, dropPoint.y - y),
  };
}

function generatePageNumber(side) {
  const size = 15;
  const offset = 10;
  const item = {
    type: 'Text',
    props: {
      id: generateId(),
      x: side === 0 ? offset : dimensions.pageWidth * 2 - offset - size,
      y: 4,
      width: size,
      height: size,
      symbol: 'pagenum',
    },
  };

  return item;
}

export function generateSpread(sectionId = '', zoom = 1) {
  const page = {
    type: 'Spread',
    props: {
      fill: '#fff',
      sectionId,
    },
    children: [],
  };

  if (sectionId === '') {
    page.props.id = generateId();
    return page;
  }
  page.props.id = generateId();

  page.children.push({
    type: 'Text',
    props: {
      id: generateId(),
      x: 10,
      y: 20,
      fill: '#ffffff',
      width: dimensions.pageWidth - 20,
      height: 20,
      symbol: 'section',
      sectionId,
    },
  });

  page.children.push({
    type: 'Text',
    props: {
      id: generateId(),
      x: 10,
      y: 37,
      fill: '#ffffff',
      width: dimensions.pageWidth - 20,
      height: 10,
      symbol: 'album',
    },
  });

  const padding = 20;

  // sticker area
  const numStickerArea = 2;
  for (let i = 0; i < numStickerArea; i += 1) {
    page.children.push({
      type: 'StickerArea',
      props: {
        id: generateId(),
        x: i * dimensions.pageWidth + padding,
        y: 55,
        width: (dimensions.pageWidth - padding * 2) * zoom,
        height: 220 * zoom,
        scale: 1 / zoom,
      },
    });
  }

  // page numbers
  for (let i = 0; i < 2; i += 1) page.children.push(generatePageNumber(i));

  return page;
}

export function generateSpreadsForSections(sections = [], zoom = 1) {
  const spreads = [];
  spreads.push(generateSpread());
  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];
    let stickerOffset = 0;
    let sectionIndex = 0;
    do {
      const spread = generateSpread(section.id, zoom);
      spreads.push(spread);
      const spreadIndex = spreads.length - 1;
      const params = placeSectionsOnSpread([section], spread, spreadIndex, {
        sectionIndex,
        stickerOffset,
        landscapeStickerIds: [],
      });
      sectionIndex = params.sectionIndex;
      stickerOffset = params.stickerOffset;
    } while (sectionIndex < 1);
  }
  spreads.push(generateSpread());
  return spreads;
}

export function generateSpreadsForColorGroups(sections = []) {
  const generateSimpleSpread = (sectionId = '', children = []) => ({
    type: 'Spread',
    props: { id: generateId(), sectionId },
    children,
  });

  const spreads = [];
  spreads.push(generateSimpleSpread());

  const zoom = 2;
  const padding = 20;
  for (let i = 0; i < sections.length; i += 1) {
    const section = sections[i];
    let stickerOffset;
    do {
      const area = {
        type: 'StickerArea',
        props: {
          id: generateId(),
          x: padding,
          y: padding,
          width: (dimensions.pageWidth - padding) * 2 * zoom,
          height: (dimensions.pageHeight - padding * 2) * zoom,
          scale: 1 / zoom,
        },
      };

      const spread = generateSimpleSpread(section.id, [area]);
      spreads.push(spread);

      const spreadIndex = spreads.length - 1;
      stickerOffset = placeSectionsOnSpread([section], spread, spreadIndex)
        .stickerOffset;
    } while (stickerOffset < section.stickers.length);
  }
  spreads.push(generateSimpleSpread());
  return spreads;
}

export function reorderSpreadsAccordingToSections(spreadNodes, sections) {
  let index = 0;
  const newSpreads = [];

  function addTillSectionNextSection(currentSectionId = false) {
    while (true) {
      if (index >= spreadNodes.length) break;
      if (currentSectionId) {
        // continue until another section is set
        if (
          spreadNodes[index].props.sectionId &&
          spreadNodes[index].props.sectionId !== currentSectionId
        )
          break;
      } else {
        // continue until any section is set
        if (spreadNodes[index].props.sectionId) break;
      }
      newSpreads.push(spreadNodes[index].props.id);
      index += 1;
    }
  }

  addTillSectionNextSection();
  sections.forEach(section => {
    const lastIndex = index;
    index = spreadNodes.findIndex(item => item.props.sectionId === section.id);
    if (index === -1) {
      index = lastIndex;
    } else {
      addTillSectionNextSection(section.id);
    }
  });
  addTillSectionNextSection();

  return newSpreads;
}

export function generateSpreads({ tags, templates, sections, images, stock }) {
  const order = [
    templateTypes.front,
    templateTypes.toc,
    templateTypes.sts_greet,
    templateTypes.sticker_placement,
    templateTypes.sponsorpage,
    templateTypes.sticker_placement,
    templateTypes.imprint,
    templateTypes.ad_full,
  ];

  const stickerSpreadsNeeded = sections.reduce((acc, section) => {
    const sectionSize = section.stickers.reduce(
      (size, { doubleSticker }) => size + (doubleSticker ? 2 : 1),
      0
    );
    return acc + Math.ceil(sectionSize / 18);
  }, 0);

  const stickerSlots = order.filter(
    type => type === templateTypes.sticker_placement
  ).length;
  const stickerSpreadsPerSlot = stickerSpreadsNeeded / stickerSlots;
  const lastStickerSpreadIndex = order.lastIndexOf(
    templateTypes.sticker_placement
  );

  let sectionIndex = 0;
  let adIndex = 0;
  let stockIndex = 0;
  let spreadIndex = 0;

  function filterItems(items, { type, tags, capacity, originalTeamId }) {
    if (type) {
      items = items.filter(t => t.details.contentType === type);
    }

    if (originalTeamId) {
      items = items.filter(t => t.details.originalTeamId === originalTeamId);
    }

    if (tags) {
      if (items.length > 0) {
        let scores = items.map(item => ({
          id: item.id,
          score: item.tags.reduce(
            (acc, cur) => acc + (tags.includes(cur.id) ? 1 : 0),
            0
          ),
        }));
        scores = scores.sort((a, b) => b.score - a.score);
        const highest = scores[0].score;
        const ids = scores
          .filter(item => item.score === highest)
          .map(item => item.id);
        items = items.filter(item => ids.includes(item.id));
      }
    }

    if (capacity) {
      // order items by sticker capacity
      items = items.sort((a, b) => {
        if (!a.details.capacity) {
          a = 0;
        } else {
          a = a.details.capacity;
        }
        if (!b.details.capacity) {
          b = 0;
        } else {
          b = b.details.capacity;
        }
        if (a < capacity && !(b < capacity)) {
          // move items too small to the end
          return 100;
        }
        if (!(a < capacity) && b < capacity) {
          // move items too small to the end
          return -100;
        }
        // order remaining items by ascending difference
        return a - capacity - (b - capacity);
      });
    }

    return items;
  }

  function typeToSpreads(type, orderIndex) {
    function findTemplate(type, capacity = null, sectionId = null) {
      const matches = filterItems(templates, { type, tags, capacity });
      if (matches.length) {
        const i = spreadIndex % matches.length;
        const template = matches[i];
        let element = JSON.parse(JSON.stringify(template.json));
        element.props.template = template.id;
        element = uniquifyIds(element);
        const [newElement, newAdIndex, newStockIndex] = replaceTemplateImages(
          element,
          spreadIndex,
          sectionId,
          images,
          stock,
          sections,
          adIndex,
          stockIndex
        );
        adIndex = newAdIndex;
        stockIndex = newStockIndex;
        return { element: newElement, capacity: template.details.capacity };
      }
      return { element: null, capacity: 0 };
    }

    function createSpread(stickerCapacityNeeded = null, sectionId = null) {
      const { element } = findTemplate(type, stickerCapacityNeeded);

      if (element) {
        let children = [element];

        // add page num on all inside spreads
        if (orderIndex > 0 && orderIndex < order.length - 1) {
          children = [
            ...children,
            findTemplate(templateTypes.pagenums).element,
          ];
        }

        // add sticker_background
        if (type === templateTypes.sticker_placement) {
          children = [
            findTemplate(templateTypes.sticker_background, null, sectionId)
              .element,
            ...children,
          ];
        }

        children = children.filter(item => item);

        spreadIndex += 1;

        return {
          type: 'Spread',
          props: {
            id: generateId(),
            fill: '#fff',
            sectionId,
          },
          children,
        };
      }
    }

    function createStickerSpreads() {
      const spreads = [];
      let stickerOffset = 0;
      while (sectionIndex < sections.length) {
        const section = sections[sectionIndex];
        const spread = createSpread(
          section.stickers.length - stickerOffset,
          section.id
        );

        const spreadIndex = spreads.length;
        let params = placeSectionsOnSpread(sections, spread, spreadIndex, {
          sectionIndex,
          stickerOffset,
          landscapeStickerIds: [],
        });
        sectionIndex = params.sectionIndex;
        stickerOffset = params.stickerOffset;
        spreads.push(spread);

        if (
          orderIndex < lastStickerSpreadIndex &&
          spreads.length >= stickerSpreadsPerSlot
        ) {
          // chunk size of sticker-pages reached unless its the last chunk
          break;
        }
      }
      return spreads;
    }

    if (type === templateTypes.sticker_placement) {
      return createStickerSpreads();
    }
    if (type === templateTypes.toc) {
      const sectionId = sections[sectionIndex].id;
      sectionIndex += 1;
      return createSpread(null, sectionId);
    }
    return createSpread();
  }

  let spreads = order.map((type, orderIndex) => {
    return typeToSpreads(type, orderIndex);
  });

  spreads = flatten(spreads);

  return spreads;
}

/**
 * Place stickers within a single sticker-area. Only used by `placeSectionsOnSpread`.
 *
 * @typedef {Object} StickerPositionsShape
 * @property {string} id - Id of the sticker
 * @property {number} x - Position
 * @property {number} y - Position

 * @param {Array} stickers - Stickers array
 * @param {Object} stickerAreaProps - Props of the regarded sticker-area
 * @param {number} stickerOffset - Offset of the next sticker to be placed (within the stickers array)
 *
 * @returns {<StickerPositionsShape>[]} result.stickerPositionsByStickerArea - List of placed stickers
 */
function placeStickersInStickerArea(
  stickers,
  stickerAreaProps,
  stickerOffset,
  landscapeStickerIds = [],
  pageNumber = 0
) {
  const { width, height, stickerPlacing } = stickerAreaProps;
  const stickerPlacingChecked = stickerPlacing || stickerPlacings.compress;

  const result = [];

  const stickerPadding =
    stickerPlacingChecked !== stickerPlacings.expand ? dimensions.gridSize : 1;

  const numCols = Math.floor(
    (width + stickerPadding) / (dimensions.stickerWidth + stickerPadding)
  );
  const numRows = Math.floor(
    (height + stickerPadding) / (dimensions.stickerHeight + stickerPadding)
  );

  const spacingCols = numCols - 1;
  const spacingRows = numRows - 1;
  const remainingSizeX =
    width - (dimensions.stickerWidth * numCols + stickerPadding * spacingCols);
  const remainingSizeY =
    height -
    (dimensions.stickerHeight * numRows + stickerPadding * spacingRows);

  let x;
  let y;
  let paddingX = stickerPadding;
  let paddingY = stickerPadding;
  if (stickerPlacingChecked === stickerPlacings.compress) {
    x = remainingSizeX / 2;
    y = remainingSizeY / 2;
  } else if (stickerPlacingChecked === stickerPlacings.grid) {
    x = 0;
    y = 0;
  } else {
    paddingX += spacingCols === 0 ? 0 : remainingSizeX / spacingCols;
    paddingY += spacingRows === 0 ? 0 : remainingSizeY / spacingRows;
    x = 0;
    y = 0;
  }

  let subtitleHeight = 0;
  let index = stickerOffset;
  while (index < stickers.length) {
    const sticker = stickers[index];

    let stickerWidth;
    if (sticker.doubleSticker) {
      if (stickerPlacingChecked === stickerPlacings.grid) {
        stickerWidth = dimensions.stickerWidth * 2 + paddingX;
      } else {
        stickerWidth = (dimensions.stickerWidth + paddingX) * 2;
      }
    } else {
      if (landscapeStickerIds.includes(sticker.id)) {
        stickerWidth = dimensions.stickerHeight + paddingX;
      } else {
        stickerWidth = dimensions.stickerWidth + paddingX;
      }
    }

    if (x + stickerWidth > width + paddingX) {
      if (stickerPlacingChecked === stickerPlacings.compress) {
        x = remainingSizeX / 2;
      } else {
        x = 0;
      }
      if (x + stickerWidth > width + paddingX) {
        return result;
      }
      y += dimensions.stickerHeight + paddingY + subtitleHeight;
      subtitleHeight = 0;
    }
    if (y + dimensions.stickerHeight > height + paddingY) {
      return result;
    }
    if (sticker.subtitle) {
      const lines = sticker.name.split('\\');
      subtitleHeight = Math.max(subtitleHeight, lines.length * 5 + 2);
    }

    result.push({ x, y, id: sticker.id, pageNumber });
    x += stickerWidth;
    index += 1;
  }
  return result;
}

export const extractElementsByType = (children, type) =>
  children.map(element => {
    if (element.type === type) {
      return element;
    }
    if (Array.isArray(element.children)) {
      return extractElementsByType(element.children, type);
    }
    return null;
  });

// TODO: extractElementsByTypeWithSpreadPosition / extractElementsByIdsWithParentPosition are almost identical - refactor into one function?
export const extractElementsByTypeWithSpreadPosition = (
  children,
  type,
  x = 0,
  y = 0
) =>
  children.map(element => {
    if (element.type === type) {
      return { ...element, x: element.props.x + x, y: element.props.y + y };
    }
    if (Array.isArray(element.children)) {
      return extractElementsByTypeWithSpreadPosition(
        element.children,
        type,
        x + element.props.x,
        y + element.props.y
      );
    }
    return null;
  });

// TODO: extractElementsByTypeWithSpreadPosition / extractElementsByIdsWithParentPosition are almost identical - refactor into one function?
export const extractElementsByIdsWithParentPosition = (
  children,
  ids,
  x = 0,
  y = 0
) =>
  children.map(element => {
    if (ids.indexOf(element.props.id) !== -1) {
      return { ...element, x, y };
    }
    if (Array.isArray(element.children)) {
      const ex = element.props.x !== undefined ? element.props.x : 0;
      const ey = element.props.y !== undefined ? element.props.y : 0;
      return extractElementsByIdsWithParentPosition(
        element.children,
        ids,
        x + ex,
        y + ey
      );
    }
    return null;
  });

export const extractElementsByIds = (children, ids) =>
  children.map(element => {
    if (ids.indexOf(element.props.id) !== -1) {
      return element;
    }
    if (Array.isArray(element.children)) {
      return extractElementsByIds(element.children, ids);
    }
    return null;
  });

/**
 * Place one or more sections on a spread
 *
 * @typedef {Object} StickerPositionsShape
 * @property {string} id - Id of the sticker
 * @property {number} x - Position
 * @property {number} y - Position

 * @param {Array} sections - Sections array
 * @param {Array} spread - Spreads array
 * @param {Object} [params] Parameters as follows:
 * @param {number} params.sectionIndex - Index of the section to be placed
 * @param {number} params.stickerOffset - Offset of the next sticker to be placed (within the section)
 * @param {number} params.landscapeStickerIds - Array of landscape sticker ids
 *
 * @returns {Object} result
 * @returns {number} result.sectionIndex - updated parameter
 * @returns {number} result.stickerOffset - updated parameter
 * @returns {Object.<string, StickerPositionsShape>} result.stickerPositionsByStickerArea - Object of lists of placed stickers by StickerArea-id
 */
export const placeSectionsOnSpread = (
  sections,
  spread,
  spreadIndex,
  params = {
    sectionIndex: 0,
    stickerOffset: 0,
    landscapeStickerIds: [],
  }
) => {
  let { sectionIndex, stickerOffset, landscapeStickerIds } = params;

  // Skip all non-section spreads
  if (!spread.props.sectionId || sectionIndex === sections.length) {
    return params;
  }

  // Find section that matches spread.sectionId
  while (spread.props.sectionId !== sections[sectionIndex].id) {
    sectionIndex += 1;
    stickerOffset = 0;

    if (sectionIndex === sections.length) {
      // End of sections reached
      return params;
    }
  }

  // Todo: extractElementsByTypeWithSpreadPosition could be replace by the new matrix-selector
  const stickerAreas = flatten(
    extractElementsByTypeWithSpreadPosition(spread.children, 'StickerArea')
  ).filter(Boolean);

  const stickerAreasWithPageNumber = stickerAreas.map(node => ({
    ...node,
    pageNumber: calculatePageNumber(spreadIndex, node.x),
  }));

  const sortedStickerArea = stickerAreasWithPageNumber.sort((a, b) => {
    if (a.pageNumber !== b.pageNumber) {
      return a.pageNumber - b.pageNumber;
    }

    if (a.y !== b.y) {
      return a.y - b.y;
    }

    return a.x - b.x;
  });

  function checkSectionIncrement() {
    if (sectionIndex === sections.length) {
      // End of sections reached
      return;
    }
    if (stickerOffset >= sections[sectionIndex].stickers.length) {
      // All stickers of the current section have been placed, increment to next section
      sectionIndex += 1;
      stickerOffset = 0;
    }
  }

  const stickerPositionsByStickerArea = sortedStickerArea.reduce(
    (acc, { props, pageNumber }) => {
      if (sectionIndex >= sections.length) {
        acc[props.id] = [];
      } else {
        const { stickers } = sections[sectionIndex];
        const stickerPositions = placeStickersInStickerArea(
          stickers,
          props,
          stickerOffset,
          landscapeStickerIds,
          pageNumber
        );
        acc[props.id] = stickerPositions;
        stickerOffset += stickerPositions.length;
        if (spread.props.multiple) {
          checkSectionIncrement();
        }
      }
      return acc;
    },
    {}
  );
  checkSectionIncrement();

  return {
    sectionIndex,
    stickerOffset,
    stickerPositionsByStickerArea,
    landscapeStickerIds,
  };
};
