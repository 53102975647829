/*

  # blockData
 "blockData" is a way to store custom data related to a block.

 we use it to store style-information at block-level, since draft does not support block-styling out of the box.
 this is used for two seperate purposes:
 - allow empty blocks to have a consistent line-height (relevant for the line-height are SIZE, FONT, LINEHEIGHT)
 - allow block text-alignment (ALIGN)

 # <BlockWrapper/>
 during rendering, the <BlockWrapper/> component converts this info to a <div/> with inline-css, which all
 unstyles <span/>-nodes within that block inherit from.

 # handleBlockSplit
 when return is pressed, the current block is split into to two new blocks. the new block needs inherits
 blockData from the current inline-style (the styling at the current cursor position, for SIZE, FONT, LINEHEIGHT)
 and also the source block (for ALIGN).

*/

import { EditorState, Modifier } from 'draft-js';
import DraftEditorBlock from 'draft-js/lib/DraftEditorBlock.react';
import { getSelectedBlocksMap } from 'draftjs-utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { fonts, fontSizes, lineHeights } from '../../../../constants';

export const HANDLED = 'handled';
export const NOT_HANDLED = 'not-handled';

export const defaultInlineStyles = [
  'FONT-RUBIK',
  'SIZE-11',
  'LINEHEIGHT-100%',
  'COLOR-#000000',
];

export const defaultBlockStyle = Immutable.Map({
  FONT: 'FONT-RUBIK',
  SIZE: 'SIZE-11',
  LINEHEIGHT: 'LINEHEIGHT-100%',
  ALIGN: 'ALIGN-LEFT',
  COLOR: 'COLOR-#000000',
});

export const textAligns = ['left', 'center', 'right', 'justify'];

export const blockAlignMap = textAligns.reduce(
  (acc, cur) => ({
    ...acc,
    [`ALIGN-${cur}`.toUpperCase()]: { textAlign: cur },
  }),
  {}
);

export const staticStyleMap = fonts.reduce(
  (result, name) => {
    // eslint-disable-next-line no-param-reassign
    result[`FONT-${name.toUpperCase()}`] = {
      fontFamily: name,
    };
    return result;
  },
  fontSizes.reduce(
    (result, size) => {
      // eslint-disable-next-line no-param-reassign
      result[`SIZE-${size}`] = {
        // pt to mm. scaled x10 up here, scaled down in foreinObject-styles, solving chrome small font size problem
        fontSize: (size / 2.835) * 10,
      };
      return result;
    },
    lineHeights.reduce(
      (result, lineHeight) => {
        // eslint-disable-next-line no-param-reassign
        result[`LINEHEIGHT-${lineHeight}`] = {
          lineHeight,
        };
        return result;
      },
      {
        UPPERCASE: { textTransform: 'uppercase' },
        /* in old albums there is an inline-style "LINEHEIGHT-1", which was later this was replaced
            by "LINEHEIGHT-100%". in these old albums, empty block would lack a lineHeight-css styling without
              this value: */
        'LINEHEIGHT-1': { lineHeight: '100%' },
        'LINEHEIGHT-MIN': { lineHeight: '4px' },
      }
    )
  )
);

const BlockWrapper = props => {
  const { block } = props;
  const data = defaultBlockStyle.merge(block.getData());

  const blockStyleReducer = (acc, blockStyleValue) =>
    Object.assign(
      acc,
      staticStyleMap[blockStyleValue],
      blockAlignMap[blockStyleValue]
    );
  let style = data.reduce(blockStyleReducer, {});

  // convert legacy block types, that were used for alignment. only needed for older albums
  const type = block.getType();
  if (type.indexOf('align-') === 0) {
    style = { ...style, ...blockAlignMap[type.toUpperCase()] };
  }

  return (
    <div style={style}>
      <DraftEditorBlock {...props} />
    </div>
  );
};

const blockShape = PropTypes.shape({
  getType: PropTypes.func,
  getData: PropTypes.func,
});

BlockWrapper.propTypes = {
  block: blockShape.isRequired,
};

export function blockRendererFn(block) {
  return {
    component: BlockWrapper,
    props: {
      block,
    },
  };
}

// limit availiable styles for specific font (e.g. "Gilroy Black" has no "BOLD" variant)
export const availableStyles = {
  'Have Heart One': ['UNDERLINE', 'UPPERCASE'],
  'Rewe Pop': ['UNDERLINE', 'UPPERCASE'],
  'Saira Stencil': ['UNDERLINE', 'UPPERCASE'],
  'Saira Condensed': ['BOLD', 'UNDERLINE', 'UPPERCASE'],
  'Edeka Chalk': ['UNDERLINE', 'UPPERCASE'],
  'Bradley Hand': ['UNDERLINE', 'UPPERCASE'],
  'Realize My Passion': ['UNDERLINE', 'UPPERCASE'],
  'Gilroy Light': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
  'Gilroy Black': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
  Rockness: ['UNDERLINE', 'UPPERCASE'],
  'DCC-Ash': ['UNDERLINE', 'UPPERCASE'],
  'DINPro-black': ['UNDERLINE', 'UPPERCASE'],
  Coolvetica: ['UNDERLINE', 'UPPERCASE'],
  'Coolvetica-Condensed': ['UNDERLINE', 'UPPERCASE'],
  'Coolvetica-Compressed': ['UNDERLINE', 'UPPERCASE'],
  'Coolvetica-Crammed': ['UNDERLINE', 'UPPERCASE'],
  'HighVoltage Rough': ['UNDERLINE', 'UPPERCASE'],
  Hind: ['BOLD', 'UNDERLINE', 'UPPERCASE'],
  'Clearface Gothic LT Std': ['UNDERLINE', 'UPPERCASE'],
  CaveatBrush: ['UNDERLINE', 'UPPERCASE'],
  SedgwickAve: ['UNDERLINE', 'UPPERCASE'],
  GlossAndBloom: ['UNDERLINE', 'UPPERCASE'],
  Exo2: ['BOLD', 'UNDERLINE', 'UPPERCASE'],
  'Sean Becker-Heavy': ['UNDERLINE', 'UPPERCASE'],
  'Raleway-Light': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
  'Apis-Light': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
  'Apis-Black': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
  'Open Sans Light': ['ITALIC', 'UNDERLINE', 'UPPERCASE'],
};

export const styles = [
  { icon: 'bold', style: 'BOLD' },
  { icon: 'italic', style: 'ITALIC' },
  { icon: 'underline', style: 'UNDERLINE' },
  { icon: 'angle double up', style: 'UPPERCASE' },
];

export const inlineStyleListToMap = list =>
  list.reduce((acc, style) => {
    const [key] = style.split('-');
    return acc.set(key, style);
  }, Immutable.Map());

export const handleBlockSplit = editorState => {
  // from https://github.com/facebook/draft-js/issues/723#issuecomment-367918580
  const newContentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );

  let splitState = EditorState.push(
    editorState,
    newContentState,
    'split-block'
  );

  // first merge all selected block styles...
  const allSelectedBlockStyles = getSelectedBlocksMap(editorState).reduce(
    (acc, cur) => acc.merge(cur.getData()),
    defaultBlockStyle
  );

  // ...fill up with current inline style
  const allSelectedStyles = allSelectedBlockStyles.merge(
    inlineStyleListToMap(editorState.getCurrentInlineStyle())
  );

  // ... limit them to line height and block relevant styles
  const blockData = allSelectedStyles.filter((value, key) =>
    ['SIZE', 'LINEHEIGHT', 'FONT', 'ALIGN'].includes(key)
  );

  const afterMergeStylesContentState = Modifier.mergeBlockData(
    splitState.getCurrentContent(),
    splitState.getSelection(),
    blockData
  );

  splitState = EditorState.push(
    editorState,
    afterMergeStylesContentState,
    'split-block'
  );

  return splitState;
};
