// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';

import { login } from '../../modules/user';

export class Login extends Component {
  state = {
    user: {
      email: '',
      password: '',
    },
  };

  handleUserChange = (e, key) => {
    const { value } = e.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [key]: value,
      },
    });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { user } = this.state;
    dispatch(login(user));
  };

  render() {
    const { user } = this.state;
    const {
      user: { errors, authenticated },
    } = this.props;

    if (authenticated) {
      return <Redirect to="/start" />;
    }

    return (
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            Login
          </Header>
          <Form size="large" onSubmit={this.handleSubmit}>
            {errors && (
              <Segment>
                <div className="ui negative message">
                  <div className="header">Da ist was schiefgelaufen:</div>
                  <p>{JSON.stringify(errors)}</p>
                </div>
              </Segment>
            )}
            <Segment stacked>
              <Form.Input
                className="qa-login-input-email"
                name="email"
                value={user.email}
                onChange={e => this.handleUserChange(e, 'email')}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-Mail"
              />
              <Form.Input
                className="qa-login-input-password"
                fluid
                name="password"
                value={user.password}
                icon="lock"
                onChange={e => this.handleUserChange(e, 'password')}
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
              <Button className="qa-login-button" primary fluid size="large">
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
