import { node, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, List } from 'semantic-ui-react';

import { assetPreviewSizeLabels, resolutions } from '../../constants';
import { setFilterTagIds } from '../../modules/controls';
import { getFilterTagIds } from '../../selectors/controls';
import { getSortedTags } from '../../selectors/tags';
import { ImageContext } from '../ImageContext';
import PopupMenuButton from '../menu/PopupMenuButton';

function FolderList({ children, imagePreviewSize, menu }) {
  const dispatch = useDispatch();
  const [collapsedIndexes, setCollapsedIndexes] = useState([]);
  const tags = useSelector(getSortedTags);
  const filterTagIds = useSelector(getFilterTagIds);

  const collapseNone = () => {
    setCollapsedIndexes([]);
  };

  const collapseAll = () => {
    const allIndexes = React.Children.map(children, (_, index) => index);
    setCollapsedIndexes(allIndexes);
  };

  const toggleCollapse = index => {
    if (collapsedIndexes.includes(index)) {
      setCollapsedIndexes(collapsedIndexes.filter(item => item !== index));
    } else {
      setCollapsedIndexes([...collapsedIndexes, index]);
    }
  };

  const renderLabel = label => ({
    color: 'green',
    content: label.text,
  });

  const resolution =
    imagePreviewSize === assetPreviewSizeLabels.large
      ? resolutions.medium
      : resolutions.small;

  const handleFilterChange = useCallback(
    (_, { value }) => {
      dispatch(setFilterTagIds(value));
    },
    [dispatch]
  );

  const filterOptions = useMemo(
    () =>
      tags.map(tag => ({
        value: tag.id,
        text: tag.title,
        className: `qa-tag-option-${tag.title}`,
      })),
    [tags]
  );

  const imageContextValue = useMemo(() => ({ resolution }), [resolution]);

  return (
    <ImageContext.Provider value={imageContextValue}>
      <div className="flex grow">
        <div className="flex vertical">
          <Button
            icon="plus square outline"
            className="qa-open-all-folders"
            compact
            onClick={collapseNone}
          />
          <Button
            icon="minus square outline"
            className="qa-close-all-folders"
            compact
            onClick={collapseAll}
          />

          <Dropdown
            className="grow qa-tag-filter-dd"
            placeholder="Filtern..."
            fluid
            multiple
            selection
            options={filterOptions}
            value={filterTagIds}
            renderLabel={renderLabel}
            onChange={handleFilterChange}
          />

          {menu && <PopupMenuButton>{menu}</PopupMenuButton>}
        </div>

        <List className="flex grow scrolling" celled>
          {React.Children.map(children, (child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <List.Item key={index}>
              {React.cloneElement(child, {
                collapsed: collapsedIndexes.includes(index),
                onCollapseClick: () => toggleCollapse(index),
              })}
            </List.Item>
          ))}
        </List>
      </div>
    </ImageContext.Provider>
  );
}

FolderList.defaultProps = {
  children: null,
  imagePreviewSize: 'Small',
  menu: null,
};

FolderList.propTypes = {
  children: node,
  imagePreviewSize: string,
  menu: node,
};

const mapStateToProps = state => ({
  imagePreviewSize: state.controls.imagePreviewSize,
});

export default connect(mapStateToProps)(FolderList);
