import assert from 'assert';

import { convertToRaw } from 'draft-js';
import { createAction } from 'redux-actions';

import { alignDirection, alignTypes, dimensions } from '../constants';
import { historyAnchor } from '../modules/history';
import { showMessage } from '../modules/menu';
import { clearSelection, elementSelect } from '../modules/selection';
import { createTemplate, patchTemplate } from '../modules/templates';
import { getImage } from '../selectors/images';
import {
  getLegacySpreads,
  getSections,
  getSelectedElementIds,
  getSelectedElements,
  getSelectedSpreadIds,
  getSpreadIds,
  getStickerCount,
  getTargetNodeId,
  getTargetSpreadIndex,
  getWorkspace,
} from '../selectors/legacy';
import { selectSectionById } from '../selectors/stickers';
import { getTocPages, getTocSections } from '../selectors/tableOfContents';
import { getEditorStateFromSymbolPropsAndXPos } from '../util/draftjs';
import {
  flatten,
  generateSpreads,
  generateSpreadsForSections,
} from '../util/generators';
import { getBoundingBox, getItemTransformInGroup } from '../util/geometry';
import { fitImageObjectIntoElement } from '../util/images';
import computeNativeImageSize from '../util/images/computeNativeImageSize';
import { generateId } from '../util/index';
import Point from '../util/Point';
import recursiveMap from '../util/recursiveMap';
import {
  findElement,
  findSpreadIndexRecursive,
  guessContentTypeFromGroup,
  replaceTemplateImages,
  uniquifyIds,
} from '../util/spreads';
import { buildTemplateObject } from '../util/templates';
import { denormalizeElement } from '../util/workspace';

export const LOAD_WORKSPACE = 'workspace/LOAD_WORKSPACE';
export const REPLACE_SPREADS = 'workspace/REPLACE_SPREADS';
export const INSERT_ELEMENTS = 'workspace/INSERT_ELEMENTS';
export const UPDATE_ELEMENTS = 'workspace/UPDATE_ELEMENTS';
export const REPLACE_ELEMENTS = 'workspace/REPLACE_ELEMENTS';
export const DELETE_ELEMENTS = 'workspace/DELETE_ELEMENTS';
export const GROUP_ELEMENTS = 'workspace/GROUP_ELEMENTS';
export const UNGROUP_ELEMENTS = 'workspace/UNGROUP_ELEMENTS';
export const SWAP_ELEMENTS = 'workspace/SWAP_ELEMENTS';
export const REORDER_SPREADS = 'workspace/REORDER_SPREADS';
export const SEND_ELEMENTS_TO_FRONT = 'workspace/SEND_ELEMENTS_TO_FRONT';
export const SEND_ELEMENTS_TO_BACK = 'workspace/SEND_ELEMENTS_TO_BACK';
export const MOVE_ELEMENTS_TO_SPREAD = 'workspace/MOVE_ELEMENTS_TO_SPREAD';

/**
 * Init the complete reducer state (ie. when loading the crash dump)
 * @param {Object} initialState - The complete reducer state
 * @param {string} initialState.root  - The id of the root node
 * @param {Object} initialState.nodes - All nodes referenced by their ids. ( { <id>: { type, props, children} )
 */
export const loadWorkspace = createAction(
  LOAD_WORKSPACE,
  initialState => ({ initialState }),
  { undoableIrreversibleCheckpoint: true }
);

/**
 * Replace the complete reducer state (ie. when loading the spreads from the server)
 * @param {Object[]} spreads - List of denormalized spreads
 * @param {Object} [options] - Optional options
 * @param {boolean} options.resetHistory - Clear undo/redo history
 */
export const replaceSpreads = createAction(
  REPLACE_SPREADS,
  spreads => ({ spreads }),
  (payload, options) => {
    const { resetHistory } = options || {};
    return {
      undoableIrreversibleCheckpoint: !!resetHistory,
    };
  }
);

/**
 * Insert one or more elements
 * @param {Object[]} elements - List of denormalized elements, all of them have the same parent
 * @param {string} parentId - Id of the parent element (for "Spread"-elements it must be "root")
 * @param {number} [index] - Index where the elements will be inserted (they will be appended at the end when `-1`)
 */
export const insertElements = createAction(
  INSERT_ELEMENTS,
  (elements, parentId, index = -1) => ({
    elements,
    parentId,
    index,
  })
);

/**
 * Update elements
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const updateElements = createAction(UPDATE_ELEMENTS, propsDeltaMap => ({
  propsDeltaMap,
}));

/**
 * Replace elements
 * @param {string[]} ids - Ids of the elements to be replaced
 * @param {Object} element - Denormalized element
 */
export const replaceElements = createAction(
  REPLACE_ELEMENTS,
  (ids, element) => ({
    ids,
    element,
  })
);

/**
 * Delete elements
 * @param {string[]} ids - Id of the elements to be deleted
 */
export const deleteElements = createAction(DELETE_ELEMENTS, ids => ({
  ids,
}));

/**
 * Group elements
 * @param {string[]} ids - Id of the elements to be replaced
 * @param {Object} groupProps - The props of the new group element (including the `id`)
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const groupElements = createAction(
  GROUP_ELEMENTS,
  (ids, groupProps, propsDeltaMap) => ({
    ids,
    groupProps,
    propsDeltaMap,
  })
);

/**
 * Ungroup elements
 * @param {string[]} ids - Id of the elements to ungroup (it is possible to ungroup different groups at the time)
 * @param {Object} propsDeltaMap - Props to update referenced by element id (e.g.: { ['id']: { x: 123, y :456 } })
 */
export const ungroupElements = createAction(
  UNGROUP_ELEMENTS,
  (ids, propsDeltaMap) => ({
    ids,
    propsDeltaMap,
  })
);

/**
 * Swap child elements
 * @param {string} parentId - Id of the parent element (for "Spread"-elements it must be "root")
 * @param {string} firstChildId - Id of the first child element to be swapped
 * @param {string} secondChildId - Id of the second child element to be swapped
 */
export const swapElements = createAction(
  SWAP_ELEMENTS,
  (parentId, firstChildId, secondChildId) => ({
    parentId,
    firstChildId,
    secondChildId,
  })
);

/**
 * Reorder spreads
 * @param {string[]} spreadIds - Spread ids in the new order
 */
export const reorderSpreads = createAction(REORDER_SPREADS, spreadIds => ({
  spreadIds,
}));

/**
 * Send elements to front
 * @param {string[]} ids - List of ids to be sent to front
 */
export const sendElementsToFront = createAction(
  SEND_ELEMENTS_TO_FRONT,
  ids => ({ ids })
);

/**
 * Send elements to back
 * @param {string[]} ids - List of ids to be sent to back
 */
export const sendElementsToBack = createAction(SEND_ELEMENTS_TO_BACK, ids => ({
  ids,
}));

/**
 * Move elements into a different spread
 * @param {string[]} ids - List of element ids to move
 * @param {string} newSpreadId - Id of the spread element where to move the elements
 * @param {Object} [propsDeltaMap] - Props to update referenced by element id. E.g.: { ['id']: { x: 123, y :456 } }
 */
export const moveElementsToSpread = createAction(
  MOVE_ELEMENTS_TO_SPREAD,
  (ids, newSpreadId, propsDeltaMap = {}) => ({
    ids,
    newSpreadId,
    propsDeltaMap,
  })
);

// Legacy mapping

export const updateSelectedElements = props => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  const propsDeltaMap = ids.reduce((acc, id) => {
    acc[id] = props;
    return acc;
  }, {});
  dispatch(updateElements(propsDeltaMap));
};

// High level actions

/**
 * Helper to apply a action to all selected elements
 */
const forSelection = action => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  return getSelectedElementIds(state).forEach(id => {
    const element = denormalizeElement({ nodes, root: id });
    dispatch(action(element));
  });
};

export const alignElements = (align, axis) => (dispatch, getState) => {
  const extractElementsByIdsWithParentPosition = (
    children,
    ids,
    x = 0,
    y = 0
  ) =>
    children.map(element => {
      if (ids.indexOf(element.props.id) !== -1) {
        return { ...element, x, y };
      }
      if (Array.isArray(element.children)) {
        const ex = element.props.x !== undefined ? element.props.x : 0;
        const ey = element.props.y !== undefined ? element.props.y : 0;
        return extractElementsByIdsWithParentPosition(
          element.children,
          ids,
          x + ex,
          y + ey
        );
      }
      return null;
    });

  const ids = getSelectedElementIds(getState());
  if (ids.length === 0) {
    return;
  }
  const state = getState();
  const {
    controls: { alignTo, alignUseMargin, pageMargins },
  } = state;

  const spreads = getLegacySpreads(state);
  const items = flatten(
    extractElementsByIdsWithParentPosition(spreads, ids)
  ).filter(item => item);

  const spreadNode = document
    .getElementById(items[0].props.id)
    .closest('.spread');

  let selection;
  if (alignTo === alignTypes.keyObject) {
    selection = getBoundingBox(items[items.length - 1], spreadNode);
  } else {
    selection = getBoundingBox(items, spreadNode);
  }

  let reference;
  switch (alignTo) {
    default:
    case alignTypes.keyObject:
    case alignTypes.selection:
      reference = selection;
      break;
    case alignTypes.spread:
      reference = {
        x: alignUseMargin ? pageMargins.left : 0,
        y: alignUseMargin ? pageMargins.top : 0,
        width:
          dimensions.pageWidth * 2 -
          (alignUseMargin ? pageMargins.right + pageMargins.left : 0),
        height:
          dimensions.pageHeight -
          (alignUseMargin ? pageMargins.top + pageMargins.bottom : 0),
      };
      break;
    case alignTypes.page:
      reference = {
        x:
          (alignUseMargin ? pageMargins.left : 0) +
          (selection.x < dimensions.pageWidth ? 0 : dimensions.pageWidth),
        y: alignUseMargin ? pageMargins.top : 0,
        width:
          dimensions.pageWidth -
          (alignUseMargin ? pageMargins.right + pageMargins.left : 0),
        height:
          dimensions.pageHeight -
          (alignUseMargin ? pageMargins.top + pageMargins.bottom : 0),
      };
      break;
  }

  const propsDeltaMap = {};

  const { isolation } = state.controls;
  let groupNode;
  if (isolation) {
    groupNode = document.getElementById(isolation).closest('.container');
  }

  items.forEach(item => {
    const { props } = item;
    const bound = getBoundingBox(item, spreadNode);
    let value;
    const size = axis === 'x' ? 'width' : 'height';
    switch (align) {
      default:
      case alignDirection.start:
        value =
          reference[axis] + (props[axis] - Math.min(bound[axis], props[axis]));
        break;
      case alignDirection.center:
        value =
          reference[axis] +
          reference[size] / 2 +
          (props[axis] - Math.min(bound[axis], props[axis])) -
          bound[size] / 2;
        break;
      case alignDirection.end:
        value =
          reference[axis] +
          reference[size] +
          (props[axis] - Math.min(bound[axis], props[axis])) -
          bound[size];
        break;
    }
    const delta = {};

    if (isolation) {
      let p = new Point(0, 0);
      p[axis] = value;
      p = p.localToGlobal(spreadNode).globalToLocal(groupNode);
      delta[axis] = p[axis];
    } else {
      delta[axis] = value;
    }

    propsDeltaMap[item.props.id] = delta;
  });

  dispatch(updateElements(propsDeltaMap));
};

export const nudgeElements = (dx, dy) => (dispatch, getState) => {
  const propsDeltaMap = {};

  const state = getState();
  const elements = getSelectedElements(state);
  const {
    selection: { selectInside },
  } = state;

  elements.forEach(item => {
    if (selectInside) {
      const cx = item.props.cx + dx;
      const cy = item.props.cy + dy;
      propsDeltaMap[item.props.id] = { cx, cy };
    } else {
      const x = item.props.x + dx;
      const y = item.props.y + dy;
      propsDeltaMap[item.props.id] = { x, y };
    }
  });
  dispatch(updateElements(propsDeltaMap));
  dispatch(historyAnchor());
};

export const applyImage = imageId => (dispatch, getState) => {
  const state = getState();
  const selectedElements = getSelectedElements(state);
  const {
    controls: { autoFit },
  } = state;
  const imageObject = getImage(state, imageId);
  let contentType = null;
  if (imageObject) {
    contentType = imageObject.contentType;
  }

  const propsDeltaMap = selectedElements.reduce((acc, element) => {
    if (element.type === 'Image') {
      let delta = { image: imageId };
      if (contentType) {
        delta.contentType = contentType;
      }
      if (autoFit) {
        delta = {
          ...delta,
          ...fitImageObjectIntoElement(imageObject, element, true),
        };
      }
      acc[element.props.id] = delta;
    }
    return acc;
  }, {});
  dispatch(updateElements(propsDeltaMap));
};

export const applyTemplate = templateId => (dispatch, getState) => {
  const state = getState();
  const spreads = getLegacySpreads(state);
  const sections = getSections(state);
  const { templates } = state;
  const { images, stock } = state;

  const templateJson = templates.find(item => item.id === templateId).json;
  const element = uniquifyIds(JSON.parse(JSON.stringify(templateJson)));
  if (typeof element.props.rotation === 'undefined') element.props.rotation = 0;
  if (typeof element.props.scale === 'undefined') element.props.scale = 1;

  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => dispatch => {
      if (item.type === 'Group') {
        const { spreadIndex } = findElement(spreads, item.props.id);
        const { sectionId } = spreads[spreadIndex].props;
        const [elementWithNewImages] = replaceTemplateImages(
          element,
          spreadIndex,
          sectionId,
          images,
          stock,
          sections
        );

        const newElement = {
          ...elementWithNewImages,
          props: {
            ...elementWithNewImages.props,
            id: item.props.id,
            template: templateId,
          },
        };

        dispatch(replaceElements([item.props.id], newElement));
      }
    })
  );
};

/**
 * Create spread
 * @param position: 0 = before active spread, 1 = after active spread
 */
const createSpreadPosition = position => (dispatch, getState) => {
  const state = getState();
  const spreadIds = getSelectedSpreadIds(state);
  const { nodes } = getWorkspace(state);
  const targetSpreadIndex = getTargetSpreadIndex(state);
  const spread = {
    type: 'Spread',
    props: {
      id: `ManualPage-${generateId()}`,
      fill: '#fff',
      sectionId: nodes[spreadIds[0]].props.sectionId,
    },
    children: [],
  };
  dispatch(insertElements([spread], 'root', targetSpreadIndex + position));
};

export const createSpreadBefore = () => createSpreadPosition(0);

export const createSpreadAfter = () => createSpreadPosition(1);

/**
 * Move spreads
 * @param direction: -1 = move up, +1 = move down
 */
const moveSpreadItems = direction => (dispatch, getState) => {
  const state = getState();
  const selectedSpreadIndex = getTargetSpreadIndex(state);
  const otherSpreadIndex = selectedSpreadIndex + direction;
  const { nodes, root } = getWorkspace(state);
  const { children } = nodes[root];
  const otherSpreadId = children[otherSpreadIndex];
  if (!otherSpreadId) {
    return;
  }
  dispatch(swapElements(root, children[selectedSpreadIndex], otherSpreadId));
};

export const moveSpreadItemsUp = () => moveSpreadItems(-1);

export const moveSpreadItemsDown = () => moveSpreadItems(+1);

/**
 * Delete spreads
 */
export const deleteSpreadItems = () => (dispatch, getState) => {
  const state = getState();
  const selectedSpreadIds = getSelectedSpreadIds(state);
  if (selectedSpreadIds.length === 0) {
    return;
  }

  const spreadCount = getSpreadIds(state).length;
  if (spreadCount === 1) {
    // eslint-disable-next-line no-alert
    window.alert('Diese Seite kann nicht gelöscht werden.');
    return;
  }

  dispatch(clearSelection());
  dispatch(deleteElements(selectedSpreadIds));
};

/**
 * Duplicate elements
 */
export const duplicateItems = () => (dispatch, getState) => {
  const state = getState();
  const workspace = getWorkspace(state);
  const selectedElements = getSelectedElements(state);
  const spreadId = getTargetNodeId(state);

  const elements = selectedElements.map(element => {
    // Resolve element's children recursively
    const denormalizedElement = denormalizeElement({
      nodes: workspace.nodes,
      root: element.props.id,
    });

    // Apply an offset to the element to not overlap the original one
    const { props } = denormalizedElement;
    denormalizedElement.props = { ...props, x: props.x + 10, y: props.y + 10 };
    return uniquifyIds(denormalizedElement);
  });

  dispatch(insertElements(elements, spreadId));
  dispatch(elementSelect(elements.map(element => element.props.id)));
};

/**
 * Convert a text-symbol to editable text
 */
export const convertToEditableText = () => (dispatch, getState) => {
  dispatch(
    forSelection(item => dispatchForSelection => {
      const state = getState();
      const { id, symbol, text } = item.props;
      const spreads = getLegacySpreads(state);
      const spreadIndex = findSpreadIndexRecursive(spreads, id);
      const spread = spreads[spreadIndex];
      const { nodes, root } = getWorkspace(state);
      const { sectionId } = spread.props;
      const spreadContextValue = {
        id: spread.props.id,
        nodeIndex: spreadIndex,
        nodeSiblingCount: nodes[root].children.length,
        sectionName: selectSectionById(state, sectionId)?.name,
        sectionId,
      };
      const props = {
        id,
        symbol,
        texts: state.texts,
        sections: getSections(state),
        album: state.albums.albumData.title,
        totalstickers: getStickerCount(state),
        toc_sections: getTocSections(state),
        toc_pages: getTocPages(state),
        text,
      };
      const { editorState } = getEditorStateFromSymbolPropsAndXPos(
        {
          props,
          xPos: 0,
        },
        spreadContextValue
      );
      dispatchForSelection(
        updateElements({
          [item.props.id]: {
            symbol: null,
            text: convertToRaw(editorState.getCurrentContent()),
          },
        })
      );
    })
  );
};

/**
 * Sending to foreground and background
 */
export const sendItemsFront = () => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  dispatch(sendElementsToFront(ids));
};

export const sendItemsBack = () => (dispatch, getState) => {
  const ids = getSelectedElementIds(getState());
  dispatch(sendElementsToBack(ids));
};

/**
 * Deleting
 */
export const deleteElementItems = () => (dispatch, getState) => {
  const selectedElementIds = getSelectedElementIds(getState());

  if (selectedElementIds.length === 0) return;

  dispatch(deleteElements(selectedElementIds));
};

/**
 * Grouping
 */
export const groupItems = () => (dispatch, getState) => {
  const state = getState();
  const ids = getSelectedElementIds(state);
  if (ids.length < 2) {
    return;
  }
  const items = getSelectedElements(state);
  const spreadDomNode = document.getElementById(items[0].props.id).parentNode;
  const groupArea = getBoundingBox(items, spreadDomNode);

  const id = generateId();
  const groupProps = {
    id,
    ...groupArea,
  };

  const propsDeltaMap = items.reduce((acc, item) => {
    acc[item.props.id] = {
      x: item.props.x - groupArea.x,
      y: item.props.y - groupArea.y,
    };
    return acc;
  }, {});

  dispatch(groupElements(ids, groupProps, propsDeltaMap));
  dispatch(elementSelect([id]));
};

export const ungroupItems = () => (dispatch, getState) => {
  const state = getState();
  const { nodes } = getWorkspace(state);
  const selectedGroupElements = getSelectedElements(state).filter(
    element => element.type === 'Group'
  );
  if (selectedGroupElements.length === 0) {
    return;
  }
  const propsDeltaMap = selectedGroupElements.reduce((parentAcc, element) => {
    const groupNode = document
      .getElementById(element.props.id)
      .parentNode.closest('.container');

    return element.children.reduce((childAcc, childId) => {
      // eslint-disable-next-line no-param-reassign
      childAcc[childId] = getItemTransformInGroup(
        nodes[childId].props,
        groupNode
      );
      return childAcc;
    }, parentAcc);
  }, {});

  const ids = selectedGroupElements.map(element => element.props.id);
  dispatch(ungroupElements(ids, propsDeltaMap));
  dispatch(elementSelect(Object.keys(propsDeltaMap)));
};

/**
 * Content and frame fitting
 */
const fitContent = (item, cover) => (dispatch, getState) => {
  const { image } = item.props;
  const imageObject = getImage(getState(), image);
  return dispatch(
    updateElements({
      [item.props.id]: fitImageObjectIntoElement(imageObject, item, cover),
    })
  );
};

export const fitContentContain = () => dispatch =>
  dispatch(forSelection(item => fitContent(item, false)));

export const fitContentCover = () => dispatch =>
  dispatch(forSelection(item => fitContent(item, true)));

export const fitFrame = () => dispatch =>
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => (dispatch, getState) => {
      const { props } = item;
      const imageId = props.image;
      let { rotation, cscale, crotation } = props;
      const imageObject = getImage(getState(), imageId);
      let { width, height } = computeNativeImageSize(imageObject);
      if (!rotation) rotation = 0;
      if (!cscale) cscale = 1;
      if (!crotation) crotation = 0;
      width *= cscale;
      height *= cscale;
      rotation += crotation;
      crotation = 0;
      const cx = 0;
      const cy = 0;
      dispatch(
        updateElements({
          [props.id]: {
            width,
            height,
            rotation,
            cx,
            cy,
            crotation,
          },
        })
      );
    })
  );

/**
 * Creates a group element as a template on the server.
 */
export const createTemplateFromGroup = () => dispatch =>
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => async dispatch => {
      const { template: templateId } = item.props;
      if (templateId) {
        showMessage(
          'Element is already a Template - please select a regular group.',
          true
        );
        return;
      }

      const contentType = guessContentTypeFromGroup(item);
      const templateObject = buildTemplateObject(item, contentType);
      const result = await dispatch(createTemplate(templateObject));

      dispatch(
        updateElements({
          [item.props.id]: {
            template: result.payload.id,
          },
        })
      );
    })
  );

/**
 * Replaces all instances of a template with the contents of the group
 */
export const updateAllInstancesFromInstance = () => dispatch =>
  dispatch(
    forSelection(item => (_, getState) => {
      const { id, templateId } = item.props;
      if (!templateId) {
        showMessage(
          'Element is not an instance - please select a template instance.',
          true
        );
        return;
      }

      const sourceProps = {
        x: item.props.x,
        y: item.props.y,
        width: item.props.width,
        height: item.props.height,
        rotation: item.props.rotation ? item.props.rotation : 0,
      };
      const childrenJson = JSON.stringify(item.children);
      let spreads = getLegacySpreads(getState());
      spreads = recursiveMap(spreads, element => {
        if (element.props.id !== id && element.props.template === templateId) {
          return uniquifyIds({
            ...element,
            children: JSON.parse(childrenJson),
            props: { ...element.props, ...sourceProps },
          });
        }
        return element;
      });
      dispatch(clearSelection());
      dispatch(replaceSpreads(spreads));
    })
  );

/**
 * Updates a group element as a template on the server.
 */
export const updateTemplateFromInstance = () => (dispatch, getState) => {
  const { templates } = getState();
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => dispatch => {
      const { template: templateId } = item.props;
      const existingTemplate = templates.find(
        template => template.id === templateId
      );
      assert(existingTemplate, 'template not found');

      const templateObject = buildTemplateObject(
        item,
        existingTemplate.details.contentType
      );

      dispatch(patchTemplate(existingTemplate.id, templateObject));
    })
  );
};

/**
 * Converts swatch colorValues to local colorValues for fill and stroke props
 */
export const convertToLocalColors = () => (dispatch, getState) => {
  const state = getState();
  const { colors } = state;
  const selectedElements = getSelectedElements(state);

  const parseStyle = style => {
    if (style.indexOf('COLOR-') === 0 && style.indexOf('COLOR-#') === -1) {
      const index = parseInt(style.split('-').pop(), 10);
      const color = colors[index];
      return `COLOR-${color}`;
    }
    return style;
  };

  const recurse = (items, deltas) => {
    if (Array.isArray(items)) {
      items.forEach(item => {
        ['fill', 'stroke'].forEach(prop => {
          const color = item.props[prop];
          if (color !== undefined) {
            const index = Number(color);
            if (!Number.isNaN(index) && index > 1) {
              // index 0 + index 1 are fixed and should not be converted
              const value = colors[index];

              // eslint-disable-next-line no-param-reassign
              deltas[item.props.id] = {
                ...deltas[item.props.id],
                [prop]: value,
              };
            }
          }
        });

        if (item.type === 'Text') {
          let raw = item.props.text;
          raw = {
            ...raw,
            blocks: raw.blocks.map(blockItem => ({
              ...blockItem,
              inlineStyleRanges: Array.isArray(blockItem.inlineStyleRanges)
                ? blockItem.inlineStyleRanges.map(styleItem => ({
                    ...styleItem,
                    style: styleItem.style
                      ? parseStyle(styleItem.style)
                      : styleItem.style,
                  }))
                : blockItem.inlineStyleRanges,
            })),
          };

          // eslint-disable-next-line no-param-reassign
          deltas[item.props.id] = { ...deltas[item.props.id], text: raw };
        }

        // eslint-disable-next-line no-param-reassign
        deltas = recurse(item.children, deltas);
      });
    }
    return deltas;
  };

  dispatch(updateElements(recurse(selectedElements, {})));
};

/**
 * Delete all spreads and create empty album
 */
export const resetSpreads = () => (dispatch, getState) => {
  const sections = getSections(getState());
  dispatch(clearSelection());
  dispatch(replaceSpreads(generateSpreadsForSections(sections)));
  dispatch(historyAnchor());
};

/**
 * Delete all spreads and build album from templates
 */
export const buildSpreads = (tags = []) => (dispatch, getState) => {
  const state = getState();
  const sections = getSections(state);
  const { templates, images, stock } = state;
  dispatch(clearSelection());
  dispatch(
    replaceSpreads(
      generateSpreads({
        tags,
        sections,
        templates,
        images,
        stock,
      })
    )
  );
  dispatch(historyAnchor());
};

export const flipImage = () => dispatch =>
  dispatch(
    // eslint-disable-next-line no-shadow
    forSelection(item => dispatch => {
      dispatch(updateElements({ [item.props.id]: { flip: !item.props.flip } }));
    })
  );

export const insertAndSelectElements = ({ elements, spreadId }) => dispatch => {
  dispatch(insertElements(elements, spreadId));
  dispatch(elementSelect(elements.map(({ props }) => props.id)));
  dispatch(historyAnchor());
};
