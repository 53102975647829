// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';

import {
  getAlbumStatisticsForPreflight,
  getSpreadIds,
  getSections,
} from '../../selectors/legacy';
import Page from './Page';
import Node from './Node';
import Content from './Content';
import { dimensions } from '../../constants';

class SpreadContainerRenderer extends React.Component {
  renderCover() {
    const { includeBleed, spine } = this.props;

    const { bleed, trim, marks } = includeBleed
      ? Page.defaultProps
      : { bleed: 0, trim: 0, marks: 'none' };

    const { spreadIds } = this.props;

    let pages;

    if (spreadIds.length < 2) {
      return null;
    }
    if (spreadIds.length < 4) {
      pages = [
        spreadIds[spreadIds.length - 1], // u4
        spreadIds[0], // u1
      ];
    } else {
      pages = [
        spreadIds[spreadIds.length - 1], // u4,
        spreadIds[0], // u1

        spreadIds[1], // u2
        spreadIds[spreadIds.length - 2], // u3
      ];
    }

    const result = [];

    for (let i = 0; i < pages.length / 2; i += 1) {
      const contents = [];
      for (let page = 0; page < 2; page += 1) {
        const key = `spread${i}_page${page}`;

        const index = i * 2 + page;

        let contentX = 0;
        const contentY = 0;
        let contentWidth = dimensions.pageWidth;
        const contentHeight = dimensions.pageHeight;

        let spreadX = 0;
        const spreadY = 0;
        let spreadWidth = dimensions.pageWidth;
        const spreadHeight = dimensions.pageHeight;

        if (index === 1) {
          contentX += dimensions.pageWidth;
          spreadX += spine;
          contentWidth += spine;
          spreadWidth += spine;
        } else if (index === 3) {
          contentX += dimensions.pageWidth + spine;
          spreadX -= dimensions.pageWidth;
        }

        const spreadId = pages[index];
        const spreadIndex = spreadIds.indexOf(spreadId);

        const content = (
          <Content
            key={key}
            id={key}
            x={contentX}
            y={contentY}
            width={contentWidth}
            height={contentHeight}
            bleed={bleed}
            skipCoverOverlays
            spine={spine}
          >
            <Node
              id={spreadId}
              nodeIndex={spreadIndex}
              nodeSiblingCount={spreadIds.length}
              visibleSpreadIds={null}
              renderingSpreadPositionAndSize={{
                x: spreadX,
                y: spreadY,
                width: spreadWidth,
                height: spreadHeight,
              }}
            />
          </Content>
        );

        contents.push(content);
      }
      const key = `cover${i}`;
      const pageWidth = dimensions.pageWidth * 2 + spine;
      const { pageHeight } = dimensions;
      result.push(
        <Page
          key={key}
          id={key}
          width={pageWidth}
          height={pageHeight}
          bleed={bleed}
          trim={trim}
          marks={marks}
        >
          {contents}
        </Page>
      );
    }

    return (
      <div>
        {result}
        {spreadIds.length && <div className="render-complete" />}
      </div>
    );
  }

  renderAlbum() {
    const { includeBleed, includeCover, mode, range, spreadIds } = this.props;

    if (!spreadIds.length) return null;

    const pages = [];

    const { bleed, trim, marks } = includeBleed
      ? Page.defaultProps
      : { bleed: 0, trim: 0, marks: 'none' };

    if (mode !== 'spread') {
      // `album` mode
      spreadIds.forEach((spreadId, i) => {
        const isFront = i === 0;
        const isBack = i === spreadIds.length - 1;

        const pageOffset = isFront ? 1 : 0;
        const pageLimit = isBack ? 1 : 2;

        for (let page = pageOffset; page < pageLimit; page += 1) {
          let include = true;

          if (!includeCover) {
            if (isFront || isBack) {
              include = false;
            } else if (i === 1 && page === 0) {
              // u3
              include = false;
            } else if (i === spreadIds.length - 2 && page === 1) {
              // u4
              include = false;
            }
          }

          if (include) {
            const key = `spread${i}_page${page}`;

            const contentX = 0;
            const contentY = 0;
            const contentWidth = dimensions.pageWidth;
            const contentHeight = dimensions.pageHeight;

            let spreadX;
            if (i === 0) {
              spreadX = 0;
            } else {
              spreadX = page === 0 ? 0 : -dimensions.pageWidth;
            }

            const spreadY = 0;
            const spreadWidth = dimensions.pageWidth * 2;
            const spreadHeight = dimensions.pageHeight;

            const { pageWidth } = dimensions;
            const { pageHeight } = dimensions;

            const content = (
              <Content
                key={key}
                id={key}
                x={contentX}
                y={contentY}
                width={contentWidth}
                height={contentHeight}
                bleed={bleed}
                skipCoverOverlays={false}
                spine={0}
              >
                <Node
                  id={spreadId}
                  nodeIndex={i}
                  nodeSiblingCount={spreadIds.length}
                  renderingSpreadPositionAndSize={{
                    x: spreadX,
                    y: spreadY,
                    width: spreadWidth,
                    height: spreadHeight,
                  }}
                />
              </Content>
            );

            const num = i * 2 + page;
            if (!range || range.indexOf(num) !== -1) {
              pages.push(
                <Page
                  key={key}
                  id={key}
                  width={pageWidth}
                  height={pageHeight}
                  bleed={bleed}
                  trim={trim}
                  marks={marks}
                >
                  {content}
                </Page>
              );
            }
          }
        }
      });
    } else {
      // `spread` mode
      spreadIds.forEach((spreadId, i) => {
        const num = i + 1;
        if (!range || range.indexOf(num) !== -1) {
          const cover = i === 0 || i === spreadIds.length - 1;
          const page = 0;
          const key = `spread${i}_page${page}`;

          const contentX = 0;
          const contentY = 0;
          const contentWidth = dimensions.pageWidth * (cover ? 1 : 2);
          const contentHeight = dimensions.pageHeight;

          const spreadX = 0;
          const spreadY = 0;
          const spreadWidth = contentWidth;
          const spreadHeight = contentHeight;

          const pageWidth = contentWidth;
          const pageHeight = contentHeight;

          const content = (
            <Content
              key={key}
              id={key}
              x={contentX}
              y={contentY}
              width={contentWidth}
              height={contentHeight}
              bleed={bleed}
            >
              <Node
                id={spreadId}
                nodeIndex={i}
                nodeSiblingCount={spreadIds.length}
                renderingSpreadPositionAndSize={{
                  x: spreadX,
                  y: spreadY,
                  width: spreadWidth,
                  height: spreadHeight,
                }}
              />
            </Content>
          );

          pages.push(
            <Page
              key={key}
              id={key}
              width={pageWidth}
              height={pageHeight}
              bleed={bleed}
              trim={trim}
              marks={marks}
            >
              {content}
            </Page>
          );
        }
      });
    }

    return (
      <div>
        {pages}
        {spreadIds.length && <div className="render-complete" />}
      </div>
    );
  }

  render() {
    const { mode } = this.props;

    // TODO: Make `renderCover()` and `renderAlbum()` components

    if (mode === 'cover') {
      return this.renderCover();
    }

    return this.renderAlbum();
  }
}

const mapStateToProps = state => ({
  spreadIds: getSpreadIds(state),
  sections: getSections(state),
  spine: getAlbumStatisticsForPreflight(state).spine,
});

export default connect(mapStateToProps)(SpreadContainerRenderer);
