import React from 'react';
import { objectOf, string } from 'prop-types';
import { Menu } from 'semantic-ui-react';

import useControlsState from '../../hooks/useControlsState';

function ControlOptions({ controlsStoreKey, options }) {
  const [selectedOption, setSelectedOption] = useControlsState(
    controlsStoreKey
  );

  return Object.keys(options).map(key => (
    <Menu.Item
      icon={key === selectedOption ? 'check' : ''}
      key={key}
      onClick={() => setSelectedOption(key)}
      content={options[key]}
    />
  ));
}

ControlOptions.propTypes = {
  controlsStoreKey: string.isRequired,
  options: objectOf(string.isRequired).isRequired,
};

export default ControlOptions;
