import React from 'react';
import { arrayOf, func } from 'prop-types';
import { Label, Menu } from 'semantic-ui-react';

import { IdListShape, TagShape } from '../../shapes';
import Submenu from './Submenu';

const StickerTemplatesMenu = ({
  stickerTags,
  tags,
  executeWithSelection,
  updateSelectedStickers,
}) => {
  return (
    <Submenu text="Sticker-Template">
      {stickerTags.map(tag => {
        const active = tags.find(tagId => tagId === tag.id);
        return (
          <Menu.Item
            key={tag.id}
            style={{ width: 300 }}
            onClick={() =>
              executeWithSelection(() =>
                updateSelectedStickers({ tags: [tag.id] })
              )
            }
          >
            <Label className="entry" color={active ? 'green' : 'grey'}>
              {tag.title}
            </Label>{' '}
          </Menu.Item>
        );
      })}
      <Menu.Item
        onClick={() =>
          executeWithSelection(() => updateSelectedStickers({ tags: [] }))
        }
      >
        Standard
      </Menu.Item>
    </Submenu>
  );
};

StickerTemplatesMenu.propTypes = {
  tags: IdListShape.isRequired,
  stickerTags: arrayOf(TagShape).isRequired,
  updateSelectedStickers: func.isRequired,
  executeWithSelection: func.isRequired,
};

export default StickerTemplatesMenu;
