// Hook to measure the position or size of a DOM node.
// React will call that callback whenever the ref gets attached to a different node.
// Source https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node

import { useState, useCallback } from 'react';

function useClientRect(initialState = null) {
  const [rect, setRect] = useState(initialState);
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

export default useClientRect;
