import { connect } from 'react-redux';

import { toggleTrashcan } from '../../../modules/controls';
import { showContextMenu } from '../../../modules/menu';
import { getTemplatePreviewSize } from '../../../selectors/controls';
import TemplateItem from './index.dnd';

const mapStateToProps = state => ({
  previewSize: getTemplatePreviewSize(state),
});

const mapDispatchToProps = {
  toggleTrashcan,
  showContextMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateItem);
