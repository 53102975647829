import React from 'react';
import { List, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';

import { getAlbumStatisticsForPreflight } from '../../selectors/legacy';
import { getAllStickersPlaced } from '../../selectors/stickers';
import { AlbumStatisticsForPreflightShape } from '../shapes';

const ExportPreflight = ({
  albumStatistics: { inlaySpreadsCount, spreadsNeeded, spine },
  allStickersPlaced,
}) => (
  <List className="stats">
    <List.Item>
      <Label>
        Seiten (ohne Umschlag):
        <Label.Detail>
          {inlaySpreadsCount} Doppel, {inlaySpreadsCount * 2} Einzel
        </Label.Detail>
      </Label>
    </List.Item>

    <List.Item>
      <Label color={spreadsNeeded ? 'red' : null}>
        Fehlende Doppelseiten für Druck:
        <Label.Detail className="qa-spreads-needed">
          {spreadsNeeded}
        </Label.Detail>
      </Label>
    </List.Item>

    {!allStickersPlaced && (
      <List.Item>
        <Label color="red">Es fehlen noch Sticker-Seiten</Label>
      </List.Item>
    )}

    <List.Item>
      <Label>
        Rückenstärke:
        <Label.Detail>{spine}mm</Label.Detail>
      </Label>
    </List.Item>
  </List>
);

ExportPreflight.propTypes = {
  albumStatistics: AlbumStatisticsForPreflightShape.isRequired,
  allStickersPlaced: bool.isRequired,
};

const mapStateToProps = state => ({
  albumStatistics: getAlbumStatisticsForPreflight(state),
  allStickersPlaced: getAllStickersPlaced(state),
});

export default connect(mapStateToProps)(ExportPreflight);
