import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Popup,
  Menu,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import { connect } from 'react-redux';

import { alignTypeLabels, alignDirection } from '../../constants';
import { updateControls } from '../../modules/controls';
import { objectToOptions } from '../../util/index';
import PageMarginInputs from './PageMarginInputs';

export class AlignButtonsClass extends Component {
  dropdownChange = (_e, { value }) => {
    const { updateControls } = this.props; // eslint-disable-line no-shadow
    updateControls({ alignTo: value });
  };

  alignUseMarginChange = () => {
    const { alignUseMargin, updateControls } = this.props; // eslint-disable-line no-shadow
    updateControls({ alignUseMargin: !alignUseMargin });
  };

  render() {
    const { onClick, alignTo, alignUseMargin } = this.props;

    return (
      <Popup
        trigger={
          <Button size="mini" className="qa-align-options">
            <Icon className="customicon customicon-align-x-start" />
          </Button>
        }
        content={
          <Menu vertical secondary>
            {['x', 'y'].map(axis => (
              <Menu.Item key={axis}>
                <Button.Group size="mini">
                  {Object.keys(alignDirection).map(align => (
                    <Button
                      size="mini"
                      className={`qa-align-${axis}-${align}`}
                      key={`${align}-${axis}`}
                      onClick={e => onClick(e, align, axis)}
                    >
                      <Icon
                        className={`customicon customicon-align-${axis}-${align}`}
                      />
                    </Button>
                  ))}
                </Button.Group>
              </Menu.Item>
            ))}
            <Menu.Item>
              <Dropdown
                size="mini"
                placeholder="Ausrichten an"
                selection
                value={alignTo}
                options={objectToOptions(alignTypeLabels)}
                onChange={this.dropdownChange}
                style={{ width: '100%' }}
              />
            </Menu.Item>
            {(alignTo === 'page' || alignTo === 'spread') && (
              <Menu.Item>
                <Checkbox
                  checked={alignUseMargin}
                  onChange={this.alignUseMarginChange}
                  label="Seitenrand verwenden"
                />
                {alignUseMargin && <PageMarginInputs />}
              </Menu.Item>
            )}
          </Menu>
        }
        on="click"
        position="bottom left"
      />
    );
  }
}

AlignButtonsClass.defaultProps = {
  onClick: null,
};

AlignButtonsClass.propTypes = {
  alignTo: PropTypes.string.isRequired,
  alignUseMargin: PropTypes.bool, // eslint-disable-line react/require-default-props
  onClick: PropTypes.func,
  updateControls: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  alignTo: state.controls.alignTo,
  alignUseMargin: state.controls.alignUseMargin,
});

const mapDispatchToProps = {
  updateControls,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlignButtonsClass);
