import React from 'react';

import { templateTypes } from '../../../constants';
import TemplateMenu from '../../menu/TemplateMenu';
import FolderList from '../FolderList';
import TemplatePanelFolder from './TemplatePanelFolder';

function TemplatePanel() {
  return (
    <div className="flex grow folder-list-template qa-template-content">
      <FolderList menu={<TemplateMenu />}>
        {Object.values(templateTypes).map(templateType => (
          <TemplatePanelFolder key={templateType} templateType={templateType} />
        ))}
      </FolderList>
    </div>
  );
}

export default TemplatePanel;
