import {
  array,
  arrayOf,
  bool,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { EditorState } from 'draft-js';

import { itemTypes, actions, appearanceInputModes } from '../constants';

export const IdShape = oneOfType([string, number]);

export const IdListShape = arrayOf(IdShape);

export const StickerShape = shape({
  id: string,
  face: object, // eslint-disable-line react/forbid-prop-types
  name: string,
  logo: IdShape,
  position: string,
  src: string,
  stickerPlacing: string,
  tags: IdListShape,
  x: number,
  y: number,
});

export const SectionShape = shape({
  id: IdShape,
  name: string,
  stickers: arrayOf(StickerShape),
  type: string,
});

export const TagShape = shape({
  id: number,
  title: string,
});

export const TemplateShape = shape({
  id: number,
  details: shape({
    contentType: string,
    type: string,
  }),
  json: object, // eslint-disable-line react/forbid-prop-types
  svg_preview: string,
  tags: arrayOf(TagShape),
  title: string,
});

export const FilterValueShape = shape({
  min: number,
  mid: number,
  max: number,
});

export const FilterValuesShape = shape({
  blue: FilterValueShape,
  con: number,
  green: FilterValueShape,
  hue: number,
  red: FilterValueShape,
  rgb: FilterValueShape,
  sat: number,
  tone: FilterValueShape,
  tone_blue: FilterValueShape,
  tone_green: FilterValueShape,
  tone_red: FilterValueShape,
});

export const FilterPresetShape = shape({
  filter: FilterValuesShape,
  id: oneOfType([string, number]),
  title: string,
});

/* 'fill' props can contains both strings (for local colors, e.g. '#00FF00') or
   numbers (for swatch-colors: index of the color in the store) */
export const FillShape = oneOfType([string, number]);

export const ElementPropsShape = shape({
  id: string.isRequired,
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
  scale: number,
  fill: FillShape,
  sectionId: string,
  symbol: string,
});

export const SpreadPropsShape = shape({
  id: string,
  nodeSiblingCount: number,
  nodeIndex: number,
  sectionId: string,
});

export const ElementTypeShape = oneOf([
  'Root',
  'Circle',
  'Group',
  'Image',
  'Line',
  'Rectangle',
  'Spread',
  'Sticker',
  'StickerArea',
  'Text',
  'Symbol',
]);

export const WorkspaceShape = shape({
  nodes: object, // eslint-disable-line react/forbid-prop-types
  root: string,
});

export const PointShape = shape({
  x: number.isRequired,
  y: number.isRequired,
});

export const RectShape = shape({
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
});

export const RefShape = shape({
  current: object, // eslint-disable-line react/forbid-prop-types
});

export const LassoShape = shape({
  position: PointShape.isRequired,
  size: PointShape.isRequired,
});

export const EditorStateShape = instanceOf(EditorState);

export const ImageObjectShape = shape({
  id: number,
  blob: oneOfType([object, string]),
  model: string,
  meta: object, // eslint-disable-line react/forbid-prop-types
  details: oneOfType([object, string]),
  dimensions: object, // eslint-disable-line react/forbid-prop-types
  tags: arrayOf(TagShape),
});

export const AlbumStatisticsForPreflightShape = shape({
  inlaySpreadsCount: number.isRequired,
  spreadsNeeded: number.isRequired,
  spine: number.isRequired,
});

export const HotkeyShape = shape({
  key: string,
  ctrlKey: bool,
  shiftKey: bool,
});

export const FileUploadsShape = shape({
  progress: number,
  name: string,
  metaData: object, // eslint-disable-line react/forbid-prop-types
});

export const NodeShape = shape({
  type: ElementTypeShape.isRequired,
  props: ElementPropsShape.isRequired,
  children: IdListShape.isRequired,
});

export const ElementTemplateShape = shape({
  type: ElementTypeShape.isRequired,
});

export const ColorShape = oneOfType([number, string]);

export const ColorsShape = arrayOf(ColorShape);

export const ImageContextShape = shape({
  debug: bool,
  previewSticker: bool.isRequired,
  printing: bool.isRequired,
  rendering: bool.isRequired,
  resolution: string.isRequired,
  stickerRendering: bool,
});
export const ItemTypeShape = oneOf(Object.keys(itemTypes));

export const ContextMenuDataShape = shape({
  id: IdShape,
  itemType: ItemTypeShape,
  clickX: number,
  clickY: number,
});

export const ActionShape = oneOf([...Object.keys(actions)]);

export const ApplicableActionsShape = arrayOf(ActionShape);

export const DropdownOptionsShape = arrayOf(
  shape({
    key: number,
    value: number,
    text: string,
  })
);

export const ReactRouterMatchShape = shape({
  isExact: bool,
  params: object.isRequired, // eslint-disable-line react/forbid-prop-types
  path: string.isRequired,
  url: string.isRequired,
});

export const StickerPositionsShape = arrayOf(
  shape({
    x: number,
    y: number,
    id: string,
  })
);

export const SizeShape = shape({
  width: number.isRequired,
  height: number.isRequired,
});

export const AlbumInfoShape = shape({
  created_at: string.isRequired,
  id: IdShape.isRequired,
  pdfs: shape({
    album: string,
    album_check: string,
    cover: string,
    preview: string,
    spread: string,
    sticker: string,
    sticker_check: string,
  }),
  title: string.isRequired,
});

export const ProjectInfoShape = shape({
  id: string.isRequired,
  name: string.isRequired,
});

export const RawDraftContentStateShape = shape({
  blocks: arrayOf(object), // eslint-disable-line react/forbid-prop-types
  entityMap: objectOf(object), // eslint-disable-line react/forbid-prop-types
});

export const DraftStyleMapShape = objectOf(object);

export const CustomStyleMapShape = object; // eslint-disable-line react/forbid-prop-types

export const StickerLocalTextsShape = shape({
  number: string.isRequired,
  nextnumber: string.isRequired,
  position1: string.isRequired,
  position2: string.isRequired,
  line: string.isRequired,
  line1: string.isRequired,
  line2: string.isRequired,
});

export const AppearanceInputModeShape = oneOf(
  Object.values(appearanceInputModes)
);

// Inspired from https://github.com/Prayer-R/react-router-prop-types
export const ReactRouterLocationShape = shape({
  hash: string.isRequired,
  key: string, // only in createBrowserHistory and createMemoryHistory
  pathname: string.isRequired,
  search: string.isRequired,
  state: oneOfType([array, bool, number, object, string]), // only in createBrowserHistory and createMemoryHistory
});
