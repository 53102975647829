import { itemTypes } from '../constants';
import {
  getLockedElementIds,
  getWorkspace,
  getSections,
  getSelectedStickerIds,
  getTargetNodeId,
} from '../selectors/legacy';
import { updateEditingElement } from './draft';
import { flatten } from '../util/generators';

export const REPLACE = 'selection/REPLACE';
export const CLEAR = 'selection/CLEAR';
export const UPDATE = 'selection/UPDATE';
export const SELECT = 'selection/SELECT';

export const initialState = Object.keys(itemTypes).reduce(
  (acc, cur) => {
    acc[itemTypes[cur]] = [];
    return acc;
  },
  { selectInside: false }
);

export default (state = initialState, action) => {
  switch (action.type) {
    case REPLACE:
      return action.payload;
    case CLEAR:
      return { ...state, ...initialState };
    case UPDATE:
      return { ...state, ...action.payload };
    case SELECT: {
      const { what, ids, selectInside = false } = action;
      const nextState = {
        ...state,
        [what]: ids,
        selectInside,
      };

      // Make sure that no elements and stickers are selected at the same time:
      if (ids.length) {
        if (what === itemTypes.element) {
          // - If some elements have been selected, clear sticker selection
          nextState.sticker = [];
        } else if (what === itemTypes.sticker) {
          // - If some stickers have been selected, clear element selection
          nextState.element = [];
        }
      }

      return nextState;
    }
    default:
      return state;
  }
};

export const setSelectInside = selectInside => dispatch => {
  dispatch({ type: UPDATE, payload: { selectInside } });
  dispatch(updateEditingElement());
};

export const elementSelect = ids => (dispatch, getState) => {
  const state = getState();
  const lockedIds = getLockedElementIds(state);
  const { nodes } = getWorkspace(state);
  const isSingleTextElement = ids.length === 1 && nodes[ids[0]].type === 'Text';
  const { selectInside } = state.selection;
  dispatch({
    type: SELECT,
    what: itemTypes.element,
    ids: ids.filter(id => lockedIds.indexOf(id) === -1),
    // Keep the selectInside-flag if a single element is selected.
    // This allows quicker switching between text-elements:
    selectInside: selectInside && isSingleTextElement,
  });
  dispatch(updateEditingElement());
};

export const spreadSelect = ids => dispatch => {
  dispatch({ type: SELECT, what: itemTypes.spread, ids });
};

export const stickerSelect = ids => dispatch => {
  dispatch({
    type: SELECT,
    what: itemTypes.sticker,
    ids,
    selectInside: true, // Sticker-selection is always "inside"
  });
  dispatch(updateEditingElement());
};

function limitToExistingIds(selection, { nodes }) {
  const availiableNodeIds = Object.keys(nodes);
  const filterIds = ids => ids.filter(id => availiableNodeIds.includes(id));
  return {
    ...selection,
    [itemTypes.element]: filterIds(selection[itemTypes.element]),
    [itemTypes.spread]: filterIds(selection[itemTypes.spread]),
  };
}

const replace = payload => ({ type: REPLACE, payload });

export const withSelection = (tempSelection, restoreSelection, callback) => (
  dispatch,
  getState
) => {
  const { selection: currentSelection } = getState();
  dispatch(replace(tempSelection));
  callback();
  if (restoreSelection) {
    const newWorkspace = getWorkspace(getState());
    dispatch(replace(limitToExistingIds(currentSelection, newWorkspace)));
  }
  dispatch(updateEditingElement());
};

export const clearSelection = () => dispatch => {
  dispatch({ type: CLEAR });
  dispatch(updateEditingElement());
};

export const selectAll = () => (dispatch, getState) => {
  const state = getState();
  const {
    controls: { stickerMode },
  } = state;
  if (!stickerMode) {
    const { nodes } = getWorkspace(state);
    const ids = nodes[getTargetNodeId(state)].children;
    dispatch(elementSelect(ids));
  } else {
    const sections = getSections(state);
    const selectedStickerIds = getSelectedStickerIds(state);
    const selectedSections = sections.filter(
      section =>
        !section.stickers.every(
          sticker => !selectedStickerIds.includes(sticker.id)
        )
    );
    const allSelected = selectedSections.every(section =>
      section.stickers.every(sticker => selectedStickerIds.includes(sticker.id))
    );
    let ids;
    if (allSelected) {
      ids = flatten(
        sections.map(section => section.stickers.map(sticker => sticker.id))
      );
    } else {
      ids = flatten(
        selectedSections.map(section =>
          section.stickers.map(sticker => sticker.id)
        )
      );
    }
    dispatch(stickerSelect(ids));
  }
};
