// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';

import { updateControls } from '../../modules/controls';
import NumberInput from './NumberInput';

const PageMarginInputsClass = ({ pageMargins, dispatch }) => {
  const change = (key, value) => {
    dispatch(updateControls({ pageMargins: { ...pageMargins, [key]: value } }));
  };

  const inputProps = { min: 0, max: 100 };

  return (
    <div className="page-margins">
      <NumberInput
        className="left"
        value={pageMargins.left}
        onChange={({ value }) => change('left', value)}
        {...inputProps}
      />
      <NumberInput
        className="top"
        value={pageMargins.top}
        onChange={({ value }) => change('top', value)}
        {...inputProps}
      />
      <NumberInput
        className="bottom"
        value={pageMargins.bottom}
        onChange={({ value }) => change('bottom', value)}
        {...inputProps}
      />
      <NumberInput
        className="right"
        value={pageMargins.right}
        onChange={({ value }) => change('right', value)}
        {...inputProps}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  pageMargins: state.controls.pageMargins,
});

export default connect(mapStateToProps)(PageMarginInputsClass);
