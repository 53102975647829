import React from 'react';
import ImageLib from 'image-js';
import skmeans from 'skmeans';
import { partition } from 'lodash';

import { getStickers, getSections } from '../selectors/legacy';
import { getImage } from '../selectors/images';
import { updateSticker } from './sections';
import { updateControls, resetView } from './controls';
import { generateSpreadsForColorGroups } from '../util/generators';
import { resolveImage } from '../util/images';
import { resolutions } from '../constants';

const UPDATE = 'groups/UPDATE';

export const initialState = {
  groups: [],
  spreads: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function updateGroups(payload) {
  return dispatch => dispatch({ type: UPDATE, payload });
}

const distance = {
  euclidean(v1, v2) {
    let total = 0;
    for (let i = 0; i < v1.length; i += 1) {
      total += (v2[i] - v1[i]) ** 2;
    }
    return Math.sqrt(total);
  },
  manhattan(v1, v2) {
    let total = 0;
    for (let i = 0; i < v1.length; i += 1) {
      total += Math.abs(v2[i] - v1[i]);
    }
    return total;
  },
  max(v1, v2) {
    let max = 0;
    for (let i = 0; i < v1.length; i += 1) {
      max = Math.max(max, Math.abs(v2[i] - v1[i]));
    }
    return max;
  },
};

const buildGroups = () => (dispatch, getState) => {
  const stickers = getStickers(getState());
  const [imageStickers, otherStickers] = partition(
    stickers,
    sticker => sticker.histogram && !sticker.doubleSticker
  );

  const imageMetrics = imageStickers.map(sticker =>
    sticker.histogram.reduce((acc, cur) => [...acc, ...cur], [])
  );
  let numGroups = Math.round(imageMetrics.length / 20);
  if (numGroups < 2) {
    numGroups = 2;
  }
  const result = skmeans(
    imageMetrics,
    numGroups,
    'kmpp',
    10000,
    distance.manhattan
  );
  const { idxs } = result;

  const groups = idxs.reduce((acc, cur, index) => {
    if (!acc[cur]) {
      acc[cur] = [];
    }
    acc[cur].push(imageStickers[index].id);
    return acc;
  }, []);

  groups.push(otherStickers.map(sticker => sticker.id));

  dispatch(updateGroups({ groups }));
  dispatch(
    updateControls({
      stickerMode: true,
      colorGroupMode: true,
      cropPreview: true,
      enableFilter: true,
    })
  );
  const sections = getSections(getState());
  const spreads = generateSpreadsForColorGroups(sections);
  dispatch(updateGroups({ spreads }));
  dispatch(resetView());
};

export const loadColorMetrics = () => (dispatch, getState) => {
  dispatch(updateControls({ colorGroupMode: false }));

  const imageContext = React.createContext({ resolution: resolutions.small });
  const stickers = getStickers(getState());

  const promises = stickers.map(
    sticker =>
      new Promise((resolve, reject) => {
        const imageObject = getImage(getState(), sticker.image);
        const { src } = resolveImage(imageObject, imageContext);

        if (src && !sticker.histogram) {
          const img = new Image();
          const canvas = document.createElement('canvas');
          img.onload = () => {
            const ctx = canvas.getContext('2d');
            const size = 100;
            const scale = Math.min(size / img.width, size / img.height);
            const w = Math.floor(img.width * scale);
            const h = Math.floor(img.height * scale);
            canvas.width = w;
            canvas.height = h;

            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w, h); // Or at whatever offset you like
            const image = ImageLib.fromCanvas(canvas);
            const histogram = image.getHistograms({
              maxSlots: 8,
              useAlpha: false,
            });

            histogram.pop(); // remove alpha histogram

            const updatedSticker = { ...sticker, histogram };
            dispatch(updateSticker(sticker.id, updatedSticker));
            resolve(updatedSticker);
          };

          img.onerror = reject;

          // const url = "https://api.stickerstars.app/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBamxSIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9e57e050edc34e50a7ad9e83144887402c1f0cf5/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MY21WemFYcGxTU0lNTVRBd2VERXdNQVk2QmtWVU9oQmhkWFJ2WDI5eWFXVnVkRlE9IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--3312adbfbfd0a3814f65779d5f3229de17f91d75/15.jpg";
          img.crossOrigin = 'Anonymous';
          img.src = src;
        } else {
          resolve(sticker);
        }
      })
  );

  return Promise.all(promises);
};

export const buildColorGroups = () => dispatch => {
  alert(
    'Diese Aktion kann bis zu 30 Sekunden dauern und läuft im Hintergrund ab...'
  );
  dispatch(loadColorMetrics()).then(() => {
    dispatch(buildGroups());
  });
};
