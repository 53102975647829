import React from 'react';
import { func, bool, node, string, arrayOf } from 'prop-types';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Table, Icon, List } from 'semantic-ui-react';

import { itemTypes } from '../../constants';
import { FileUploadsShape } from '../shapes';

function Folder({
  children,
  className,
  connectDropTarget,
  filesUploading,
  isOver,
  selected,
  title,
  collapsed,
  onCollapseClick,
}) {
  const hasBody =
    (children && children.length) ||
    (filesUploading && filesUploading.length > 0);

  return connectDropTarget(
    <div className={`${isOver ? 'dnd-hover' : ''} ${className}`}>
      <Table celled padded size="small" className="sections">
        <Table.Header
          onClick={onCollapseClick}
          className={selected ? 'highlight-selected' : ''}
        >
          <Table.Row>
            <Table.HeaderCell>
              <span className="field">
                {hasBody && (
                  <Icon name={collapsed ? 'triangle right' : 'triangle down'} />
                )}
              </span>
              <span className="field">{title}</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {!collapsed && hasBody && (
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <List>
                  {React.Children.map(children, (child, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <List.Item className="folder-item" key={`image${index}`}>
                      {child}
                    </List.Item>
                  ))}

                  {filesUploading.map(file => (
                    <List.Item
                      className="folder-item"
                      key={`upload${file.name}`}
                    >
                      <List.Content>
                        <List.Header>{file.name}</List.Header>
                        uploading...
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}
      </Table>
    </div>
  );
}

Folder.defaultProps = {
  className: '',
  filesUploading: [],
  isOver: false,
  selected: false,
  title: '',
  collapsed: false,
  onCollapseClick: () => {},
};

Folder.propTypes = {
  children: node,
  className: string,
  connectDropTarget: func.isRequired,
  filesUploading: arrayOf(FileUploadsShape),
  isOver: bool,
  selected: bool,
  title: string,
  collapsed: bool,
  onCollapseClick: func,
};

const fileOrImageTarget = {
  drop(props, monitor, component) {
    const { onDrop } = props;
    onDrop({ props, monitor, component });
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget(
  [NativeTypes.FILE, itemTypes.image, itemTypes.template],
  fileOrImageTarget,
  collect
)(Folder);
