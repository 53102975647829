import React from 'react';
import { bool, number } from 'prop-types';

import { dimensions } from '../../../constants';

export const zoomThresholdNoGrid = 1;
export const zoomThresholdCoarseGrid = 2;

function GridPattern({ zoom, gridEnabled }) {
  /**
   * Don't show the grid when too zoomed out.
   *
   * Apparently, Safari will break when the `grid` id is
   * missing, so we're returning an empty pattern here.
   */
  if (!gridEnabled || zoom < zoomThresholdNoGrid) {
    return <pattern id="grid" />;
  }

  /**
   * Set the grid size based on the zoom:
   * draw every grid line when zoomed in
   * draw every 2nd grid line when zoomed out
   */
  const gridSize =
    dimensions.gridSize * (zoom > zoomThresholdCoarseGrid ? 1 : 2);

  const strokeWidth = 0.25 / zoom;

  return (
    <pattern
      id="grid"
      width={gridSize}
      height={gridSize}
      patternUnits="userSpaceOnUse"
    >
      <rect
        fill="none"
        stroke="#000"
        strokeWidth={strokeWidth}
        strokeOpacity="0.25"
        width={gridSize}
        height={gridSize}
      />
    </pattern>
  );
}

GridPattern.propTypes = {
  gridEnabled: bool.isRequired,
  zoom: number.isRequired,
};

export default GridPattern;
