import { connect } from 'react-redux';

import { getSpreadsCount, getSections } from '../../../selectors/legacy';
import { toggleTrashcan, updateControls } from '../../../modules/controls';
import { getImage } from '../../../selectors/images';
import { insertElements } from '../../../actions/workspace';
import { generateSpread } from '../../../util/generators';
import { patchExistingImage } from '../../../actions/images';
import {
  createSticker,
  moveSticker,
  updateSticker,
  createStickerImageAfterDirectUpload,
} from '../../../modules/sections';
import { historyAnchor } from '../../../modules/history';
import { showContextMenu } from '../../../modules/menu';
import StickerInput from './index.dnd';
import { itemTypes } from '../../../constants';
import { selectStickerNumberById } from '../../../selectors/stickers';

const mapStateToProps = (state, ownProps) => ({
  stickerField: state.controls.stickerField,
  sectionDragEnabled: state.controls.sectionDragEnabled,
  sections: getSections(state),
  spreadsCount: getSpreadsCount(state),
  imageObject: getImage(state, ownProps.image),
  stickerNumber: selectStickerNumberById(state, ownProps.id),
});

const mapDispatchToProps = {
  toggleTrashcan,
  historyAnchor,
  moveSticker,
  insertElements,
  generateSpread,
  patchExistingImage,
  createSticker,
  updateSticker,
  updateControls,
  createStickerImageAfterDirectUpload,
  showContextMenu,
};

function mergeProps(
  stateProps,
  { showContextMenu, ...dispatchProps }, // eslint-disable-line no-shadow
  ownProps
) {
  const { id } = ownProps;
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onContextMenu: event => showContextMenu(event, itemTypes.sticker, id),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StickerInput);
