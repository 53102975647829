import React from 'react';
import { arrayOf, func, oneOf } from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

import { importSourceTypes } from '../../../constants';
import { AlbumInfoShape, IdShape, ProjectInfoShape } from '../../shapes';
import FilterableList from './FilterableList';

const SetSource = ({
  projects,
  albums,
  onProjectSelect,
  onAlbumSelect,
  project,
  album,
  source,
  onSetSource,
}) => {
  const handleChange = (e, { value }) => {
    onSetSource(value);

    // Clear selection to avoid selecting an album with id of a PZ-project or vice-versa
    onAlbumSelect('');
    onProjectSelect('');
  };

  return (
    <>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Radio
              label="Projekt aus der Stickerzentrale"
              name="radioGroup"
              value={importSourceTypes.appProject}
              checked={source === importSourceTypes.appProject}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label="Bestehendes Editor-Album"
              name="radioGroup"
              value={importSourceTypes.editorAlbum}
              checked={source === importSourceTypes.editorAlbum}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {source === importSourceTypes.appProject && (
        <FilterableList
          activeItemId={project}
          items={projects}
          onSelect={onProjectSelect}
        />
      )}
      {source === importSourceTypes.editorAlbum && (
        <FilterableList
          activeItemId={album}
          items={albums}
          onSelect={onAlbumSelect}
        />
      )}
    </>
  );
};

SetSource.defaultProps = {
  project: null,
  album: null,
  source: null,
};

SetSource.propTypes = {
  project: IdShape,
  album: IdShape,
  source: oneOf(Object.values(importSourceTypes)),
  projects: arrayOf(ProjectInfoShape).isRequired,
  albums: arrayOf(AlbumInfoShape).isRequired,
  onProjectSelect: func.isRequired,
  onAlbumSelect: func.isRequired,
  onSetSource: func.isRequired,
};

export default SetSource;
