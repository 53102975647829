import {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { createRef } from 'react';
import { Icon, Input, Table } from 'semantic-ui-react';

import { generateSpread } from '../../../util/generators';
import { generateId } from '../../../util/index';
import { FileUploadsShape, IdShape, StickerShape } from '../../shapes';
import SectionLoader from '../SectionLoader';
import SectionPlaceholder from './SectionPlaceholder';
import StickersList from './StickersList';

class SectionInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      name: props.placeholder ? '' : props.name,
    };
    this.titleInputRef = createRef();
  }

  headerClick = e => {
    const { editing } = this.state;

    if (editing) {
      return;
    }

    const { updateControls } = this.props;

    e.preventDefault();
    e.stopPropagation();

    this.setState({ editing: true }, () => {
      /** Select the current title */
      this.titleInputRef.current.focus();
      this.titleInputRef.current.select();
    });
    updateControls({ sectionDragEnabled: false });
  };

  nameChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  nameKeyPress = e => {
    if (e.key === 'Enter') {
      this.nameBlur(e);
    }
  };

  nameBlur = e => {
    const {
      updateControls,
      createSection,
      insertElements,
      historyAnchor,
      albumUnsaved,
      updateSection,
      id,
      placeholder,
      spreadsCount,
    } = this.props;
    const { name } = this.state;

    e.preventDefault();
    e.stopPropagation();

    this.setState({ editing: false });
    updateControls({ sectionDragEnabled: true });

    if (placeholder) {
      if (name !== '') {
        const newId = generateId();

        const newSection = {
          id: newId,
          name,
          stickers: [],
        };

        // Insert a spread for the new section before the last spread
        const index = spreadsCount - 1;

        createSection(newSection);
        insertElements([generateSpread(newId)], 'root', index);
        historyAnchor();

        this.setState({ name: '' });

        albumUnsaved();
      }
    } else {
      updateSection(id, { name });
      historyAnchor();
      albumUnsaved();
    }
  };

  render() {
    const {
      id,
      isOver,
      isLoading,
      name,
      stickers,
      onCollapseClick,
      open,
      placeholder,
      dragRef,
      stickerUploads,
      onContextMenu,
      createSection,
    } = this.props;
    const { editing, name: stateName } = this.state;

    if (isLoading) {
      return <SectionLoader />;
    }

    /** We need the slug for our feature tests for now */
    const slug = (name && name.replace(/ /g, '-').toLowerCase()) || '';

    if (placeholder) {
      return (
        <SectionPlaceholder
          onHeaderClick={this.headerClick}
          onNameChange={this.nameChange}
          onNameBlur={this.nameBlur}
          onNameKeyPress={this.nameKeyPress}
          editing={editing}
          name={stateName}
          titleInputRef={this.titleInputRef}
          createSection={createSection}
        />
      );
    }

    let nonEmptyName = ' ';
    if (name !== '') {
      nonEmptyName = name;
    }

    return (
      <div ref={dragRef}>
        <Table
          celled
          size="small"
          className={`sections${isOver ? ' dnd-hover' : ''} ${!placeholder &&
            `qa-section-item qa-section-${slug} qa-section-${id}`}`}
        >
          <Table.Header onContextMenu={onContextMenu}>
            <Table.Row>
              <Table.HeaderCell
                className={
                  !placeholder &&
                  `qa-existing-section-header qa-section-header-${slug} qa-section-header-${id}`
                }
              >
                {onCollapseClick && (
                  <span className="field section-triangle">
                    <Icon
                      name={open ? 'triangle down' : 'triangle right'}
                      onClick={onCollapseClick}
                    />
                  </span>
                )}
                <span
                  className={`field section-title qa-edit-section-title-${id}`}
                  onClick={this.headerClick}
                  role="button"
                  onKeyUp={this.headerClick}
                  tabIndex={0}
                >
                  {editing ? (
                    <Input
                      placeholder="Name..."
                      autoFocus={false}
                      onChange={this.nameChange}
                      onBlur={this.nameBlur}
                      onKeyPress={this.nameKeyPress}
                      value={stateName}
                      ref={this.titleInputRef}
                      name="section_name"
                    />
                  ) : (
                    nonEmptyName
                  )}
                </span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {open && !placeholder && (
            <StickersList
              sectionId={id}
              stickers={stickers}
              stickerUploads={stickerUploads}
            />
          )}
        </Table>
      </div>
    );
  }
}

SectionInput.defaultProps = {
  id: null,
  name: '',
  stickers: null,
  placeholder: false,
  onCollapseClick: null,
  open: false,
};

SectionInput.propTypes = {
  id: IdShape,
  isOver: bool.isRequired,
  isLoading: bool.isRequired,
  name: string,
  placeholder: bool,
  stickers: arrayOf(StickerShape),
  dragRef: oneOfType([elementType, object]).isRequired,
  updateSection: func.isRequired,
  updateControls: func.isRequired,
  createSection: func.isRequired,
  insertElements: func.isRequired,
  historyAnchor: func.isRequired,
  albumUnsaved: func.isRequired,
  spreadsCount: number.isRequired,
  stickerUploads: FileUploadsShape.isRequired,
  open: bool,
  onCollapseClick: func,
  onContextMenu: func.isRequired,
};

export default SectionInput;
