import lodash from 'lodash';
import { DirectUpload } from 'activestorage';

import {
  getSpreadNodes,
  getSections,
  getSelectedStickerIds,
  getSelectedSectionIds,
  getSelectedSections,
  getSelectedStickers,
} from '../selectors/legacy';
import { getImage } from '../selectors/images';
import { dimensions, UPLOADS_URL, faceAlign, resolutions } from '../constants';
import { flatten, reorderSpreadsAccordingToSections } from '../util/generators';
import { resolveImage } from '../util/images';
import Point from '../util/Point';
import { createImage } from './images';
import {
  postImage,
  patchExistingImage,
  fillImageDimensions,
} from '../actions/images';
import { reorderSpreads } from '../actions/workspace';
import { downloadFile } from '../util/index';
import { updateControls } from './controls';
import { stickerSelect } from './selection';
import { historyAnchor } from './history';
import {
  transformRectCorners,
  axisAlignedBoundingRect,
  elementMatrix,
} from '../util/geometry';

const CREATE = 'sections/CREATE';
const UPDATE = 'sections/UPDATE';
const UPDATE_ALL = 'sections/UPDATE_ALL';
const DELETE = 'sections/DELETE';
const REPLACE = 'sections/REPLACE';
const MOVE = 'sections/MOVE';
const STICKER_CREATE = 'sticker/CREATE';
const STICKER_UPDATE = 'sticker/UPDATE';
const STICKER_UPDATES_BY_OBJECT = 'sticker/STICKER_UPDATES_BY_OBJECT';
const STICKER_DELETE = 'sticker/DELETE';
const STICKER_MOVE = 'sticker/MOVE';

export const initialState = [];

/**
 * Helper to apply a action to all selected stickers
 */
const forSelection = action => (dispatch, getState) => {
  return getSelectedStickers(getState()).forEach(item => {
    dispatch(action(item));
  });
};

// REDUCER

function nextState(state, action) {
  switch (action.type) {
    case CREATE:
      if (typeof action.payload.id === 'undefined') {
        console.error('id not set during create 123');
        return state;
      }
      return [...state, action.payload];

    case UPDATE:
      const updateIndex = state.findIndex(item => item.id === action.id);
      if (updateIndex === -1) {
        console.error('id not found during update');
        return state;
      }
      const nextItem = { ...state[updateIndex], ...action.payload };
      return [
        ...state.slice(0, updateIndex),
        nextItem,
        ...state.slice(updateIndex + 1),
      ];

    case UPDATE_ALL:
      return state.map(section => ({ ...section, ...action.payload }));
    case DELETE:
      const deleteIndex = state.findIndex(item => item.id === action.id);
      if (deleteIndex === -1) {
        console.error('id not found during delete');
        return state;
      }
      return [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];

    case MOVE:
      const moveIndex = state.findIndex(item => item.id === action.id);
      if (moveIndex === -1) {
        console.error('id not found during move');
        return state;
      }
      const item = state[moveIndex];
      const nextState = [
        ...state.slice(0, moveIndex),
        ...state.slice(moveIndex + 1),
      ];
      nextState.splice(action.index, 0, item);
      return nextState;

    case STICKER_CREATE:
      const { sectionId } = action;
      return state.map(section => ({
        ...section,
        stickers:
          section.id === sectionId
            ? [...section.stickers, action.payload]
            : section.stickers,
      }));

    case STICKER_MOVE: {
      const { id, index, newSectionId } = action;
      let stickerIndex = -1;
      const sourceIndex = state.findIndex(item => {
        stickerIndex = item.stickers.findIndex(
          sticker => sticker.id === action.id
        );
        return stickerIndex !== -1;
      });
      let targetIndex = -1;
      if (!newSectionId) {
        targetIndex = sourceIndex;
      } else {
        targetIndex = state.findIndex(item => item.id === action.newSectionId);
      }
      if (sourceIndex === targetIndex) {
        // reodering within same section
        return state.map((section, sectionIndex) => {
          if (sectionIndex === targetIndex) {
            let { stickers } = section;
            const sticker = stickers[stickerIndex];
            stickers = [
              ...stickers.slice(0, stickerIndex),
              ...stickers.slice(stickerIndex + 1),
            ];
            stickers.splice(index, 0, sticker);
            return { ...section, stickers };
          }
          return section;
        });
      }
      // moving from one section to another
      let moving = [];
      state = state.map(section => {
        const [out, stickers] = lodash.partition(
          section.stickers,
          sticker => sticker.id === id
        );
        moving = [...moving, ...out];
        return { ...section, stickers };
      });
      state = state.map(section => {
        if (section.id === newSectionId) {
          return {
            ...section,
            stickers: [
              ...section.stickers.slice(0, index),
              ...moving,
              ...section.stickers.slice(index),
            ],
          };
        }
        return section;
      });
      return state;
    }

    case STICKER_DELETE:
      return state.map(section => {
        return {
          ...section,
          stickers: section.stickers.filter(
            sticker => sticker.id !== action.id
          ),
        };
      });

    case STICKER_UPDATE:
      let { ids } = action;
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      return state.map(section => {
        return {
          ...section,
          stickers: section.stickers.map(sticker => {
            if (ids.includes(sticker.id)) {
              return { ...sticker, ...action.payload };
            }
            return sticker;
          }),
        };
      });

    case STICKER_UPDATES_BY_OBJECT:
      const { deltas } = action;
      const deltaIds = Object.keys(deltas);
      return state.map(section => {
        return {
          ...section,
          stickers: section.stickers.map(sticker => {
            if (deltaIds.indexOf(sticker.id) !== -1) {
              return { ...sticker, ...deltas[sticker.id] };
            }
            return sticker;
          }),
        };
      });

    case REPLACE:
      return action.payload;
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case CREATE:
    case UPDATE:
    case UPDATE_ALL:
    case DELETE:
    case REPLACE:
    case MOVE:
    case STICKER_CREATE:
    case STICKER_UPDATE:
    case STICKER_UPDATES_BY_OBJECT:
    case STICKER_DELETE:
    case STICKER_MOVE:
      return nextState(state, action);
    default:
      return state;
  }
};

export function createSection(payload) {
  return dispatch => dispatch({ type: CREATE, payload });
}

export function updateSection(id, payload) {
  return dispatch => dispatch({ type: UPDATE, id, payload });
}

export function updateAllSections(payload) {
  return dispatch => dispatch({ type: UPDATE_ALL, payload });
}

export const moveSection = (id, index) => (dispatch, getState) => {
  dispatch({ type: MOVE, id, index });
  const spreads = getSpreadNodes(getState());
  const sections = getSections(getState());
  const newSpreadIds = reorderSpreadsAccordingToSections(spreads, sections);
  dispatch(reorderSpreads(newSpreadIds));
};

export function replaceSections(payload) {
  return dispatch => dispatch({ type: REPLACE, payload });
}

export function createSticker(sectionId, payload) {
  return dispatch => dispatch({ type: STICKER_CREATE, sectionId, payload });
}

/**
 * Creates a new sticker after a successful direct-upload.
 * @param {} sectionId The ID of the section where the new sticker should be appended.
 * @param {*} blobId The blobId returned by the direct-upload.
 * @param {*} sticker The sticker data e. g. id, name.
 */
export const createStickerAfterDirectUpload = ({
  sectionId,
  blobId,
  albumId,
  sticker,
}) => dispatch => {
  postImage({
    image: {
      blob_id: blobId,
      model: 'sticker',
    },
    albumId,
  })
    .then(response => dispatch(fillImageDimensions(response)))
    .then(({ data: { image } }) => {
      dispatch(createImage(image));
      dispatch(
        createSticker(sectionId, {
          ...sticker,
          image: image.id,
        })
      );
    });
};

/**
 * Creates a new sticker image for an existing sticker after a successful direct-upload.
 * @param {} stickerId The ID of the sticker
 * @param {*} blobId The blobId returned by the direct-upload.
 */
export const createStickerImageAfterDirectUpload = (stickerId, blobId) => (
  dispatch,
  getState
) => {
  const {
    albums: { currentAlbum: albumId },
  } = getState();

  postImage({
    image: {
      blob_id: blobId,
      model: 'sticker',
    },
    albumId,
  })
    .then(response => dispatch(fillImageDimensions(response)))
    .then(({ data: { image } }) => {
      dispatch(createImage(image));
      dispatch(updateSticker(stickerId, { image: image.id }));
    });
};

export function updateSticker(ids, payload) {
  return dispatch => dispatch({ type: STICKER_UPDATE, ids, payload });
}

export const updateSelectedStickers = payload => (dispatch, getState) => {
  const ids = getSelectedStickerIds(getState());
  dispatch(updateSticker(ids, payload));
};

export const setStickerLogo = (imageId, logo = 'logo') =>
  updateSelectedStickers({ [logo]: imageId });

export function updateStickersByObject(deltas) {
  return dispatch => dispatch({ type: STICKER_UPDATES_BY_OBJECT, deltas });
}

export const moveSticker = (id, index, newSectionId = null) => dispatch =>
  dispatch({ type: STICKER_MOVE, id, index, newSectionId });

function getStickerPositionFromFaceData(state, sticker) {
  const { face, doubleSticker } = sticker;
  if (!face || !face.boundingbox || doubleSticker) {
    return sticker;
  }

  const { agerange } = face;
  let { boundingbox } = face;

  let targetWidth = faceAlign.width;
  if (agerange && agerange.low < faceAlign.kidsAgeThreshold) {
    targetWidth = faceAlign.widthKids;
  }

  const { meta } = getImage(state, sticker.image);
  const rw = boundingbox.width * meta.width;
  const rh = boundingbox.height * meta.height;

  let px;
  let py;
  let sw;
  let sh;
  let cx;
  let cy;
  let cscale;
  let crotation;
  let flipY;

  if (rw > rh) {
    crotation = -90;
    flipY = -1;

    // flip bounding box
    const r = meta.width / meta.height;
    boundingbox = {
      width: boundingbox.height / r,
      height: boundingbox.width * r,
      left: boundingbox.top / r,
      top: boundingbox.left * r,
    };
  } else {
    crotation = 0;
    flipY = 1;
  }

  const ratio = Math.min(
    dimensions.stickerWidth / meta.width,
    dimensions.stickerHeight / meta.height
  );

  // content scale
  cscale = targetWidth / boundingbox.width;

  // true width/height
  const tw = meta.width * ratio;
  const th = meta.height * ratio;
  // pivot - to be centered
  px = boundingbox.left + boundingbox.width / 2;
  py = boundingbox.top + boundingbox.height / 2;

  // scaled width/height
  sw = tw * cscale;
  sh = th * cscale;
  // pivot in mm
  px *= sw;
  py *= sh;
  // content offset in mm
  cx = dimensions.stickerWidth * 0.5 - px;
  cy = dimensions.stickerHeight * faceAlign.top - py * flipY;
  return { cx, cy, cscale, crotation };
}

export const alignStickersUsingFaceData = () => (dispatch, getState) => {
  const state = getState();
  const sections = getSections(state);
  const newSections = sections.map(section => ({
    ...section,
    stickers: section.stickers.map(sticker => ({
      ...sticker,
      ...getStickerPositionFromFaceData(state, sticker),
    })),
  }));
  dispatch(replaceSections(newSections));
  dispatch(historyAnchor());
};

export const replaceSectionText = (search, replace, scope) => (
  dispatch,
  getState
) => {
  search = search.split('\\').join('\\\\');
  const pattern = new RegExp(search, 'gi');

  let count = 0;

  const doReplace = s => {
    return s.replace(pattern, () => {
      count += 1;
      return replace;
    });
  };
  const state = getState();
  let sections = getSections(state);

  sections = sections.map(section => {
    switch (scope) {
      default:
        return section;
      case 'sticker_section':
        return { ...section, name: doReplace(section.name) };
      case 'sticker_position':
      case 'sticker_name':
        return {
          ...section,
          stickers: section.stickers.map(sticker => {
            switch (scope) {
              case 'sticker_position':
                return { ...sticker, position: doReplace(sticker.position) };
              case 'sticker_name':
                return { ...sticker, name: doReplace(sticker.name) };
              default:
                return sticker;
            }
          }),
        };
    }
  });

  // TODO dont show this info in an action / use a modal
  alert(`${count} Stellen ersetzt`);

  dispatch(replaceSections(sections));
};

const scaleStickers = (delta = 1) => (dispatch, getState) => {
  const state = getState();
  const sections = getSections(state);
  const selectedStickerIds = getSelectedStickerIds(state);

  const selectedStickers = flatten(
    sections.map(section =>
      section.stickers.filter(
        sticker => selectedStickerIds.indexOf(sticker.id) !== -1
      )
    )
  );

  const deltas = selectedStickers.reduce(function(acc, cur) {
    let { cscale, image: imageId, crotation, cx, cy } = cur;
    if (typeof cx === 'undefined') cx = 0;
    if (typeof cy === 'undefined') cy = 0;
    if (typeof cscale === 'undefined') cscale = 1;
    if (typeof crotation === 'undefined') crotation = 0;

    const imageObject = getImage(state, imageId);
    if (imageObject) {
      const { meta } = imageObject;
      const ratio = Math.min(
        dimensions.stickerWidth / meta.width,
        dimensions.stickerHeight / meta.height
      );
      const c = new Point(meta.width * ratio, meta.height * ratio).scaled(-0.5);
      c.rotate((crotation / 180) * Math.PI);
      const before = c.scaled(cscale);
      cscale *= 1 + delta * 0.05;
      const after = c.scaled(cscale);
      after.subtract(before);
      cx += after.x;
      cy += after.y;
      acc[cur.id] = { cscale, cx, cy };
    }
    return acc;
  }, {});

  dispatch(updateStickersByObject(deltas));
};

export const scaleStickersOut = () => scaleStickers(-1);
export const scaleStickersIn = () => scaleStickers(1);

export const clearStickerLogo = () => (dispatch, getState) => {
  const selectedStickerIds = getSelectedStickerIds(getState());
  dispatch(updateSticker(selectedStickerIds, { logo: null }));
};

const zoomToElements = ids => dispatch => {
  const allCorners = ids.reduce((corners, id) => {
    const element = document.querySelector(`[data-id='${id}']`);
    if (!element) {
      return corners;
    }
    const { width, height } = element.getBBox();
    const matrix = elementMatrix(id);
    const itemCorners = transformRectCorners(matrix, width, height);
    return [...corners, ...itemCorners];
  }, []);
  if (allCorners.length === 0) {
    return;
  }
  const area = axisAlignedBoundingRect(allCorners);
  const { clientWidth, clientHeight } = document.querySelector('.viewport');
  const zoom = Math.min(clientWidth / area.width, clientHeight / area.height);
  const pan = {
    x: area.x,
    y: area.y,
  };
  dispatch(updateControls({ pan, zoom }));
};

const zoomToSectionIds = sectionIds => (dispatch, getState) => {
  const state = getState();
  const spreadIds = getSpreadNodes(state)
    .filter(spread => sectionIds.includes(spread.props.sectionId))
    .map(spread => spread.props.id);
  if (spreadIds.length) {
    dispatch(zoomToElements(spreadIds));
  } else {
    alert('Dieses Team wurde noch nicht plaziert.');
  }
};

export const zoomSection = () => (dispatch, getState) => {
  const sectionIds = getSelectedSectionIds(getState());
  dispatch(zoomToSectionIds(sectionIds));
};

export const zoomSticker = () => (dispatch, getState) => {
  const state = getState();
  const stickerIds = getSelectedStickerIds(state);
  if (stickerIds.length === 0) {
    return;
  }
  const [firstStickerId] = stickerIds;
  const node = document.querySelector(`[data-id='${firstStickerId}']`);
  if (node) {
    dispatch(zoomToElements(stickerIds));
    return;
  }
  // Sticker is not visible currently - zoom to section first:
  const sections = getSections(state);
  function getSectionsIdFromStickerIds(stickerIds) {
    return sections
      .filter(section =>
        section.stickers.find(sticker => stickerIds.includes(sticker.id))
      )
      .map(section => section.id);
  }
  const sectionIds = getSectionsIdFromStickerIds(stickerIds);
  dispatch(zoomToSectionIds(sectionIds));
  setTimeout(() => {
    dispatch(zoomToElements(stickerIds));
  }, 0);
};

export const selectStickersInSection = () => (dispatch, getState) => {
  const ids = flatten(
    getSelectedSections(getState()).map(section =>
      section.stickers.map(sticker => sticker.id)
    )
  );
  dispatch(stickerSelect(ids));
};

export const downloadSticker = () => (dispatch, getState) => {
  const state = getState();
  const selectedStickers = getSelectedStickers(state);
  const { image: imageId } = selectedStickers[0];
  const imageObject = getImage(state, imageId);
  if (!imageObject) {
    return;
  }
  const { src, filename } = resolveImage(imageObject, resolutions.original);
  downloadFile(src, filename);
};

export const uploadSticker = () => dispatch =>
  dispatch(
    forSelection(item => dispatch => {
      // Todo: Move upload logic to a helper, share this logic with image/stock-uploading
      const uploadInput = document.createElement('input');

      const change = () => {
        Array.from(uploadInput.files).forEach(file => {
          const upload = new DirectUpload(file, UPLOADS_URL);
          upload.create((error, blob) => {
            if (error) {
              console.error(error);
            } else {
              if (item.image) {
                dispatch(patchExistingImage(item.image, { blob_id: blob.id }));
                dispatch(updateSticker(item.id, { face: null }));
              } else {
                dispatch(createStickerImageAfterDirectUpload(item.id, blob.id));
              }
              dispatch(historyAnchor());
            }
          });
        });
        document.documentElement.removeChild(uploadInput);
      };

      const attrs = { type: 'file', multiple: false };
      Object.keys(attrs).forEach(key => {
        uploadInput.setAttribute(key, attrs[key]);
      });
      uploadInput.onchange = change;
      document.documentElement.appendChild(uploadInput);
      uploadInput.click();
    })
  );

export const autoAlignSticker = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    forSelection(sticker => dispatch => {
      dispatch(
        updateSticker(
          [sticker.id],
          getStickerPositionFromFaceData(state, sticker)
        )
      );
    })
  );
};

export const nudgeStickers = (dx, dy) => (dispatch, getState) => {
  const propsDeltaMap = {};

  const stickers = getSelectedStickers(getState());

  stickers.forEach(item => {
    const cx = (item.cx || 0) + dx;
    const cy = (item.cy || 0) + dy;
    propsDeltaMap[item.id] = { cx, cy };
  });
  dispatch(updateStickersByObject(propsDeltaMap));
  dispatch(historyAnchor());
};
