import { string } from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import {
  getGlobalStyleMap,
  getLocalStyleMap,
} from '../../../../selectors/legacy';
import { emptyRawContentState } from '../../../../util/draftjs';
import { DraftStyleMapShape, RawDraftContentStateShape } from '../../../shapes';
import FreeText from './FreeText';
import SymbolText from './SymbolText';
import { staticStyleMap } from './textConstants';

function Text(props) {
  const { symbol, text, globalStyleMap } = props;
  const localStyleMap = useMemo(() => getLocalStyleMap(text), [text]);

  /**
   * The `customStyleMap` is a Draft-JS property that maps the names of
   * inline-styles to CSS-objects, e.g. `{ FONT-ARIAL: { font-family: "Arial" } }`
   *
   * There are 3 sources for these styles:
   * - staticStyleMap: created just once initialy and contains styles, that never change,
   * such as font-family, -size, lineheight, since these values are limited to a defined set.
   *
   * - globalStyleMap: derived from state.colors via a regular redux-selector.
   *
   * - localStyleMap: some styles like a local color or letter-spacing can have too many
   * different values, so in these cases they are created dynamically based on the values
   * in the content-state.
   */
  const customStyleMap = useMemo(
    () => ({ ...staticStyleMap, ...globalStyleMap, ...localStyleMap }),
    [globalStyleMap, localStyleMap]
  );

  const Component = symbol ? SymbolText : FreeText;
  return <Component {...props} customStyleMap={customStyleMap} />;
}

Text.defaultProps = {
  symbol: null,
  text: emptyRawContentState,
};

Text.propTypes = {
  symbol: string,
  text: RawDraftContentStateShape,
  globalStyleMap: DraftStyleMapShape.isRequired,
};

const mapStateToProps = state => ({
  globalStyleMap: getGlobalStyleMap(state),
});

export default connect(mapStateToProps)(Text);
