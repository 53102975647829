import { UPDATE } from '../actions/sync';

const initialState = {
  stickerIds: [],
  queue: [],
  intervalId: null,
  directoryHandle: null,
  selectionOnly: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
