import Color from 'color';
import { bool, func, string } from 'prop-types';
import ColorPicker from 'rc-color-picker';
import React, { useContext, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Button } from 'semantic-ui-react';

import { ColorShape } from '../../shapes';
import NestedOverlayContext from '../NestedOverlayTrigger/NestedOverlayContext';

import 'rc-color-picker/assets/index.css';

function ColorInput({
  icon,
  editable,
  onChange,
  onSelect,
  title,
  selected,
  className,
  colorValue,
}) {
  const [show, setShow] = useState(false);
  const { setRootClose } = useContext(NestedOverlayContext);
  const [localRootClose, setLocalRootClose] = useState(false);

  const classNames = [className, 'button-swatch'];
  if (selected) {
    classNames.push('highlight-selected');
  } else {
    classNames.push('highlight-none');
  }
  if (colorValue === null) {
    classNames.push('background-none');
  } else if (Color(colorValue).luminosity() > 0.7) {
    classNames.push('highlight-shadow');
  }

  const hasEyeDropperFeature = !!window.EyeDropper;

  const openEyeDropper = () => {
    const eyeDropper = new window.EyeDropper();

    eyeDropper
      .open()
      .then(result => {
        onChange({ color: result.sRGBHex });
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  return (
    <div
      title={title}
      style={{ display: 'inline-block', verticalAlign: 'top' }}
    >
      <Button
        className={`${classNames.join(' ')}`}
        style={{ background: colorValue }}
        onClick={onSelect}
      />
      <div>
        <OverlayTrigger
          trigger="click"
          placement="bottom-end"
          show={show}
          transition={false}
          onToggle={() => {
            setShow(!show);
            setLocalRootClose(!show);
          }}
          onHide={() => setShow(false)}
          disabled={!editable}
          rootClose={localRootClose}
          overlay={
            <Popover>
              <Popover.Content>
                <div
                  role="button"
                  tabIndex={-1}
                  onMouseDown={() => setRootClose(false)}
                  onMouseUp={() => setTimeout(() => setRootClose(true), 0)}
                >
                  <ColorPicker.Panel
                    color={colorValue}
                    enableAlpha={false}
                    onChange={onChange}
                  />
                  {hasEyeDropperFeature && (
                    <Button
                      className="ml-2"
                      basic
                      icon="eye dropper"
                      onClick={openEyeDropper}
                    />
                  )}
                </div>
              </Popover.Content>
            </Popover>
          }
        >
          <Button compact icon={icon} disabled={!editable} />
        </OverlayTrigger>
      </div>
    </div>
  );
}

ColorInput.defaultProps = {
  colorValue: null,
  className: '',
  onSelect: null,
  onChange: null,
  editable: true,
  title: null,
  selected: false,
};

ColorInput.propTypes = {
  colorValue: ColorShape,
  className: string,
  onChange: func,
  onSelect: func,
  editable: bool,
  title: string,
  selected: bool,
  icon: string.isRequired,
};

export default ColorInput;
