import React, { useContext } from 'react';
import { bool, number, string, node, func } from 'prop-types';

import { colorValues, itemTypes } from '../../../constants';
import { ImageContext } from '../../ImageContext';

const BaseElement = ({
  id,
  type,
  children,
  x,
  y,
  rotation,
  scale,
  width,
  height,
  contentType,
  isElementHighlighted,
  visibleSpreadCount,
  stickerMode,
  isElementInteractive,
  showContextMenu,
}) => {
  const { printing } = useContext(ImageContext);

  const handleContextMenu = showContextMenu
    ? e => showContextMenu(e, itemTypes.element, id)
    : null;

  // Display performance: If its a group, always show its children. Otherwise:
  if (type !== 'Group') {
    // - don't show anything except for StickerAreas in stickerMode
    if (stickerMode && type !== 'StickerArea') {
      return null;
    }
    // - don't show anything except background images if the viewport shows more than 6 pages at a time
    if (
      visibleSpreadCount > 6 &&
      !(type === 'Image' && contentType === 'background')
    ) {
      return null;
    }
  }

  return (
    <g
      key={id}
      id={id}
      data-id={id}
      transform={`translate(${x},${y}) rotate(${rotation}) scale(${scale})`}
      className={`${
        isElementInteractive ? 'element' : ''
      } container qa-${type}-node qa-element-${id}`}
      onContextMenu={
        isElementInteractive && !printing ? handleContextMenu : null
      }
    >
      {/* This allows empty elements (e.g. a Line or a Rectangle without a filling) to be clickable */}
      <rect
        className="box qa-element-box"
        width={width}
        height={height}
        fill="none"
      />

      {children}
      {isElementHighlighted && (
        <rect
          className="highlight-box qa-element-highlight-box"
          width={width}
          height={height}
          stroke={colorValues.selection}
          fill="none"
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
        />
      )}
    </g>
  );
};

BaseElement.defaultProps = {
  x: 0,
  y: 0,
  rotation: 0,
  scale: 1,
  width: 0,
  height: 0,
  contentType: null,
  stickerMode: false,
  showContextMenu: null,
};

BaseElement.propTypes = {
  id: string.isRequired,
  type: string.isRequired,
  children: node.isRequired,
  x: number,
  y: number,
  rotation: number,
  scale: number,
  width: number,
  height: number,
  contentType: string,
  isElementHighlighted: bool.isRequired,
  isElementInteractive: bool.isRequired,
  stickerMode: bool,

  // From mapDispatchToProps in Node
  showContextMenu: func,

  visibleSpreadCount: number.isRequired,
};

export default BaseElement;
