import React from 'react';

import { dimensions } from '../../../../constants';
import { StickerLocalTextsShape, StickerShape } from '../../../shapes';
import StickerNumber from './StickerNumber';

function StickerCellSingle({ sticker, localTexts: { line1, line2 } }) {
  return (
    <g>
      <rect
        x={0.5}
        y={0.5}
        stroke="white"
        strokeWidth={0.5}
        strokeLinecap="square"
        fill="black"
        fillOpacity={0.2}
        width={dimensions.stickerWidth - 1}
        height={dimensions.stickerHeight - 1}
        className="inside"
      />

      <text
        fontFamily="Gilroy"
        fontSize={8}
        fill="white"
        fillOpacity={1}
        x={dimensions.stickerWidth / 2}
        y={dimensions.stickerWidth / 2}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        <StickerNumber stickerId={sticker.id} />
      </text>

      {!sticker.textless && !sticker.subtitle && (
        <text
          fontFamily="Gilroy"
          fontSize={4}
          fill="white"
          fillOpacity={1}
          y={dimensions.stickerHeight - 20}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <tspan x={dimensions.stickerWidth / 2} dy={5}>
            {line1}
          </tspan>
          <tspan x={dimensions.stickerWidth / 2} dy={5}>
            {line2}
          </tspan>
        </text>
      )}
    </g>
  );
}

StickerCellSingle.propTypes = {
  sticker: StickerShape.isRequired,
  localTexts: StickerLocalTextsShape.isRequired,
};

export default StickerCellSingle;
