import { connect } from 'react-redux';

import { insertElements } from '../../../../actions/workspace';
import { getSpreadButtonPositions } from '../../../../selectors/stickers';
import {
  getVisibleSpreadIds,
  getTargetSpreadId,
  getAlbumStatisticsForPreflight,
} from '../../../../selectors/legacy';
import SpreadComponent from './index';

function mapStateToProps(state, ownProps) {
  return {
    spreadVisible: getVisibleSpreadIds(state).includes(ownProps.id),
    targetSpreadId: getTargetSpreadId(state),
    cropPreview: state.controls.cropPreview,
    spine: getAlbumStatisticsForPreflight(state).spine,
    additionalSpreadNeededForSectionId: getSpreadButtonPositions(state)[
      ownProps.id
    ],
  };
}

const mapDispatchToProps = {
  insertElements,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpreadComponent);
