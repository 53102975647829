import { connect } from 'react-redux';

import {
  createAndPlaceImageAfterDirectUpload,
  convertToStock,
  patchExistingImage,
} from '../../../actions/images';
import {
  setStickerLogo,
  updateSelectedStickers,
} from '../../../modules/sections';
import {
  withSelection,
  initialState as emptySelection,
} from '../../../modules/selection';
import { historyAnchor } from '../../../modules/history';
import { getImage } from '../../../selectors/images';
import {
  getWorkspace,
  getApplicableActionsFromItemType,
  getStickerById,
  getSingleSelectedElement,
  getIsolation,
} from '../../../selectors/legacy';
import { getStickerTags } from '../../../selectors/templates';
import ContextMenu from './index';
import { itemTypes } from '../../../constants';
import { hideContextMenu } from '../../../modules/menu';
import executeActionByName from '../../../actions/executeActionByName';
import { getSelectInside } from '../../../selectors/selection';

export function mapStateToProps(state) {
  // The content of the context-menu is defined by itemType and id
  const { contextMenuData } = state.menu;
  if (!contextMenuData) {
    return {};
  }
  const { id, itemType } = contextMenuData;

  // Calculate the interim selection:
  let { selection } = state;
  if (
    !!id &&
    (itemType !== itemTypes.sticker || selection[itemType].length === 0)
  ) {
    selection = {
      ...emptySelection,
      [itemType]: [id],
    };
  }
  if (selection[itemType].length === 0) {
    return {};
  }

  if (itemType === itemTypes.element) {
    // If the item is an element also include its corresponding spread
    const { nodes } = getWorkspace(state);
    const isolation = getIsolation(state);
    const [nodeId] = selection[itemType];
    const spreadId = isolation ? nodes[isolation].parent : nodes[nodeId].parent;
    selection = {
      ...selection,
      [itemTypes.spread]: [spreadId],
    };
  }

  // If the item is an image, resolve its corresponding imageObject
  let imageObject = null;
  if (itemType === itemTypes.image) {
    imageObject = getImage(state, id);
  }

  // Sanitize the sticker
  let sticker = getStickerById(state, id);
  if (sticker) {
    let {
      doubleSticker,
      textless,
      noStickerstarsLogo,
      subtitle,
      showTeamInPosition,
      tags,
    } = sticker;
    doubleSticker = !!doubleSticker;
    textless = !!textless;
    noStickerstarsLogo = !!noStickerstarsLogo;
    subtitle = !!subtitle;
    showTeamInPosition = !!showTeamInPosition;
    if (!Array.isArray(tags)) {
      tags = [];
    }
    sticker = {
      ...sticker,
      doubleSticker,
      textless,
      noStickerstarsLogo,
      subtitle,
      showTeamInPosition,
      tags,
    };
  }

  return {
    stickerTags: getStickerTags(state),
    applicableActions: getApplicableActionsFromItemType(
      itemType,
      getSelectInside(state),
      getSingleSelectedElement(state)
    ),
    sticker,
    contextMenuData,
    imageObject,
    selection,
    // For elements: If an `id` is given, restore the original selection afterwards
    // Otherwise: always restore the original selection
    restoreSelection: itemType !== itemTypes.element || !!id,
  };
}

const mapDispatchToProps = {
  createAndPlaceImageAfterDirectUpload,
  updateSelectedStickers,
  convertToStock,
  setStickerLogo,
  patchExistingImage,
  historyAnchor,
  withSelection,
  hideContextMenu,
  executeActionByName,
};

function mergeProps(
  { selection, restoreSelection, ...stateProps },
  { withSelection: dispatchWithSelection, ...dispatchProps },
  ownProps
) {
  const executeWithSelection = callback =>
    dispatchWithSelection(selection, restoreSelection, callback);
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    executeWithSelection,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ContextMenu);
