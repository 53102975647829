import { createSelector } from 'reselect';

export const getTags = state => state.tags;

const archivedTagTitle = 'archived';

// Returns all tags sorted by title. Sort "archived" tag to the top.
export const getSortedTags = createSelector([getTags], tags => {
  const sortedTags = tags.sort((a, b) => {
    if (a.title === archivedTagTitle && b.title !== archivedTagTitle) {
      return -1;
    }

    if (b.title === archivedTagTitle && a.title !== archivedTagTitle) {
      return 1;
    }

    return a.title.localeCompare(b.title);
  });
  return sortedTags;
});

export const getArchivedTagId = createSelector(
  [getTags],
  tags => tags.find(tag => tag.title === archivedTagTitle)?.id
);
