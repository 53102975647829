// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { symbolTypes } from '../../constants';
import { selectAvailableStickerMetaFields } from '../../selectors/stickers';
import { objectToOptions } from '../../util/index';

class SymbolSelectClass extends React.Component {
  change = (e, { value }) => {
    const { onChange } = this.props;
    this.setState({ value });
    if (onChange) onChange(e, value);
  };

  render() {
    const { value, availableStickerMetaFields } = this.props;

    return (
      <Dropdown
        placeholder="(Feld)"
        value={value}
        onChange={this.change}
        style={{ width: 250 }}
        className="qa-text-symbol-dropdown"
        selection
        options={[
          { key: '0', value: null, text: '(Freier Text)' },
          ...objectToOptions(symbolTypes),
          ...objectToOptions(availableStickerMetaFields),
        ]}
      />
    );
  }
}

function SymbolSelectWithStickerMetaFields(props) {
  const availableStickerMetaFields = useSelector(
    selectAvailableStickerMetaFields
  );

  return (
    <SymbolSelectClass
      {...props}
      availableStickerMetaFields={availableStickerMetaFields}
    />
  );
}

export default SymbolSelectWithStickerMetaFields;
