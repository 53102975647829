import { useSelector, useDispatch } from 'react-redux';

import { getStickerMode } from '../../../selectors/controls';
import { itemTypes } from '../../../constants';
import { showContextMenu } from '../../../modules/menu';
import { getSelectedStickerIds } from '../../../selectors/legacy';

export default function useHandleContextMenu() {
  const stickerMode = useSelector(getStickerMode);
  const dispatch = useDispatch();
  const [stickerId] = useSelector(getSelectedStickerIds);

  // Element context menu
  return event => {
    dispatch(
      showContextMenu(
        event,
        stickerMode ? itemTypes.sticker : itemTypes.element,
        stickerMode ? stickerId : null
      )
    );
  };
}
