import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MoveOperation from '../../operations/MoveOperation';
import RotateOperation from '../../operations/RotateOperation';
import ResizeOperation from '../../operations/ResizeOperation';
import ScaleOperation from '../../operations/ScaleOperation';
import {
  getApplicableOperations,
  getSingleSelectedElement,
} from '../../../selectors/legacy';
import { getZoom, getStickerMode } from '../../../selectors/controls';
import { getSelectInside } from '../../../selectors/selection';
import { operations } from '../../../constants';
import HandlesGroup from './HandlesGroup';
import useShiftOrMetaPressed from '../../../hooks/useShiftOrMetaPressed';
import useHandleDoubleClick from './useHandleDoubleClick';
import useHandleContextMenu from './useHandleContextMenu';
import { getHandlesBounds } from '../../../selectors/bounds';

const operationComponents = {
  [operations.move]: MoveOperation,
  [operations.rotate]: RotateOperation,
  [operations.resize]: ResizeOperation,
  [operations.scale]: ScaleOperation,
};

function Handles() {
  const zoom = useSelector(getZoom);
  const applicableOperations = useSelector(getApplicableOperations);
  const handleDoubleClick = useHandleDoubleClick();
  const handleContextMenu = useHandleContextMenu();
  const shiftOrMetaPressed = useShiftOrMetaPressed();
  const selectInside = useSelector(getSelectInside);
  const stickerMode = useSelector(getStickerMode);
  const { type } = useSelector(getSingleSelectedElement) || {};

  /*
   This component needs to calculate its bounds by reading from DOM-nodes. This 
   is done inside `getHandlesBounds`. When an element is e.g. moved, the DOM is 
   not yet updated, leading to a noticeable "lag" of the Handles. We need to 
   delay the calculation of the bounds until the DOM was updated in order to 
   prevent this. The following block is a reformulation of this selector-call: 
   `const { frameBounds, operationBounds } = useSelector(getHandlesBounds);`
   */
  const [{ frameBounds, operationBounds }, setBounds] = useState({
    frameBounds: null,
    operationBounds: null,
  });
  // As a consequence of this delayed execution, we can not use a hook inside the
  // requestAnimationFrame-callback, so we store the redux-state in a variable
  const currentState = useSelector(state => state);
  useEffect(() => {
    requestAnimationFrame(() => setBounds(getHandlesBounds(currentState)));
  }, [currentState]);

  // Hide moveOperation, if shift-key is down
  const operationsWithoutMove = applicableOperations.filter(
    name => name !== 'moveOperation'
  );
  const availableOperations = shiftOrMetaPressed
    ? operationsWithoutMove
    : applicableOperations;

  // Map operation names to their react-elements
  const operationInstances =
    operationBounds &&
    availableOperations.map(operation =>
      React.createElement(operationComponents[operation], {
        key: operation,
        zoom,
        width: operationBounds.width,
        height: operationBounds.height,
      })
    );

  let commonClass;
  if (stickerMode) {
    commonClass = 'sticker';
  } else {
    commonClass = `${selectInside ? 'inside' : ''} type-${type}`;
  }
  const frameClass = `handles frame ${commonClass} qa-handles-frame`;
  const operationClass = `handles operations ${commonClass} qa-handles-operation`;
  return (
    <g
      className="js-handles"
      onContextMenu={handleContextMenu}
      onDoubleClick={handleDoubleClick}
    >
      {operationBounds && (
        <HandlesGroup className={operationClass} {...operationBounds}>
          {operationInstances}
        </HandlesGroup>
      )}
      {frameBounds && <HandlesGroup className={frameClass} {...frameBounds} />}
    </g>
  );
}

export default Handles;
