import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import { getSelectedBlocksMap } from 'draftjs-utils';
import Immutable from 'immutable';

import { getSelectedTextElements } from '../selectors/legacy';
import {
  createEditorStateFromJson,
  createSelectionWithAllContentSelected,
  emptyRawContentState,
} from '../util/draftjs';
import { historyAnchor } from './history';

/* This action should naturally be in 'actions/workspace'. But since importing from there introduces a circular
dependency (actions/workspace.js > modules/selection.js > modules/draft.js), we create the action here. */
const updateElementText = (id, text) => ({
  type: 'workspace/UPDATE_ELEMENTS',
  payload: {
    propsDeltaMap: { [id]: { text } },
  },
});

const selectAll = editorState => {
  const contentState = editorState.getCurrentContent();
  const selection = createSelectionWithAllContentSelected(contentState);
  return EditorState.acceptSelection(editorState, selection);
};

const createJsonFromEditorState = editorState => {
  return convertToRaw(editorState.getCurrentContent());
};

const UPDATE = 'draft/UPDATE';

export const initialState = {
  editorState: null,
  editingId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const mapAllEditorStates = callback => (dispatch, getState) => {
  const {
    draft: { editingId, editorState },
  } = getState();
  if (editingId) {
    // when editing a single element, always keep the focus
    const focusedEditorState = EditorState.acceptSelection(
      editorState,
      editorState.getSelection().merge({ hasFocus: true })
    );
    dispatch(setEditorState(callback(focusedEditorState)));
  } else {
    const state = getState();
    const selectedElements = getSelectedTextElements(state);
    let mergedEditorState = EditorState.createEmpty();

    selectedElements.forEach(element => {
      const newContentState = convertFromRaw(
        element.props.text || emptyRawContentState
      );
      if (newContentState) {
        let editorState = selectAll(
          EditorState.createWithContent(newContentState)
        );
        editorState = callback(editorState);
        const text = createJsonFromEditorState(editorState);
        dispatch(updateElementText(element.props.id, text));
      }
    });
    mergedEditorState = EditorState.push(
      mergedEditorState,
      editorState.getCurrentContent(),
      'insert-fragment'
    );
    mergedEditorState = selectAll(mergedEditorState);
    dispatch(setEditorState(callback(mergedEditorState)));
  }
};

export const updateEditingElement = () => (dispatch, getState) => {
  const state = getState();
  const selectedElements = getSelectedTextElements(state);
  const {
    draft: { editingId, editorState },
    selection: { selectInside },
  } = state;

  let selectedElement = null;
  let selectedElementId = null;
  if (
    selectInside &&
    selectedElements.length === 1 &&
    selectedElements[0].type === 'Text' &&
    !selectedElements[0].props.symbol
  ) {
    selectedElement = selectedElements[0];
    selectedElementId = selectedElement.props.id;
  }

  if (editingId && !selectedElementId) {
    const text = createJsonFromEditorState(editorState);
    dispatch(updateElementText(editingId, text));
    dispatch(setEditorState(null));
    dispatch(setEditingId(null));
    dispatch(historyAnchor());
  } else if (!editingId && selectedElementId) {
    const newEditorState = createEditorStateFromJson(
      selectedElement.props.text
    );
    dispatch(setEditorState(newEditorState));
    dispatch(setEditingId(selectedElementId));
    dispatch(historyAnchor());
  } else if (editingId !== selectedElementId) {
    const text = createJsonFromEditorState(editorState);
    dispatch(updateElementText(editingId, text));
    const newEditorState = createEditorStateFromJson(
      selectedElement.props.text
    );
    dispatch(setEditorState(newEditorState));
    dispatch(setEditingId(selectedElementId));
    dispatch(historyAnchor());
  } else {
    let mergedEditorState = EditorState.createEmpty();
    // let selectionState = SelectionState.createEmpty();
    selectedElements.forEach(element => {
      let contentState = false;
      try {
        contentState = convertFromRaw(element.props.text);
      } catch (e) {
        contentState = false;
      }
      if (contentState) {
        mergedEditorState = EditorState.push(
          mergedEditorState,
          contentState,
          'insert-fragment'
        );
      }
    });
    dispatch(setEditorState(mergedEditorState));
    dispatch(setEditingId(null));
  }
};

export const setEditorState = editorState => dispatch => {
  dispatch({ type: UPDATE, payload: { editorState } });
};

const setEditingId = editingId => dispatch => {
  dispatch({ type: UPDATE, payload: { editingId } });
};

export const setInlineStyle = inlineStyle => dispatch => {
  dispatch(
    mapAllEditorStates(originalEditorState => {
      let editorState = originalEditorState;

      const selection = editorState.getSelection();

      // set inline styles
      const currentStyle = editorState.getCurrentInlineStyle();
      const inlineStyleList = inlineStyle.split('-');
      if (inlineStyleList.length >= 2) {
        const prefix = `${inlineStyleList[0]}-`;
        currentStyle.forEach(style => {
          if (style.indexOf(prefix) === 0) {
            editorState = RichUtils.toggleInlineStyle(editorState, style);
          }
        });
      }
      editorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);

      // save styles relevant for line height calculation also in block styles:
      const key = inlineStyleList[0];

      if (['SIZE', 'LINEHEIGHT', 'FONT', 'ALIGN'].includes(key)) {
        // first merge all selected block styles...
        const allSelectedBlockStyles = getSelectedBlocksMap(editorState).reduce(
          (acc, cur) => acc.merge(cur.getData()),
          Immutable.Map()
        );

        // ... limit them to line height relevant styles
        const relevantSelectedStyles = allSelectedBlockStyles.filter(
          (value, key) => ['SIZE', 'LINEHEIGHT', 'FONT'].includes(key)
        );

        // ... and set the current one
        const mergedStyles = relevantSelectedStyles.set(key, inlineStyle);

        let contentState = editorState.getCurrentContent();

        contentState = Modifier.mergeBlockData(
          contentState,
          selection,
          mergedStyles
        );

        // revert all block types to "unstyled" (block types were used for aligning in older versions)
        contentState = Modifier.setBlockType(
          contentState,
          selection,
          'unstyled'
        );

        editorState = EditorState.push(
          editorState,
          contentState,
          'add-block-data'
        );
      }

      return editorState;
    })
  );
  dispatch(historyAnchor());
};
