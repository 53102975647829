import React, { useState } from 'react';
import { string, func, number } from 'prop-types';

import './sliders.scss';
import { RefShape } from '../../shapes';
import { valueBetween } from '../../../util/index';

const Scrubber = ({
  color,
  value,
  min,
  max,
  trackRef,
  onChange,
  onChanged,
  label,
}) => {
  const [dragging, setDragging] = useState(false);

  const mouseMove = event => {
    const { x, width } = trackRef.current.getBoundingClientRect();
    const left = event.clientX - x;
    const position = left / width;
    const newValue = valueBetween(position, min, max);
    onChange(newValue);
  };

  const mouseUp = event => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', mouseUp);
    mouseMove(event);
    setDragging(false);
    onChanged();
  };

  const mouseDown = event => {
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseUp);
    mouseMove(event);
    setDragging(true);
  };

  const currentValue = valueBetween(value, min, max);
  const showLabel = label && dragging;

  return (
    <div
      role="slider"
      tabIndex="-1"
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={currentValue}
      className="scrubber qa-scrubber"
      style={{ left: `${currentValue * 100}%`, background: color }}
      onMouseDown={mouseDown}
    >
      <div className="arrow" style={{ borderBottomColor: color }} />
      {showLabel && <div className="label qa-scrubber-label">{label}</div>}
    </div>
  );
};

Scrubber.defaultProps = {
  label: null,
};

Scrubber.propTypes = {
  color: string.isRequired,
  value: number.isRequired,
  min: number.isRequired,
  max: number.isRequired,
  trackRef: RefShape.isRequired,
  onChange: func.isRequired,
  onChanged: func.isRequired,
  label: string,
};

export default Scrubber;
