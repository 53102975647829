// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { isEqual } from 'lodash';

import { hexToRgb } from './index';

export const defaultFilter = {
  con: 1,
  rgb: { min: 0, mid: 0.5, max: 1 },
  red: { min: 0, mid: 0.5, max: 1 },
  green: { min: 0, mid: 0.5, max: 1 },
  blue: { min: 0, mid: 0.5, max: 1 },
  hue: 0.5,
  sat: 0.5,
  tone: { min: 0.25, mid: 0.5, max: 0.75 },
  tone_red: { min: 0.25, mid: 0.5, max: 0.75 },
  tone_green: { min: 0.25, mid: 0.5, max: 0.75 },
  tone_blue: { min: 0.25, mid: 0.5, max: 0.75 },
};

export function calcFilters(state) {
  state = { ...defaultFilter, ...state };

  let { hue, sat, tone } = state;
  hue = (hue - 0.5) * 360;
  sat *= 2;

  // convert slider values to feFunc-parameters
  // feFunc[type=gamma] calculates the new value: c' = amplitude * pow(c,exponent) + offset
  // https://books.google.de/books?id=46KmyRXPW8EC&pg=PA330&lpg=PA330&dq=fefunc+gamma&source=bl&ots=Q45OdUfaMM&sig=ACfU3U0nXHr4h3jun6PbEaswlVZtlz_-DQ&hl=de&sa=X&ved=2ahUKEwiMt6O3oNrjAhXGKVAKHb2WBS8Q6AEwAnoECAgQAQ#v=onepage&q=fefunc%20gamma&f=false
  const { rgb, red, green, blue } = Object.keys(state).reduce((acc, cur) => {
    // these values range from 0..1, where mid is always between min and max
    const { min, mid, max } = state[cur];

    // "amplitude" is the slope of the function: 1 = neutral, > 1 = increase contrast, < 1 = reduce contrast
    const amplitude = 1 / (max - min);

    // "offset" is the y-intercept of the function
    const offset = -min * amplitude;

    // "exponent" is the actual gamma-value, converted to a log-scale from 0 to 10: 1 = neutral, < 1 = darker, > 1 = tonegher
    const n_mid = (mid - min) / (max - min); // normalize mid to a range 0..1
    const exponent = Math.pow(10, n_mid * 2) / 10;
    const gamma = 1 / exponent;

    const labels = {
      min: `Min.: ${Math.round(min * 255)}`,
      mid: `Gamma: ${gamma.toFixed(2)}`,
      max: `Max.: ${Math.round(max * 255)}`,
    };

    acc[cur] = { amplitude, exponent, offset, labels };
    return acc;
  }, {});

  // convert slider values to feFunc-parameters
  // feFunc[type=gamma] calculates the new value: c' = amplitude * pow(c,exponent) + offset
  // https://books.google.de/books?id=46KmyRXPW8EC&pg=PA330&lpg=PA330&dq=fefunc+gamma&source=bl&ots=Q45OdUfaMM&sig=ACfU3U0nXHr4h3jun6PbEaswlVZtlz_-DQ&hl=de&sa=X&ved=2ahUKEwiMt6O3oNrjAhXGKVAKHb2WBS8Q6AEwAnoECAgQAQ#v=onepage&q=fefunc%20gamma&f=false
  const { tone_red, tone_green, tone_blue } = [
    'tone_red',
    'tone_green',
    'tone_blue',
  ].reduce((acc, cur) => {
    // these values range from 0..1, where mid is always between min and max
    const { min, mid, max } = state[cur];

    const table = [];
    const steps = 5;
    for (let i = 0; i < steps; i += 1) {
      table.push(i / (steps - 1));
    }
    // const table = [0,min,mid,max,1];
    table[1] = min + (tone.min - defaultFilter.tone.min);
    table[2] = mid + (tone.mid - defaultFilter.tone.mid);
    table[3] = max + (tone.max - defaultFilter.tone.max);

    const labels = {
      min: `Low: ${min.toFixed(2)}`,
      mid: `Mid.: ${mid.toFixed(2)}`,
      max: `High: ${max.toFixed(2)}`,
    };

    const tableValues = table.join(' ');
    acc[cur] = { tableValues, labels };
    return acc;
  }, {});

  // merge overall(rgb) and individual channel values
  const [r, g, b] = [red, green, blue].map(
    ({ amplitude, offset, exponent }) => {
      amplitude *= rgb.amplitude;
      exponent *= rgb.exponent;
      offset += rgb.offset;
      if (amplitude === 1 && exponent === 1 && offset === 0) {
        return null; // neutral
      }
      return { amplitude, exponent, offset };
    }
  );

  return {
    rgb,
    red,
    green,
    blue,
    r,
    g,
    b,
    hue,
    sat,
    tone,
    tone_red,
    tone_green,
    tone_blue,
  };
}

function buildGradientMap(source, target, colors, gradientMapIntensity = 1) {
  const rgbColors = colors.map(hexToRgb);
  const mix = (val1, val2) =>
    val1 * gradientMapIntensity + val2 * (1 - gradientMapIntensity);
  return (
    <feComponentTransfer key={target} in={source} result={target}>
      <feFuncR
        type="table"
        tableValues={rgbColors
          .map((item, index) =>
            mix(item.r / 255, index / (rgbColors.length - 1))
          )
          .join(' ')}
      />
      <feFuncG
        type="table"
        tableValues={rgbColors
          .map((item, index) =>
            mix(item.g / 255, index / (rgbColors.length - 1))
          )
          .join(' ')}
      />
      <feFuncB
        type="table"
        tableValues={rgbColors
          .map((item, index) =>
            mix(item.b / 255, index / (rgbColors.length - 1))
          )
          .join(' ')}
      />
    </feComponentTransfer>
  );
}

function buildGrayscale(source, target, intensity = 1) {
  let sat = 1 - intensity;
  return (
    <feColorMatrix
      key="grayscale"
      type="saturate"
      values={sat}
      in={source}
      result={target}
    />
  );
}

export function buildFilters(
  {
    id,
    filter,
    blend,
    fillOpacity,
    fill,
    grayscale,
    gradientMap,
    gradientMapIntensity,
  },
  colors = null
) {
  const items = [];

  let source;

  if (filter) {
    const { hue, sat, r, g, b, tone_red, tone_green, tone_blue } = calcFilters(
      filter
    );

    if (hue !== 0)
      items.push(<feColorMatrix key={0} type="hueRotate" values={hue} />);
    if (sat !== 1)
      items.push(<feColorMatrix key={1} type="saturate" values={sat} />);

    const allEqual = [
      'rgb',
      'red',
      'green',
      'blue',
      'tone_red',
      'tone_green',
      'tone_blue',
    ].every(key => isEqual(filter[key], defaultFilter[key]));

    if (!allEqual) {
      items.push(
        <feComponentTransfer result="filtered1" key={2}>
          {r && <feFuncR type="gamma" {...r} />}
          {g && <feFuncG type="gamma" {...g} />}
          {b && <feFuncB type="gamma" {...b} />}
        </feComponentTransfer>
      );

      items.push(
        // eslint-disable-next-line react/no-unknown-property
        <feComponentTransfer source="filtered1" result="filtered" key={3}>
          {tone_red && (
            <feFuncR type="table" tableValues={tone_red.tableValues} />
          )}
          {tone_green && (
            <feFuncG type="table" tableValues={tone_green.tableValues} />
          )}
          {tone_blue && (
            <feFuncB type="table" tableValues={tone_blue.tableValues} />
          )}
        </feComponentTransfer>
      );
    }
    source = 'filtered';
  } else {
    source = 'SourceGraphic';
  }

  if (colors) {
    // only for images

    if (grayscale) {
      items.push(buildGrayscale(source, 'grayscale'));
      source = 'grayscale';
    }

    if (gradientMap) {
      const lastThreeColors = colors
        .slice(1)
        .slice(-3)
        .reverse();

      items.push(
        buildGradientMap(
          source,
          'gradientMap',
          lastThreeColors,
          gradientMapIntensity
        )
      );
      source = 'gradientMap';
    }

    if (fill && blend) {
      // color overlay, only for 'Image'

      /**
       * `buildFilters` is currently called from Image.js and Sticker.js.
       * Image.js no longer passes `fill` as an integer, but always as string
       * (as of #STC-207). Following if statement could thus be dropped once
       * Sticker.js is discarded in favor of a more dynamic approach.
       */
      if (typeof fill === 'number') {
        fill = colors[fill];
      }

      if (blend === 'luminosity') {
        if (!grayscale) {
          items.push(buildGrayscale(source, 'grayscale', fillOpacity));
          source = 'grayscale';
        }
        items.push(
          buildGradientMap(
            source,
            'blend',
            ['#000000', fill, '#ffffff'],
            fillOpacity
          )
        );
      } else {
        items.push(
          <feFlood
            key="floodFill"
            result="floodFill"
            x="0"
            y="0"
            width="100%"
            height="100%"
            floodColor={fill}
            floodOpacity={fillOpacity}
          />
        );
        items.push(
          <feBlend key="blend" in="floodFill" in2={source} mode={blend} />
        );
      }
    }
  }

  if (items.length) {
    return (
      <filter
        colorInterpolationFilters={colors ? 'sRGB' : null}
        id={`filter-${id}`}
      >
        {items}
      </filter>
    );
  }
  return null;
}
