import { DirectUpload } from 'activestorage';

import formatStickerNumber from '../components/svg/elements/Sticker/formatStickerNumber';
import { UPLOADS_URL } from '../constants';

const localStorageKey = 'sync_last_modified';

export const getLastModifiedObject = () => {
  const dataString = localStorage.getItem(localStorageKey);
  return dataString ? JSON.parse(dataString) : {};
};

export const persistLastModified = (stickerId, lastModified) => {
  const data = { ...getLastModifiedObject(), [stickerId]: lastModified };
  localStorage.setItem(localStorageKey, JSON.stringify(data));
};

/**
 * Extracts the sticker id from the filename.
 * E.g. "001 Susan Sample [123].png" => "123"
 * Returns undefined if no id could be extracted.
 */
export const extractIdFromFilename = filename => {
  const match = filename.match(/\[(.+)\]/);
  return (match || [])[1];
};

/**
 * Removed any dangerous or unwanted characters from a string
 * so its save to use as a filename.
 */
export const sanitizeFilename = s => {
  let sanitized = s;

  // Replace proprietary "\" line seperation literal
  sanitized = sanitized.replace('\\', ' ');

  // Keep only allowed characters
  sanitized = sanitized.replace(/[^ A-Za-z0-9öäüÖÄÜß]/gi, '');

  // Replace double spaces with single space
  sanitized = sanitized.replace(/\s{2,}/g, ' ');

  sanitized = sanitized.trim();
  return sanitized;
};

/**
 * Generates a filename for a sticker, e.g. "001 Susan Sample [123].png"
 */
export const formatFilename = (sticker, stickerNumber, extension) =>
  `${formatStickerNumber(stickerNumber)} ${sanitizeFilename(sticker.name)} [${
    sticker.id
  }].${extension}`;

/**
 * Checks if the directory handle has all necessary permissions and requests
 * them if needed
 */
export async function verifyPermission(directoryHandle) {
  const options = {
    mode: 'readwrite',
  };
  if ((await directoryHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  if ((await directoryHandle.requestPermission(options)) === 'granted') {
    return true;
  }
  return false;
}

export const fileUploadViaDirectUpload = async file =>
  new Promise((resolve, reject) => {
    new DirectUpload(file, UPLOADS_URL).create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob);
      }
    });
  });

export const getFilesInDirectory = async directoryHandle => {
  // List all files in the directory
  const entries = [];
  // eslint-disable-next-line no-restricted-syntax
  for await (const entry of directoryHandle.values()) {
    entries.push(entry);
  }
  const fileEntries = entries.filter(entry => entry.kind === 'file');
  const files = await Promise.all(fileEntries.map(entry => entry.getFile()));
  return files;
};
