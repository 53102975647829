import React from 'react';
import { arrayOf, string, func } from 'prop-types';
import { Label, Table, Button, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
  getApplicableProps,
  getSingleSelectedElement,
  getSelectedStickers,
  getTargetSpreadId,
  getSections,
  getWorkspace,
} from '../../selectors/legacy';
import {
  updateSelectedElements,
  updateElements,
} from '../../actions/workspace';
import FilterMenu from './FilterMenu';
import AlbumTitleInput from './AlbumTitleInput';
import NumberInput from './NumberInput';
import {
  SectionShape,
  StickerShape,
  NodeShape,
  WorkspaceShape,
} from '../shapes';

export class PropertiesPanelClass extends React.PureComponent {
  sectionIdControl() {
    const {
      sections,
      selectedStickers,
      workspace,
      targetSpreadId,
      updateElements, // eslint-disable-line no-shadow
    } = this.props;

    const spreadNode = workspace.nodes[targetSpreadId];
    if (!spreadNode) {
      return null;
    }

    const parentNode = workspace.nodes[spreadNode.parent];
    const spreadIndex = parentNode.children.indexOf(targetSpreadId);
    const cover = spreadIndex === 0 || spreadIndex === parentNode.children - 1;

    const { id, multiple, sectionId } = spreadNode.props;

    let sectionName = '(Kein Team)';
    let section;
    if (sectionId) {
      section = sections.find(item => item.id === sectionId);
      if (section) sectionName = section.name;
    }

    const targetSpreadString = `Doppelseite ${spreadIndex + 1}${' '}`;
    const targetPagesString = `Seiten ${spreadIndex * 2}/${spreadIndex * 2 +
      1}`;
    const selectedStickersString = `${selectedStickers.length} Sticker`;

    return (
      <>
        <Table fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="2">Album</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>
                <AlbumTitleInput />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table fixed>
          <Table.Header key={0}>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                {targetSpreadString}
                {!cover && <Label>{targetPagesString}</Label>}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body key={1}>
            <Table.Row key={0}>
              <Table.Cell>Team</Table.Cell>
              <Table.Cell className={sectionId && `qa-section-${sectionId}`}>
                {sectionName}
                {section && (
                  <Button
                    icon="delete"
                    className="qa-drop-section-btn"
                    onClick={() =>
                      updateElements({ [id]: { sectionId: null } })
                    }
                  />
                )}
              </Table.Cell>
            </Table.Row>

            {section && (
              <Table.Row key={1}>
                <Table.Cell>Mehrere Teams</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    className="qa-allow-multiple-teams-on-spread"
                    onClick={() =>
                      updateElements({ [id]: { multiple: !multiple } })
                    }
                    checked={multiple}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>

        {selectedStickers.length > 0 && (
          <Table fixed>
            <Table.Header key={1}>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {selectedStickersString}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body key={2}>
              <Table.Row>
                <Table.Cell colSpan="2">
                  <FilterMenu value={selectedStickers[0].filter} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </>
    );
  }

  controlForProperty(prop) {
    const { singleSelectedElement, updateSelectedElements } = this.props; // eslint-disable-line no-shadow
    let label = prop;
    let max;
    let min;
    let placeholder;
    let step = 5;
    let truncateStep = true;
    let value = singleSelectedElement.props[prop];

    switch (prop) {
      case 'cscale':
      case 'scale':
        if (Number.isNaN(Number(value))) value = 1;
        max = 100;
        min = 0.01;
        label = 'scale';
        step = 0.01;
        truncateStep = false;
        break;
      case 'crotation':
      case 'rotation':
        if (Number.isNaN(Number(value))) value = 0;
        max = 360;
        min = -360;
        label = 'rotation';
        step = 45;
        truncateStep = false;
        value = Math.round(value * 10) / 10;
        break;
      case 'cx':
      case 'cy':
      case 'x':
      case 'y':
        if (Number.isNaN(Number(value))) value = 0;
        max = 1000;
        min = -1000;
        label = label[label.length - 1];
        value = Math.round(value * 10) / 10;
        break;
      case 'width':
      case 'height':
        max = 10000;
        min = 1;
        placeholder = `${value} [${prop}]`;
        value = Math.round(value * 10) / 10;
        break;
      default:
        return null;
    }

    const numberInputProps = {
      id: `number-input-${prop}`,
      max,
      min,
      name: prop,
      onChange: e => updateSelectedElements({ [prop]: e.value }),
      placeholder,
      showButtons: true,
      step,
      truncateStep,
      value,
    };

    return [
      <Table.Cell key={0}>{label}</Table.Cell>,
      <Table.Cell key={1}>
        <NumberInput {...numberInputProps} />
      </Table.Cell>,
    ];
  }

  render() {
    const { applicableProps, singleSelectedElement } = this.props;

    if (singleSelectedElement) {
      return (
        <div className="flex grow scrolling qa-properties-content">
          {this.sectionIdControl()}
          <Table fixed className="qa-element-properties-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Element</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {applicableProps.map(prop => (
                <Table.Row key={prop}>
                  {this.controlForProperty(prop)}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      );
    }
    return (
      <div className="flex grow scrolling qa-properties-content">
        {this.sectionIdControl()}
      </div>
    );
  }
}

PropertiesPanelClass.defaultProps = {
  applicableProps: [],
  sections: [],
  selectedStickers: [],
  singleSelectedElement: null,
};

PropertiesPanelClass.propTypes = {
  applicableProps: arrayOf(string),
  sections: arrayOf(SectionShape),
  selectedStickers: arrayOf(StickerShape),
  singleSelectedElement: NodeShape,
  workspace: WorkspaceShape.isRequired,
  targetSpreadId: string.isRequired,
  updateSelectedElements: func.isRequired,
  updateElements: func.isRequired,
};

const mapStateToProps = state => ({
  applicableProps: getApplicableProps(state),
  singleSelectedElement: getSingleSelectedElement(state),
  sections: getSections(state),
  selectedStickers: getSelectedStickers(state),
  workspace: getWorkspace(state),
  targetSpreadId: getTargetSpreadId(state),
});

const mapDispatchToProps = {
  updateSelectedElements,
  updateElements,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesPanelClass);
