import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateControls } from '../modules/controls';

function useControlsState(controlsStoreKey) {
  // `controlsStoreKey` is used to read the `selectedOption` for a
  // specific "control option" in the `controls` reducer
  const selector = state => state.controls[controlsStoreKey];
  const selectedOption = useSelector(selector);

  const dispatch = useDispatch();
  const setSelectedOption = useCallback(
    value => {
      dispatch(updateControls({ [controlsStoreKey]: value }));
    },
    [controlsStoreKey, dispatch]
  );

  return [selectedOption, setSelectedOption];
}

export default useControlsState;
