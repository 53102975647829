import { albumChanged } from './history';

const UPDATE = 'colors/UPDATE';
const REPLACE = 'colors/REPLACE';

export const initialState = [
  '#000000',
  '#FFFFFF',
  '#888888',
  '#2e2a7d',
  '#ff0630',
  '#FFEA4D',
  '#0E9947',
];

function updateReplace(state, action) {
  switch (action.type) {
    case UPDATE:
      return [
        ...state.slice(0, action.index),
        action.payload,
        ...state.slice(action.index + 1),
      ];
    case REPLACE:
      return action.payload;
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
    case REPLACE:
      return updateReplace(state, action);
    default:
      return state;
  }
};

export const updateColor = (index, payload) => dispatch => {
  dispatch(albumChanged());
  dispatch({ type: UPDATE, index, payload });
};

export const replaceColors = payload => dispatch => {
  dispatch(albumChanged());
  dispatch({ type: REPLACE, payload });
};
