import React, { useRef } from 'react';
import { string, func, number, arrayOf } from 'prop-types';
import { Button } from 'semantic-ui-react';

import Scrubber from './Scrubber';
import { gradient, defaultColors } from './utils';
import './sliders.scss';

const SingleSlider = ({
  onChange,
  onChanged,
  onReset,
  value,
  defaultValue,
  colors,
  background,
  label,
}) => {
  const trackRef = useRef(null);

  const reset = () => {
    onChange(defaultValue);
    onChanged();
    onReset();
  };

  return (
    <div className="slider">
      <div className="control">
        <div
          className="track qa-single-slider-track"
          style={{ background: background || gradient(colors) }}
          ref={trackRef}
        />
        <Scrubber
          label={label}
          value={value}
          min={0}
          max={1}
          trackRef={trackRef}
          onChange={onChange}
          onChanged={onChanged}
          color="#666"
        />
      </div>
      <Button icon="undo" onClick={reset} />
    </div>
  );
};

SingleSlider.defaultProps = {
  value: 0,
  defaultValue: 0,
  colors: defaultColors,
  background: null,
};

SingleSlider.propTypes = {
  value: number,
  defaultValue: number,
  colors: arrayOf(string),
  background: string,
  label: string.isRequired,
  onChange: func.isRequired,
  onChanged: func.isRequired,
  onReset: func.isRequired,
};

export default SingleSlider;
