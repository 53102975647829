import { node, number, string } from 'prop-types';
import React from 'react';

function Page({ children, id, width, height, bleed, trim, marks }) {
  const bleedWidth = width + bleed * 2;
  const bleedHeight = height + bleed * 2;

  return (
    <section
      className={id}
      style={{
        position: 'relative',
        width: `${bleedWidth}mm`,
        height: `${bleedHeight}mm`,
        left: `${-bleed}mm`,
        top: `${-bleed}mm`,
      }}
    >
      <style>{`
        @page ${id} {
            size: ${width}mm ${height}mm;
            margin: 0;
            padding: 0;
            marks: ${marks};
            prince-bleed: ${bleed}mm;
            prince-trim: ${trim}mm;
            prince-mark-offset: ${bleed}mm;
            prince-mark-length: ${trim}mm;
            prince-mark-width: 0.2mm;
        }

        .${id} { 
          page: ${id} 
        }
      `}</style>
      {children}
    </section>
  );
}

Page.defaultProps = {
  width: 100,
  height: 100,
  bleed: 3,
  trim: 7,
  marks: 'crop cross',
};

Page.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  width: number,
  height: number,
  bleed: number,
  trim: number,
  marks: string,
};

export default Page;
