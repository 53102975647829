import React from 'react';
import { string, arrayOf, number, bool, func } from 'prop-types';
import { List } from 'semantic-ui-react';

import { itemTypes } from '../../../constants';
import Image from '../Image';
import { ImageObjectShape, TagShape, IdShape } from '../../shapes';

function ImageItem({
  active,
  className,
  connectDragSource,
  detail,
  image,
  imageObject,
  imagePreviewSize,
  label,
  onClick,
  tags,
  usage,
  showContextMenu,
}) {
  let dim;
  let size;
  switch (imagePreviewSize) {
    case 'detail':
    case 'small':
      size = 'mini';
      dim = 50;
      break;
    default:
    case 'medium':
      size = 'small';
      dim = 100;
      break;
    case 'large':
      size = 'medium';
      dim = 150;
      break;
  }

  // Hide '0' usage for stock images
  const showUsage = !imageObject.stock || usage > 0;

  return connectDragSource(
    <button
      type="button"
      className={`image-item ${
        active ? 'highlight-selected highlight-pulse' : 'highlight-grey'
      } ${imagePreviewSize.toLowerCase()} ${className} qa-image-${imagePreviewSize}`}
      onClick={onClick}
      onContextMenu={event => showContextMenu(event, itemTypes.image, image)}
      style={{ position: 'relative' }}
    >
      <Image
        image={image}
        imageObject={imageObject}
        size={size}
        style={{ width: dim, height: dim, objectFit: 'contain' }}
      />
      {imagePreviewSize === 'detail' && (
        <div>
          <List.Header>{label}</List.Header>
          <List.Description>{detail}</List.Description>
          <List.Content>{tags}</List.Content>
        </div>
      )}
      {showUsage && (
        <span
          className={`usage qa-usage-counter-${image}`}
          style={usage === 0 ? { background: 'red' } : null}
        >
          {usage}
        </span>
      )}
    </button>
  );
}

ImageItem.defaultProps = {
  className: '',
  detail: '',
  image: null,
  imageObject: null,
  imagePreviewSize: 'small',
  label: '',
  onClick: () => {},
  usage: null,
  tags: [],
};

ImageItem.propTypes = {
  active: bool.isRequired,
  className: string,
  dispatchSetModal: func,
  image: IdShape.isRequired,
  label: string,
  detail: string,
  onClick: func,
  usage: number,
  tags: arrayOf(TagShape).isRequired,

  // DnD
  connectDragSource: func.isRequired,

  // mapStateToProps
  imageObject: ImageObjectShape,
  imagePreviewSize: string.isRequired,

  // mapDispatchToProps
  toggleTrashcan: func.isRequired,
  showContextMenu: func.isRequired,
};

export default ImageItem;
