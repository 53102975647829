import { createSelector } from 'reselect';

const getImages = state => state.images;

const getStockImages = state => state.stock;

const getAllImages = createSelector(
  getImages,
  getStockImages,
  (images, stock) => [...images, ...stock]
);

export const getImage = (state, imageId) => {
  // NOTE: placeholder images have their id set to null.
  // We also need to return null for their imageObject.
  if (!imageId) return null;

  const images = getAllImages(state);
  const matchImageId = ({ id }) => id === Number(imageId);
  const image = images.find(matchImageId);
  if (!image) return null;
  return image;
};

export default getImage;
