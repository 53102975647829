import React from 'react';
import { func, string } from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { stickerPlacings } from '../../constants';

function StickerPlacingSelect({ value, onChange }) {
  return (
    <div className="btn-group">
      <Button.Group>
        <Button
          active={value === stickerPlacings.compress}
          icon
          onClick={e => onChange(e, 'compress')}
        >
          <Icon name="compress" />
        </Button>
        <Button
          active={value === stickerPlacings.expand}
          icon
          onClick={e => onChange(e, 'expand')}
        >
          <Icon name="expand" />
        </Button>
        <Button
          active={value === stickerPlacings.grid}
          icon
          onClick={e => onChange(e, 'grid')}
        >
          <Icon name="grid layout" />
        </Button>
      </Button.Group>
    </div>
  );
}

StickerPlacingSelect.defaultProps = {
  value: stickerPlacings.compress,
};

StickerPlacingSelect.propTypes = {
  value: string,
  onChange: func.isRequired,
};

export default StickerPlacingSelect;
