import { createSelector } from 'reselect';

import { getSections } from './legacy';
import { getPlacedStickerPositionInfoById } from './stickers';

export const getTableOfContents = createSelector(
  [getSections, getPlacedStickerPositionInfoById],
  (sections, placedStickerInfoById) =>
    sections
      .map(section => {
        if (section.stickers.length === 0) {
          // Empty section: not included in the TOC
          return null;
        }

        const [firstSticker] = section.stickers;
        const position = placedStickerInfoById[firstSticker.id];
        if (!position) {
          // First sticker is not placed: return an empty pageNumber
          return { name: section.name, pageNumber: '' };
        }

        // Return the page where the first sticker is placed
        return { name: section.name, pageNumber: position.pageNumber };
      })
      .filter(Boolean)
);

export const getTocSections = createSelector([getTableOfContents], toc => {
  return toc.reduce((acc, cur) => `${acc + cur.name}\n`, '');
});

export const getTocPages = createSelector([getTableOfContents], toc => {
  return toc.reduce((acc, cur) => `${acc + cur.pageNumber}\n`, '');
});
