function get(key, fallback = undefined) {
  try {
    const value = JSON.parse(localStorage.getItem(key));
    return value;
  } catch {
    return fallback;
  }
}

function set(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}

function rm(key) {
  return localStorage.removeItem(key);
}

export default { get, set, rm };
