import React from 'react';
import { number, string } from 'prop-types';

import { dimensions } from '../../../../../constants';
import SizedText from '../../SizedText';

const szSymbol = '{#SZ#}';

const customUpperCase = s =>
  s
    .replace('ß', szSymbol)
    .toLocaleUpperCase()
    .replace(szSymbol, 'ß');

const StickerName = ({ line1, line2, yOffset }) => (
  <>
    <SizedText
      fontSize={3}
      fontFamily="Gilroy Black"
      fontStyle="italic"
      fill="black"
      fillOpacity={1}
      x={3}
      y={dimensions.stickerHeight - 10 + yOffset}
      maxWidth={34}
    >
      {customUpperCase(line1)}
    </SizedText>
    <SizedText
      fontSize={3.6}
      fontFamily="Gilroy Black"
      fontStyle="italic"
      fill="black"
      fillOpacity={1}
      x={5}
      y={dimensions.stickerHeight - 6 + yOffset}
      maxWidth={44}
    >
      {customUpperCase(line2)}
    </SizedText>
  </>
);

StickerName.defaultProps = {
  line1: '',
  line2: '',
};

StickerName.propTypes = {
  line1: string,
  line2: string,
  yOffset: number.isRequired,
};

export default StickerName;
