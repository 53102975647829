export default function clientToViewportCoordinates(
  { x, y },
  pan,
  zoom,
  viewportRef
) {
  // Browsers like Mozilla Firefox and Google Chrome calculate offsetX and
  // offsetY for mouse events differently. To get consistent results, we
  // compute these values based on the viewport "bounding rect" and mouse
  // coordinates.
  if (!viewportRef.current) {
    return { x: 0, y: 0 };
  }
  const rect = viewportRef.current.getBoundingClientRect();
  const offsetX = x - rect.left;
  const offsetY = y - rect.top;
  return {
    x: pan.x + offsetX / zoom,
    y: pan.y + offsetY / zoom,
  };
}
