import axios from 'axios';
import { createSelector } from 'reselect';

import { API_URL } from '../constants';

export const SET_COMMENTS = 'comments/SET_COMMENTS';
export const ADD_COMMENT = 'comments/ADD_COMMENT';
export const REMOVE_COMMENT = 'comments/REMOVE_COMMENT';
export const UPDATE_COMMENT = 'comments/UPDATE_COMMENT';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS:
      return action.payload;
    case ADD_COMMENT:
      return [...state, action.payload];
    case REMOVE_COMMENT:
      return state.filter(comment => comment.id !== action.payload);
    case UPDATE_COMMENT:
      return state.map(comment =>
        comment.id === action.payload.id ? action.payload : comment
      );
    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export const fetchComments = albumId => async dispatch => {
  const { data } = await axios.get(`${API_URL}/albums/${albumId}/comments`);
  dispatch({ type: SET_COMMENTS, payload: data.comments });
};

export const createComment = (albumId, payload) => async dispatch => {
  const res = await axios.post(
    `${API_URL}/albums/${albumId}/comments`,
    payload
  );
  dispatch({ type: ADD_COMMENT, payload: res.data });
};

export const deleteComment = commentId => async dispatch => {
  await axios.delete(`${API_URL}/comments/${commentId}`);
  dispatch({ type: REMOVE_COMMENT, payload: commentId });
};

export const updateComment = (commentId, payload) => async dispatch => {
  const res = await axios.put(`${API_URL}/comments/${commentId}`, payload);
  dispatch({ type: UPDATE_COMMENT, payload: res.data });
};

/**
 * SELECTORS
 */

export const getComments = state => state.comments;

export const getPendingComments = createSelector(getComments, comments =>
  comments.filter(comment => !comment.done)
);

export const getCommentsBySpreadId = createSelector(getComments, comments => {
  return comments.reduce(
    (acc, comment) => ({
      ...acc,
      [comment.spread_id]: [...(acc[comment.spread_id] || []), comment],
    }),
    {}
  );
});
