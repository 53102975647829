import React from 'react';
import { func, bool, string, arrayOf } from 'prop-types';
import { connect } from 'react-redux';

import {
  getSelectedElements,
  getSelectedStickers,
  getWorkspace,
} from '../../selectors/legacy';
import {
  getBoundingBox,
  getSizeFromImageObject,
  getStickerImageBox,
} from '../../util/geometry';
import Point from '../../util/Point';
import { updateControls } from '../../modules/controls';
import { updateElements, moveElementsToSpread } from '../../actions/workspace';
import { findTargetSpreadIdOnWorkspace } from '../../util/generators';
import { updateStickersByObject } from '../../modules/sections';
import { historyAnchor } from '../../modules/history';
import { getImage } from '../../selectors/images';
import { NodeShape, WorkspaceShape, StickerShape, SizeShape } from '../shapes';

function getElementNode(id, selectInside) {
  const selector = `[data-id='${id}']${selectInside ? ' .inside' : ''}`;
  return document.querySelector(selector);
}

class AbstractOperation extends React.Component {
  // targets parent group
  parentNode;

  // mouse start coordinates in targets parent group
  parentStart;

  // array of objects, holds duplicates of sticker/element props. prefixed props get converted (cx > x)
  startProps;

  // pivot coordinates in targets parent group
  pivot;

  stickerMode;

  getPivot(pivotName, { width, height }) {
    switch (pivotName) {
      default:
      case 'center':
        return new Point(width / 2, height / 2);
      case 'topLeft':
        return new Point(0, 0);
      case 'topRight':
        return new Point(width, 0);
      case 'bottomLeft':
        return new Point(0, height);
      case 'bottomRight':
        return new Point(width, height);
    }
  }

  onStart = (e, pivotName = null, moveElements = false) => {
    // Respond to left mouse button only
    if (e.button !== 0) {
      return;
    }

    const {
      dispatch,
      selectedElements,
      selectedStickers,
      selectInside,
      selectedImageSize,
    } = this.props;

    this.moveElements = moveElements;

    const defaults = {
      x: 0,
      y: 0,
      rotation: 0,
      scale: 1,
      width: 100,
      height: 100,
    };
    let targets;

    if (selectedStickers.length === 1 && selectedImageSize) {
      this.parentNode = getElementNode(selectedStickers[0].id, false);
      this.localNode = getElementNode(selectedStickers[0].id, true);

      const p = this.getPivot(pivotName, selectedImageSize);
      this.pivotGlobal = p.localToGlobal(this.localNode);
      const p2 = this.getPivot('center', selectedImageSize);
      this.pivotGlobalAlt = p2.localToGlobal(this.localNode);

      // this.parentNode = stickerNode.closest(".sticker-root");
      // this.parentNode = elementNode.closest("[transform]"); // !!!
      this.stickerMode = true;
      targets = selectedStickers;
      this.prefixedProps = true;
    } else if (selectedElements.length) {
      this.prefixedProps = selectInside;

      const { id } = selectedElements[0].props;
      const elementNode = getElementNode(id, false);
      if (!elementNode) return;
      if (selectInside) {
        this.parentNode = elementNode;
        this.localNode = getElementNode(id, true);
      } else {
        this.parentNode = elementNode.parentNode.closest('[transform]');
        this.localNode = elementNode;
      }

      this.stickerMode = false;
      targets = selectedElements;
      if (selectedElements.length === 1 && selectedImageSize) {
        const p = this.getPivot(pivotName, selectedImageSize);
        this.pivotGlobal = p.localToGlobal(this.localNode);
        const p2 = this.getPivot('center', selectedImageSize);
        this.pivotGlobalAlt = p2.localToGlobal(this.localNode);
      } else {
        const bounds = getBoundingBox(selectedElements);
        this.pivotGlobal = this.getPivot(pivotName, bounds);
        this.pivotGlobal.x += bounds.x;
        this.pivotGlobal.y += bounds.y;
        this.pivotGlobalAlt = this.getPivot('center', bounds);
        this.pivotGlobalAlt.x += bounds.x;
        this.pivotGlobalAlt.y += bounds.y;
      }
    } else {
      return;
    }

    this.pivot = this.pivotGlobal.globalToLocal(this.parentNode);
    this.pivotAlt = this.pivotGlobalAlt.globalToLocal(this.parentNode);

    dispatch(updateControls({ pivot: e.altKey ? this.pivotAlt : this.pivot }));

    const globalStart = new Point(e.clientX, e.clientY);
    this.parentStart = globalStart.globalToLocal(this.parentNode);
    this.localStart = globalStart.globalToLocal(this.localNode);

    this.startProps = targets.reduce((acc, cur) => {
      const props = this.stickerMode ? cur : cur.props;
      const data = Object.keys(defaults).reduce(
        (acc, cur) => {
          let key;
          if (
            this.prefixedProps &&
            (cur === 'x' ||
              cur === 'y' ||
              cur === 'rotation' ||
              cur === 'scale')
          ) {
            key = `c${cur}`;
          } else {
            key = cur;
          }
          if (typeof props[key] !== 'undefined') {
            acc[cur] = props[key];
          }
          return acc;
        },
        { ...defaults }
      );
      data.pivotLocal = this.pivotGlobal.globalToLocal(
        getElementNode(props.id, selectInside)
      );
      data.pivotLocalAlt = this.pivotGlobalAlt.globalToLocal(
        getElementNode(props.id, selectInside)
      );
      acc[props.id] = data;
      return acc;
    }, {});

    document.addEventListener('mousemove', this.mouseMove);
    document.addEventListener('mouseup', this.mouseUp);
    dispatch(updateControls({ operationActive: true }));
  };

  mouseMove = e => {
    if (e.clientX === this.lastClientX && e.clientY === this.lastClientY) {
      return;
    }

    const { dispatch, onUpdate, selectInside } = this.props;

    this.lastClientX = e.clientX;
    this.lastClientY = e.clientY;
    const parentPointer = new Point(e.clientX, e.clientY).globalToLocal(
      this.parentNode
    );
    if (!parentPointer) return;
    const parentDelta = parentPointer.minus(this.parentStart);

    const localPointer = new Point(e.clientX, e.clientY).globalToLocal(
      this.localNode
    );
    const localDelta = localPointer.minus(this.localStart);

    // const parentPointer = new Point(e.clientX, e.clientY).globalToLocal(this.parentNode);
    // const currentDelta   = parentPointer.minus(this.parentStart);

    const spreadNode = this.parentNode.closest('.spread');

    const data = {
      parentPointer,
      parentDelta,
      localPointer,
      localDelta,
      selectInside,
      parentStart: this.parentStart,
      pivot: e.altKey ? this.pivotAlt : this.pivot,
      parentNode: this.parentNode,
      spreadNode,
    };

    const deltas = Object.keys(this.startProps).reduce((acc, id, index) => {
      const startProps = this.startProps[id];

      let delta = onUpdate(e, startProps, data, index);

      if (typeof delta.x === 'undefined' || typeof delta.y === 'undefined') {
        const props = { ...startProps, ...delta };

        const elementNode = getElementNode(
          id,
          this.stickerMode || selectInside
        );
        elementNode.setAttribute(
          'transform',
          `translate(${props.x},${props.y}) scale(${props.scale}) rotate(${props.rotation})`
        );

        let diff;
        if (e.altKey) {
          const pivotAfter = startProps.pivotLocalAlt
            .localToGlobal(elementNode)
            .globalToLocal(this.parentNode);
          diff = this.pivotAlt.minus(pivotAfter);
        } else {
          const pivotAfter = startProps.pivotLocal
            .localToGlobal(elementNode)
            .globalToLocal(this.parentNode);
          diff = this.pivot.minus(pivotAfter);
        }

        elementNode.setAttribute(
          'transform',
          `translate(${props.x + diff.x},${props.y + diff.y}) scale(${
            props.scale
          }) rotate(${props.rotation})`
        );

        delta = { x: props.x + diff.x, y: props.y + diff.y, ...delta };
      }

      delta = Object.keys(delta).reduce((acc, cur) => {
        let key;
        if (
          this.prefixedProps &&
          (cur === 'x' || cur === 'y' || cur === 'rotation' || cur === 'scale')
        ) {
          key = `c${cur}`;
        } else {
          key = cur;
        }
        acc[key] = delta[cur];
        return acc;
      }, {});

      acc[id] = delta;

      return acc;
    }, {});

    // update
    if (this.stickerMode) {
      dispatch(updateStickersByObject(deltas));
    } else {
      dispatch(updateElements(deltas));
    }
  };

  mouseUp = e => {
    const {
      dispatch,
      selectedElements,
      selectInside,
      workspace,
      isolation,
    } = this.props;

    document.removeEventListener('mousemove', this.mouseMove);
    document.removeEventListener('mouseup', this.mouseUp);

    e.stopPropagation();
    e.preventDefault();

    // todo: move objects to spreads, also after ungroup action
    if (!selectInside && this.moveElements) {
      const dropPoint = new Point(e.clientX, e.clientY).screenToSvg();
      const { spreadId } = findTargetSpreadIdOnWorkspace(dropPoint, workspace);
      const spreadDomNode = document.getElementById(spreadId);
      const propsDeltaMap = selectedElements.reduce((acc, element) => {
        let currentSpreadId = workspace.nodes[element.props.id].parent;

        // Loop until spread is found (only in isolation mode)
        while (workspace.nodes[currentSpreadId].type !== 'Spread') {
          currentSpreadId = workspace.nodes[currentSpreadId].parent;
        }

        if (currentSpreadId !== spreadId) {
          // Element has been moved to another spread
          const currentSpreadDomNode = document.getElementById(currentSpreadId);
          // Translate position to new spread
          let p = new Point(element.props.x, element.props.y);
          p = p.localToGlobal(currentSpreadDomNode);
          p = p.globalToLocal(spreadDomNode);
          acc[element.props.id] = { x: p.x, y: p.y };
        }
        return acc;
      }, {});

      const movedIds = Object.keys(propsDeltaMap);

      if (movedIds.length) {
        /* Todo: if an element in isolation mode is dragged onto another spread, it should probably be removed
           from the group and the groups size should be updated accordingly ("dynamic ungrouping"). Since this
           is not yet done, we simply reset the transform in this case. */
        if (isolation) {
          dispatch(updateElements(this.startProps));
        } else {
          dispatch(moveElementsToSpread(movedIds, spreadId, propsDeltaMap));
        }
      }
    }

    dispatch(historyAnchor());
    dispatch(updateControls({ operationActive: false }));
  };

  render() {
    const { render } = this.props;
    return render(this);
  }
}

AbstractOperation.defaultProps = {
  isolation: null,
  selectedImageSize: null,
};

AbstractOperation.propTypes = {
  render: func.isRequired,
  dispatch: func.isRequired,
  onUpdate: func.isRequired,
  isolation: string,
  selectInside: bool.isRequired,
  selectedElements: arrayOf(NodeShape).isRequired,
  selectedStickers: arrayOf(StickerShape).isRequired,
  selectedImageSize: SizeShape,
  workspace: WorkspaceShape.isRequired,
};

const mapStateToProps = state => {
  const selectedElements = getSelectedElements(state);
  const selectedStickers = getSelectedStickers(state);

  let selectedImageSize = null;

  if (selectedElements.length === 1) {
    const [{ type, props }] = selectedElements;
    if (type === 'Image') {
      const { image } = props;
      selectedImageSize = getSizeFromImageObject(getImage(state, image));
    }
  } else if (selectedStickers.length === 1) {
    const [sticker] = selectedStickers;
    const { image } = sticker;
    selectedImageSize = getSizeFromImageObject(
      getImage(state, image),
      getStickerImageBox(sticker)
    );
  }

  return {
    selectedElements,
    selectedStickers,
    selectedImageSize,
    selectInside: state.selection.selectInside,
    workspace: getWorkspace(state),
    isolation: state.controls.isolation,
  };
};

export default connect(mapStateToProps)(AbstractOperation);
