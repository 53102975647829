import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useKeyPress, useEvent } from 'react-use';

import { updateControls } from '../../../modules/controls';
import useViewportState from './useViewportState';

export default function useSpacebarPan(viewportRef) {
  const { pan, zoom, setPan } = useViewportState();
  const [spacebarPressed] = useKeyPress(event => event.key === ' ');
  const [mouseDownPan, setMouseDownPan] = useState(null);
  const [mouseDownPointer, setMouseDownPointer] = useState(null);
  const spacebarPanActive = !!mouseDownPointer;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateControls({ spacebarPanActive }));
  }, [dispatch, spacebarPanActive]);

  const handleMouseDown = ({ clientX, clientY, button }) => {
    // Return early if not left click
    if (button !== 0) {
      return;
    }
    setMouseDownPan(pan);
    setMouseDownPointer({ clientX, clientY });
  };

  const handleMouseMove = ({ clientX, clientY }) => {
    if (!mouseDownPan || !mouseDownPointer) {
      return;
    }
    const nextPan = {
      x: mouseDownPan.x + (mouseDownPointer.clientX - clientX) / zoom,
      y: mouseDownPan.y + (mouseDownPointer.clientY - clientY) / zoom,
    };
    setPan(nextPan);
  };

  const handleMouseUp = () => {
    setMouseDownPan(null);
    setMouseDownPointer(null);
  };

  useEvent(
    'mousedown',
    spacebarPressed && handleMouseDown,
    viewportRef.current
  );
  useEvent('mousemove', mouseDownPointer && handleMouseMove);
  useEvent('mouseup', mouseDownPointer && handleMouseUp);

  return {
    spacebarPanActive,
    spacebarPressed,
  };
}
