import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import { Menu, Label, Icon } from 'semantic-ui-react';

import { isMac } from '../../../util';
import { HotkeyShape } from '../../shapes';

/**
 * Convert a list of hotkey-objects to their readable string-representation
 *
 * @param {Array.<{key: String, ctrlKey: Boolean, shiftKey: Boolean}>} hotkeys - List of the hotkey-objects
 */
export function stringifyHotkeys(hotkeys) {
  let stringifiedHotkey = hotkeys
    .map(hotkey => {
      const modifierMap = {
        ctrlKey: isMac ? '⌘' : 'Strg',
        shiftKey: 'Shift',
        altKey: 'Alt',
        key: hotkey.key.toUpperCase(),
      };

      return ['ctrlKey', 'shiftKey', 'altKey', 'key']
        .filter(key => hotkey[key])
        .map(key => modifierMap[key])
        .join(' + ');
    })
    .join(', ');

  if (stringifiedHotkey === 'DELETE, BACKSPACE') {
    stringifiedHotkey = 'Delete';
  }
  return stringifiedHotkey;
}

function ActionItem({ label, hotkeys, iconName, onClick, className }) {
  const stringifiedHotkeys = hotkeys && stringifyHotkeys(hotkeys);
  return (
    <Menu.Item onClick={onClick} className={className}>
      {label}
      {iconName && <Icon name={iconName} />}
      {stringifiedHotkeys && <Label>{stringifiedHotkeys}</Label>}
    </Menu.Item>
  );
}

ActionItem.defaultProps = {
  className: null,
  hotkeys: [],
  iconName: null,
  label: null,
};

ActionItem.propTypes = {
  className: string,
  hotkeys: arrayOf(HotkeyShape),
  iconName: string,
  label: string,
  onClick: func.isRequired,
};

export default ActionItem;
