import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Undoable from 'redux-live-undo';

import colors from './colors';
import texts from './texts';
import images from './images';
import workspace from './workspace';
import sections from './sections';
import controls from './controls';
import albums from './albums';
import user from './user';
import stock from './stock';
import menu from './menu';
import templates from './templates';
import tags from './tags';
import loading from './loading';
import selection from './selection';
import groups from './groups';
import draft from './draft';
import history from './history';
import sync from './sync';
import comments from './comments';

export default combineReducers({
  router: routerReducer,
  colors,
  texts,
  workspaceAndSections: Undoable({
    workspace,
    sections,
  }),
  controls,
  images,
  albums,
  user,
  stock,
  templates,
  tags,
  loading,
  selection,
  menu,
  groups,
  draft,
  history,
  sync,
  comments,
});
