import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import { createComment } from '../../modules/comments';
import UnzoomedContent from '../../components/svg/UnzoomedContent';

function AlbumCommentForm({ position, onClose, albumId }) {
  const { viewportX, viewportY } = position;
  const textFieldRef = useRef(null);
  const [content, setContent] = useState('');
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    textFieldRef.current?.focus();
  }, []);

  function handleChange({ target: { value } }) {
    setContent(value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { spreadId, spreadX, spreadY } = position;

    await dispatch(
      createComment(albumId, {
        content,
        x: spreadX,
        y: spreadY,
        spread_id: spreadId,
      })
    );

    onClose();
  }

  return (
    <g transform={`translate(${viewportX} ${viewportY})`}>
      <UnzoomedContent>
        <foreignObject width="300">
          <div className="pulsating-circle" />
          <div className="comment-form-wrapper qa-comment-form">
            <Form
              onClick={e => e.stopPropagation()}
              onSubmit={event => handleSubmit(event)}
            >
              <Form.Field>
                {/* eslint-disable-next-line */}
                <label htmlFor="comment_content">Kommentar hinzufügen</label>
                <textarea
                  id="comment_content"
                  name="comment_content"
                  ref={textFieldRef}
                  value={content}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Button primary type="submit">
                  Speichern
                </Button>
                <Button onClick={onClose}>abbrechen</Button>
              </Form.Field>
            </Form>
          </div>
        </foreignObject>
      </UnzoomedContent>
    </g>
  );
}

AlbumCommentForm.propTypes = {
  position: PropTypes.shape({
    viewportX: PropTypes.number.isRequired,
    viewportY: PropTypes.number.isRequired,
    spreadId: PropTypes.string.isRequired,
    spreadX: PropTypes.number.isRequired,
    spreadY: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  albumId: PropTypes.string.isRequired,
};

export default AlbumCommentForm;
