// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { Menu, Button, Icon, Loader } from 'semantic-ui-react';
import Beforeunload from 'react-beforeunload';
import { Prompt } from 'react-router';

import {
  getTargetSpreadIndex,
  makeLoadingSelector,
} from '../../selectors/legacy';
import { saveAlbum } from '../../modules/albums';
import { logout } from '../../modules/user';
import { isDevelopment } from '../../util/index';
import { historyUndo, historyRedo } from '../../modules/history';

class GlobalNavigation extends React.Component {
  unsavedAlbumMessage =
    'Es wurden Änderungen vorgenommen - Album trotzdem schließen?';

  logoutClick = () => {
    const { dispatch } = this.props;
    dispatch(logout());
    document.location = '/';
  };

  beforeUnload = e => {
    const { changed } = this.props;
    if (changed && !isDevelopment()) {
      e.preventDefault();
      return this.unsavedAlbumMessage;
    }
    return null;
  };

  render() {
    const {
      canUndo,
      canRedo,
      changed,
      dispatch,
      loading,
      saving,
      style,
    } = this.props;

    return (
      <Beforeunload onBeforeunload={this.beforeUnload}>
        <Menu
          secondary
          style={style}
          className="global-navigation--menu qa-global-navigation"
          /* Prevent the buttons from getting the focus */
          onMouseDownCapture={event => event.preventDefault()}
        >
          <Menu.Item>
            <Button
              className="qa-undo-btn"
              disabled={!canUndo}
              onClick={() => dispatch(historyUndo())}
            >
              <Icon name="reply" />
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              className="qa-redo-btn"
              disabled={!canRedo}
              onClick={() => dispatch(historyRedo())}
            >
              <Icon name="share" />
            </Button>
          </Menu.Item>

          <Menu.Item position="right">
            <Menu secondary>
              {loading && (
                <Menu.Item className="spinner qa-loading-spinner">
                  <Loader active />
                </Menu.Item>
              )}
              <Menu.Item>
                <Button
                  primary
                  onClick={() => dispatch(saveAlbum())}
                  className="qa-save-btn"
                  disabled={saving || !changed}
                >
                  {saving ? (
                    <>Speichere...</>
                  ) : (
                    <>
                      <Icon name="save" />
                      {changed ? 'Speichern' : 'Gespeichert'}
                    </>
                  )}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button onClick={this.logoutClick} icon="sign out" />
              </Menu.Item>
            </Menu>
          </Menu.Item>
        </Menu>

        {!isDevelopment() && (
          <Prompt when={changed} message={() => this.unsavedAlbumMessage} />
        )}
      </Beforeunload>
    );
  }
}

const mapStateToProps = state => ({
  changed: state.history.changed,
  selectedIds: state.controls.selectedIds,
  targetSpreadIndex: getTargetSpreadIndex(state),
  canUndo: state.workspaceAndSections.past.length > 2,
  canRedo: state.workspaceAndSections.future.length > 0,
  currentAlbum: state.albums.currentAlbum,
  saving: state.albums.saving,
  loading: makeLoadingSelector(false)(state),
});

export default connect(mapStateToProps)(GlobalNavigation);
