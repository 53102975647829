import React from 'react';
import { useEvent } from 'react-use';
import { arrayOf, func } from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { itemTypes } from '../../../constants';
import {
  StickerShape,
  TagShape,
  ImageObjectShape,
  ApplicableActionsShape,
  ContextMenuDataShape,
} from '../../shapes';
import TagsSubmenu from './TagsSubmenu/index.container';
import ImageActionItems from './ImageActionItems';
import ActionItems from './ActionItems';
import StickerPropItems from './StickerPropItems';
import useClientRect from '../../../hooks/useClientRect';

// Compute context menu position based on the mouse click coordinates
// the menu size and the window size.
function computeMenuPosition(rect, clickX, clickY) {
  const { width, height } = rect;

  const clientWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const clientHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const left = clickX > clientWidth - width ? clickX - width : clickX;
  const top =
    clickY > clientHeight - height ? Math.max(0, clickY - height) : clickY;

  return [left, top];
}

function ContextMenu({
  contextMenuData,

  executeWithSelection,
  applicableActions,

  setStickerLogo,
  convertToStock,
  updateSelectedStickers,
  historyAnchor,
  patchExistingImage,
  hideContextMenu,
  executeActionByName,

  stickerTags,
  sticker,
  imageObject,
}) {
  // Hide the menu when clicking on the menu itself or outside
  useEvent('click', contextMenuData ? hideContextMenu : null, document);
  useEvent('touchstart', contextMenuData ? hideContextMenu : null, document);

  // Get the size of this element to calculate the menu position
  const [rect, refWrapper] = useClientRect({ width: 0, height: 0 });

  if (!contextMenuData) {
    return null;
  }

  const { id, itemType, clickX, clickY } = contextMenuData;

  const [left, top] = computeMenuPosition(rect, clickX, clickY);
  const style = { left, top };

  const actionItemProps = {
    executeWithSelection,
    historyAnchor,
    applicableActions,
    executeActionByName,
    hideContextMenu,
  };

  return (
    <div
      className="context-menu qa-context-menu-wrapper"
      style={style}
      ref={refWrapper}
      /*
       This prevents the contents of this menu from "stealing" the focus. Used here to capture the event
       before it delegates to the menu items, since these items don't support a custom onMouseDown handler:
       https://react.semantic-ui.com/collections/menu/
       */
      onMouseDownCapture={event => event.preventDefault()}
    >
      <Menu vertical className={`qa-${itemType}-context-menu`}>
        {itemType === itemTypes.image && imageObject && (
          <ImageActionItems
            setStickerLogo={setStickerLogo}
            convertToStock={convertToStock}
            patchExistingImage={patchExistingImage}
            imageObject={imageObject}
            hideContextMenu={hideContextMenu}
          />
        )}

        {itemType === itemTypes.element && (
          <ActionItems
            header="Element"
            itemType={itemTypes.element}
            {...actionItemProps}
          />
        )}

        {itemType === itemTypes.sticker && (
          <>
            <StickerPropItems
              sticker={sticker}
              stickerTags={stickerTags}
              updateSelectedStickers={updateSelectedStickers}
              executeWithSelection={executeWithSelection}
            />
            <ActionItems
              header="Sticker"
              itemType={itemTypes.sticker}
              {...actionItemProps}
            />
          </>
        )}

        {itemType === itemTypes.section && (
          <ActionItems
            header="Team"
            itemType={itemTypes.section}
            {...actionItemProps}
          />
        )}

        {itemType === itemTypes.template && (
          <TagsSubmenu itemType={itemType} itemId={id} />
        )}

        {(itemType === itemTypes.element || itemType === itemTypes.spread) && (
          <ActionItems
            header="Seite"
            itemType={itemTypes.spread}
            {...actionItemProps}
          />
        )}
      </Menu>
    </div>
  );
}

ContextMenu.defaultProps = {
  contextMenuData: null,
  applicableActions: null,
  stickerTags: null,
  sticker: null,
  imageObject: null,
};

ContextMenu.propTypes = {
  // mapStateToProps
  contextMenuData: ContextMenuDataShape,
  applicableActions: ApplicableActionsShape,
  stickerTags: arrayOf(TagShape),
  sticker: StickerShape,
  imageObject: ImageObjectShape,

  // mapDispatchToProps
  setStickerLogo: func.isRequired,
  convertToStock: func.isRequired,
  updateSelectedStickers: func.isRequired,
  historyAnchor: func.isRequired,
  patchExistingImage: func.isRequired,
  hideContextMenu: func.isRequired,
  executeActionByName: func.isRequired,

  // mergeProps
  executeWithSelection: func.isRequired,
};

export default ContextMenu;
