// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import { Modal, Header, Button, Message } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import store from '../store';
import { fetchTemplates } from '../modules/templates';
import {
  setAlbum,
  fetchAlbum,
  fetchAlbumAndMergeCrashSave,
} from '../modules/albums';
import { albumChanged } from '../modules/history';
import { fetchStocks } from '../actions/images';
import { fetchTags } from '../modules/tags';

class ErrorBoundary extends React.Component {
  state = {
    errorInfo: null,
    crashSaveDataData: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    window.addEventListener('error', this.windowError);

    // TODO: This does not belong into the ErrorBoundary
    Promise.all([
      dispatch(fetchStocks()),
      dispatch(fetchTemplates()),
      dispatch(fetchTags()),
    ]).then(() => {
      this.checkCrashSaveOrLoadAlbum();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.windowError);
  }

  checkCrashSaveOrLoadAlbum() {
    const { albumId } = this.props;
    let crashSaveData = localStorage.getItem(`crashsave_${albumId}`);
    if (crashSaveData) {
      try {
        crashSaveData = JSON.parse(crashSaveData);
      } catch (e) {
        crashSaveData = null;
      }
    }
    if (crashSaveData) {
      this.setState({ crashSaveData });
    } else {
      this.loadAlbum();
    }
  }

  loadAlbum = (crashSaveData = null) => {
    const { dispatch, albumId } = this.props;

    dispatch(setAlbum(albumId));

    if (crashSaveData) {
      this.setState({ crashSaveData: null });
      dispatch(fetchAlbumAndMergeCrashSave(albumId, crashSaveData));
      dispatch(albumChanged());
    } else {
      this.setState({ crashSaveData: null });
      dispatch(fetchAlbum(albumId));
    }
  };

  executeCrashSave = () => {
    const state = store.getState();

    const {
      workspaceAndSections: {
        present: { workspace, sections },
      },
      colors,
    } = state;

    const data = {
      colors,
      sections,
      workspace,
    };

    const {
      albums: { currentAlbum },
    } = state;
    localStorage.setItem(`crashsave_${currentAlbum}`, JSON.stringify(data));
  };

  deleteCrashSave = () => {
    const { albumId } = this.props;
    localStorage.removeItem(`crashsave_${albumId}`);
    this.loadAlbum();
    this.setState({ crashSaveData: null });
  };

  handleError(error) {
    console.error(error);
    Sentry.captureException(error);
    this.executeCrashSave();
    this.setState({ errorInfo: error.message });
  }

  componentDidCatch(error) {
    this.handleError(error);
  }

  windowError = ({ error }) => {
    // An event with message "ResizeObserver loop limit exceeded" and no event.error shows up since the viewport-backport
    // This is probably because of the weird "shrinking scrollbar" behavior during load and should be fixed when the editor-layout
    // is cleaned up.
    // Todo: Avoid workaround (see https://stickerstars.atlassian.net/browse/STC-344)
    if (error) {
      this.handleError(error);
    }
  };

  render() {
    const { children } = this.props;
    const { errorInfo, crashSaveData } = this.state;

    if (crashSaveData) {
      return (
        <Modal open style={{ maxWidth: 500 }}>
          <Header>Es ist ein Crash-Save vorhanden</Header>
          <Modal.Content>
            <Button onClick={() => this.loadAlbum(crashSaveData)}>
              Crash-Save anzeigen
            </Button>
            <Button onClick={() => this.loadAlbum()}>
              Crash-Save ignorieren und Server-Stand laden
            </Button>
            <Button onClick={() => this.deleteCrashSave()} color="red">
              Crash-Save löschen und Server-Stand laden
            </Button>
          </Modal.Content>
        </Modal>
      );
    }

    if (errorInfo) {
      return (
        <Modal open style={{ maxWidth: 500 }}>
          <Header>Crashed....</Header>
          <Modal.Content>
            <p>Der letzte Stand wurde aber gespeichert.</p>
            <Message negative>
              <p>
                <code>{errorInfo}</code>
              </p>
            </Message>
            <Button onClick={() => window.location.reload()}>Neu Laden</Button>
          </Modal.Content>
        </Modal>
      );
    }

    return children;
  }
}

export default connect()(ErrorBoundary);
