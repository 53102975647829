import React from 'react';
import { number } from 'prop-types';
import { useSelector } from 'react-redux';

import { dimensions } from '../../constants';
import AbstractOperation from './AbstractOperation';

const ResizeOperation = ({ width, height, zoom }) => {
  const gridEnabled = useSelector(state => state.controls.gridEnabled);

  function update(
    e,
    { width: newWidth, height: newHeight },
    { localDelta },
    dim = null
  ) {
    let delta = {
      width: newWidth + localDelta.x,
      height: newHeight + localDelta.y,
    };

    if (gridEnabled !== e.altKey) {
      const s = dimensions.gridSize;
      delta.width = Math.round(delta.width / s) * s;
      delta.height = Math.round(delta.height / s) * s;
    }

    if (delta.width < 1) {
      delta.width = 1;
    }

    if (delta.height < 1) {
      delta.height = 1;
    }

    if (dim) {
      delta = { [dim]: delta[dim] };
    }

    return delta;
  }

  function updatePosition(
    e,
    { x, y, width: newWidth, height: newHeight },
    { parentDelta },
    dim = null
  ) {
    const delta = {
      x: x + parentDelta.x,
      y: y + parentDelta.y,
    };
    if (gridEnabled !== e.altKey) {
      const s = dimensions.gridSize;
      delta.x = Math.round(delta.x / s) * s;
      delta.y = Math.round(delta.y / s) * s;
    }
    if (dim === 'x') {
      delta.y = y;
      delta.width = newWidth + (x - delta.x);
    } else if (dim === 'y') {
      delta.x = x;
      delta.height = newHeight + (y - delta.y);
    }
    return delta;
  }

  const handleRadius = 5 / zoom;
  const cx = width / 2;
  const cy = height / 2;

  return (
    <>
      <AbstractOperation
        onUpdate={(e, props, data) => updatePosition(e, props, data, 'x')}
        render={({ onStart }) => (
          <circle
            r={handleRadius}
            cx={0}
            cy={cy}
            className="handle resize"
            onMouseDown={onStart}
            style={{ cursor: 'ew-resize' }}
          />
        )}
      />
      <AbstractOperation
        onUpdate={(e, props, data) => updatePosition(e, props, data, 'y')}
        render={({ onStart }) => (
          <circle
            r={handleRadius}
            cx={cx}
            cy={0}
            className="handle resize"
            onMouseDown={onStart}
            style={{ cursor: 'ns-resize' }}
          />
        )}
      />
      <AbstractOperation
        onUpdate={(e, props, data) => update(e, props, data, 'width')}
        render={({ onStart }) => (
          <circle
            r={handleRadius}
            cx={width}
            cy={cy}
            className="handle resize qa-handle-resize-width"
            onMouseDown={onStart}
            style={{ cursor: 'ew-resize' }}
          />
        )}
      />
      <AbstractOperation
        onUpdate={(e, props, data) => update(e, props, data, 'height')}
        render={({ onStart }) => (
          <circle
            r={handleRadius}
            cx={cx}
            cy={height}
            className="handle resize qa-handle-center-bottom"
            onMouseDown={onStart}
            style={{ cursor: 'ns-resize' }}
          />
        )}
      />
    </>
  );
};

ResizeOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  zoom: number.isRequired,
};

export default ResizeOperation;
