import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { AppearanceInputModeShape } from '../../shapes';
import NestedOverlayTrigger from '../NestedOverlayTrigger';
import AppearanceInputForm from './AppearanceInputForm';

function AppearanceInput(props) {
  const { mode } = props;

  return (
    <NestedOverlayTrigger overlay={<AppearanceInputForm {...props} />}>
      <Button className={`qa-toolbar-${mode}-btn`} size="mini">
        <Icon className={`customicon customicon-${mode}`} />
      </Button>
    </NestedOverlayTrigger>
  );
}

AppearanceInput.propTypes = {
  mode: AppearanceInputModeShape.isRequired,
};

export default AppearanceInput;
