import React, { useState } from 'react';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { List, Icon } from 'semantic-ui-react';

import { mapIconToElementType, getNodeDescription } from './util';
import { IdListShape, RawDraftContentStateShape } from '../../../shapes';
import {
  getSelectedElementIds,
  getWorkspace,
  getInteractiveParentIds,
} from '../../../../selectors/legacy';
import { elementSelect } from '../../../../modules/selection';
import { updateElements } from '../../../../actions/workspace';

export const TreeNode = ({
  children,
  isDragging,
  isInteractive,
  isLocked,
  isSelected,
  onLock,
  onSelect,
  symbol,
  text,
  type,
}) => {
  const [hovered, setHovered] = useState(false);
  const showLock = isInteractive && (isLocked || hovered);

  const iconName = mapIconToElementType(type);
  const nodeDescription = getNodeDescription(type, text, symbol).slice(0, 20);

  return (
    <List.Item
      className={isSelected ? 'active' : ''}
      style={{ opacity: isDragging ? 0.5 : null }}
    >
      <List.Icon name={iconName} className="node-icon" />
      <List.Content>
        <List.Header
          onClick={isInteractive ? onSelect : () => {}}
          style={{ cursor: 'pointer' }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div>{nodeDescription}</div>
          {showLock && (
            <Icon
              onClick={onLock}
              name={!isLocked ? 'unlock alternate' : 'lock'}
              className={`lock-icon qa-lock-node${isLocked ? ' locked' : ''}`}
            />
          )}
        </List.Header>
        {children.length > 0 && (
          <List.List>
            {children
              .slice()
              .reverse()
              .map(childId => (
                <ConnectedTreeNode key={childId} id={childId} />
              ))}
          </List.List>
        )}
      </List.Content>
    </List.Item>
  );
};

TreeNode.defaultProps = {
  children: [],
  isDragging: false,
  symbol: undefined,
  text: undefined,
};

TreeNode.propTypes = {
  children: IdListShape,
  isDragging: bool,
  isInteractive: bool.isRequired,
  isLocked: bool.isRequired,
  isSelected: bool.isRequired,
  onSelect: func.isRequired,
  onLock: func.isRequired,
  symbol: string,
  text: RawDraftContentStateShape,
  type: string.isRequired,
};

const mapStateToProps = (state, { id, targetNodeId }) => {
  const { nodes } = getWorkspace(state);
  const { [id]: node } = nodes;
  const {
    type,
    props: { locked, symbol, text },
    children,
  } = node;

  const selectedIds = getSelectedElementIds(state);
  const isInteractive = getInteractiveParentIds(state).includes(targetNodeId);

  return {
    children,
    type,
    symbol,
    text,
    targetNodeId,
    isLocked: !!locked,
    isSelected: selectedIds.includes(id),
    selectedIds,
    isInteractive,
  };
};

const mapDispatchToProps = {
  elementSelect,
  updateElements,
};

export const mergeProps = (
  { isLocked, selectedIds, ...stateProps },
  { elementSelect, updateElements }, // eslint-disable-line no-shadow
  { id, isDragging }
) => ({
  ...stateProps,
  isDragging,
  isLocked,
  onSelect: () => elementSelect([id]),
  onLock: event => {
    event.stopPropagation();
    updateElements({
      [id]: {
        locked: !isLocked,
      },
    });
    elementSelect(selectedIds.filter(selectedId => selectedId !== id));
  },
});

const ConnectedTreeNode = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TreeNode);

export default ConnectedTreeNode;
