import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducer from './modules';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk];
const enhancers = composeEnhancers(applyMiddleware(...middlewares));

export default createStore(reducer, enhancers);
