import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { List } from 'semantic-ui-react';

import { dimensions, itemTypes } from '../../../constants';
import { TemplateShape } from '../../shapes';

function TemplateItem({
  active,
  detail,
  label,
  previewSize,
  onClick,
  template,
  showContextMenu,
  connectDragSource,
}) {
  let { width, height } = template.json.props;
  if (width > dimensions.pageWidth * 2) {
    width = dimensions.pageWidth * 2;
  }
  if (height > dimensions.pageHeight) {
    height = dimensions.pageHeight;
  }

  let size;
  switch (previewSize) {
    case 'detail':
    case 'small':
      size = 50;
      break;
    default:
    case 'medium':
      size = 100;
      break;
    case 'large':
      size = 150;
      break;
  }

  return connectDragSource(
    <button
      type="button"
      className={`image-item ${
        active ? 'highlight-selected highlight-pulse' : 'highlight-grey'
      } qa-template-${template.id}`}
      onClick={onClick}
      onContextMenu={event =>
        showContextMenu(event, itemTypes.template, template.id)
      }
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: template.svg_preview }}
      />
      {previewSize === 'detail' && (
        <List.Content>
          <List.Header>{label}</List.Header>
          {detail}
        </List.Content>
      )}
    </button>
  );
}

TemplateItem.defaultProps = {
  detail: '',
  previewSize: '',
  onClick: () => {},
  label: null,
};

TemplateItem.propTypes = {
  active: bool.isRequired,
  detail: string,
  label: node,
  previewSize: string,
  onClick: func,
  template: TemplateShape.isRequired,

  // DnD
  connectDragSource: func.isRequired,
};

export default TemplateItem;
