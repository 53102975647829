import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Icon, Button, Progress } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { API_URL } from '../../constants';
import CreateJob from './CreateJob';

export const secsToDuration = secs => {
  const date = new Date(null);
  date.setSeconds(secs);
  return date.toISOString().substr(11, 8);
};

const JobItem = ({
  id,
  params,
  state,
  eta,
  progress,
  file_url,
  onDelete,
  created_at,
  currentAlbum,
}) => {
  let icon;
  switch (state) {
    default:
    case 'queued':
      icon = 'hourglass half';
      break;
    case 'finished':
      icon = 'check';
      break;
    case 'exists':
      icon = 'save';
      break;
    case 'working':
      icon = 'sync';
      break;
    case 'error':
      icon = 'dont';
      break;
  }

  const filename = `${params.mode}.${params.format}`;

  let title;
  if (params.album_id !== currentAlbum) {
    title = `Anderes Album (ID: ${params.album_id}): ${filename} / ${id}`;
  } else {
    title = `${filename} / ${id}`;
  }

  return (
    <List.Item className={`job job-${state} qa-job qa-job-${state}`}>
      <div className="first">
        <Icon name={icon} />
      </div>
      <div className="middle">
        <div className="title">
          {title}
          <span className="time">{moment(created_at).fromNow()}</span>
        </div>
        {state === 'working' && (
          <Progress
            active
            indicating
            progress
            percent={progress}
            label={`${secsToDuration(eta)} verbleibend`}
          />
        )}
        {file_url && (
          <>
            <div className="links">
              <a href={file_url} target="_blank" rel="noopener noreferrer">
                <Button
                  className="qa-job-download-btn"
                  compact
                  size="small"
                  icon="download"
                />
              </a>
            </div>
          </>
        )}
      </div>
      <div className="last">
        <Button compact icon="delete" onClick={onDelete} />
      </div>
    </List.Item>
  );
};

JobItem.defaultProps = {
  file_url: null,
  progress: null,
  eta: null,
};

export const jobParamsShape = PropTypes.shape({
  album_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eta: null,
  mode: PropTypes.string,
  format: PropTypes.string,
});

JobItem.propTypes = {
  id: PropTypes.number.isRequired,
  params: jobParamsShape.isRequired,
  state: PropTypes.string.isRequired,
  eta: PropTypes.number,
  progress: PropTypes.number,
  file_url: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  created_at: PropTypes.string.isRequired,
  currentAlbum: PropTypes.string.isRequired,
};

export const JobsPanelComponent = ({ currentAlbum }) => {
  const [jobs, setJobs] = useState([]);

  const pollRunningRef = useRef(false);

  const poll = useCallback(() => {
    if (!pollRunningRef.current) {
      pollRunningRef.current = true;
      axios
        .get(`${API_URL}/jobs?album_id=${currentAlbum}`)
        .then(({ data: { jobs: jobsFromServer } }) => {
          setJobs(jobsFromServer);
          pollRunningRef.current = false;
        })
        .catch(() => {
          pollRunningRef.current = false;
        });
    }
  }, [pollRunningRef, setJobs, currentAlbum]);

  useEffect(() => {
    poll();
    const ivPoll = setInterval(poll, 5 * 1000);
    return () => clearInterval(ivPoll);
  }, [poll]);

  const abort = id => {
    axios.delete(`${API_URL}/jobs/${id}`).then(poll);
  };

  const addJob = job => {
    setJobs([job, ...jobs]);
  };

  return (
    <div className="flex grow scrolling qa-jobs-content">
      <CreateJob onJobCreate={addJob} />
      <List className="flex qa-current-jobs" celled>
        {jobs.map(job => (
          <List.Item key={`album-${job.id}`}>
            <JobItem
              {...job}
              onDelete={() => abort(job.id)}
              currentAlbum={currentAlbum}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
};

JobsPanelComponent.propTypes = {
  currentAlbum: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  currentAlbum: state.albums.currentAlbum,
});

export default connect(mapStateToProps)(JobsPanelComponent);
